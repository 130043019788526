import { APP_MENU_THEME } from "config/style";
import { Drawer as AntDrawer } from "antd";
import styled from "styled-components";

export const Drawer: typeof AntDrawer = styled(AntDrawer)`
  .ant-drawer-content {
    background: ${APP_MENU_THEME === "dark" ? "#5e32d2" : "#fff"};
  }
  .ant-drawer-body {
    padding: 0;
  }
` as any;
