import { SiderProps } from "antd/lib/layout";
import drawerData, { DrawerSubmenuItem } from "config/drawer";
import { Route } from "config/routes";
import { APP_MENU_THEME, APP_MENU_WIDTH } from "config/style";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import Logo from "library/components/Logo";
import {
  Item,
  ItemGroup,
  Menu,
  Sider,
  SiderContainer,
  SubMenu,
  LogoContainer,
} from "./AppSider.css";

interface Props {
  siderProps: SiderProps;
  onLinkClick?: () => void;
}

const routeMatch = (pathname: string, { path, routes }: Route) => {
  const routesToMatch = routes ? routes.map(route => route.path) : path;
  const match = matchPath(pathname, {
    path: routesToMatch,
    exact: true,
    strict: true,
  });
  return match;
};

const getMatchingRouteIndex = (pathname: string) => {
  return drawerData.reduce((prev, { items, route }, index) => {
    // First level
    if (route) {
      const match = routeMatch(pathname, route);
      if (match) {
        prev.index = index;
      }
    }
    // Second level
    else if (items) {
      items.forEach(({ route }, subIndex) => {
        if (route) {
          const match = routeMatch(pathname, route);
          if (match) {
            prev.index = index;
            prev.subIndex = subIndex;
          }
        }
      });
    }
    return prev;
  }, {} as { index: number; subIndex?: number });
};

const getSubItems = (items: DrawerSubmenuItem[], index: number) => {
  return items.reduce((elems, { title, route }, subIndex) => {
    if (route) {
      elems.push(
        <Item key={`${index}-${subIndex}`}>
          <Link to={route.path}>{title}</Link>
        </Item>,
      );
    }
    return elems;
  }, [] as JSX.Element[]);
};

const AppSider: React.FC<Props> = props => {
  const siderProps: SiderProps = {
    collapsedWidth: "0",
    collapsible: true,
    theme: APP_MENU_THEME,
    width: APP_MENU_WIDTH,
    ...props.siderProps,
  };

  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState<string>();
  const [openKeys, setOpenKeys] = useState<string>();

  useEffect(() => {
    const matchingRoute = getMatchingRouteIndex(location.pathname);
    setOpenKeys(`${matchingRoute.index}`);
    setSelectedKeys(
      isNil(matchingRoute.subIndex)
        ? undefined
        : `${matchingRoute.index}-${matchingRoute.subIndex}`,
    );
  }, [location.pathname]);

  const items = drawerData.reduce((elems, { items, route, title }, index) => {
    if (route) {
      // First level items
      elems.push(
        <Item key={`${index}`}>
          <Link to={route.path}>{title}</Link>
        </Item>,
      );
    } else if (items) {
      // Submenus
      const subItems = getSubItems(items, index);
      elems.push(
        <SubMenu
          key={`${index}`}
          title={title}
          onTitleClick={() => {
            if (openKeys === `${index}`) setOpenKeys(undefined);
            else setOpenKeys(`${index}`);
          }}
        >
          <ItemGroup>{subItems}</ItemGroup>
        </SubMenu>,
      );
    }
    return elems;
  }, [] as JSX.Element[]);

  return (
    <SiderContainer>
      <Sider {...siderProps}>
        <LogoContainer>
          <Logo dark={APP_MENU_THEME !== "dark"} />
        </LogoContainer>
        <Menu
          theme={APP_MENU_THEME}
          openKeys={openKeys ? [openKeys] : undefined}
          selectedKeys={selectedKeys ? [selectedKeys] : undefined}
          mode="inline"
        >
          {items}
        </Menu>
      </Sider>
    </SiderContainer>
  );
};

export default AppSider;
