import { Col, Result, Row } from "antd";
import React from "react";
import { ResultMemberState } from "./MemberSearchState";

interface MemberSearchResultProps {
  status: ResultMemberState;
  title: string;
}

const MemberSearchResult: React.FC<MemberSearchResultProps> = ({
  status,
  title,
}) => {
  return (
    <Row justify="center">
      <Col>
        <Result status={status} title={title} />
      </Col>
    </Row>
  );
};

export default MemberSearchResult;
