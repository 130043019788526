import client from "config/client";
import gql from "graphql-tag";
import { Query, QueryMemberSearchArgs } from "types/schema";

const MEMBER_SEARCH = gql`
  query MemberSearch($term: String!, $birthdate: String!) {
    memberSearch(term: $term, birthdate: $birthdate) {
      totalCount
      results {
        id
        firstName
        firstLastName
        secondLastName
        dateOfBirth
        gender
        antiquitySince
        hasActiveHealthPlan
        healthPlanUuid
        callToAction
      }
    }
  }
`;

export const memberSearch = async (vars: QueryMemberSearchArgs) => {
  const response = await client.query<Query, QueryMemberSearchArgs>({
    query: MEMBER_SEARCH,
    fetchPolicy: "no-cache",
    variables: vars,
  });
  return response.data.memberSearch;
};
