import React from "react";
import { Footer } from "./AppFooter.css";

const AppFooter: React.FC = () => {
  return (
    <Footer>
      Cualquier duda o sugerencia contacta a Sofía al 55 4161 4846 o por{" "}
      <a href="mailto:ayuda@sofiasalud.com">correo electrónico</a>.
    </Footer>
  );
};

export default AppFooter;
