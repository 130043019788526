import { Alert, Form, Input, Modal } from "antd";
import { useObserver } from "mobx-react";
import React, { useEffect, useState } from "react";
import Loader from "stores/Loader";
import { forgetPasswordUser } from "./ResetPasswordApi";

interface Props {
  email?: string;
  onClose?: () => void;
  visible?: boolean;
}

const ResetPassword: React.FC<Props> = ({
  email,
  onClose,
  visible = false,
}) => {
  const [loader] = useState(new Loader());
  const [userEmail, setUserEmail] = useState(email);

  useEffect(() => {
    setUserEmail(email);
  }, [email]);

  const handleOk = async () => {
    await loader.load(async () => {
      await forgetPasswordUser(userEmail);
    });

    if (!loader.hasError) {
      Modal.success({
        title: "Correo enviado con éxito",
        content: `Hemos enviado un correo a ${userEmail} con las instrucciones
        para cambiar tu contraseña.`,
      });

      if (onClose) onClose();
    }
  };

  const handleAfterClose = () => {
    loader.error = undefined;
  };

  return useObserver(() => (
    <Modal
      title="Cambiar contraseña"
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      afterClose={handleAfterClose}
      confirmLoading={loader.isLoading}
    >
      <Form layout="vertical">
        <Form.Item label="Correo eléctronico">
          <Input
            value={userEmail}
            onChange={ev => setUserEmail(ev.target.value)}
          />
        </Form.Item>
        {loader.hasError ? (
          <Alert message={loader.errorMessage?.content} type="error" showIcon />
        ) : undefined}
      </Form>
    </Modal>
  ));
};

export default ResetPassword;
