import React from "react";
import { Switch } from "react-router-dom";
import RouteWithSubRoutes from "../RouteWithSubRoutes";
import { Route } from "config/routes";

interface Props {
  routes: Route[];
}

/**
 * @todo - Type properly
 * @see {@link https://bit.ly/2Mj3X2f} Route config example
 */
const NestedSwitch: React.FC<Props> = props => {
  return (
    <Switch>
      {props.routes.map((route: any, index: number) => (
        <RouteWithSubRoutes key={index} {...route} />
      ))}
    </Switch>
  );
};

export default NestedSwitch;
