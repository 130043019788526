import UndefinedValue from "library/components/UndefinedValue";
import { isNil } from "lodash";
import React from "react";
import { Maybe } from "types/schema";

export function valueOrUndefined<T>(
  value: Maybe<T> | undefined,
  render?: (value: T, key?: string) => number | string | JSX.Element,
  key?: string,
) {
  if (isNil(value) || (value as any) === "") {
    return <UndefinedValue key={key} />;
  } else if (render) {
    return render(value, key);
  }
  return value;
}
