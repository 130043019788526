import client from "config/client";
import gql from "graphql-tag";
import { Mutation, MutationForgetPasswordUserArgs } from "types/schema";

const FORGET_PASSWORD_USER = gql`
  mutation ForgetPasswordUser($email: String) {
    forgetPasswordUser(email: $email) {
      ok
    }
  }
`;

export const forgetPasswordUser = async (email?: string) => {
  await client.mutate<Mutation, MutationForgetPasswordUserArgs>({
    mutation: FORGET_PASSWORD_USER,
    variables: { email },
  });
};
