import { PageHeader } from "antd";
import BreadCrumbs, { BreadcrumbData } from "library/components/Breadcrumbs";
import React, { ReactNode } from "react";
import { BreadcrumbsContainer, Container } from "./Header.css";

interface Props {
  title?: string;
  breadcrumbs?: Array<BreadcrumbData>;
  extra?: Array<ReactNode>;
}

const getBreadcrumbs = (breadcrumbs: Props["breadcrumbs"]) => {
  if (breadcrumbs) {
    return <BreadCrumbs data={breadcrumbs} />;
  }
};

const Header: React.FC<Props> = ({ title = "", breadcrumbs, extra }) => {
  return (
    <Container>
      <BreadcrumbsContainer>{getBreadcrumbs(breadcrumbs)}</BreadcrumbsContainer>
      <PageHeader ghost={false} title={title} extra={extra} />
    </Container>
  );
};

export default Header;
