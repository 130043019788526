import strings from "res/strings";
import moment from "moment";

export const isDate = (value: string) => {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp);
};

const validateDate = (value: string, func: () => string) => {
  if (isDate(value)) {
    return func();
  }
  console.warn(`Value "${value}" is not a valid date.`);
  return "";
};

export const getFormattedDate = (value: string) => {
  return validateDate(value, () => {
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const formattedDay = `${day}`.padStart(2, "0");
    const formattedMonth = strings.months[month];
    return `${formattedDay}/${formattedMonth}/${year}`;
  });
};

export const getFormattedTime = (value: string) => {
  return validateDate(value, () => {
    const date = new Date(value);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = `${hours}`.padStart(2, "0");
    const formattedMinutes = `${minutes}`.padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  });
};

export const getFormattedDateAndTime = (value: string) => {
  return validateDate(value, () => {
    const formattedDate = getFormattedDate(value);
    const formattedTime = getFormattedTime(value);
    return `${formattedDate}, ${formattedTime}`;
  });
};

/**
 * Disables DatePickers dates greater than now and less than 90 years from now
 */
export const memberAgeFilter = (value: moment.Moment) => {
  const now = moment();
  return value && (value > now || value < now.subtract(90, "years"));
};
