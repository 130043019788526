import routes, { Route } from "./routes";

export interface DrawerSubmenuItem {
  title: string;
  route?: Route;
}

export interface DrawerItem {
  title: string;
  route?: Route;
  items?: DrawerSubmenuItem[];
}

const findRoute = (path: string, overwrites?: Partial<Route>) => {
  const route = routes.find(route => route.path === path);
  if (route) {
    return {
      ...route,
      ...overwrites,
    } as Route;
  }
};

const drawerData: DrawerItem[] = [
  {
    title: "Búsqueda de asegurados",
    route: findRoute("/"),
  },
];

export default drawerData;
