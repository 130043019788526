import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import ResetPassword from "library/components/ResetPassword";
import { afterQueryActions } from "library/utils/afterLoadActions";
import { useObserver } from "mobx-react";
import React, { useState } from "react";
import loginStore from "stores/login";
import { Container, View } from "./Login.css";

/**
 * @todo - Validate e-mail
 */
const Login: React.FC = () => {
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

  const handleLogin = async () => {
    await loginStore.login();
    afterQueryActions(loginStore.loginLoader);
  };

  return useObserver(() => {
    const { loginLoader } = loginStore;
    return (
      <View>
        <Container>
          <Card title="Bienvenido">
            <Form.Item>
              <Input
                id="user-email"
                prefix={<UserOutlined />}
                placeholder="Correo electrónico"
                defaultValue={loginStore.email}
                onChange={ev => (loginStore.email = ev.currentTarget.value)}
                onPressEnter={handleLogin}
                disabled={loginLoader.isLoading}
                required
                autoFocus
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                id="user-password"
                prefix={<LockOutlined />}
                placeholder="Contraseña"
                onChange={ev => (loginStore.password = ev.currentTarget.value)}
                onPressEnter={handleLogin}
                disabled={loginLoader.isLoading}
                required
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleLogin}
                disabled={loginLoader.isLoading}
                loading={loginLoader.isLoading}
              >
                Ingresar
              </Button>
              <Button type="link" onClick={() => setResetPasswordVisible(true)}>
                ¿Olvidaste tu contraseña?
              </Button>
            </Form.Item>
          </Card>
        </Container>
        <ResetPassword
          email={loginStore.email}
          visible={resetPasswordVisible}
          onClose={() => setResetPasswordVisible(false)}
        />
      </View>
    );
  });
};

export default Login;
