import styled from "styled-components";

export const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ececec;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 8px;
`;
