import React from "react";

interface Props {
  dark?: Boolean;
}

const Logo: React.FC<Props> = ({ dark }) => {
  const color = dark ? "#4a25aa" : "white";
  return (
    <svg
      fill={color}
      height="38"
      viewBox="0 0 183 38"
      width="183"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M66.67 29.29L73.8 18.87L66.67 8.45001H71.25L78.52 18.87L71.25 29.29H66.67Z" />
      <path d="M64.59 24.46C64.6 24.45 64.6 24.44 64.6 24.43C64.94 23.72 65.22 22.96 65.36 22.12H61.23C61.1 22.48 60.91 22.78 60.73 23.09C60.52 23.45 60.27 23.77 60 24.07C59.94 24.14 59.87 24.2 59.8 24.26C59.55 24.5 59.28 24.71 58.98 24.89C58.8 25 58.59 25.08 58.39 25.17C58.14 25.28 57.91 25.39 57.63 25.47C57.13 25.6 56.59 25.68 56 25.68C55.97 25.68 55.94 25.69 55.91 25.69C52.1 25.69 49.75 22.77 49.75 18.88C49.75 14.83 52.31 11.83 55.87 11.83C56.52 11.83 57.1 11.94 57.65 12.11C57.9 12.19 58.12 12.31 58.35 12.41C58.59 12.52 58.83 12.64 59.04 12.78C59.33 12.97 59.59 13.19 59.82 13.43C59.9 13.51 59.97 13.59 60.04 13.67C60.3 13.98 60.54 14.31 60.71 14.66C60.87 14.99 61.06 15.32 61.14 15.67H65.28C65.23 15.31 65.12 14.99 65.04 14.66C64.77 13.61 64.33 12.68 63.75 11.88C63.74 11.87 63.74 11.86 63.73 11.86C63.45 11.48 63.14 11.12 62.8 10.8C62.77 10.77 62.74 10.75 62.71 10.72C62.39 10.42 62.05 10.15 61.69 9.90001C61.61 9.85001 61.53 9.81001 61.46 9.76001C61.13 9.55001 60.79 9.35001 60.43 9.19001C60.28 9.12001 60.13 9.07001 59.98 9.01001C59.67 8.89001 59.36 8.76001 59.03 8.66001C58.77 8.59001 58.51 8.54001 58.24 8.49001C58 8.44001 57.76 8.37001 57.51 8.34001C56.98 8.27001 56.44 8.23001 55.89 8.23001C55.16 8.23001 54.44 8.30001 53.76 8.43001C48.99 9.36001 45.67 13.43 45.67 18.89C45.67 24.85 49.68 29.31 55.93 29.31C56.04 29.31 56.15 29.29 56.26 29.29C56.74 29.28 57.21 29.25 57.66 29.19C57.93 29.15 58.18 29.08 58.44 29.03C58.71 28.97 58.99 28.92 59.25 28.85C59.56 28.76 59.84 28.64 60.13 28.52C60.31 28.45 60.5 28.38 60.67 28.3C60.99 28.15 61.29 27.97 61.59 27.78C61.7 27.71 61.82 27.64 61.93 27.57C62.25 27.35 62.54 27.1 62.83 26.84C62.89 26.78 62.95 26.73 63.01 26.68C63.31 26.39 63.58 26.07 63.83 25.74C63.85 25.71 63.88 25.67 63.9 25.64C64.16 25.25 64.39 24.87 64.59 24.46Z" />
      <path d="M97.23 17.21L95.69 16.8C93.71 16.23 92.04 15.71 92.04 13.92C92.04 12.26 93.46 11.37 95.24 11.37C97.35 11.37 98.65 12.51 98.85 14.13H102.82C102.62 11.13 100.3 8.21001 95.2 8.21001C91.39 8.21001 88.06 10.36 88.06 14.13C88.06 18.02 91.18 19.12 93.7 19.8L95.28 20.21C97.39 20.78 99.25 21.55 99.25 23.57C99.25 25.4 97.59 26.13 95.72 26.13C93.49 26.13 91.67 25.2 91.42 22.6H87.45C87.65 26.57 90.37 29.29 95.76 29.29C99.81 29.29 103.22 27.51 103.22 23.45C103.23 19.6 100.35 18.06 97.23 17.21Z" />
      <path d="M113.17 3.10001H109.4V5.58001C109.4 7.77001 108.51 8.62001 106.12 8.62001H104.7V11.94H109.08V23.98C109.08 26.29 109.89 29.29 114.64 29.29C115.98 29.29 117.03 29.05 117.8 28.84V25.64C117.19 25.84 116.46 25.96 115.81 25.96C113.66 25.96 113.18 24.75 113.18 23.4V11.94H118.33V8.62001H113.18V3.10001H113.17Z" />
      <path d="M138.03 24.1V15.79C138.03 10.72 134.54 8.21001 129.92 8.21001C125.05 8.21001 122.05 11.13 121.69 15.14H125.78C126.15 13.03 127.36 11.57 129.92 11.57C132.43 11.57 133.89 13.03 133.89 15.62V16.63L127.69 17.24C123.8 17.6 120.88 19.59 120.88 23.44C120.88 27.41 123.84 29.28 127.77 29.28C130.81 29.28 133.04 28.1 134.42 26.36C134.99 28.26 136.32 28.87 138.23 28.87H140.1V25.75H139.58C138.56 25.77 138.03 25.24 138.03 24.1ZM133.9 21.1C133.9 24.75 130.9 26.05 128.39 26.05C126.56 26.05 125.03 25.32 125.03 23.29C125.03 21.35 126.69 20.29 129.08 20.09L133.9 19.6V21.1Z" />
      <path d="M182.04 18.47C182.04 11.5 177.7 8.21001 172.75 8.21001C167.24 8.21001 162.9 12.34 162.9 18.95C162.9 25.2 167.08 29.29 172.91 29.29C177.69 29.29 181.1 26.86 181.95 22.8H177.98C177.33 24.87 175.47 25.92 172.91 25.92C169.34 25.92 167.15 23.4 166.95 19.8H181.95C181.99 19.28 182.04 18.87 182.04 18.47ZM166.99 16.89C167.4 13.93 169.55 11.58 172.75 11.58C175.87 11.58 177.98 13.77 178.02 16.89H166.99Z" />
      <path d="M11.34 8.21001C8.21999 8.21001 6.10999 9.63001 5.05999 10.97V0.100006H0.929993V28.88H4.28999L4.64999 26.16H4.92999C5.93999 27.54 7.92999 29.28 11.34 29.28C16.77 29.28 20.75 25.1 20.75 18.7C20.75 12.22 16.65 8.21001 11.34 8.21001ZM10.73 25.68C7.27999 25.68 5.04999 22.88 5.04999 18.71C5.04999 14.61 7.27999 11.82 10.73 11.82C14.22 11.82 16.61 14.62 16.61 18.71C16.61 22.89 14.22 25.68 10.73 25.68Z" />
      <path d="M41.19 24.1V15.79C41.19 10.72 37.7 8.21001 33.08 8.21001C28.22 8.21001 25.21 11.13 24.85 15.14H28.95C29.31 13.03 30.53 11.57 33.08 11.57C35.59 11.57 37.05 13.03 37.05 15.62V16.63L30.85 17.24C26.96 17.6 24.04 19.59 24.04 23.44C24.04 27.41 27 29.28 30.93 29.28C33.97 29.28 36.2 28.1 37.58 26.36C38.15 28.26 39.48 28.87 41.39 28.87H43.26V25.75H42.73C41.72 25.77 41.19 25.24 41.19 24.1ZM37.06 21.1C37.06 24.75 34.06 26.05 31.55 26.05C29.73 26.05 28.18 25.32 28.18 23.29C28.18 21.35 29.84 20.29 32.24 20.09L37.06 19.6V21.1V21.1Z" />
      <path d="M159.09 6.55C158.28 7.6 156.99 8.73 155.74 9.38C154.64 8.64 153.32 8.21 151.89 8.21C148.06 8.21 144.96 11.31 144.96 15.14C144.96 16.92 145.65 18.52 146.75 19.74L145.99 20.13C144.57 20.9 143.36 22.16 143.36 23.98C143.36 25.72 144.33 27.06 145.87 27.71L142.63 30.51C142.99 34.24 145.87 37.4 152.04 37.4C157.43 37.4 161.12 34.97 161.12 30.67C161.12 27.1 158.56 24.87 153.94 24.87H149.93C148.27 24.87 147.09 24.3 147.09 23.13C147.09 22.4 147.5 21.88 148.27 21.47L148.67 21.24C149.64 21.75 150.72 22.07 151.9 22.07C155.73 22.07 158.83 18.97 158.83 15.14C158.83 13.76 158.41 12.48 157.72 11.4C159.17 10.75 160.65 9.67 161.3 8.91L159.09 6.55ZM149.76 28.16H153.45C155.11 28.16 157.14 28.77 157.14 30.88C157.14 33.19 154.75 34.24 152.03 34.24C148.62 34.24 147.12 32.54 146.64 30.79L149.76 28.16Z" />
      <path d="M84.09 0.100006H79.96V37.64H84.09V0.100006Z" />
    </svg>
  );
};

export default Logo;
