import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import loginStore from "stores/login";

export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PUBLIC_SOFIA_API_URL,
  credentials: "same-origin",
});

/**
 * @see {@link https://bit.ly/2A6PO4S} - Catch 401 errors
 */
export const errorLink = onError(({ networkError }) => {
  // Logout if unauthorized
  if ((networkError as any)?.statusCode === 401) {
    loginStore.logout();
  }
});

export const authLink = setContext((_, { headers }) => {
  const output = {
    headers: {
      ...headers,
    },
  };
  if (loginStore.data.token) {
    output.headers.authorization = `JWT ${loginStore.data.token}`;
  }
  return output;
});

export default new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});
