import Breadcrumb from "antd/lib/breadcrumb";
import Spin from "antd/lib/spin";
import isNil from "lodash/isNil";
import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.css";

export interface BreadcrumbData {
  href?: string;
  isLoading?: boolean;
  text?: string;
}

interface Props {
  data: Array<BreadcrumbData>;
}

const { Item } = Breadcrumb;
const Breadcrumbs: React.FC<Props> = ({ data = [] }) => {
  const getItemContent = ({ href, text, isLoading }: BreadcrumbData) => {
    if (isLoading) return <Spin size="small" />;
    if (isNil(href)) return text;
    return <Link to={href}>{text}</Link>;
  };

  const getItems = () =>
    data.map((dataItem, index) => (
      <Item key={index}>{getItemContent(dataItem)}</Item>
    ));

  return (
    <div className="Breadcrumbs">
      <Breadcrumb>{getItems()}</Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
