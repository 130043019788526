import { Layout as AntLayout } from "antd";
import { APP_MENU_WIDTH } from "config/style";
import styled from "styled-components";

export const Layout: typeof AntLayout = styled(AntLayout)`
  min-height: 100vh;
` as any;

export const ContentLayout: typeof AntLayout = styled(AntLayout)`
  &:not(.is-mobile-menu) {
    transition: margin 0.2s;
    &.is-menu-open {
      margin-left: ${APP_MENU_WIDTH}px;
    }
  }
` as any;

export const Content: typeof AntLayout.Content = styled(AntLayout.Content)`
  margin: 16px;
` as any;
