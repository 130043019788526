import routes from "config/routes";
import { SCREEN_XL } from "config/style";
import NestedSwitch from "library/components/NestedSwitch";
import useEventListener from "library/hooks/useEventListener";
import React, { useEffect, useState } from "react";
import AppMenu from "../AppMenu";
import AppToolbar from "../AppToolbar";
import AppFooter from "../AppFooter";
import { Content, ContentLayout, Layout } from "./AppLayout.css";

const AppLayout: React.FC = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  useEffect(() => {
    // Below XL screens the Sider will no longer be part of the layout and
    // instead will be inside a drawer (above the content)...
    setIsMobileMenu(window.innerWidth < SCREEN_XL);
    // ...in this case it should be hidden by default.
    setIsMenuOpen(window.innerWidth >= SCREEN_XL);
  }, []);

  useEventListener("resize", () => {
    setIsMobileMenu(window.innerWidth < SCREEN_XL);
  });

  const toggleDrawer = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getContentLayoutClassName = () => {
    let classes = "";
    if (isMobileMenu) classes += "is-mobile-menu ";
    if (isMenuOpen) classes += "is-menu-open ";
    return classes;
  };

  const onAppMenuLinkClick = () => {
    if (isMobileMenu) setIsMenuOpen(false);
  };

  return (
    <Layout>
      <AppMenu
        visible={isMenuOpen}
        onClose={toggleDrawer}
        wrapInDrawer={isMobileMenu}
        onLinkClick={onAppMenuLinkClick}
      />
      <ContentLayout className={getContentLayoutClassName()}>
        <AppToolbar isMenuOpen={isMenuOpen} onTriggerClick={toggleDrawer} />
        <Content>
          <main>
            <NestedSwitch routes={routes} />
          </main>
        </Content>
        <AppFooter />
      </ContentLayout>
    </Layout>
  );
};

export default AppLayout;
