import { Layout } from "antd";
import React from "react";
import styled from "styled-components";

export const Header: React.FC = styled(Layout.Header)`
  background-color: #fff;
  padding: 0;
`;

export const IconContainer = styled.div`
  float: left;
  padding: 0 24px;
  line-height: 64px;
  cursor: pointer;

  .anticon {
    color: #989898;
    font-size: 18px;
    transition: color 0.3s ease-out;

    &:hover {
      color: #4a25aa;
    }
  }
`;

export const RightActions = styled.div`
  float: right;
  padding: 0 24px;
`;
