import Loader from "stores/Loader";
import * as memberSearchApi from "./memberSearchApi";
import { observable, computed } from "mobx";
import { ChangeEvent } from "react";
import { LimitedMemberType } from "types/schema";
import { Moment } from "moment";

export enum ResultMemberState {
  ER = "error",
  SC = "success",
  WN = "warning",
}

class MemberSearchState {
  queryLoader = new Loader();

  @observable
  results?: Array<LimitedMemberType>;

  @observable
  name = "";

  @observable
  date?: Moment;

  @computed
  get member() {
    return this.results?.[0];
  }

  @computed
  get validArgs() {
    return this.name !== "" && this.date;
  }

  @computed
  get isGracePeriod() {
    const { member } = this;
    return (
      member?.callToAction ===
      "El plan de salud del soci@ está en periodo de gracia. El pago de los servicios es responsabilidad del soci@. Este podrá solicitar un reembolso a Sofía posteriormente."
    );
  }

  @computed
  get tagColor() {
    const { isGracePeriod } = this;
    if (isGracePeriod) {
      return "yellow";
    }
    return this.member?.hasActiveHealthPlan ? "green" : "volcano";
  }

  @computed
  get tagText() {
    return this.member?.hasActiveHealthPlan ? "Activa" : "Inactiva";
  }

  @computed
  get resultText() {
    const { member } = this;
    return member?.callToAction ? member?.callToAction : "";
  }

  @computed
  get resultState() {
    const { isGracePeriod, member } = this;
    if (isGracePeriod) return ResultMemberState.WN;
    return member?.hasActiveHealthPlan
      ? ResultMemberState.SC
      : ResultMemberState.ER;
  }

  reset = (): void => {
    this.name = "";
    this.results = undefined;
    this.date = undefined;
  };

  load = this.queryLoader.load(async () => {
    if (this.validArgs) {
      this.results = undefined;
      const response = await memberSearchApi.memberSearch({
        term: this.name,
        birthdate: this.date ? this.date.format("YYYY-MM-DD") : undefined,
      });
      this.results = response?.results ?? undefined;
    }
  });

  onChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    this.name = event.target.value;
  };
}

export default MemberSearchState;
