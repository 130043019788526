import MemberSearch from "screens/MemberSearch";

export interface RouteItem {
  path: string;
  component: React.FC<any> | React.ComponentClass;
}

export type Route = RouteItem & {
  routes?: RouteItem[];
};

const routes: Route[] = [
  {
    path: "/",
    component: MemberSearch,
  },
];

export default routes;
