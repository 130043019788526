import { ConfigProvider } from "antd";
import esEs from "antd/es/locale/es_ES";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import "res/styles/base.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/**
 * Use ISO8601 that reflects the moment's UTC offset
 * @see {@link https://momentjs.com/docs/#/displaying/as-json/} Source
 */
moment.fn.toJSON = function () {
  return this.format();
};

ReactDOM.render(
  <ConfigProvider locale={esEs}>
    <App />
  </ConfigProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
