import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  margin: -15px -16px 16px -16px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
`;

export const BreadcrumbsContainer = styled.div`
  padding: 0 25px;
`;
