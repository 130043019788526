import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Empty, Input } from "antd";
import Header from "library/components/Header";
import { afterQueryActions } from "library/utils/afterLoadActions";
import { rmNull } from "library/utils/fields";
import { useObserver } from "mobx-react";
import React, { useState } from "react";
import MemberCardInfo from "./MemberCardInfo";
import {
  Container,
  InputContainer,
  SearchButton,
  SearchCard,
} from "./MemberSearch.css";
import MemberSearchState from "./MemberSearchState";

const MemberSearch: React.FC = () => {
  const [state] = useState(new MemberSearchState());

  const onSearch = async () => {
    await state.load();
    afterQueryActions(state.queryLoader, { errorTitle: "¡Uuups!" });
  };

  return useObserver(() => (
    <React.Fragment>
      <Header title="Búsqueda de asegurados" />
      <SearchCard>
        <Container>
          <InputContainer>
            <Input.Group compact>
              <Input
                placeholder="Nombre completo"
                type={"text"}
                onChange={state.onChangeName}
                value={state.name}
                style={{ width: "60%" }}
              />
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Fecha de nacimiento"
                value={state.date}
                onChange={date => (state.date = rmNull(date))}
                style={{ width: "40%" }}
              />
            </Input.Group>
          </InputContainer>
          <SearchButton
            type="primary"
            icon={<SearchOutlined />}
            onClick={onSearch}
            loading={state.queryLoader.isLoading}
          >
            Buscar
          </SearchButton>
          <Button onClick={state.reset}>Limpiar</Button>
        </Container>
      </SearchCard>

      {state.results?.length === 0 ? (
        <Card>
          <Empty description={<>No se encontraron resultados</>} />
        </Card>
      ) : null}

      {state.member ? (
        <MemberCardInfo
          member={state.member}
          stateText={state.tagText}
          stateColor={state.tagColor}
          resultText={state.resultText}
          resultState={state.resultState}
        />
      ) : null}
    </React.Fragment>
  ));
};

export default MemberSearch;
