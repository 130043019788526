import { ApolloError } from "@apollo/client";
import client from "config/client";
import { includesError } from "library/utils/error";
import { action, computed, observable } from "mobx";
import Loader from "stores/Loader";
import {
  ProvidersAuthDocument,
  ProvidersAuthMutation,
  ProvidersAuthMutationVariables,
  RefreshTokenDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  VerifyTokenDocument,
  VerifyTokenMutation,
  VerifyTokenMutationVariables,
} from "types/schema";

interface LoginData {
  loaded?: boolean;
  token?: string;
  refreshToken?: string;
}

class Login {
  loginLoader = new Loader();
  refreshLoader = new Loader();

  @observable
  email = "";

  @observable
  password = "";

  @observable
  data: LoginData = {};

  constructor() {
    this.data = Login.cache;
  }

  @computed
  get isLoggedIn() {
    return this.data.loaded;
  }

  @action
  updateData = (value: LoginData) => {
    const { data } = this;
    Login.cache = {
      ...data,
      ...value,
    };
    this.data = Login.cache;
  };

  @action
  clearData = () => {
    Login.clearCache();
    this.data = {};
  };

  login = this.loginLoader.load(async () => {
    const response = await client.mutate<
      ProvidersAuthMutation,
      ProvidersAuthMutationVariables
    >({
      mutation: ProvidersAuthDocument,
      variables: {
        email: this.email,
        password: this.password,
      },
    });
    const data = response?.data?.providersToken;
    if (data?.token && data?.refreshToken) {
      this.updateData({
        token: data.token,
        refreshToken: data.refreshToken,
        loaded: true,
      });
    }
  });

  refreshToken = this.refreshLoader.load(async () => {
    const { token, refreshToken } = this.data;
    if (!token || !refreshToken) return;

    try {
      await client.mutate<VerifyTokenMutation, VerifyTokenMutationVariables>({
        mutation: VerifyTokenDocument,
        variables: { token },
      });
    } catch (err) {
      if (
        err instanceof ApolloError &&
        includesError(err, "Unrecognized session!")
      ) {
        const response = await client.mutate<
          RefreshTokenMutation,
          RefreshTokenMutationVariables
        >({
          mutation: RefreshTokenDocument,
          variables: { refreshToken },
        });
        const data = response?.data?.refreshToken;
        if (data?.token && data?.refreshToken) {
          this.updateData({
            token: data.token,
            refreshToken: data.refreshToken,
          });
        }
      }
    }
  });

  logout = () => {
    this.clearData();
  };

  static get cache(): LoginData {
    const jsonString = localStorage.getItem("LOGIN_DATA");
    return jsonString ? JSON.parse(jsonString) : {};
  }

  static set cache(data: LoginData) {
    const jsonData = JSON.stringify(data);
    localStorage.setItem("LOGIN_DATA", jsonData);
  }

  static clearCache = () => {
    localStorage.removeItem("LOGIN_DATA");
  };
}

export default new Login();
