import { ApolloError } from "@apollo/client";
import { getErrorMsg } from "library/utils/error";
import isObject from "lodash/isObject";
import { computed, observable, runInAction } from "mobx";

export interface LoaderOptions {
  isLoaded?: boolean;
  isLoading?: boolean;
}

export default class Loader {
  @observable
  isLoading = false;

  @observable
  isLoaded = false;

  @observable
  error?: Error | ApolloError;

  @computed
  get hasError() {
    return typeof this.error !== "undefined";
  }

  @computed
  get errorMessage() {
    const { error } = this;
    if (isObject(error)) {
      return getErrorMsg(error);
    }
    return undefined;
  }

  constructor(opts: LoaderOptions = {}) {
    this.isLoaded = opts.isLoaded ?? false;
    this.isLoading = opts.isLoading ?? false;
  }

  load = <P extends unknown[], T = void>(callback: (...args: P) => T) => {
    return async (...args: P): Promise<T | undefined> => {
      runInAction(() => {
        this.isLoading = true;
        this.isLoaded = false;
        this.error = undefined;
      });

      try {
        const response = await callback(...args);
        runInAction(() => (this.isLoaded = true));
        return response;
      } catch (error) {
        runInAction(() => {
          if (error instanceof Error) {
            this.error = error;
          }
        });
      } finally {
        runInAction(() => (this.isLoading = false));
      }
    };
  };
}
