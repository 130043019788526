import React from "react";

export type RenderIfFnValue<T> = T extends () => unknown
  ? ReturnType<NonNullable<T>>
  : NonNullable<T>;

export interface RenderIfProps<T extends React.ReactNode> {
  children?: React.ReactNode | ((event: RenderIfFnValue<T>) => React.ReactNode);
  fallback?: React.ReactNode | (() => React.ReactNode);
  value?: T;
}

function RenderIf<T extends React.ReactNode>({
  value,
  fallback,
  children,
}: RenderIfProps<T>): JSX.Element {
  if (value) {
    value = typeof value === "function" ? value() : value;

    if (value) {
      if (typeof children === "function") {
        return <>{children(value as RenderIfFnValue<T>)}</>;
      }
      return <>{children ? children : value}</>;
    }
  }

  return <>{typeof fallback === "function" ? fallback() : fallback}</>;
}

export default RenderIf;
