import { SiderProps } from "antd/lib/layout";

export const APP_MENU_WIDTH = 240;
export const APP_MENU_THEME: SiderProps["theme"] = "dark";

// #region
// MEDIA QUERIES BREAKPOINTS
// This values match ant theme variables
// node_modules/antd/lib/style/themes/default.less

// Extra small screen / phone
export const SCREEN_XS = 480;
export const SCREEN_XS_MIN = SCREEN_XS;

// Small screen / tablet
export const SCREEN_SM = 576;
export const SCREEN_SM_MIN = SCREEN_SM;

// Medium screen / desktop
export const SCREEN_MD = 768;
export const SCREEN_MD_MIN = SCREEN_MD;

// Large screen / wide desktop
export const SCREEN_LG = 992;
export const SCREEN_LG_MIN = SCREEN_LG;

// Extra large screen / full hd
export const SCREEN_XL = 1200;
export const SCREEN_XL_MIN = SCREEN_XL;

// Extra extra large screen / large desktop
export const SCREEN_XXL = 1600;
export const SCREEN_XXL_MIN = SCREEN_XXL;

// provide a maximum
export const SCREEN_XS_MAX = SCREEN_SM_MIN - 1;
export const SCREEN_SM_MAX = SCREEN_MD_MIN - 1;
export const SCREEN_MD_MAX = SCREEN_LG_MIN - 1;
export const SCREEN_LG_MAX = SCREEN_XL_MIN - 1;
export const SCREEN_XL_MAX = SCREEN_XXL_MIN - 1;
// #endregion
