import { ApolloProvider } from "@apollo/client";
import { Spin } from "antd";
import client from "config/client";
import AppLayout from "library/components/AppLayout";
import RenderIf from "library/components/RenderIf";
import { useObserver } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Login from "screens/Login";
import loginStore from "stores/login";

const App: React.FunctionComponent = () => {
  useEffect(() => {
    loginStore.refreshToken();
  }, []);

  return useObserver(() => (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <RenderIf
          value={!loginStore.refreshLoader.isLoading}
          fallback={<Spin />}
        >
          {loginStore.isLoggedIn ? <AppLayout /> : <Login />}
        </RenderIf>
      </ApolloProvider>
    </BrowserRouter>
  ));
};

export default App;
