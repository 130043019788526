import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import { LimitedMemberType, Maybe, MemberType } from "types/schema";
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getFormattedTime,
} from "./date";

export const getDate = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedDate(date);
};

export const getTime = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedTime(date);
};

export const getDateAndTime = (date?: Maybe<string>) => {
  return isNil(date) ? "" : getFormattedDateAndTime(date);
};

export const getJSONString = (value?: Maybe<string>) => {
  if (isNil(value) || isEmpty(value)) {
    return "";
  }
  const parsedValue = JSON.parse(value);
  return JSON.stringify(parsedValue, null, 2);
};

export const getMemberName = (
  member?: Maybe<Partial<MemberType | LimitedMemberType>>,
) => {
  if (isNil(member)) return "";
  const firstName = member.firstName ?? "";
  const firstLastName = member.firstLastName ?? "";
  const secondLastName = member.secondLastName ?? "";
  return `${firstName} ${firstLastName} ${secondLastName}`.trim();
};

/** Removes null for Ant components */
export const rmNull = <T>(value?: Maybe<T>) => {
  return isNil(value) ? undefined : value;
};

export const getMoment = (dateString?: string | null) => {
  return dateString ? moment(dateString) : undefined;
};
