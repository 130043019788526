import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Date: string;
  UUID: string;
  JSONString: string;
  Decimal: any;
  GenericScalar: any;
  Time: any;
};

export type Query = {
  __typename?: 'Query';
  medicalHistory?: Maybe<MedicalHistoryType>;
  memberMedicalHistory?: Maybe<MedicalHistoryType>;
  medicalNote?: Maybe<MedicalNoteType>;
  memberMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  doctorMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  closedMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  consultsLatestMedicalNotes?: Maybe<PaginatedMedicalNoteType>;
  allServices?: Maybe<PaginatedServices>;
  memberYearServiceSummary?: Maybe<MemberYearServiceSummary>;
  reinbursementServiceInfo?: Maybe<ReinbursementServiceInfo>;
  event?: Maybe<CaseEventType>;
  allEvents?: Maybe<PaginatedCaseEvent>;
  doctorsByEvent?: Maybe<Array<EventDoctorSelectorType>>;
  healthPlanApplications?: Maybe<PageBasedAccessHealthPlanApplicationType>;
  healthPlanApplication?: Maybe<HealthPlanApplicationType>;
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  productQuestionnaire?: Maybe<Scalars['JSONString']>;
  questionnaireForMember?: Maybe<Scalars['JSONString']>;
  questionnaireStateForMember?: Maybe<Scalars['Boolean']>;
  products?: Maybe<PageBasedAccessProductType>;
  product?: Maybe<ProductType>;
  ineData?: Maybe<NubariumIneType>;
  satInfoValidation?: Maybe<NubariumSatInfoValidationType>;
  checkBirthday?: Maybe<InAppRejectionType>;
  currentPublicProductData?: Maybe<PublicProductType>;
  availableProductModules?: Maybe<Array<PublicKeyValueType>>;
  productData?: Maybe<PublicProductType>;
  multipleSubscriptionsPrice?: Maybe<MultipleSubscriptionsPriceType>;
  latestScheduledApprovalCall?: Maybe<ScheduledApprovalCallType>;
  user?: Maybe<UserType>;
  users?: Maybe<PageBasedAccessUserType>;
  documents?: Maybe<PageBasedAccessDocumentType>;
  members?: Maybe<PageBasedAccessMemberType>;
  member?: Maybe<MemberType>;
  memberSearch?: Maybe<MemberSearchResultsType>;
  document?: Maybe<DocumentType>;
  /** @deprecated Use managedMembers */
  healthPlanMembers?: Maybe<SequentialAccessMemberType>;
  managedMembers?: Maybe<SequentialAccessMemberType>;
  memberRepresentedById?: Maybe<MemberType>;
  lastRegisteredTestUser?: Maybe<UserType>;
  checkPassword?: Maybe<Array<Maybe<Scalars['String']>>>;
  verifyPassword?: Maybe<VerifyPasswordType>;
  minSupportedVersion?: Maybe<MinSupportedVersionType>;
  giftAVcUrl?: Maybe<Scalars['String']>;
  coronaidActiveStatus?: Maybe<CoronaidActiveStatus>;
  authenticatedVars?: Maybe<AuthenticatedVars>;
  waitingRoomEnabledStatus?: Maybe<WaitingRoomEnabledStatus>;
  schemaIntrospection?: Maybe<IntrospectionQueryResult>;
  isTelephoneAlreadyOnUse?: Maybe<IsTelephoneAlreadyOnUseType>;
  ipAddress?: Maybe<IpAddressType>;
  checkEmail?: Maybe<CheckEmailType>;
  inAppNotifications?: Maybe<InAppNotificationsType>;
  checkExpertsWorkingHours?: Maybe<CheckExpertsWorkingHoursType>;
  hasPaymentsPending?: Maybe<CheckUserHasPaymentsPending>;
  verifyPasswordToken?: Maybe<VerifyPasswordTokenType>;
  referralCodeFeatureFlag?: Maybe<Scalars['Boolean']>;
  referralCode?: Maybe<ReferralCodeType>;
  remainingInvitations?: Maybe<Scalars['Int']>;
  validateReferralCode?: Maybe<ReferralCodeValidationType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  doctorFriends?: Maybe<PaginatedDoctorFriendsType>;
  doctorPatients?: Maybe<PaginatedDoctorPatientsType>;
  allDoctorPatients?: Maybe<PaginatedDoctorPatientsType>;
  applicants?: Maybe<PaginatedDoctorPatientsType>;
  providerCategories?: Maybe<Array<ProviderCategoryType>>;
  providerDoctorSpecialties?: Maybe<Array<Maybe<ProviderDoctorSpecialtiesType>>>;
  providerBranchOffice?: Maybe<ProviderBranchOfficeType>;
  providerBranchOffices?: Maybe<PaginatedProviderBranchOfficesType>;
  providerBranch?: Maybe<ProviderBranchType>;
  careDirectory?: Maybe<SequentialAccessDirectoryListType>;
  careDirectoryContinue?: Maybe<SequentialAccessDirectoryListType>;
  specialtiesDoctorsDirectory?: Maybe<SequentialAccessDirectoryListType>;
  specialtiesDoctorsDirectoryContinue?: Maybe<SequentialAccessDirectoryListType>;
  provider?: Maybe<ProvidersType>;
  providers?: Maybe<PaginatedProvidersType>;
  careProviderDirectory?: Maybe<SequentialAccessProviderListType>;
  careProviderDirectoryContinue?: Maybe<SequentialAccessProviderListType>;
  doctor?: Maybe<DoctorType>;
  doctorProfile?: Maybe<DoctorProfileType>;
  doctors?: Maybe<PageBasedAccessDoctorType>;
  onDemandAuthorizedDoctors?: Maybe<PageBasedAccessDoctorType>;
  doctorsInPersonConsultsByWeek?: Maybe<PageBasedAccessDoctorType>;
  doctorHuliData?: Maybe<HuliDoctorDataType>;
  specializationFare?: Maybe<SpecializationFareType>;
  specializationFares?: Maybe<Array<Maybe<SpecializationFareType>>>;
  doctorReminders?: Maybe<SequentialAccessDoctorReminderListType>;
  doctorReminder?: Maybe<DoctorReminderType>;
  pharmacyOrders?: Maybe<PaginatedPharmacyOrdersType>;
  pharmacyOrder?: Maybe<PharmacyOrderType>;
  group?: Maybe<GroupType>;
  groups?: Maybe<PaginatedGroupType>;
  permission?: Maybe<PermissionType>;
  permissions?: Maybe<PaginatedPermissionType>;
  paymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  payoutPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  payinPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  billingInfo?: Maybe<Array<Maybe<BillingInfoType>>>;
  stripeSources?: Maybe<SequentialAccessStripeSourceType>;
  transactions?: Maybe<Array<Maybe<TransactionType>>>;
  paginatedTransactions?: Maybe<PageBasedAccessTransactionType>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  paginatedLocations?: Maybe<PageBasedAccessLocationType>;
  invoice?: Maybe<InvoiceType>;
  invoiceItemIssuers: Array<Maybe<IssuerType>>;
  invoiceItems?: Maybe<Array<Maybe<InvoiceItemType>>>;
  payinInvoices?: Maybe<PageBasedInvoiceType>;
  invoices?: Maybe<PageBasedInvoiceType>;
  electronicVouchers?: Maybe<PageBasedElectronicVoucherType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  incomes?: Maybe<PageBasedIncomeType>;
  manualPaymentSourcesSchema?: Maybe<Scalars['GenericScalar']>;
  manualPaymentTypeSatMap?: Maybe<Scalars['JSONString']>;
  incentiveCode?: Maybe<IncentiveCodeType>;
  publicIncentiveCodeData?: Maybe<PublicIncentiveCodeType>;
  referralIncentiveCode?: Maybe<IncentiveCodeType>;
  referralReferredBetweenDates?: Maybe<ReferredOnDatesType>;
  lastValidIncentiveStage?: Maybe<ReferralIncentiveStageType>;
  healthPlans?: Maybe<PageBasedAccessHealthPlanType>;
  healthPlan?: Maybe<HealthPlanType>;
  healthPlanAmendments?: Maybe<PageBasedAccessHealthPlanAmendmentType>;
  healthPlanAmendment?: Maybe<HealthPlanAmendmentType>;
  healthPlanRenewQuote?: Maybe<HealthPlanQuoteDifferenceType>;
  sentMessages?: Maybe<SequentialAccessMessageType>;
  receivedMessages?: Maybe<SequentialAccessMessageType>;
  chatMessages?: Maybe<SequentialAccessMessageType>;
  drugAutocomplete?: Maybe<Array<Maybe<DrugType>>>;
  doctorPrescriptions?: Maybe<SequentialAccessPrescriptionListType>;
  huliEmrEntries?: Maybe<SequentialAccessHuliEmrEntryType>;
  huliEmrs?: Maybe<PageBasedAccessHuliEmrEntryType>;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  inPersonConsults?: Maybe<PageBasedAccessInPersonConsultType>;
  memberOnDemandConsults?: Maybe<SequentialAccessOnDemandConsultType>;
  doctorOnDemandConsults: SequentialAccessOnDemandConsultType;
  doctorOnDemandConsultsWithMessages?: Maybe<SequentialAccessOnDemandConsultType>;
  doctorOnDemandConsult: OnDemandConsultType;
  doctorInPersonConsults: SequentialAccessLimitedInPersonConsultType;
  doctorInPersonConsult: LimitedInPersonConsultType;
  doctorScheduledConsults?: Maybe<SequentialAccessScheduledConsultType>;
  doctorPaginatedScheduledConsults?: Maybe<PaginatedScheduledConsultType>;
  doctorScheduledConsult?: Maybe<ScheduledConsultType>;
  availableOnDemandDoctorCount?: Maybe<Scalars['Int']>;
  readyOnDemandConsults?: Maybe<SequentialAccessOnDemandConsultType>;
  consultState?: Maybe<ActualConsultType>;
  consult?: Maybe<ConsultUnionType>;
  onDemandConsultHourlyFees?: Maybe<OnDemandConsultHourlyFeesType>;
  onDemandConsultPrice?: Maybe<OnDemandConsultPriceType>;
  inPersonConsultFee?: Maybe<InPersonConsultFeeType>;
  inPersonConsultTaxes?: Maybe<TaxesForInPersonConsults>;
  prescription?: Maybe<PrescriptionType>;
  lastValidScheduledConsult?: Maybe<WelcomeScheduledConsultType>;
  lastScheduledConsult?: Maybe<ScheduledConsultType>;
  allScheduledConsults?: Maybe<SequentialAccessScheduledConsultType>;
  listScheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
  listMemberScheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
  listAllScheduledConsults?: Maybe<PaginatedScheduledConsultType>;
  scheduledConsult?: Maybe<ScheduledConsultType>;
  onDemandAvailable?: Maybe<Scalars['Boolean']>;
  pediatricOnDemandAvailable?: Maybe<Scalars['Boolean']>;
  createGoogleCalendarAuthUrl: Scalars['String'];
  calendarEvents?: Maybe<Scalars['JSONString']>;
  calendarEvent?: Maybe<Scalars['JSONString']>;
  calendarDatesAvailability?: Maybe<Array<CalendarDatesAvailabilityType>>;
  allCalendarDatesAvailability?: Maybe<Array<CalendarDatesDoctorsAvailabilityType>>;
  calendarDatesSlotsAvailability?: Maybe<Array<CalendarDatesSlotsAvailability>>;
  allCalendarDatesSlotsAvailability?: Maybe<Array<CalendarDatesDoctorsSlotsAvailability>>;
  calendarNextAvailability?: Maybe<CalendarDatesSlotsAvailability>;
  allCalendarNextAvailability?: Maybe<CalendarDateNextSlotAvailability>;
  firstTodayScheduledConsult?: Maybe<ScheduledConsultType>;
  unclaimedConsults?: Maybe<Array<SubscriptionConsultType>>;
  complianceAlerts?: Maybe<PageBasedComplianceAlertType>;
  complianceAlert?: Maybe<ComplianceAlertType>;
  serviceType?: Maybe<PaginatedServiceType>;
  cieAutocomplete?: Maybe<Array<Maybe<KeyValueType>>>;
  services?: Maybe<ServicesType>;
  limitedServices?: Maybe<LimitedServicesType>;
  cptAutocomplete?: Maybe<Array<Maybe<KeyValueType>>>;
  evidence?: Maybe<EvidenceType>;
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  memberCaseUndefinedServicesCount?: Maybe<Scalars['Int']>;
  memberDefinedDiseaseCases?: Maybe<Array<Maybe<DiseaseCaseType>>>;
  doctorMemberDefinedDiseaseCases?: Maybe<Array<Maybe<LimitedDiseaseCaseType>>>;
  memberServices?: Maybe<PaginatedServices>;
  appMemberServices?: Maybe<Array<Maybe<AppLimitedServicesType>>>;
  hospitalizationSubtotalAmountByDays?: Maybe<Scalars['Int']>;
  checksLog?: Maybe<PaginatedChecksLogType>;
  runChecks?: Maybe<PaginatedChecksLogType>;
  checkCfdiExists?: Maybe<Array<Scalars['ID']>>;
  invoiceItemsByEvent?: Maybe<Array<InvoiceItemType>>;
  businessHealthPlan?: Maybe<HealthPlanType>;
  businessInvoiceItems?: Maybe<PaginatedInvoiceItemType>;
  businessHealthPlanApplications?: Maybe<PageBasedAccessHealthPlanApplicationType>;
  businessHealthPlans?: Maybe<PageBasedAccessBusinessHealthPlanType>;
  businessServicesStatistics?: Maybe<BusinessServicesStatisticsType>;
  business?: Maybe<BusinessType>;
  businesses?: Maybe<PageBasedBusinessType>;
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
  businessGroupsConfigs?: Maybe<Array<BusinessGroupsConfigType>>;
  businessStakeholder?: Maybe<BusinessStakeholderType>;
  businessEmployee?: Maybe<BusinessEmployeeType>;
  businessBeneficiaryByMember?: Maybe<BusinessEmployeeType>;
  businessEmployees?: Maybe<PageBasedBusinessEmployeeType>;
  businessEmployeesStatistics?: Maybe<BusinessEmployeeStatisticsType>;
  searchBusinessEmployees?: Maybe<SearchBusinessEmployeesType>;
  businessBillInfo?: Maybe<BusinessBillInfoType>;
  businessLastPaidBillInfo?: Maybe<BusinessLastPaidBillInfoType>;
  businessInvoice?: Maybe<LimitedInvoiceType>;
  businessInvoices?: Maybe<PageBasedBusinessInvoiceType>;
  businessElectronicVoucher?: Maybe<ElectronicVoucherType>;
  businessElectronicVouchers?: Maybe<PageBasedElectronicVoucherType>;
  businessPaymentMethods?: Maybe<SequentialAccessPaymentMethodsType>;
  businessAdmin?: Maybe<BusinessAdminType>;
  businessAdmins?: Maybe<Array<BusinessAdminType>>;
  balanceAmounts?: Maybe<CurrentBalanceType>;
  miniServicesParams?: Maybe<Array<ServiceParameters>>;
};


export type QueryMedicalHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMemberMedicalHistoryArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMedicalNoteArgs = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryMemberMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDoctorMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryClosedMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  doctorName?: InputMaybe<Scalars['String']>;
  memberName?: InputMaybe<Scalars['String']>;
  noteType?: InputMaybe<Array<NoteType>>;
};


export type QueryConsultsLatestMedicalNotesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  doctorName?: InputMaybe<Scalars['String']>;
  memberName?: InputMaybe<Scalars['String']>;
  noteType?: InputMaybe<Array<NoteType>>;
};


export type QueryAllServicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  memberNameFilter?: InputMaybe<Scalars['String']>;
  doctorOrProviderNameFilter?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  diagnosisIcdIsNull?: InputMaybe<Scalars['Boolean']>;
  cptIsNull?: InputMaybe<Scalars['Boolean']>;
  hasDiagnosis?: InputMaybe<Scalars['Boolean']>;
  latestAmountIsLargeFilter?: InputMaybe<Scalars['Boolean']>;
  serviceTypeValue?: InputMaybe<Array<InputMaybe<ServiceType>>>;
  productFamilyValue?: InputMaybe<ProductFamilies>;
  processState?: InputMaybe<Array<InputMaybe<ServiceProcessStateTypeEnum>>>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMemberYearServiceSummaryArgs = {
  memberId: Scalars['ID'];
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryReinbursementServiceInfoArgs = {
  serviceId?: InputMaybe<Scalars['ID']>;
};


export type QueryEventArgs = {
  eventId: Scalars['Int'];
};


export type QueryAllEventsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  memberNameFilter?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  doctorOrProviderNameFilter?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<InputMaybe<CaseEventCategory>>>;
};


export type QueryDoctorsByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryHealthPlanApplicationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Array<InputMaybe<HealthPlanApplicationState>>>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  memberName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  productFamily?: InputMaybe<ProductFamilies>;
};


export type QueryHealthPlanApplicationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanApplicationItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProductQuestionnaireArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireForMemberArgs = {
  memberId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  healthPlanApplicationId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireStateForMemberArgs = {
  memberId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  healthPlanApplicationId?: InputMaybe<Scalars['Int']>;
  applicationItemId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryIneDataArgs = {
  frontsideBase64: Scalars['String'];
  backsideBase64?: InputMaybe<Scalars['String']>;
};


export type QuerySatInfoValidationArgs = {
  rfc: Scalars['String'];
  legalName: Scalars['String'];
  zipCode: Scalars['String'];
};


export type QueryCheckBirthdayArgs = {
  date: Scalars['Date'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isMini?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProductDataArgs = {
  productFamily: Scalars['String'];
};


export type QueryMultipleSubscriptionsPriceArgs = {
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  emailFilter?: InputMaybe<Scalars['String']>;
  emailFilterRegex?: InputMaybe<Scalars['String']>;
  groupFilter?: InputMaybe<Array<GroupName>>;
  telephoneFilter?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryMembersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  isBeneficiary?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isB2b?: InputMaybe<Scalars['Boolean']>;
  hpState?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryMemberArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberSearchArgs = {
  term?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  hasActiveHealthPlan?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDocumentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanMembersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryManagedMembersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryMemberRepresentedByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCheckPasswordArgs = {
  candidatePassword?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyPasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']>;
};


export type QueryIsTelephoneAlreadyOnUseArgs = {
  telephone: Scalars['String'];
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};


export type QueryHasPaymentsPendingArgs = {
  id: Scalars['Int'];
};


export type QueryVerifyPasswordTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryValidateReferralCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorFriendArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorFriendsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorPatientsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryAllDoctorPatientsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryApplicantsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  filteredBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProviderBranchOfficeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProviderBranchOfficesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryProviderBranchArgs = {
  providerBranchId?: InputMaybe<Scalars['Int']>;
};


export type QueryCareDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  providerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  specialties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  sortByTier?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCareDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QuerySpecialtiesDoctorsDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  specialty?: InputMaybe<MedicalSpecialtyType>;
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QuerySpecialtiesDoctorsDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryProviderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryProvidersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryCareProviderDirectoryArgs = {
  term?: InputMaybe<Scalars['String']>;
  providerTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  specialties?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tiers?: InputMaybe<Array<InputMaybe<ProviderTier>>>;
  sortByTier?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<States>;
  sortByDistance?: InputMaybe<Scalars['Boolean']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};


export type QueryCareProviderDirectoryContinueArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorProfileArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  cityFilter?: InputMaybe<Scalars['String']>;
  primaryCare?: InputMaybe<Scalars['Boolean']>;
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  specializationFilter?: InputMaybe<Array<MedicalSpecialtyType>>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOnDemandAuthorizedDoctorsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorsInPersonConsultsByWeekArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  currentDate?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorHuliDataArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
};


export type QuerySpecializationFareArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDoctorRemindersArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DoctorReminderStatus>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
  consultId?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryDoctorReminderArgs = {
  reminderId?: InputMaybe<Scalars['Int']>;
};


export type QueryPharmacyOrdersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPharmacyOrderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroupArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroupsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryPermissionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<PaymentFlowTypes>;
};


export type QueryPayoutPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryPayinPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryStripeSourcesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryTransactionsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  paymentResult?: InputMaybe<TransactionResult>;
  withInvoices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPaginatedTransactionsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
  paymentResult?: InputMaybe<TransactionResult>;
  withInvoices?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedLocationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  zipcodes?: InputMaybe<Array<Scalars['String']>>;
  zipcodeRegex?: InputMaybe<Array<Scalars['String']>>;
  neighborhoods?: InputMaybe<Array<Scalars['String']>>;
  neighborhoodType?: InputMaybe<Array<Scalars['String']>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipalities?: InputMaybe<Array<Scalars['String']>>;
  stateNames?: InputMaybe<Array<Scalars['String']>>;
  cities?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryInvoiceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceItemIssuersArgs = {
  paid?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvoiceItemsArgs = {
  issuerId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  paid?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPayinInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  modelType?: InputMaybe<Scalars['String']>;
};


export type QueryInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  idList?: InputMaybe<Array<Scalars['ID']>>;
  uuidList?: InputMaybe<Array<Scalars['ID']>>;
  withElectronicVoucherError?: InputMaybe<Scalars['Boolean']>;
  modelType?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  invoiceState?: InputMaybe<Scalars['String']>;
  invoiceStateFilters?: InputMaybe<Array<InvoiceState>>;
  voucherType?: InputMaybe<SatVoucherType>;
  counterpartyName?: InputMaybe<Scalars['String']>;
  counterpartyId?: InputMaybe<Scalars['ID']>;
  productFamilyFilter?: InputMaybe<ProductFamilies>;
  counterparty?: InputMaybe<Array<InvoiceCounterpartyType>>;
  withoutInvoiceItems?: InputMaybe<Scalars['Boolean']>;
  filterByUsersWithNoPaymentMethod?: InputMaybe<Scalars['Boolean']>;
  withAutoEmittedInvoice?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<InvoicesQueryServiceType>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryElectronicVouchersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  voucherType?: InputMaybe<SatVoucherType>;
  state?: InputMaybe<SatOperationTypeStatus>;
  recipientTaxNumber?: InputMaybe<Scalars['String']>;
  issuerOrRecipientId?: InputMaybe<Scalars['ID']>;
  issuerTaxNumber?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};


export type QueryElectronicVoucherArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryIncomesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  counterpartyName?: InputMaybe<Scalars['String']>;
};


export type QueryManualPaymentSourcesSchemaArgs = {
  invoiceId?: InputMaybe<Scalars['Int']>;
};


export type QueryIncentiveCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryPublicIncentiveCodeDataArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type QueryReferralReferredBetweenDatesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryHealthPlansArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  uuidFilter?: InputMaybe<Scalars['String']>;
  stateFilter?: InputMaybe<Scalars['String']>;
  productFamilyFilter?: InputMaybe<ProductFamilies>;
};


export type QueryHealthPlanArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QueryHealthPlanAmendmentsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  categoryFilter?: InputMaybe<Scalars['String']>;
  reviewResultFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
};


export type QueryHealthPlanAmendmentArgs = {
  id: Scalars['Int'];
};


export type QueryHealthPlanRenewQuoteArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QuerySentMessagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryReceivedMessagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryChatMessagesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryDrugAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorPrescriptionsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryHuliEmrEntriesArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryHuliEmrsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  emrType?: InputMaybe<Array<EmrType>>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  doctorName?: InputMaybe<Scalars['String']>;
  patientName?: InputMaybe<Scalars['String']>;
  huliConsultId?: InputMaybe<Scalars['ID']>;
};


export type QueryHuliEmrEntryArgs = {
  id: Scalars['ID'];
};


export type QueryInPersonConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  authorized?: InputMaybe<Scalars['Boolean']>;
  hasHuliEmrEntry?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMemberOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultsWithMessagesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorOnDemandConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorInPersonConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorInPersonConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorScheduledConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryDoctorPaginatedScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorScheduledConsultArgs = {
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type QueryReadyOnDemandConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryConsultStateArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryConsultArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
};


export type QueryPrescriptionArgs = {
  id: Scalars['Int'];
};


export type QueryAllScheduledConsultsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
};


export type QueryListMemberScheduledConsultsArgs = {
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<ScheduledConsultState>>>;
  scheduledConsultClassification?: InputMaybe<GrapheneScheduledConsultClassification>;
};


export type QueryListAllScheduledConsultsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  isRequest: Scalars['Boolean'];
  isCancelled: Scalars['Boolean'];
  scheduledFrom?: InputMaybe<Scalars['DateTime']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']>;
  memberName?: InputMaybe<Scalars['String']>;
  doctorName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  scheduledConsultClassification?: InputMaybe<GrapheneScheduledConsultClassification>;
  createdAtSort?: InputMaybe<QuerySortBy>;
  scheduledAtSort?: InputMaybe<QuerySortBy>;
};


export type QueryScheduledConsultArgs = {
  id: Scalars['ID'];
};


export type QueryCalendarEventsArgs = {
  doctorId: Scalars['ID'];
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCalendarEventArgs = {
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
};


export type QueryCalendarDatesAvailabilityArgs = {
  doctorId: Scalars['ID'];
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryAllCalendarDatesAvailabilityArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCalendarDatesSlotsAvailabilityArgs = {
  doctorId: Scalars['ID'];
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryAllCalendarDatesSlotsAvailabilityArgs = {
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCalendarNextAvailabilityArgs = {
  doctorId: Scalars['ID'];
  isWelcome?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryAllCalendarNextAvailabilityArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFirstTodayScheduledConsultArgs = {
  memberId?: InputMaybe<Scalars['ID']>;
  memberUuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryComplianceAlertsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  alertCategoryFilter?: InputMaybe<Array<InputMaybe<ComplianceAlertCategory>>>;
  applicationStateFilter?: InputMaybe<Array<InputMaybe<HealthPlanApplicationState>>>;
  nameFilter?: InputMaybe<Scalars['String']>;
  annotationFilter?: InputMaybe<ComplianceAlertAnnotation>;
};


export type QueryComplianceAlertArgs = {
  id: Scalars['Int'];
};


export type QueryServiceTypeArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  productId: Scalars['ID'];
};


export type QueryCieAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryServicesArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryLimitedServicesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  uuid?: InputMaybe<Scalars['UUID']>;
};


export type QueryCptAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryEvidenceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryServiceCoverageRulingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberCaseUndefinedServicesCountArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberDefinedDiseaseCasesArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryDoctorMemberDefinedDiseaseCasesArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryMemberServicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  memberId?: InputMaybe<Scalars['Int']>;
};


export type QueryAppMemberServicesArgs = {
  memberId: Scalars['ID'];
};


export type QueryHospitalizationSubtotalAmountByDaysArgs = {
  serviceId: Scalars['ID'];
};


export type QueryChecksLogArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
  byLastCheckedDate?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRunChecksArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
};


export type QueryCheckCfdiExistsArgs = {
  cfdiUuid?: InputMaybe<Scalars['String']>;
};


export type QueryInvoiceItemsByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryBusinessHealthPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessInvoiceItemsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  itemDescription?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessHealthPlanApplicationsArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  linkedToBusinessEmployee?: InputMaybe<Scalars['Boolean']>;
  statesFilter?: InputMaybe<Array<HealthPlanApplicationState>>;
};


export type QueryBusinessHealthPlansArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  statesFilter?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBusinessArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  nameSort?: InputMaybe<QuerySortBy>;
  hasActiveBeneficiariesFilter?: InputMaybe<Scalars['Boolean']>;
  hasActiveBeneficiariesSort?: InputMaybe<QuerySortBy>;
  isCollectiveFilter?: InputMaybe<Scalars['Boolean']>;
  isCollectiveSort?: InputMaybe<QuerySortBy>;
  businessStateFilter?: InputMaybe<Array<BusinessState>>;
};


export type QueryBusinessGroupsConfigArgs = {
  id: Scalars['ID'];
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessGroupsConfigsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  stateFilter?: InputMaybe<Array<BusinessGroupsConfigState>>;
};


export type QueryBusinessStakeholderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessBeneficiaryByMemberArgs = {
  id?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessEmployeesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  businessFilter?: InputMaybe<Scalars['ID']>;
  emailFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  hasLatestHealthPlanApplication?: InputMaybe<Scalars['Boolean']>;
  latestHealthPlanApplicationStates?: InputMaybe<Array<HealthPlanApplicationState>>;
  beneficiaryStates?: InputMaybe<Array<BusinessBeneficiaryState>>;
  beneficiaryCategories?: InputMaybe<Array<BusinessBeneficiaryCategory>>;
};


export type QueryBusinessEmployeesStatisticsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QuerySearchBusinessEmployeesArgs = {
  name: Scalars['String'];
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessInvoiceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessInvoicesArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  idList?: InputMaybe<Array<Scalars['ID']>>;
  uuidList?: InputMaybe<Array<Scalars['ID']>>;
  paymentFlow?: InputMaybe<Scalars['String']>;
  invoiceStateFilters?: InputMaybe<Array<InvoiceState>>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
};


export type QueryBusinessElectronicVoucherArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessElectronicVouchersArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  employeeName?: InputMaybe<Scalars['String']>;
  voucherType?: InputMaybe<SatVoucherType>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessPaymentMethodsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  paymentFlow?: InputMaybe<PaymentFlowTypes>;
};


export type QueryBusinessAdminArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessAdminsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};


export type QueryBalanceAmountsArgs = {
  healthPlanId?: InputMaybe<Scalars['Int']>;
};


export type QueryMiniServicesParamsArgs = {
  healthPlanId: Scalars['ID'];
  serviceType?: InputMaybe<ServiceType>;
};

export type MedicalHistoryType = {
  __typename?: 'MedicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  member: MemberType;
  nonPathologicalHistory: NonPathologicalHistoryType;
  lastUpdatedBy?: Maybe<UserType>;
  lastHuliSyncSnapshot?: Maybe<Scalars['JSONString']>;
  familyPathologiesNotes?: Maybe<Scalars['String']>;
  familyPathologiesState?: Maybe<MedicalHistorySectionState>;
  pathologiesNotes?: Maybe<Scalars['String']>;
  pathologiesState?: Maybe<MedicalHistorySectionState>;
  hospitalizationsNotes?: Maybe<Scalars['String']>;
  hospitalizationsState?: Maybe<MedicalHistorySectionState>;
  surgeriesNotes?: Maybe<Scalars['String']>;
  surgeriesState?: Maybe<MedicalHistorySectionState>;
  allergiesNotes?: Maybe<Scalars['String']>;
  allergiesState?: Maybe<MedicalHistorySectionState>;
  medicinesNotes?: Maybe<Scalars['String']>;
  vaccinesNotes?: Maybe<Scalars['String']>;
  vaccinesState?: Maybe<MedicalHistorySectionState>;
  examsNotes?: Maybe<Scalars['String']>;
  malformationsNotes?: Maybe<Scalars['String']>;
  malformationsState?: Maybe<MedicalHistorySectionState>;
  mentalHealthDiagnosesNotes?: Maybe<Scalars['String']>;
  mentalHealthDiagnosesState?: Maybe<MedicalHistorySectionState>;
  hasTherapy?: Maybe<Scalars['Boolean']>;
  therapyNotes?: Maybe<Scalars['String']>;
  perinatalHistory?: Maybe<PerinatalHistoryType>;
  parentPerinatalHistory?: Maybe<ParentPerinatalHistoryType>;
  basicInfo?: Maybe<BasicInfoType>;
  obstetricGynecologicalHistory?: Maybe<ObstetricGynecologicalHistoryType>;
  allergies: Array<AllergyType>;
  exams: Array<ExamType>;
  familyPathologies: Array<FamilyPathologyType>;
  hospitalizations: Array<HospitalizationType>;
  malformations: Array<MalformationType>;
  medicines: Array<MedicineType>;
  pathologies: Array<PathologyType>;
  surgeries: Array<SurgeryType>;
  vaccines: Array<VaccineType>;
  mentalHealthDiagnoses: Array<MentalHealthDiagnosisType>;
  generalObservations: Array<GeneralObservationType>;
};

export type MemberType = {
  __typename?: 'MemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  assignedPrimaryCareDoctor?: Maybe<DoctorType>;
  antiquitySince?: Maybe<Scalars['Date']>;
  nickname: Scalars['String'];
  riskFactor?: Maybe<Scalars['Decimal']>;
  uuid: Scalars['UUID'];
  heightCm?: Maybe<Scalars['Int']>;
  weightKg?: Maybe<Scalars['Int']>;
  rfc?: Maybe<Scalars['String']>;
  curp?: Maybe<Scalars['String']>;
  representedByUser?: Maybe<UserType>;
  activeMemberSince?: Maybe<Scalars['Date']>;
  birthCountryCode: MemberBirthCountryCode;
  nationalityCountryCode: MemberNationalityCountryCode;
  birthCountry?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  huliPatientFileId?: Maybe<Scalars['String']>;
  maternityWaitingUntil?: Maybe<Scalars['Date']>;
  dentalWaitingUntil?: Maybe<Scalars['Date']>;
  complianceAlerts: Array<ComplianceAlertType>;
  diseaseCases: Array<DiseaseCaseType>;
  user?: Maybe<UserType>;
  /** @deprecated Use representedByUser */
  createdByUser?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  residenceAddress?: Maybe<AddressType>;
  idDoc?: Maybe<DocumentType>;
  rfcDoc?: Maybe<DocumentType>;
  curpDoc?: Maybe<DocumentType>;
  idBacksideDoc?: Maybe<DocumentType>;
  latestApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  memberPhotoUrl?: Maybe<Scalars['String']>;
  memberInformalPhotoUrl?: Maybe<Scalars['String']>;
  antiquityMonths?: Maybe<Scalars['Int']>;
  onDemandConsults?: Maybe<Array<Maybe<OnDemandConsultType>>>;
  welcomeConsultStatus?: Maybe<WelcomeConsultStatusCategory>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
  chatToken?: Maybe<Scalars['String']>;
  idIsPassport?: Maybe<Scalars['Boolean']>;
  insuranceRemainingBalance?: Maybe<Scalars['Int']>;
  percentageInsuranceRemainingBalance?: Maybe<Scalars['String']>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
  displayName: Scalars['String'];
  isBusinessBeneficiary?: Maybe<Scalars['Boolean']>;
  yearlyPsychologyConsultsCount?: Maybe<Scalars['Int']>;
};

export enum MemberGender {
  U = 'U',
  M = 'M',
  F = 'F'
}

export type DoctorType = {
  __typename?: 'DoctorType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  nickname: Scalars['String'];
  licenseNumber: Scalars['String'];
  licenseInstitution: Scalars['String'];
  availableOnDemand: Scalars['Boolean'];
  isAuthorizedForOnDemand?: Maybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: Maybe<Scalars['Boolean']>;
  showDoctorInApp?: Maybe<Scalars['Boolean']>;
  directAppointment: Scalars['Boolean'];
  appointmentTelephone?: Maybe<Scalars['String']>;
  huliId?: Maybe<Scalars['Int']>;
  descriptor?: Maybe<Scalars['String']>;
  autoEmitEnabled: Scalars['Boolean'];
  isPrimaryCareDoctor?: Maybe<Scalars['Boolean']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  welcomeCalendlyUrl?: Maybe<Scalars['String']>;
  onLeaveFrom?: Maybe<Scalars['Date']>;
  onLeaveUntil?: Maybe<Scalars['Date']>;
  leaveReason?: Maybe<DoctorLeaveReason>;
  allowPrimaryCareMembersAssignment: Scalars['Boolean'];
  specializationFare?: Maybe<SpecializationFareType>;
  isActive?: Maybe<Scalars['Boolean']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  isPediatricsCareDoctor?: Maybe<Scalars['Boolean']>;
  isPediatric?: Maybe<Scalars['Boolean']>;
  residencyHospital?: Maybe<Scalars['String']>;
  curp?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  internalNotes?: Maybe<Scalars['String']>;
  doctoraliaUrl?: Maybe<Scalars['String']>;
  photo?: Maybe<DocumentType>;
  liabilityInsurance?: Maybe<DocumentType>;
  curriculumVitae?: Maybe<DocumentType>;
  user?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
  officeAddress?: Maybe<AddressType>;
  secondaryOfficeAddress?: Maybe<AddressType>;
  doctorSpecialistDetails?: Maybe<Array<DoctorSpecialistDetailType>>;
  prefixedFullName?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  feedToken?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  isOnLeave?: Maybe<Scalars['Boolean']>;
  isAllowedForScheduledConsults?: Maybe<Scalars['Boolean']>;
  signatureImageUrl?: Maybe<Scalars['String']>;
};

export enum DoctorLeaveReason {
  Maternity = 'MATERNITY',
  Paternity = 'PATERNITY',
  Vacationing = 'VACATIONING',
  Recovery = 'RECOVERY'
}

export type SpecializationFareType = {
  __typename?: 'SpecializationFareType';
  id: Scalars['ID'];
  name: Scalars['String'];
  specialization: SpecializationFareSpecialization;
  amountCents: Scalars['Int'];
};

export enum SpecializationFareSpecialization {
  Cra = 'CRA',
  Any = 'ANY',
  Ana = 'ANA',
  Bra = 'BRA',
  Cry = 'CRY',
  Jns = 'JNS',
  Brs = 'BRS',
  Crs = 'CRS',
  Skb = 'SKB',
  Rta = 'RTA',
  Drs = 'DRS',
  Hmg = 'HMG',
  Mfm = 'MFM',
  Gns = 'GNS',
  Ons = 'ONS',
  Pdr = 'PDR',
  Pla = 'PLA',
  Rbs = 'RBS',
  Dry = 'DRY',
  Pdd = 'PDD',
  Eny = 'ENY',
  Dga = 'DGA',
  Phy = 'PHY',
  Gsy = 'GSY',
  Pdg = 'PDG',
  Mdg = 'MDG',
  Grs = 'GRS',
  Gya = 'GYA',
  Dgi = 'DGI',
  Crm = 'CRM',
  Inm = 'INM',
  Npy = 'NPY',
  Nny = 'NNY',
  Pdp = 'PDP',
  Nrs = 'NRS',
  Clr = 'CLR',
  Nry = 'NRY',
  Cln = 'CLN',
  Opy = 'OPY',
  Mdo = 'MDO',
  Ors = 'ORS',
  Otr = 'OTR',
  Pdo = 'PDO',
  Oty = 'OTY',
  Pds = 'PDS',
  Clp = 'CLP',
  Psy = 'PSY',
  Lnp = 'LNP',
  Vsa = 'VSA',
  Rhy = 'RHY',
  Ury = 'URY',
  Gyu = 'GYU',
  Rhm = 'RHM',
  Onu = 'ONU',
  Pdu = 'PDU',
  Iny = 'INY',
  Cly = 'CLY',
  Aos = 'AOS',
  Pnm = 'PNM',
  Aim = 'AIM',
  Gae = 'GAE',
  Cap = 'CAP'
}

export type DocumentType = {
  __typename?: 'DocumentType';
  id: Scalars['ID'];
  s3Key?: Maybe<Scalars['String']>;
  isValidated: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  uploadedBy?: Maybe<BriefUserType>;
  url?: Maybe<Scalars['String']>;
  validatedBy?: Maybe<BriefUserType>;
  category?: Maybe<DocumentCategory>;
  documentSubtype?: Maybe<DocumentSubtype>;
  documentVersions?: Maybe<Array<Maybe<DocumentVersionType>>>;
};

export type BriefUserType = {
  __typename?: 'BriefUserType';
  id: Scalars['ID'];
};

export enum DocumentCategory {
  Signature = 'SIGNATURE',
  Identity = 'IDENTITY',
  IdentityBackside = 'IDENTITY_BACKSIDE',
  Curp = 'CURP',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  Dependent = 'DEPENDENT',
  Rfc = 'RFC',
  ElectronicVoucherXml = 'ELECTRONIC_VOUCHER_XML',
  ElectronicVoucherPdf = 'ELECTRONIC_VOUCHER_PDF',
  CollectiveInitialSubscriptionAmendmentCover = 'COLLECTIVE_INITIAL_SUBSCRIPTION_AMENDMENT_COVER',
  CollectiveAdditionalSubscriptionAmendmentCover = 'COLLECTIVE_ADDITIONAL_SUBSCRIPTION_AMENDMENT_COVER',
  CollectiveSubscriptionCancellationAmendmentCover = 'COLLECTIVE_SUBSCRIPTION_CANCELLATION_AMENDMENT_COVER',
  SubscriptionAmendmentCover = 'SUBSCRIPTION_AMENDMENT_COVER',
  MaternityAmendmentCover = 'MATERNITY_AMENDMENT_COVER',
  DentalAmendmentCover = 'DENTAL_AMENDMENT_COVER',
  SubscriptionBrochure = 'SUBSCRIPTION_BROCHURE',
  SubscriptionGeneralConditions = 'SUBSCRIPTION_GENERAL_CONDITIONS',
  ClaimsEvidence = 'CLAIMS_EVIDENCE',
  ClaimsInvoiceXml = 'CLAIMS_INVOICE_XML',
  ClaimsInvoicePdf = 'CLAIMS_INVOICE_PDF',
  ClaimsInvoiceAnalysis = 'CLAIMS_INVOICE_ANALYSIS',
  AmendmentComplementary = 'AMENDMENT_COMPLEMENTARY',
  AmendmentSummary = 'AMENDMENT_SUMMARY',
  InvoiceXml = 'INVOICE_XML',
  InvoicePdf = 'INVOICE_PDF',
  InvoiceXlsx = 'INVOICE_XLSX',
  InvoicePdfSnapshot = 'INVOICE_PDF_SNAPSHOT',
  IntlCoverageAxaCert = 'INTL_COVERAGE_AXA_CERT',
  BusinessDocument = 'BUSINESS_DOCUMENT',
  StakeholderDocument = 'STAKEHOLDER_DOCUMENT',
  BusinessTaxRegistration = 'BUSINESS_TAX_REGISTRATION',
  BusinessDigitalTaxSignatureProof = 'BUSINESS_DIGITAL_TAX_SIGNATURE_PROOF',
  BusinessTradeRegistrationProof = 'BUSINESS_TRADE_REGISTRATION_PROOF',
  BusinessProofOfAddress = 'BUSINESS_PROOF_OF_ADDRESS',
  BusinessContractDocument = 'BUSINESS_CONTRACT_DOCUMENT',
  BusinessRegistrationToCommercePublicRegistry = 'BUSINESS_REGISTRATION_TO_COMMERCE_PUBLIC_REGISTRY',
  BusinessInvoiceElectronicVouchersXmlZip = 'BUSINESS_INVOICE_ELECTRONIC_VOUCHERS_XML_ZIP',
  BusinessInvoiceElectronicVouchersPdfZip = 'BUSINESS_INVOICE_ELECTRONIC_VOUCHERS_PDF_ZIP',
  StakeholderId = 'STAKEHOLDER_ID',
  StakeholderRoleProof = 'STAKEHOLDER_ROLE_PROOF',
  StakeholderForeignerDoc = 'STAKEHOLDER_FOREIGNER_DOC',
  StakeholderCurpDoc = 'STAKEHOLDER_CURP_DOC',
  PrescriptionPdf = 'PRESCRIPTION_PDF',
  TransactionProof = 'TRANSACTION_PROOF',
  CovidVaccineProof = 'COVID_VACCINE_PROOF',
  OdontoprevCard = 'ODONTOPREV_CARD',
  OdontoprevCover = 'ODONTOPREV_COVER',
  OdontoprevCollectiveCover = 'ODONTOPREV_COLLECTIVE_COVER',
  EmrDocument = 'EMR_DOCUMENT',
  CaseEventQuote = 'CASE_EVENT_QUOTE',
  CaseEventAuthorizationLetter = 'CASE_EVENT_AUTHORIZATION_LETTER',
  Quote = 'QUOTE',
  DoctorCurriculumVitae = 'DOCTOR_CURRICULUM_VITAE',
  DoctorLiabilityInsurance = 'DOCTOR_LIABILITY_INSURANCE',
  DoctorPhoto = 'DOCTOR_PHOTO',
  DoctorSpecializationCertificate = 'DOCTOR_SPECIALIZATION_CERTIFICATE'
}

export enum DocumentSubtype {
  Unspecified = 'UNSPECIFIED',
  Ine = 'INE',
  Passport = 'PASSPORT'
}

export type DocumentVersionType = {
  __typename?: 'DocumentVersionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  selfDocument?: Maybe<DocumentType>;
  previousDocument?: Maybe<DocumentType>;
  version: Scalars['Int'];
  replacedAt: Scalars['DateTime'];
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  email: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  isSuperuser: Scalars['Boolean'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  intercomContactId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  unverifiedEmail?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  /** @deprecated Use user.is_doctor */
  doctorEnabled?: Maybe<Scalars['Boolean']>;
  memberEnabled: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  isOnboarded: Scalars['Boolean'];
  appStage: UserAppStage;
  subscriptionState?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<UserNamePrefix>;
  productFamilySubscriptionIntent?: Maybe<ProductFamilies>;
  member?: Maybe<MemberType>;
  membersRepresented: Array<MemberType>;
  healthPlans?: Maybe<Array<Maybe<HealthPlanType>>>;
  providerBranchOffices: Array<ProviderBranchType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  doctor?: Maybe<DoctorType>;
  businessAdmin?: Maybe<BusinessAdminType>;
  firstName?: Maybe<Scalars['String']>;
  isDoctor: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  pushTokens?: Maybe<Array<Maybe<PushTokenType>>>;
  billingAddress?: Maybe<AddressType>;
  shippingAddress?: Maybe<AddressType>;
  /** @deprecated Use business_admin.business */
  adminBusiness?: Maybe<BusinessType>;
  latestHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  nextBillingDate?: Maybe<Scalars['Date']>;
  membersCreated?: Maybe<Array<Maybe<MemberType>>>;
  primaryBillingInfo?: Maybe<BillingInfoType>;
  payoutMethod?: Maybe<PaymentMethodType>;
  payinMethod?: Maybe<PaymentMethodType>;
  payinSpeiMethod?: Maybe<PaymentMethodType>;
  payinManualMethod?: Maybe<PaymentMethodType>;
  payoutManualMethod?: Maybe<PaymentMethodType>;
  payinMethods?: Maybe<Array<Maybe<PaymentMethodType>>>;
  hasManualPaymentMethods?: Maybe<Scalars['Boolean']>;
  referralCode?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  s3ProfilePhotoKey?: Maybe<Scalars['String']>;
  firebaseCustomToken?: Maybe<Scalars['String']>;
  interestFreePaymentEnabled?: Maybe<Scalars['Boolean']>;
  payableInvoices?: Maybe<Array<Maybe<InvoiceType>>>;
  receivableInvoices?: Maybe<Array<Maybe<InvoiceType>>>;
  representedBeneficiaryHealthPlans?: Maybe<Array<Maybe<RepresentedBeneficiaryHealthPlanType>>>;
  ownedHealthPlans?: Maybe<Array<Maybe<OwnedHealthPlanType>>>;
  groups?: Maybe<Array<GroupName>>;
  hasElectronicVouchersPendingSignature?: Maybe<Scalars['Boolean']>;
  canFreeUpEmailOrTelephone?: Maybe<Scalars['Boolean']>;
};

export enum UserAppStage {
  Su = 'SU',
  Sb = 'SB',
  Co = 'CO',
  Wl = 'WL',
  Wb = 'WB',
  Wc = 'WC',
  Ac = 'AC',
  In = 'IN'
}

export enum UserNamePrefix {
  Dr = 'DR',
  Dra = 'DRA'
}

export enum ProductFamilies {
  Morado = 'morado',
  Mini = 'mini',
  Colectivo = 'colectivo'
}

export type HealthPlanType = {
  __typename?: 'HealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
  relationship: HealthPlanRelationship;
  state: HealthPlanState;
  validSince?: Maybe<Scalars['DateTime']>;
  validThrough?: Maybe<Scalars['DateTime']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  maternityAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  product?: Maybe<ProductType>;
  isOwnedByBusiness: Scalars['Boolean'];
  responsibleForServices?: Maybe<ResponsibleForServices>;
  uuid: Scalars['UUID'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  initialExclusions?: Maybe<Scalars['String']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  subscriptionType: HealthPlanSubscriptionType;
  automaticRenew: Scalars['Boolean'];
  version: Scalars['Int'];
  owner?: Maybe<UserType>;
  amendments?: Maybe<Array<Maybe<HealthPlanAmendmentType>>>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  gracePeriodEnd?: Maybe<Scalars['String']>;
  daysOnGracePeriod?: Maybe<Scalars['Int']>;
  isInGracePeriod?: Maybe<Scalars['Boolean']>;
  isCollective?: Maybe<Scalars['Boolean']>;
  intlCoverageValidSince?: Maybe<Scalars['DateTime']>;
  generalConditionsDoc?: Maybe<DocumentType>;
  brochureDoc?: Maybe<DocumentType>;
  coverDoc?: Maybe<DocumentType>;
  insuredAmountCents?: Maybe<Scalars['Int']>;
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
  totalCents?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  premiumProvidersAddon?: Maybe<Scalars['Boolean']>;
  exclusionsPendingApproval?: Maybe<Scalars['String']>;
  productMetadata?: Maybe<Scalars['JSONString']>;
  hasActiveServices?: Maybe<Scalars['Boolean']>;
  beneficiaryRepresentativeHasInvoicesPendingPayment?: Maybe<Scalars['Boolean']>;
};

export enum HealthPlanRelationship {
  Wf = 'WF',
  Hs = 'HS',
  Dg = 'DG',
  Sl = 'SL',
  Sn = 'SN',
  Ft = 'FT',
  Mt = 'MT',
  Gp = 'GP',
  Sb = 'SB',
  Gc = 'GC',
  Np = 'NP'
}

export enum HealthPlanState {
  Ac = 'AC',
  In = 'IN'
}

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: ProductCategory;
  productMetadataParameters?: Maybe<Scalars['JSONString']>;
  pricingModel?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  productFamily?: Maybe<ProductFamilies>;
  regulatoryRegistrationId?: Maybe<Scalars['String']>;
  userRightsAgencyRegistrationId?: Maybe<Scalars['String']>;
  state: ProductState;
  healthPlanTemplate?: Maybe<Scalars['String']>;
  questionnaire: Scalars['JSONString'];
  closedAt?: Maybe<Scalars['DateTime']>;
  pricingParameters?: Maybe<Scalars['JSONString']>;
};

export enum ProductCategory {
  In = 'IN'
}

export enum ProductState {
  Dr = 'DR',
  Av = 'AV',
  Cl = 'CL'
}

export enum ResponsibleForServices {
  Owner = 'OWNER',
  Beneficiary = 'BENEFICIARY'
}

export enum HealthPlanSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type HealthPlanAmendmentType = {
  __typename?: 'HealthPlanAmendmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  reviewedAt?: Maybe<Scalars['DateTime']>;
  remainingMonthsBeforeApplying?: Maybe<Scalars['Int']>;
  amendmentCategory: HealthPlanAmendmentAmendmentCategory;
  reviewResult: HealthPlanAmendmentReviewResult;
  amendmentDetails?: Maybe<Scalars['JSONString']>;
  amendmentExtras?: Maybe<Scalars['JSONString']>;
  reviewedByUser?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  previousHealthPlan?: Maybe<HealthPlanType>;
  attachmentDocuments?: Maybe<Array<Maybe<DocumentType>>>;
  ownerPrimaryBillingInfo?: Maybe<BillingInfoType>;
};

export enum HealthPlanAmendmentAmendmentCategory {
  Em = 'EM',
  Cn = 'CN',
  Db = 'DB',
  Md = 'MD',
  Pp = 'PP',
  Fr = 'FR',
  Bf = 'BF',
  Sa = 'SA',
  Bi = 'BI',
  Mt = 'MT',
  Dt = 'DT',
  Ic = 'IC',
  Ac = 'AC',
  Cs = 'CS',
  As = 'AS',
  Cd = 'CD',
  Cm = 'CM',
  Cic = 'CIC',
  Cpc = 'CPC',
  Cdc = 'CDC',
  Cgc = 'CGC',
  Ccc = 'CCC',
  Cac = 'CAC'
}

export enum HealthPlanAmendmentReviewResult {
  Pn = 'PN',
  Ap = 'AP',
  Dn = 'DN'
}

export type BillingInfoType = {
  __typename?: 'BillingInfoType';
  id: Scalars['ID'];
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
  satUseType?: Maybe<BillingInfoSatUseType>;
  satProductServiceKey?: Maybe<BillingInfoSatProductServiceKey>;
  taxRegime?: Maybe<SatRegime>;
  addressZipCode?: Maybe<Scalars['String']>;
  requiresElectronicVoucher: Scalars['Boolean'];
  address?: Maybe<AddressType>;
};

export enum BillingInfoSatUseType {
  D07 = 'D07',
  D01 = 'D01',
  P01 = 'P01',
  G03 = 'G03',
  S01 = 'S01',
  Cp01 = 'CP01',
  Unk = 'UNK'
}

export enum BillingInfoSatProductServiceKey {
  A_85121500 = 'A_85121500',
  A_85121501 = 'A_85121501',
  A_85121502 = 'A_85121502',
  A_85121503 = 'A_85121503',
  A_85121504 = 'A_85121504',
  A_85121600 = 'A_85121600',
  A_85121601 = 'A_85121601',
  A_85121602 = 'A_85121602',
  A_85121603 = 'A_85121603',
  A_85121604 = 'A_85121604',
  A_85121605 = 'A_85121605',
  A_85121606 = 'A_85121606',
  A_85121607 = 'A_85121607',
  A_85121608 = 'A_85121608',
  A_85121609 = 'A_85121609',
  A_85121610 = 'A_85121610',
  A_85121611 = 'A_85121611',
  A_85121612 = 'A_85121612',
  A_85121613 = 'A_85121613',
  A_85121614 = 'A_85121614',
  A_85121700 = 'A_85121700',
  A_85121701 = 'A_85121701',
  A_85121702 = 'A_85121702',
  A_85121703 = 'A_85121703',
  A_85121704 = 'A_85121704',
  A_85121705 = 'A_85121705',
  A_85121706 = 'A_85121706',
  A_85121800 = 'A_85121800',
  A_85121801 = 'A_85121801',
  A_85121802 = 'A_85121802',
  A_85121803 = 'A_85121803',
  A_85121804 = 'A_85121804',
  A_85121805 = 'A_85121805',
  A_85121806 = 'A_85121806',
  A_85121807 = 'A_85121807',
  A_85121808 = 'A_85121808',
  A_85121809 = 'A_85121809',
  A_85121810 = 'A_85121810',
  A_85121811 = 'A_85121811',
  A_85121900 = 'A_85121900',
  A_85121901 = 'A_85121901',
  A_85121902 = 'A_85121902',
  A_85122000 = 'A_85122000',
  A_85122001 = 'A_85122001',
  A_85122002 = 'A_85122002',
  A_85122003 = 'A_85122003',
  A_85122004 = 'A_85122004',
  A_85122005 = 'A_85122005',
  A_85122100 = 'A_85122100',
  A_85122101 = 'A_85122101',
  A_85122102 = 'A_85122102',
  A_85122103 = 'A_85122103',
  A_85122104 = 'A_85122104',
  A_85122105 = 'A_85122105',
  A_85122106 = 'A_85122106',
  A_85122107 = 'A_85122107',
  A_85122108 = 'A_85122108',
  A_85122109 = 'A_85122109',
  A_85122200 = 'A_85122200',
  A_85122201 = 'A_85122201',
  A_84131602 = 'A_84131602',
  A_84111506 = 'A_84111506',
  A_85101605 = 'A_85101605',
  A_85101601 = 'A_85101601',
  A_01010101 = 'A_01010101',
  A_85101501 = 'A_85101501',
  A_53131613 = 'A_53131613'
}

export enum SatRegime {
  LegalEntity = 'LEGAL_ENTITY',
  NaturalPerson = 'NATURAL_PERSON',
  GenericEntity = 'GENERIC_ENTITY',
  NaturalPersonSimplifiedTaxRegime = 'NATURAL_PERSON_SIMPLIFIED_TAX_REGIME',
  NaturalPersonSalariesAndSimilarsIncome = 'NATURAL_PERSON_SALARIES_AND_SIMILARS_INCOME',
  NaturalPersonTaxIncorporation = 'NATURAL_PERSON_TAX_INCORPORATION',
  NaturalPersonWithTechPlatformIncome = 'NATURAL_PERSON_WITH_TECH_PLATFORM_INCOME',
  NaturalPersonForeignResidence = 'NATURAL_PERSON_FOREIGN_RESIDENCE',
  LegalEntityNonLucrative = 'LEGAL_ENTITY_NON_LUCRATIVE',
  Lease = 'LEASE',
  GoodsAcquisition = 'GOODS_ACQUISITION',
  OtherIncome = 'OTHER_INCOME',
  StockIncome = 'STOCK_INCOME',
  InterestIncome = 'INTEREST_INCOME',
  PrizeIncome = 'PRIZE_INCOME',
  DeferredIncome = 'DEFERRED_INCOME',
  AgriculturalLivestockForestryAndFishingActivities = 'AGRICULTURAL_LIVESTOCK_FORESTRY_AND_FISHING_ACTIVITIES',
  OptionalForSocietyGroups = 'OPTIONAL_FOR_SOCIETY_GROUPS',
  Coordinated = 'COORDINATED'
}

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  primary: Scalars['Boolean'];
  references?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  huliClinicId?: Maybe<Scalars['Int']>;
  googleMapsUrl?: Maybe<Scalars['String']>;
  temporaryVirtualOffice: Scalars['Boolean'];
  proofDoc?: Maybe<DocumentType>;
  telephoneCategory?: Maybe<TelephoneCategory>;
};

export enum TelephoneCategory {
  Unknown = 'UNKNOWN',
  Landline = 'LANDLINE',
  Mobile = 'MOBILE'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state?: Maybe<InvoiceState>;
  billingInfo: Scalars['JSONString'];
  dueAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['UUID'];
  digitalTaxVoucherDoc?: Maybe<DocumentType>;
  items?: Maybe<Array<Maybe<InvoiceItemType>>>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
  hasPayInElectronicVoucher?: Maybe<Scalars['Boolean']>;
  totalAmountCents?: Maybe<Scalars['Int']>;
  sumTotalCents?: Maybe<Scalars['Int']>;
  sumVatCents?: Maybe<Scalars['Int']>;
  sumSubtotalCents?: Maybe<Scalars['Int']>;
  /** @deprecated Name is not representative, use sumSubtotalCents */
  sumAmountCents?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<TransactionType>>>;
  latestElectronicVoucher?: Maybe<ElectronicVoucherType>;
  issuedPaymentElectronicVouchers?: Maybe<Scalars['Int']>;
  paymentElectronicVouchers?: Maybe<Scalars['Int']>;
  successfulTransaction?: Maybe<TransactionType>;
  requiredElectronicVouchers?: Maybe<Array<Maybe<SatVoucherType>>>;
  signedSumTotalCents?: Maybe<Scalars['Int']>;
  successfulElectronicVoucherCount?: Maybe<Scalars['Int']>;
  expectedElectronicVouchersCount?: Maybe<Scalars['Int']>;
  servicesList?: Maybe<Array<Maybe<Scalars['String']>>>;
  canBePaid?: Maybe<Scalars['Boolean']>;
  allRelatedElectronicVouchersAreIssued?: Maybe<Scalars['Boolean']>;
  latestDispute?: Maybe<PaymentDisputeType>;
  isSubscriptionInvoice?: Maybe<Scalars['Boolean']>;
  isServiceInvoice?: Maybe<Scalars['Boolean']>;
  isAutomaticPaymentServiceInvoice?: Maybe<Scalars['Boolean']>;
  subscriptionHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  customerPaymentFlowType?: Maybe<CustomerPaymentFlowType>;
  scheduledPayoutEta?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Array<Maybe<CommentType>>>;
};

export enum InvoiceState {
  Draft = 'DRAFT',
  NotPaid = 'NOT_PAID',
  PaymentRejected = 'PAYMENT_REJECTED',
  Refunded = 'REFUNDED',
  FundsWithdrawn = 'FUNDS_WITHDRAWN',
  PaymentOrderSent = 'PAYMENT_ORDER_SENT',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  InReview = 'IN_REVIEW',
  Paid = 'PAID',
  Applied = 'APPLIED',
  Cancelled = 'CANCELLED',
  Uncollectible = 'UNCOLLECTIBLE'
}

export type InvoiceItemType = {
  __typename?: 'InvoiceItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  objectId: Scalars['Int'];
  vatCents: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  paymentFlow?: Maybe<InvoiceItemPaymentFlow>;
  description?: Maybe<Scalars['String']>;
  totalCents: Scalars['Int'];
  currency: InvoiceItemCurrency;
  installmentNumber: Scalars['Int'];
  state?: Maybe<InvoiceState>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  /** @deprecated Name is not representative, use subtotalCents */
  amountCents?: Maybe<Scalars['Int']>;
  contentType?: Maybe<ContentTypeType>;
  contentObject?: Maybe<ContentObjectType>;
  electronicVoucherStatus?: Maybe<Scalars['String']>;
  relatedIncome?: Maybe<IncomeType>;
  isService?: Maybe<Scalars['Boolean']>;
  healthPlanRelatedDetails?: Maybe<InvoiceItemHealthPlanRelatedDetails>;
};

export enum InvoiceItemPaymentFlow {
  In = 'IN',
  Out = 'OUT'
}

export enum InvoiceItemCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ElectronicVoucherType = {
  __typename?: 'ElectronicVoucherType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
  voucherType: ElectronicVoucherVoucherType;
  xmlDocument?: Maybe<DocumentType>;
  pdfDocument?: Maybe<DocumentType>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
  electronicVoucherOperationState?: Maybe<ElectronicVoucherElectronicVoucherOperationState>;
  discountCents: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  totalCents: Scalars['Int'];
  totalWithholdTaxesCents: Scalars['Int'];
  totalTransferredTaxesCents: Scalars['Int'];
  emissionZipcode?: Maybe<Scalars['String']>;
  cfdiVersion?: Maybe<Scalars['String']>;
  issuerTaxRegime?: Maybe<SatRegime>;
  issuerName?: Maybe<Scalars['String']>;
  recipientTaxRegime?: Maybe<SatRegime>;
  recipientUseCase?: Maybe<SatInvoiceUseType>;
  recipientName?: Maybe<Scalars['String']>;
  issuerTaxNumber?: Maybe<Scalars['String']>;
  recipientTaxNumber?: Maybe<Scalars['String']>;
  recipientAddressZipcode?: Maybe<Scalars['String']>;
  paymentType?: Maybe<SatPaymentForm>;
  electronicVoucherRelationType?: Maybe<SatElectronicVoucherRelationType>;
  secondaryElectronicVouchers?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
  invoiceItems?: Maybe<Array<Maybe<InvoiceItemType>>>;
  payments?: Maybe<Array<Maybe<ElectronicVoucherPaymentType>>>;
  transactions?: Maybe<Array<Maybe<ElectronicVoucherTransactionType>>>;
  items?: Maybe<Array<Maybe<ElectronicVoucherItemsType>>>;
  taxes?: Maybe<Array<Maybe<ElectronicVoucherTaxesType>>>;
  latestTransaction?: Maybe<ElectronicVoucherTransactionType>;
  paymentsApplied?: Maybe<Array<Maybe<ElectronicVoucherPaymentItemType>>>;
  member?: Maybe<LimitedHealthPlanMemberType>;
  isSigned?: Maybe<Scalars['Boolean']>;
  relatedIncomeElectronicVouchers?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
  description?: Maybe<Scalars['String']>;
  incomes?: Maybe<Array<Maybe<IncomeType>>>;
  xmlString?: Maybe<Scalars['String']>;
};

export enum ElectronicVoucherVoucherType {
  I = 'I',
  E = 'E',
  P = 'P'
}

export enum ElectronicVoucherElectronicVoucherOperationState {
  Is = 'IS',
  Ca = 'CA',
  Ip = 'IP',
  Cf = 'CF',
  Ni = 'NI',
  Dr = 'DR',
  Nc = 'NC',
  Cb = 'CB',
  Rp = 'RP',
  Nd = 'ND'
}

export enum SatInvoiceUseType {
  MedicalExpensesInsurance = 'MEDICAL_EXPENSES_INSURANCE',
  MedicalDentistryAndHospitalServices = 'MEDICAL_DENTISTRY_AND_HOSPITAL_SERVICES',
  ToBeDefined = 'TO_BE_DEFINED',
  GeneralExpenses = 'GENERAL_EXPENSES',
  NoTaxApplicable = 'NO_TAX_APPLICABLE',
  PaymentsUseCase = 'PAYMENTS_USE_CASE',
  Unknown = 'UNKNOWN'
}

export enum SatPaymentForm {
  ToBeDefined = 'TO_BE_DEFINED',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  WireTransfer = 'WIRE_TRANSFER',
  PaymentGateway = 'PAYMENT_GATEWAY',
  DigitalCurrency = 'DIGITAL_CURRENCY',
  Cash = 'CASH',
  Check = 'CHECK',
  Compensation = 'COMPENSATION'
}

export enum SatElectronicVoucherRelationType {
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  MerchandiseReturn = 'MERCHANDISE_RETURN',
  ElectronicVoucherSubstitution = 'ELECTRONIC_VOUCHER_SUBSTITUTION',
  PreviousInvoicedMerchandiseTransfer = 'PREVIOUS_INVOICED_MERCHANDISE_TRANSFER',
  ElectronicVoucherFromPreviousTransferredTax = 'ELECTRONIC_VOUCHER_FROM_PREVIOUS_TRANSFERRED_TAX',
  DownPayment = 'DOWN_PAYMENT',
  PpdGeneratedElectronicVoucher = 'PPD_GENERATED_ELECTRONIC_VOUCHER',
  PueGeneratedElectronicVoucher = 'PUE_GENERATED_ELECTRONIC_VOUCHER'
}

export type ElectronicVoucherPaymentType = {
  __typename?: 'ElectronicVoucherPaymentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  amountCents: Scalars['Int'];
  currency: ElectronicVoucherPaymentCurrency;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentType: ElectronicVoucherPaymentPaymentType;
  taxCode?: Maybe<ElectronicVoucherPaymentTaxCode>;
  items?: Maybe<Array<Maybe<ElectronicVoucherPaymentItemType>>>;
};

export enum ElectronicVoucherPaymentCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum ElectronicVoucherPaymentPaymentType {
  A_99 = 'A_99',
  A_04 = 'A_04',
  A_28 = 'A_28',
  A_03 = 'A_03',
  A_31 = 'A_31',
  A_06 = 'A_06',
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_17 = 'A_17'
}

export enum ElectronicVoucherPaymentTaxCode {
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_03 = 'A_03'
}

export type ElectronicVoucherPaymentItemType = {
  __typename?: 'ElectronicVoucherPaymentItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherPayment?: Maybe<ElectronicVoucherPaymentType>;
  electronicVoucherPaid?: Maybe<ElectronicVoucherType>;
  electronicVoucherUuid?: Maybe<Scalars['String']>;
  seriesValue?: Maybe<ElectronicVoucherPaymentItemsSeriesValue>;
  folioNumber?: Maybe<Scalars['String']>;
  lastBalanceCents: Scalars['Int'];
  paidCents: Scalars['Int'];
  unpaidBalanceCents: Scalars['Int'];
  paymentMethod?: Maybe<ElectronicVoucherPaymentItemsPaymentMethod>;
  currency: ElectronicVoucherPaymentItemsCurrency;
  installmentNumber: Scalars['Int'];
};

export enum ElectronicVoucherPaymentItemsSeriesValue {
  Hpi = 'HPI',
  Hpp = 'HPP',
  Hpei = 'HPEI',
  Hpep = 'HPEP',
  Vc = 'VC',
  Ip = 'IP',
  Ami = 'AMI',
  Amo = 'AMO',
  SofiaOd = 'SOFIA_OD',
  SofiaIp = 'SOFIA_IP',
  Cso = 'CSO',
  Po = 'PO',
  Csi = 'CSI'
}

export enum ElectronicVoucherPaymentItemsPaymentMethod {
  Pue = 'PUE',
  Ppd = 'PPD'
}

export enum ElectronicVoucherPaymentItemsCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ElectronicVoucherTransactionType = {
  __typename?: 'ElectronicVoucherTransactionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucherGatewayRequest: Scalars['String'];
  electronicVoucherGatewayConfirmation: Scalars['String'];
  electronicVoucherGatewayResult?: Maybe<ElectronicVoucherTransactionElectronicVoucherGatewayResult>;
  isAutoEmitted: Scalars['Boolean'];
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherGatewayCancellationResult?: Maybe<Scalars['String']>;
  signingErrorCategory?: Maybe<ElectronicVoucherTransactionSigningErrorCategory>;
};

export enum ElectronicVoucherTransactionElectronicVoucherGatewayResult {
  Ok = 'OK',
  Fl = 'FL'
}

export enum ElectronicVoucherTransactionSigningErrorCategory {
  CfdiUseError = 'CFDI_USE_ERROR',
  CsdNotAuthorized = 'CSD_NOT_AUTHORIZED',
  DuplicatedVoucher = 'DUPLICATED_VOUCHER',
  GeneralPublicInfoError = 'GENERAL_PUBLIC_INFO_ERROR',
  InvalidVoucherStructure = 'INVALID_VOUCHER_STRUCTURE',
  IssuerRfcNotRegisteredInReachcore = 'ISSUER_RFC_NOT_REGISTERED_IN_REACHCORE',
  IssuerRfcNotValid = 'ISSUER_RFC_NOT_VALID',
  RecipientNameError = 'RECIPIENT_NAME_ERROR',
  RecipientRfcNotRegisteredInSat = 'RECIPIENT_RFC_NOT_REGISTERED_IN_SAT',
  RecipientRfcNotValid = 'RECIPIENT_RFC_NOT_VALID',
  RecipientTaxAddressExpeditionPlaceError = 'RECIPIENT_TAX_ADDRESS_EXPEDITION_PLACE_ERROR',
  RecipientTaxAddressMatchError = 'RECIPIENT_TAX_ADDRESS_MATCH_ERROR',
  RegimeError = 'REGIME_ERROR',
  WithholdTaxesError = 'WITHHOLD_TAXES_ERROR',
  TotalFieldSumError = 'TOTAL_FIELD_SUM_ERROR',
  TotalTransferredTaxesSumError = 'TOTAL_TRANSFERRED_TAXES_SUM_ERROR',
  TransferAmountError = 'TRANSFER_AMOUNT_ERROR',
  ZipcodeError = 'ZIPCODE_ERROR',
  UnknownError = 'UNKNOWN_ERROR'
}

export type ElectronicVoucherItemsType = {
  __typename?: 'ElectronicVoucherItemsType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  productServiceKey?: Maybe<ElectronicVoucherItemsProductServiceKey>;
  unitKey?: Maybe<ElectronicVoucherItemsUnitKey>;
  taxCode?: Maybe<ElectronicVoucherItemsTaxCode>;
  description?: Maybe<Scalars['String']>;
  amountCents: Scalars['Int'];
  discountCents: Scalars['Int'];
  unitValueCents: Scalars['Int'];
  totalWithholdTaxesCents: Scalars['Int'];
  totalTransferredTaxesCents: Scalars['Int'];
  electronicVoucherItems: Array<ServiceElectronicVoucherItemType>;
  taxes?: Maybe<Array<Maybe<ElectronicVoucherTaxesType>>>;
};

export type ContentTypeType = {
  __typename?: 'ContentTypeType';
  id: Scalars['ID'];
  appLabel: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  permissionSet: Array<PermissionType>;
  paymentdisputeSet: Array<PaymentDisputeType>;
  commentSet: Array<CommentType>;
  incomeSet: Array<IncomeType>;
  invoiceitemSet: Array<InvoiceItemType>;
  electronicvouchertaxesSet: Array<ElectronicVoucherTaxesType>;
  electronicvoucheritemsSet: Array<ElectronicVoucherItemsType>;
  serviceSet: Array<ServicesType>;
  name?: Maybe<Scalars['String']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  codename: Scalars['String'];
  appLabel?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type PaymentDisputeType = {
  __typename?: 'PaymentDisputeType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  transactionDisputed?: Maybe<LimitedTransactionType>;
  fundsWithdrawnTransaction?: Maybe<LimitedTransactionType>;
  state: PaymentDisputeState;
  amountCents: Scalars['Int'];
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  invoicePreviousState?: Maybe<InvoiceState>;
  refundType?: Maybe<PaymentDisputeRefundType>;
  capturedBy?: Maybe<UserType>;
  authorizedBy?: Maybe<UserType>;
  disputeDescription?: Maybe<Scalars['String']>;
  authorizationDescription?: Maybe<Scalars['String']>;
  rulingType?: Maybe<PaymentDisputeRulingTypes>;
};

export type LimitedTransactionType = {
  __typename?: 'LimitedTransactionType';
  createdAt: Scalars['DateTime'];
  currency: TransactionCurrency;
  amountCents: Scalars['Int'];
  transactionType: TransactionTransactionType;
  paymentGatewayResult?: Maybe<TransactionPaymentGatewayResult>;
  paymentInformation?: Maybe<PaymentInformationType>;
};

export enum TransactionCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum TransactionTransactionType {
  At = 'AT',
  Db = 'DB',
  Cr = 'CR',
  Rf = 'RF',
  Py = 'PY',
  Cn = 'CN',
  Ps = 'PS',
  Fw = 'FW',
  Si = 'SI',
  Cp = 'CP',
  Sr = 'SR',
  Bi = 'BI',
  Bd = 'BD',
  Po = 'PO',
  Pc = 'PC',
  Pr = 'PR',
  Uc = 'UC'
}

export enum TransactionPaymentGatewayResult {
  Ok = 'OK',
  Fl = 'FL'
}

export type PaymentInformationType = {
  __typename?: 'PaymentInformationType';
  paymentMethod?: Maybe<Scalars['String']>;
  cardDetails?: Maybe<CardDetails>;
  clabe?: Maybe<Scalars['String']>;
};

export type CardDetails = {
  __typename?: 'CardDetails';
  cardLastDigits?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
};

export enum PaymentDisputeState {
  Nw = 'NW',
  Ev = 'EV',
  Wn = 'WN',
  Ls = 'LS',
  Cr = 'CR'
}

export enum PaymentDisputeRefundType {
  Tc = 'TC',
  Bg = 'BG',
  Pm = 'PM',
  Cm = 'CM',
  Cb = 'CB',
  Id = 'ID'
}

export enum PaymentDisputeRulingTypes {
  AcceptRefundSetNotPaid = 'ACCEPT_REFUND_SET_NOT_PAID',
  AcceptRefundSetUncollectible = 'ACCEPT_REFUND_SET_UNCOLLECTIBLE',
  AcceptRefundSetCancelled = 'ACCEPT_REFUND_SET_CANCELLED',
  RejectRefundSetPreviousState = 'REJECT_REFUND_SET_PREVIOUS_STATE',
  AcceptGatewayDisputeSetNotPaid = 'ACCEPT_GATEWAY_DISPUTE_SET_NOT_PAID',
  AcceptGatewayDisputeSetUncollectible = 'ACCEPT_GATEWAY_DISPUTE_SET_UNCOLLECTIBLE',
  AcceptGatewayDisputeSetCancelled = 'ACCEPT_GATEWAY_DISPUTE_SET_CANCELLED',
  AcceptUnpaidDisputeSetUncollectible = 'ACCEPT_UNPAID_DISPUTE_SET_UNCOLLECTIBLE',
  RejectUnpaidDisputeSetPreviousState = 'REJECT_UNPAID_DISPUTE_SET_PREVIOUS_STATE',
  AcceptAndChargeAgain = 'ACCEPT_AND_CHARGE_AGAIN',
  CancelAndRefund = 'CANCEL_AND_REFUND'
}

export type CommentType = {
  __typename?: 'CommentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  objectId: Scalars['Int'];
  contentType: ContentTypeType;
  text: Scalars['String'];
  user?: Maybe<UserType>;
  replyTo?: Maybe<CommentType>;
  replies: Array<CommentType>;
};

export type IncomeType = {
  __typename?: 'IncomeType';
  id: Scalars['ID'];
  contentType?: Maybe<ContentTypeType>;
  objectId: Scalars['Int'];
  subtotalCents: Scalars['Int'];
  paymentFlow?: Maybe<IncomePaymentFlow>;
  description?: Maybe<Scalars['String']>;
  totalCents: Scalars['Int'];
  currency: IncomeCurrency;
  relatedIncome?: Maybe<IncomeType>;
  satPaymentMethod?: Maybe<SatPaymentMethod>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  contentObject?: Maybe<ContentObjectType>;
  electronicVoucherStatus?: Maybe<Scalars['String']>;
  recipient?: Maybe<UserType>;
  issuer?: Maybe<UserType>;
};

export enum IncomePaymentFlow {
  In = 'IN',
  Out = 'OUT'
}

export enum IncomeCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum SatPaymentMethod {
  PaymentInOneExhibition = 'PAYMENT_IN_ONE_EXHIBITION',
  PaymentInInstallmentsOrDeferred = 'PAYMENT_IN_INSTALLMENTS_OR_DEFERRED'
}

export type ContentObjectType = HealthPlanType | OnDemandConsultTimeLogType | HealthPlanAmendmentType | ServicesType | OnDemandConsultType | InPersonConsultType | PharmacyOrderType | MedicalFeeType | HospitalServiceType | OtherServiceType | RehabilitationTherapyType | LabStudiesType | MedicinesType | MedicalEquipmentType | HomeNurseType | ProstheticsAndMechanicalEquipmentType | AmbulanceType | IncentiveType | ExternalConsultType | ExternalVideoConsultType | UrgencyType;

export type OnDemandConsultTimeLogType = {
  __typename?: 'OnDemandConsultTimeLogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  rateType?: Maybe<OnDemandConsultTimeLogRateType>;
  subtotalCents: Scalars['Int'];
  vatCents: Scalars['Int'];
  withholdTaxesCents: Scalars['Int'];
  totalCents: Scalars['Int'];
  paymentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  paymentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  hoursLogged: Scalars['Int'];
  doctor: DoctorProfileType;
};

export enum OnDemandConsultTimeLogRateType {
  Wd = 'WD',
  We = 'WE'
}

export type DoctorProfileType = {
  __typename?: 'DoctorProfileType';
  id: Scalars['ID'];
  licenseNumber: Scalars['String'];
  licenseInstitution: Scalars['String'];
  autoEmitEnabled: Scalars['Boolean'];
  isPrimaryCareDoctor?: Maybe<Scalars['Boolean']>;
  calendlyUrl?: Maybe<Scalars['String']>;
  welcomeCalendlyUrl?: Maybe<Scalars['String']>;
  onLeaveFrom?: Maybe<Scalars['Date']>;
  onLeaveUntil?: Maybe<Scalars['Date']>;
  leaveReason?: Maybe<DoctorLeaveReason>;
  isPediatricsCareDoctor?: Maybe<Scalars['Boolean']>;
  isPediatric?: Maybe<Scalars['Boolean']>;
  officeAddress?: Maybe<AddressType>;
  secondaryOfficeAddress?: Maybe<AddressType>;
  doctorSpecialistDetails?: Maybe<Array<Maybe<DoctorSpecialistDetailType>>>;
  telephone?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  prefixedFullName?: Maybe<Scalars['String']>;
  isAllowedForScheduledConsults?: Maybe<Scalars['Boolean']>;
  isOnLeave?: Maybe<Scalars['Boolean']>;
};

export type DoctorSpecialistDetailType = {
  __typename?: 'DoctorSpecialistDetailType';
  id: Scalars['ID'];
  specialistNumber: Scalars['String'];
  specialistInstitution: Scalars['String'];
  available: Scalars['Boolean'];
  certificateValidUntil?: Maybe<Scalars['Date']>;
  certificate?: Maybe<DocumentType>;
  specialization: MedicalSpecialtyType;
  specializationName?: Maybe<Scalars['String']>;
};

export enum MedicalSpecialtyType {
  CardiovascularAnesthesia = 'CARDIOVASCULAR_ANESTHESIA',
  Anesthesiology = 'ANESTHESIOLOGY',
  AngiologyAndVascularSurgery = 'ANGIOLOGY_AND_VASCULAR_SURGERY',
  BariatricsAndComorbidities = 'BARIATRICS_AND_COMORBIDITIES',
  Cardiology = 'CARDIOLOGY',
  JointSurgery = 'JOINT_SURGERY',
  BariatricSurgery = 'BARIATRIC_SURGERY',
  CardiothoracicSurgery = 'CARDIOTHORACIC_SURGERY',
  SkullBaseSurgery = 'SKULL_BASE_SURGERY',
  RetinalAndVitreousSurgery = 'RETINAL_AND_VITREOUS_SURGERY',
  DermatologicalSurgery = 'DERMATOLOGICAL_SURGERY',
  Hematology = 'HEMATOLOGY',
  FetalMaternalMedicine = 'FETAL_MATERNAL_MEDICINE',
  GeneralSurgery = 'GENERAL_SURGERY',
  OncologicSurgery = 'ONCOLOGIC_SURGERY',
  PediatricSurgery = 'PEDIATRIC_SURGERY',
  PlasticAndReconstructiveSurgery = 'PLASTIC_AND_RECONSTRUCTIVE_SURGERY',
  RoboticSurgery = 'ROBOTIC_SURGERY',
  Dermatology = 'DERMATOLOGY',
  PediatricDermatology = 'PEDIATRIC_DERMATOLOGY',
  Endocrinology = 'ENDOCRINOLOGY',
  DiagnosticAndTherapeuticEndoscopy = 'DIAGNOSTIC_AND_THERAPEUTIC_ENDOSCOPY',
  Physiotherapy = 'PHYSIOTHERAPY',
  Gastroenterology = 'GASTROENTEROLOGY',
  PediatricGastroenterologyAndNutrition = 'PEDIATRIC_GASTROENTEROLOGY_AND_NUTRITION',
  MedicalGenetics = 'MEDICAL_GENETICS',
  Geriatrics = 'GERIATRICS',
  GynecologyAndObstetrics = 'GYNECOLOGY_AND_OBSTETRICS',
  DiagnosticAndTherapeuticImaging = 'DIAGNOSTIC_AND_THERAPEUTIC_IMAGING',
  CriticalMedicine = 'CRITICAL_MEDICINE',
  InternalMedicine = 'INTERNAL_MEDICINE',
  Nephrology = 'NEPHROLOGY',
  Neonatology = 'NEONATOLOGY',
  PediatricPulmonology = 'PEDIATRIC_PULMONOLOGY',
  Neurosurgery = 'NEUROSURGERY',
  ClinicalNeurophysiology = 'CLINICAL_NEUROPHYSIOLOGY',
  Neurology = 'NEUROLOGY',
  ClinicalNutritionology = 'CLINICAL_NUTRITIONOLOGY',
  Ophthalmology = 'OPHTHALMOLOGY',
  MedicalOncology = 'MEDICAL_ONCOLOGY',
  Orthopedics = 'ORTHOPEDICS',
  OrthopedicsAndTraumatology = 'ORTHOPEDICS_AND_TRAUMATOLOGY',
  PediatricOrthopedics = 'PEDIATRIC_ORTHOPEDICS',
  Otorhinolaryngology = 'OTORHINOLARYNGOLOGY',
  Pediatrics = 'PEDIATRICS',
  ClinicalPsychology = 'CLINICAL_PSYCHOLOGY',
  Psychiatry = 'PSYCHIATRY',
  LinkPsychiatry = 'LINK_PSYCHIATRY',
  VascularAndInterventionalRadiology = 'VASCULAR_AND_INTERVENTIONAL_RADIOLOGY',
  Rheumatology = 'RHEUMATOLOGY',
  Urology = 'UROLOGY',
  GynecologicalUrology = 'GYNECOLOGICAL_UROLOGY',
  RehabilitationMedicine = 'REHABILITATION_MEDICINE',
  OncologicalUrology = 'ONCOLOGICAL_UROLOGY',
  PediatricUrology = 'PEDIATRIC_UROLOGY',
  Infectiology = 'INFECTIOLOGY',
  Coloproctology = 'COLOPROCTOLOGY',
  AudiologyOtoneurologyAndSpeechTherapy = 'AUDIOLOGY_OTONEUROLOGY_AND_SPEECH_THERAPY',
  Pneumology = 'PNEUMOLOGY',
  AllergyAndImmunology = 'ALLERGY_AND_IMMUNOLOGY',
  GastrointestinalEndoscopy = 'GASTROINTESTINAL_ENDOSCOPY',
  ChildAndAdolescentPsychiatry = 'CHILD_AND_ADOLESCENT_PSYCHIATRY'
}

export type ServicesType = {
  __typename?: 'ServicesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  ondemandconsult?: Maybe<OnDemandConsultType>;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  cpt?: Maybe<Array<Maybe<KeyValueType>>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceTypeSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  electronicVoucherItems: Array<ServiceElectronicVoucherItemType>;
  evidences?: Maybe<Array<Maybe<EvidenceType>>>;
  state?: Maybe<RulingState>;
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  payoutInvoice?: Maybe<InvoiceType>;
  payinInvoice?: Maybe<InvoiceType>;
  isFreeOfCharge?: Maybe<Scalars['Boolean']>;
  serviceType?: Maybe<ServiceTypeType>;
  doctor?: Maybe<DoctorProfileType>;
  doctorFriend?: Maybe<DoctorFriendType>;
  provider?: Maybe<ProviderBranchType>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  hospitalizationDays?: Maybe<Scalars['Int']>;
  dischargeDate?: Maybe<Scalars['Date']>;
  isCancellable?: Maybe<Scalars['Boolean']>;
};

export enum ServiceServiceTypeValue {
  MedicalFees = 'MEDICAL_FEES',
  HospitalService = 'HOSPITAL_SERVICE',
  OtherService = 'OTHER_SERVICE',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  HomeNurse = 'HOME_NURSE',
  ProstheticsAndMechanicalEquipment = 'PROSTHETICS_AND_MECHANICAL_EQUIPMENT',
  Ambulance = 'AMBULANCE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  PharmacyOrder = 'PHARMACY_ORDER',
  ExternalConsult = 'EXTERNAL_CONSULT',
  Urgency = 'URGENCY',
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  SurgicalMaterials = 'SURGICAL_MATERIALS',
  IncludedConsult = 'INCLUDED_CONSULT'
}

export enum ServiceProcessState {
  ProcessStarted = 'PROCESS_STARTED',
  ReviewingEvidences = 'REVIEWING_EVIDENCES',
  Ruling = 'RULING',
  RegisteringAmounts = 'REGISTERING_AMOUNTS',
  ProcessingPayments = 'PROCESSING_PAYMENTS',
  ProcessFinalized = 'PROCESS_FINALIZED',
  Cancelled = 'CANCELLED'
}

export type OnDemandConsultType = {
  __typename?: 'OnDemandConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state: OnDemandConsultState;
  reason: Scalars['String'];
  classification?: Maybe<Scalars['String']>;
  member?: Maybe<MemberType>;
  opentokSession?: Maybe<OpentokSessionType>;
  emrEntry?: Maybe<EmrEntryType>;
  healthPlan?: Maybe<HealthPlanType>;
  billedAmount?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  lastConsultMessage?: Maybe<MessageType>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  doctor?: Maybe<DoctorType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
};

export enum OnDemandConsultState {
  Hn = 'HN',
  Mn = 'MN',
  Re = 'RE',
  Cl = 'CL',
  Ip = 'IP',
  Ir = 'IR',
  Io = 'IO',
  Cs = 'CS',
  Ca = 'CA'
}

export type OpentokSessionType = {
  __typename?: 'OpentokSessionType';
  apiKey?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type EmrEntryType = {
  __typename?: 'EmrEntryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  signedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Scalars['JSONString']>;
  member?: Maybe<MemberType>;
  doctor?: Maybe<DoctorType>;
  diagnosis?: Maybe<Scalars['String']>;
  otherRecommendations?: Maybe<Scalars['String']>;
  labs?: Maybe<Scalars['String']>;
  medicines?: Maybe<Scalars['JSONString']>;
  notes?: Maybe<Scalars['String']>;
};

export type PrescriptionType = {
  __typename?: 'PrescriptionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  memberName: Scalars['String'];
  memberAge: Scalars['Int'];
  doctorName: Scalars['String'];
  licenseNumber: Scalars['String'];
  licenseInstitution: Scalars['String'];
  specialization?: Maybe<Scalars['String']>;
  specialistNumber?: Maybe<Scalars['String']>;
  specialistInstitution?: Maybe<Scalars['String']>;
  doctorOfficeAddress: Scalars['String'];
  doctorTelephone: Scalars['String'];
  diagnosis?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  hasAntibiotics?: Maybe<Scalars['Boolean']>;
  prescriptionItemsMemberChecks?: Maybe<Scalars['JSONString']>;
  labsCheck?: Maybe<Scalars['Boolean']>;
  medicinesCheck?: Maybe<Scalars['Boolean']>;
  referencesCheck?: Maybe<Scalars['Boolean']>;
  pdfDocument?: Maybe<DocumentType>;
  prescriptionItems?: Maybe<Scalars['JSONString']>;
  doctor?: Maybe<DoctorType>;
  items?: Maybe<Array<Maybe<PrescriptionItemType>>>;
};

export type PrescriptionItemType = {
  __typename?: 'PrescriptionItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  group?: Maybe<PrescriptionItemGroup>;
  service?: Maybe<LimitedServicesType>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  checked: Scalars['Boolean'];
  prescriptionItemDetails?: Maybe<PrescriptionItemDetailsType>;
};

export enum PrescriptionItemGroup {
  Labs = 'LABS',
  Medicines = 'MEDICINES',
  References = 'REFERENCES',
  Checkups = 'CHECKUPS',
  Urgencies = 'URGENCIES'
}

export type LimitedServicesType = {
  __typename?: 'LimitedServicesType';
  id: Scalars['ID'];
  ondemandconsult?: Maybe<OnDemandConsultType>;
  doctor?: Maybe<DoctorProfileType>;
  inPersonConsult?: Maybe<LimitedInPersonConsultType>;
};

export type LimitedInPersonConsultType = {
  __typename?: 'LimitedInPersonConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member?: Maybe<MemberType>;
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  emrEntry?: Maybe<EmrEntryType>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  scheduledconsultSet: Array<ScheduledConsultType>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  serviceCoverageRulingState?: Maybe<RulingState>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export type LimitedDiseaseCaseType = {
  __typename?: 'LimitedDiseaseCaseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};

export type CaseEventType = {
  __typename?: 'CaseEventType';
  id: Scalars['ID'];
  invoice?: Maybe<InvoiceType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  description: Scalars['String'];
  originDiagnosisIcd?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<CaseEventCategory>;
  eventClass?: Maybe<CaseEventClassOptions>;
  admissionSource?: Maybe<CaseEventAdmissionSource>;
  dischargeReason?: Maybe<CaseEventDischargeReason>;
  deferredSince?: Maybe<Scalars['Date']>;
  internalNotes?: Maybe<Scalars['String']>;
  backgroundCheck?: Maybe<Scalars['Boolean']>;
  informedHospital?: Maybe<Scalars['Boolean']>;
  requestedPartnerDoctorInfo?: Maybe<Scalars['Boolean']>;
  scheduledHospitalDate?: Maybe<Scalars['Date']>;
  relevantHistory?: Maybe<Scalars['String']>;
  preAdmissionDoctors: Array<DoctorProfileType>;
  finalAmountsState?: Maybe<CaseEventFinalAmountsState>;
  bedNumber?: Maybe<Scalars['String']>;
  evidenceLinkSent?: Maybe<Scalars['Boolean']>;
  attendingDoctors: Array<DoctorProfileType>;
  invoiceReceived?: Maybe<Scalars['Boolean']>;
  memberStage?: Maybe<CaseEventMemberStage>;
  stage?: Maybe<CaseEventStage>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
  preapprovedCents?: Maybe<Scalars['JSONString']>;
  approvedCents?: Maybe<Scalars['JSONString']>;
  memberBrief?: Maybe<MemberType>;
  providerName?: Maybe<Scalars['String']>;
};

export type DiseaseCaseType = {
  __typename?: 'DiseaseCaseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  member: MemberType;
  originDiagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  medicalDescription?: Maybe<Scalars['String']>;
  sumServicesTotalCents?: Maybe<Scalars['Int']>;
  servicesCount?: Maybe<Scalars['Int']>;
};

export type KeyValueType = {
  __typename?: 'KeyValueType';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum CaseEventCategory {
  Urgency = 'URGENCY',
  Admission = 'ADMISSION',
  SurgeryAdmission = 'SURGERY_ADMISSION',
  AmbulatorySurgery = 'AMBULATORY_SURGERY',
  UnscheduledSurgery = 'UNSCHEDULED_SURGERY',
  OfficeProcedure = 'OFFICE_PROCEDURE'
}

export enum CaseEventClassOptions {
  Outpatient = 'OUTPATIENT',
  Inpatient = 'INPATIENT'
}

export enum CaseEventAdmissionSource {
  Referral = 'REFERRAL',
  Transfer = 'TRANSFER',
  Emergency = 'EMERGENCY'
}

export enum CaseEventDischargeReason {
  Improvement = 'IMPROVEMENT',
  PalliativeCare = 'PALLIATIVE_CARE',
  VoluntaryDischarge = 'VOLUNTARY_DISCHARGE',
  Transfer = 'TRANSFER'
}

export enum CaseEventFinalAmountsState {
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED'
}

export enum CaseEventMemberStage {
  Informed = 'INFORMED',
  OnTheWay = 'ON_THE_WAY',
  Hospitalized = 'HOSPITALIZED',
  Discharged = 'DISCHARGED'
}

export enum CaseEventStage {
  PreAdmission = 'PRE_ADMISSION',
  Hospitalized = 'HOSPITALIZED',
  Discharged = 'DISCHARGED'
}

export enum ServiceSpecification {
  EmergencyAmbulance = 'EMERGENCY_AMBULANCE',
  TransferAmbulance = 'TRANSFER_AMBULANCE',
  HospitalEmergency = 'HOSPITAL_EMERGENCY',
  HospitalAdmission = 'HOSPITAL_ADMISSION',
  TreatingPhysician = 'TREATING_PHYSICIAN',
  Surgeon = 'SURGEON',
  FirstHelper = 'FIRST_HELPER',
  SecondHelper = 'SECOND_HELPER',
  Anesthesiologist = 'ANESTHESIOLOGIST',
  ConsultingPhysician = 'CONSULTING_PHYSICIAN'
}

export enum ServiceAutomaticPaymentApprovalFlagLastCheck {
  R = 'R',
  G = 'G',
  Y = 'Y'
}

export type HuliEmrEntryType = {
  __typename?: 'HuliEmrEntryType';
  id: Scalars['ID'];
  huliConsultId: Scalars['Int'];
  patientUuid?: Maybe<Scalars['String']>;
  doctorHuliId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  healthPlanUuid?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  gender?: Maybe<HuliEmrEntryGender>;
  bmi?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  initialConsult?: Maybe<Scalars['Boolean']>;
  followUpConsult?: Maybe<Scalars['Boolean']>;
  numberOfLaboratoryTests: Scalars['Int'];
  laboratoryTests?: Maybe<Scalars['String']>;
  numberOfMedicines: Scalars['Int'];
  medicines?: Maybe<Scalars['String']>;
  suffering?: Maybe<Scalars['String']>;
  physicalNote?: Maybe<Scalars['String']>;
  planNote?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  appointmentDatetime?: Maybe<Scalars['DateTime']>;
  consultStartsAt?: Maybe<Scalars['DateTime']>;
  emrType?: Maybe<EmrType>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  completedSync: Scalars['Boolean'];
  classificationNotes?: Maybe<Scalars['String']>;
  huliClinicId?: Maybe<Scalars['Int']>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  reviewedBy?: Maybe<UserType>;
  tobacco?: Maybe<Scalars['Boolean']>;
  patientId?: Maybe<Scalars['String']>;
  healthPlanId?: Maybe<Scalars['String']>;
  doctorIdUser?: Maybe<Scalars['Int']>;
  huliIdEvent?: Maybe<Scalars['Int']>;
  onDemandConsult?: Maybe<OnDemandConsultType>;
  service?: Maybe<ServicesType>;
  doctor?: Maybe<DoctorType>;
  diagnosticImpression?: Maybe<Scalars['String']>;
  huliPatientFileId?: Maybe<Scalars['Int']>;
  consultAddress?: Maybe<AddressType>;
  healthPlan?: Maybe<HealthPlanType>;
};

export enum HuliEmrEntryGender {
  U = 'U',
  M = 'M',
  F = 'F'
}

export enum EmrType {
  PreventiveVc = 'PREVENTIVE_VC',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  InPersonConsult = 'IN_PERSON_CONSULT',
  DuplicatedConsult = 'DUPLICATED_CONSULT',
  UnclassifiedConsult = 'UNCLASSIFIED_CONSULT',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  FolowupNotes = 'FOLOWUP_NOTES',
  IgnoreRecord = 'IGNORE_RECORD'
}

export type InPersonConsultType = {
  __typename?: 'InPersonConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member?: Maybe<MemberType>;
  healthPlan?: Maybe<HealthPlanType>;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Maybe<KeyValueType>>>;
  cpt?: Maybe<Array<Maybe<KeyValueType>>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  emrEntry?: Maybe<EmrEntryType>;
  prescriptions: Array<PrescriptionType>;
  scheduledconsultSet: Array<ScheduledConsultType>;
  provider?: Maybe<ProviderBranchType>;
  serviceType?: Maybe<ServiceTypeType>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
  note?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<DoctorType>;
  hasDoctorBeenPaid?: Maybe<Scalars['Boolean']>;
  hasElectronicVoucher?: Maybe<Scalars['Boolean']>;
  doctorFeeCents?: Maybe<Scalars['Int']>;
  doctorPayoutFeeCents?: Maybe<Scalars['Int']>;
  hasBeenPaidIn?: Maybe<Scalars['Boolean']>;
  hasPayInAndPayOut?: Maybe<Scalars['Boolean']>;
};

export type ScheduledConsultType = {
  __typename?: 'ScheduledConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  scheduledConsultClassification?: Maybe<GrapheneScheduledConsultClassification>;
  user?: Maybe<UserType>;
  member?: Maybe<MemberType>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  extraNotes?: Maybe<Scalars['String']>;
  onDemandConsultClassification?: Maybe<OnDemandConsultClassificationType>;
  /** @deprecated Not used */
  scheduledConsultKind?: Maybe<ScheduledConsultKind>;
  category?: Maybe<ConsultCategory>;
  tasks?: Maybe<Scalars['JSONString']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  huliId?: Maybe<Scalars['Int']>;
  calendlyId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  onDemandConsult?: Maybe<OnDemandConsultType>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  doctor?: Maybe<DoctorProfileType>;
  address?: Maybe<AddressType>;
  secondaryAddress?: Maybe<AddressType>;
  confirmed: Scalars['Boolean'];
  specialization?: Maybe<MedicalSpecialtyType>;
  urgency?: Maybe<AppointmentUrgency>;
  urgencyDescription?: Maybe<Scalars['String']>;
  timeWindow?: Maybe<AppointmentTimeWindow>;
  timeWindowDescription?: Maybe<Scalars['String']>;
  dayWindow?: Maybe<AppointmentDayWindow>;
  dayWindowDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  latestState?: Maybe<ScheduledConsultLatestState>;
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  deprecatedScheduledConsultClassification?: Maybe<GrapheneScheduledConsultClassificationDeprecated>;
  state: ScheduledConsultState;
};

export enum GrapheneScheduledConsultClassification {
  None = 'None',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT'
}

export enum OnDemandConsultClassificationType {
  NotApplicable = 'NOT_APPLICABLE',
  Welcome = 'WELCOME',
  AnnualCheckup = 'ANNUAL_CHECKUP',
  PrimaryCare = 'PRIMARY_CARE',
  NextAvailable = 'NEXT_AVAILABLE',
  OnDemand = 'ON_DEMAND',
  NightCall = 'NIGHT_CALL',
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum ScheduledConsultKind {
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum ConsultCategory {
  Preventive = 'PREVENTIVE',
  General = 'GENERAL',
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum AppointmentUrgency {
  Asap = 'ASAP',
  NextWeek = 'NEXT_WEEK',
  NextMonth = 'NEXT_MONTH',
  Other = 'OTHER'
}

export enum AppointmentTimeWindow {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Other = 'OTHER'
}

export enum AppointmentDayWindow {
  Weekday = 'WEEKDAY',
  WeekEnd = 'WEEK_END',
  Other = 'OTHER'
}

export enum ScheduledConsultLatestState {
  Tb = 'TB',
  Rq = 'RQ',
  Sd = 'SD',
  Cf = 'CF',
  Ds = 'DS',
  Dn = 'DN',
  Cn = 'CN'
}

export enum ConsultCancellationReason {
  MemberInsufficientTime = 'MEMBER_INSUFFICIENT_TIME',
  MemberInadequateLocation = 'MEMBER_INADEQUATE_LOCATION',
  MemberBadInternet = 'MEMBER_BAD_INTERNET',
  DoctorUrgency = 'DOCTOR_URGENCY',
  StuckConsultCard = 'STUCK_CONSULT_CARD',
  MemberNoShow = 'MEMBER_NO_SHOW',
  CancelledByMember = 'CANCELLED_BY_MEMBER',
  Other = 'OTHER'
}

export enum GrapheneScheduledConsultClassificationDeprecated {
  OnDemandConsultPrimary = 'ON_DEMAND_CONSULT_PRIMARY',
  OnDemandConsultWelcome = 'ON_DEMAND_CONSULT_WELCOME',
  OnDemandConsultMain = 'ON_DEMAND_CONSULT_MAIN',
  PediatricsPrimary = 'PEDIATRICS_PRIMARY',
  PediatricsWelcome = 'PEDIATRICS_WELCOME',
  RehabilitationTherapy = 'REHABILITATION_THERAPY'
}

export enum ScheduledConsultState {
  ToBeRequested = 'TO_BE_REQUESTED',
  Requested = 'REQUESTED',
  Scheduled = 'SCHEDULED',
  Confirmed = 'CONFIRMED',
  DueSoon = 'DUE_SOON',
  Done = 'DONE',
  Cancelled = 'CANCELLED'
}

export type ProviderBranchType = {
  __typename?: 'ProviderBranchType';
  id: Scalars['ID'];
  name: Scalars['String'];
  telephones?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
  tier?: Maybe<ProviderTier>;
  provider: ProvidersType;
  emitMonthlyInvoice: Scalars['Boolean'];
  address?: Maybe<AddressType>;
  providerType?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export enum ProviderTier {
  Regular = 'REGULAR',
  Premium = 'PREMIUM'
}

export type ProvidersType = {
  __typename?: 'ProvidersType';
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  category?: Maybe<ProviderCategoryType>;
};

export type ProviderCategoryType = {
  __typename?: 'ProviderCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['Int'];
};

export type ServiceTypeType = {
  __typename?: 'ServiceTypeType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  scheduling?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  rulingType?: Maybe<Scalars['String']>;
  causesVat?: Maybe<Scalars['Boolean']>;
  isProvider?: Maybe<Scalars['Boolean']>;
  isOneFreeOfCharge?: Maybe<Scalars['Boolean']>;
  isAssistance?: Maybe<Scalars['Boolean']>;
  specifications?: Maybe<Array<Maybe<ServiceTypeSpecification>>>;
  availablePayoutModes: Array<ServiceCoveragePayoutMode>;
  defaultPayoutMode: ServiceCoveragePayoutMode;
  availablePayInMethods?: Maybe<Array<PayInMethods>>;
};

export enum ServiceTypeSpecification {
  EmergencyAmbulance = 'EMERGENCY_AMBULANCE',
  TransferAmbulance = 'TRANSFER_AMBULANCE',
  HospitalEmergency = 'HOSPITAL_EMERGENCY',
  HospitalAdmission = 'HOSPITAL_ADMISSION',
  TreatingPhysician = 'TREATING_PHYSICIAN',
  Surgeon = 'SURGEON',
  FirstHelper = 'FIRST_HELPER',
  SecondHelper = 'SECOND_HELPER',
  Anesthesiologist = 'ANESTHESIOLOGIST',
  ConsultingPhysician = 'CONSULTING_PHYSICIAN'
}

export enum ServiceCoveragePayoutMode {
  Direct = 'DIRECT',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  DirectHospital = 'DIRECT_HOSPITAL',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  DirectConsultingRoom = 'DIRECT_CONSULTING_ROOM',
  Reimbursement = 'REIMBURSEMENT',
  /** @deprecated Deprecated use DIRECT or REIMBURSEMENT */
  ReimbursementCfdiRecipientMember = 'REIMBURSEMENT_CFDI_RECIPIENT_MEMBER',
  Compensation = 'COMPENSATION'
}

export enum PayInMethods {
  Card = 'CARD',
  InSitu = 'IN_SITU'
}

export type MedicalNoteType = {
  __typename?: 'MedicalNoteType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  member: MemberType;
  doctor: DoctorType;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  service?: Maybe<LimitedServicesType>;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
  occurrenceTime: Scalars['DateTime'];
  suffering?: Maybe<Scalars['String']>;
  motive?: Maybe<Scalars['String']>;
  noteType?: Maybe<NoteType>;
  state: NoteState;
  tasks?: Maybe<Scalars['JSONString']>;
  shouldBeFreeOfCharge: Scalars['Boolean'];
  cancellationReason?: Maybe<ConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
  dischargeInfo?: Maybe<DischargeInfoType>;
  noteBasicInfo: NoteBasicInfoType;
  evaluation: EvaluationType;
  physicalExam: PhysicalExamType;
  physicalActivity?: Maybe<PhysicalActivityType>;
  obstetricGynecologicalInfo?: Maybe<ObstetricGynecologicalInfoType>;
  interrogations: Array<InterrogationType>;
  explorations: Array<ExplorationType>;
  procedures: Array<ProcedureType>;
  addenda: Array<AddendumType>;
  exams: Array<ExamType>;
  prescriptions: Array<PrescriptionType>;
  huliId?: Maybe<Scalars['ID']>;
  isDischarge?: Maybe<Scalars['Boolean']>;
};

export enum NoteType {
  InPerson = 'IN_PERSON',
  Video = 'VIDEO',
  Included = 'INCLUDED',
  Hospitalization = 'HOSPITALIZATION',
  Urgency = 'URGENCY'
}

export enum NoteState {
  Open = 'OPEN',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type DischargeInfoType = {
  __typename?: 'DischargeInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  entryDate?: Maybe<Scalars['Date']>;
  dischargeDate?: Maybe<Scalars['Date']>;
  visitsAmount?: Maybe<Scalars['Int']>;
  dischargeReason?: Maybe<DischargeReason>;
  evolution?: Maybe<Scalars['String']>;
  staySummary?: Maybe<Scalars['String']>;
};

export enum DischargeReason {
  Improvement = 'IMPROVEMENT',
  Death = 'DEATH',
  Voluntary = 'VOLUNTARY'
}

export type NoteBasicInfoType = {
  __typename?: 'NoteBasicInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  bloodType?: Maybe<BloodType>;
  percentile?: Maybe<Scalars['Int']>;
  zScore?: Maybe<Scalars['Float']>;
  cephalicPerimeter?: Maybe<Scalars['Float']>;
};

export enum BloodType {
  ONegative = 'O_NEGATIVE',
  OPositive = 'O_POSITIVE',
  ANegative = 'A_NEGATIVE',
  APositive = 'A_POSITIVE',
  BNegative = 'B_NEGATIVE',
  BPositive = 'B_POSITIVE',
  AbNegative = 'AB_NEGATIVE',
  AbPositive = 'AB_POSITIVE'
}

export type EvaluationType = {
  __typename?: 'EvaluationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  diagnosticImpression?: Maybe<Scalars['String']>;
  cieKeys: Array<Scalars['String']>;
  diagnostics: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type PhysicalExamType = {
  __typename?: 'PhysicalExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  heartRate?: Maybe<Scalars['Int']>;
  oxygenation?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  breathingFrequency?: Maybe<Scalars['Int']>;
  bodyFat?: Maybe<Scalars['Float']>;
  muscleMass?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

export type PhysicalActivityType = {
  __typename?: 'PhysicalActivityType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  hasExercise?: Maybe<Scalars['Boolean']>;
  exerciseActivities?: Maybe<Scalars['String']>;
  exerciseWeeklyFrequency?: Maybe<Scalars['Int']>;
  exerciseNotes?: Maybe<Scalars['String']>;
};

export type ObstetricGynecologicalInfoType = {
  __typename?: 'ObstetricGynecologicalInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  hasBirthControl?: Maybe<Scalars['Boolean']>;
  hasMenstrualCycle?: Maybe<Scalars['Boolean']>;
  hasBeenPregnant?: Maybe<Scalars['Boolean']>;
  hasGynecologicalExams?: Maybe<Scalars['Boolean']>;
};

export type InterrogationType = {
  __typename?: 'InterrogationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  system?: Maybe<InterrogationSystem>;
  systemName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum InterrogationSystem {
  Articulate = 'ARTICULATE',
  Cardiovascular = 'CARDIOVASCULAR',
  Circulatory = 'CIRCULATORY',
  Digestive = 'DIGESTIVE',
  Endocrine = 'ENDOCRINE',
  Skeletal = 'SKELETAL',
  Excretory = 'EXCRETORY',
  Immune = 'IMMUNE',
  Integumentary = 'INTEGUMENTARY',
  Lymphatic = 'LYMPHATIC',
  Locomotive = 'LOCOMOTIVE',
  Muscular = 'MUSCULAR',
  Nervous = 'NERVOUS',
  Reproductive = 'REPRODUCTIVE',
  Respiratory = 'RESPIRATORY',
  Other = 'OTHER'
}

export type ExplorationType = {
  __typename?: 'ExplorationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  segment?: Maybe<ExplorationSegment>;
  segmentName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export enum ExplorationSegment {
  Head = 'HEAD',
  Neck = 'NECK',
  Chest = 'CHEST',
  Abdomen = 'ABDOMEN',
  Spine = 'SPINE',
  UpperLimbs = 'UPPER_LIMBS',
  Genitals = 'GENITALS',
  LowerLimbs = 'LOWER_LIMBS',
  Other = 'OTHER'
}

export type ProcedureType = {
  __typename?: 'ProcedureType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  document?: Maybe<DocumentType>;
  procedure?: Maybe<ProcedureName>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  pathologySendingRequired: Scalars['Boolean'];
  scheduled: Scalars['Boolean'];
};

export enum ProcedureName {
  Ultrasound = 'ULTRASOUND',
  Electrocardiogram = 'ELECTROCARDIOGRAM',
  Papanicolaou = 'PAPANICOLAOU',
  BandagesSplintPlacementPlaster = 'BANDAGES_SPLINT_PLACEMENT_PLASTER',
  Sutures = 'SUTURES',
  AbscessDrainage = 'ABSCESS_DRAINAGE',
  NasalBleedingControl = 'NASAL_BLEEDING_CONTROL',
  AudiometrySpeechAudiometryImpedanciometryVideonystagmography = 'AUDIOMETRY_SPEECH_AUDIOMETRY_IMPEDANCIOMETRY_VIDEONYSTAGMOGRAPHY',
  Other = 'OTHER'
}

export type AddendumType = {
  __typename?: 'AddendumType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalNote: MedicalNoteType;
  document?: Maybe<DocumentType>;
  notes?: Maybe<Scalars['String']>;
};

export type ExamType = {
  __typename?: 'ExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  medicalNote?: Maybe<MedicalNoteType>;
  document?: Maybe<DocumentType>;
  doctor?: Maybe<DoctorProfileType>;
  uploadedAt: Scalars['Date'];
  notes?: Maybe<Scalars['String']>;
};

export enum RulingState {
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Accepted = 'ACCEPTED',
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED'
}

export type PrescriptionItemDetailsType = {
  __typename?: 'PrescriptionItemDetailsType';
  id: Scalars['ID'];
  time?: Maybe<Scalars['String']>;
  dose?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  administration?: Maybe<Scalars['String']>;
  preparation?: Maybe<Scalars['String']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  fromUser?: Maybe<BriefUserType>;
  toUser?: Maybe<BriefUserType>;
  content?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  published: Scalars['Boolean'];
  consult?: Maybe<OnDemandConsultType>;
  attachmentUrl?: Maybe<Scalars['String']>;
};

export type ServiceElectronicVoucherItemType = {
  __typename?: 'ServiceElectronicVoucherItemType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  service: ServicesType;
  electronicVoucherItem: ElectronicVoucherItemsType;
  accepted: Scalars['Boolean'];
};

export type EvidenceType = {
  __typename?: 'EvidenceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  reportType: TypeformTokenReportType;
  typeformEventId?: Maybe<Scalars['String']>;
  typeformResponse?: Maybe<Scalars['JSONString']>;
  createdBy: UserType;
  member: MemberType;
  doctor?: Maybe<DoctorProfileType>;
  provider?: Maybe<ProviderBranchType>;
  state: TypeformTokenState;
  generatedFormLink: Scalars['String'];
  consumed?: Maybe<Scalars['Boolean']>;
  formLink?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['JSONString']>;
  validatedBy?: Maybe<UserType>;
  attachments?: Maybe<Array<Maybe<DocumentType>>>;
};

export enum TypeformTokenReportType {
  Memberdisease = 'MEMBERDISEASE',
  Memberpostservice = 'MEMBERPOSTSERVICE',
  Memberpostserviceshort = 'MEMBERPOSTSERVICESHORT',
  Memberpostserviceshortsofiaform = 'MEMBERPOSTSERVICESHORTSOFIAFORM',
  Otherprovider = 'OTHERPROVIDER',
  Hospitalservice = 'HOSPITALSERVICE',
  Doctorfullprocedure = 'DOCTORFULLPROCEDURE',
  Doctorofficeservice = 'DOCTOROFFICESERVICE',
  Doctorpostservice = 'DOCTORPOSTSERVICE',
  Doctorfullhospitalservice = 'DOCTORFULLHOSPITALSERVICE',
  Doctorpreservice = 'DOCTORPRESERVICE',
  Doctorposthospitalservice = 'DOCTORPOSTHOSPITALSERVICE',
  Doctorinterconsultant = 'DOCTORINTERCONSULTANT',
  Memberdiseasedev = 'MEMBERDISEASEDEV',
  Memberpostservicedev = 'MEMBERPOSTSERVICEDEV',
  Memberpostserviceshortdev = 'MEMBERPOSTSERVICESHORTDEV',
  Memberpostserviceshortsofiaformdev = 'MEMBERPOSTSERVICESHORTSOFIAFORMDEV',
  Otherproviderdev = 'OTHERPROVIDERDEV',
  Hospitalservicedev = 'HOSPITALSERVICEDEV',
  Doctorfullproceduredev = 'DOCTORFULLPROCEDUREDEV',
  Doctorofficeservicedev = 'DOCTOROFFICESERVICEDEV',
  Doctorpostservicedev = 'DOCTORPOSTSERVICEDEV',
  Doctorfullhospitalservicedev = 'DOCTORFULLHOSPITALSERVICEDEV',
  Doctorpreservicedev = 'DOCTORPRESERVICEDEV',
  Doctorposthospitalservicedev = 'DOCTORPOSTHOSPITALSERVICEDEV',
  Doctorinterconsultantdev = 'DOCTORINTERCONSULTANTDEV',
  Prescription = 'PRESCRIPTION'
}

export enum TypeformTokenState {
  In = 'IN',
  Co = 'CO'
}

export type ServicesCoverageRulingType = {
  __typename?: 'ServicesCoverageRulingType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  healthPlan?: Maybe<HealthPlanType>;
  state: ServiceCoverageRulingState;
  declinedReason?: Maybe<ServiceCoverageRulingDeclinedReason>;
  ruledByUser?: Maybe<UserType>;
  initialCostCents: Scalars['Int'];
  preauthorizationDoc?: Maybe<DocumentType>;
  postauthorizationDoc?: Maybe<DocumentType>;
  totalCostCents: Scalars['Int'];
  rejectionComment?: Maybe<Scalars['String']>;
  allowPayoutOnRejection: Scalars['Boolean'];
  service?: Maybe<ServicesType>;
  checks?: Maybe<Array<Maybe<ClaimRulingChecksType>>>;
  preapprovedAmounts: Array<PreapprovedAmountHistoryType>;
  /** @deprecated Use payout field */
  payouts?: Maybe<Array<Maybe<ServiceCoveragePayoutType>>>;
  payout?: Maybe<ServiceCoveragePayoutType>;
  payInRequest?: Maybe<ServicePayInRequestType>;
};

export enum ServiceCoverageRulingState {
  Pn = 'PN',
  Ir = 'IR',
  Ac = 'AC',
  Pa = 'PA',
  Dc = 'DC',
  Cn = 'CN'
}

export enum ServiceCoverageRulingDeclinedReason {
  Mi = 'MI',
  Hp = 'HP',
  Pr = 'PR',
  Ex = 'EX',
  Wt = 'WT',
  Mn = 'MN',
  On = 'ON',
  Sc = 'SC',
  Pi = 'PI'
}

export type ClaimRulingChecksType = {
  __typename?: 'ClaimRulingChecksType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  declinedReason?: Maybe<ClaimRulingChecksDeclinedReason>;
  state?: Maybe<RulingCheckState>;
};

export enum ClaimRulingChecksDeclinedReason {
  Mi = 'MI',
  Hp = 'HP',
  Pr = 'PR',
  Ex = 'EX',
  Wt = 'WT',
  Mn = 'MN',
  On = 'ON',
  Sc = 'SC',
  Pi = 'PI'
}

export enum RulingCheckState {
  Pending = 'PENDING',
  Passed = 'PASSED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type PreapprovedAmountHistoryType = {
  __typename?: 'PreapprovedAmountHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  currency: PreapprovedAmountHistoryCurrency;
  coveredSubtotalCents: Scalars['Int'];
  coveredVatCents: Scalars['Int'];
  coveredTotalCents: Scalars['Int'];
  coinsuredSubtotalCents: Scalars['Int'];
  coinsuredVatCents: Scalars['Int'];
  coinsuredTotalCents: Scalars['Int'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  description: Scalars['String'];
  approvedBy?: Maybe<UserType>;
  movementType: PreapprovedAmountHistoryMovementType;
  remarks?: Maybe<Scalars['String']>;
};

export enum PreapprovedAmountHistoryCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum PreapprovedAmountHistoryMovementType {
  Ini = 'INI',
  Inc = 'INC',
  Dec = 'DEC',
  Can = 'CAN',
  Pay = 'PAY'
}

export type ServiceCoveragePayoutType = {
  __typename?: 'ServiceCoveragePayoutType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  currency: ServiceCoveragePayoutCurrency;
  coveredSubtotalCents: Scalars['Int'];
  coveredVatCents: Scalars['Int'];
  coveredTotalCents: Scalars['Int'];
  coinsuredSubtotalCents: Scalars['Int'];
  coinsuredVatCents: Scalars['Int'];
  coinsuredTotalCents: Scalars['Int'];
  serviceCoverageRuling: ServicesCoverageRulingType;
  invoice?: Maybe<ClaimPayoutInvoiceType>;
  approvedBy?: Maybe<UserType>;
  amountAnalysisDocument?: Maybe<DocumentType>;
  paidCoinsuranceSubtotalCents: Scalars['Int'];
  paidCoinsuranceVatCents: Scalars['Int'];
  paidCoinsuranceTotalCents: Scalars['Int'];
  rejectedAmountSubtotalCents: Scalars['Int'];
  rejectedAmountVatCents: Scalars['Int'];
  rejectedAmountTotalCents: Scalars['Int'];
  rejectedAmountCentsComment?: Maybe<Scalars['String']>;
  payoutAmountSubtotalCents: Scalars['Int'];
  payoutAmountVatCents: Scalars['Int'];
  payoutAmountTotalCents: Scalars['Int'];
  payeeUser?: Maybe<UserType>;
  payeeProvider?: Maybe<ProviderBranchType>;
  payeeMember?: Maybe<MemberType>;
  state: ServiceCoveragePayoutState;
  payoutMode: ServiceCoveragePayoutPayoutMode;
  withholdTaxesCents: Scalars['Int'];
  coinsuredPercent?: Maybe<Scalars['Int']>;
  coveredPercent?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
};

export enum ServiceCoveragePayoutCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export type ClaimPayoutInvoiceType = {
  __typename?: 'ClaimPayoutInvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  xmlDocument?: Maybe<DocumentType>;
  pdfDocument?: Maybe<DocumentType>;
  isValid?: Maybe<Scalars['Boolean']>;
  payout: Array<ServiceCoveragePayoutType>;
};

export enum ServiceCoveragePayoutState {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  Failed = 'FAILED',
  Confirmed = 'CONFIRMED',
  InSitu = 'IN_SITU',
  Uncollectible = 'UNCOLLECTIBLE',
  Refunded = 'REFUNDED'
}

export enum ServiceCoveragePayoutPayoutMode {
  Direct = 'DIRECT',
  DirectHospital = 'DIRECT_HOSPITAL',
  DirectConsultingRoom = 'DIRECT_CONSULTING_ROOM',
  Reimbursement = 'REIMBURSEMENT',
  ReimbursementCfdiRecipientMember = 'REIMBURSEMENT_CFDI_RECIPIENT_MEMBER',
  Compensation = 'COMPENSATION'
}

export type ServicePayInRequestType = {
  __typename?: 'ServicePayInRequestType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  serviceCoverageRuling?: Maybe<ServicesCoverageRulingType>;
  method?: Maybe<PayInMethods>;
  state: ServicePayInRequestState;
};

export enum ServicePayInRequestState {
  Draft = 'DRAFT',
  Requested = 'REQUESTED',
  Failed = 'FAILED',
  Confirmed = 'CONFIRMED',
  InSitu = 'IN_SITU',
  Uncollectible = 'UNCOLLECTIBLE',
  Refunded = 'REFUNDED'
}

export type DoctorFriendType = {
  __typename?: 'DoctorFriendType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  user?: Maybe<UserType>;
  tier: DoctorFriendTier;
  billingInfo?: Maybe<BillingInfoType>;
};

export enum DoctorFriendTier {
  Regular = 'REGULAR',
  Premium = 'PREMIUM'
}

export type PharmacyOrderType = {
  __typename?: 'PharmacyOrderType';
  id: Scalars['ID'];
  state: PharmacyOrderState;
  handledBy?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  providerConfirmationId?: Maybe<Scalars['String']>;
  xmlProvided: Scalars['Boolean'];
  prescription?: Maybe<PrescriptionType>;
  user?: Maybe<UserType>;
  items?: Maybe<Array<Maybe<PharmacyOrderItemType>>>;
  amount?: Maybe<Scalars['Int']>;
  xmlDocumentId?: Maybe<Scalars['Int']>;
  provider?: Maybe<ProviderBranchType>;
};

export enum PharmacyOrderState {
  Dr = 'DR',
  Ip = 'IP',
  Cm = 'CM',
  Cl = 'CL'
}

export type PharmacyOrderItemType = {
  __typename?: 'PharmacyOrderItemType';
  id: Scalars['ID'];
  description: Scalars['String'];
  subtotal: Scalars['Int'];
  vat: Scalars['Int'];
  amount: Scalars['Int'];
};

export type MedicalFeeType = {
  __typename?: 'MedicalFeeType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  servicePtr: ServicesType;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
};

export type HospitalServiceType = {
  __typename?: 'HospitalServiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
  hospitalizationDays: Scalars['Int'];
  dischargeDate?: Maybe<Scalars['Date']>;
};

export type OtherServiceType = {
  __typename?: 'OtherServiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type RehabilitationTherapyType = {
  __typename?: 'RehabilitationTherapyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
  huliEmrEntry?: Maybe<HuliEmrEntryType>;
};

export type LabStudiesType = {
  __typename?: 'LabStudiesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type MedicinesType = {
  __typename?: 'MedicinesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type MedicalEquipmentType = {
  __typename?: 'MedicalEquipmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type HomeNurseType = {
  __typename?: 'HomeNurseType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type ProstheticsAndMechanicalEquipmentType = {
  __typename?: 'ProstheticsAndMechanicalEquipmentType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type AmbulanceType = {
  __typename?: 'AmbulanceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type IncentiveType = {
  __typename?: 'IncentiveType';
  id: Scalars['ID'];
  state: IncentiveState;
};

export enum IncentiveState {
  Cr = 'CR',
  Gr = 'GR',
  Ca = 'CA'
}

export type ExternalConsultType = {
  __typename?: 'ExternalConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type ExternalVideoConsultType = {
  __typename?: 'ExternalVideoConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type UrgencyType = {
  __typename?: 'UrgencyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceServiceTypeValue>;
  processState: ServiceProcessState;
  updatedAt: Scalars['DateTime'];
  member: MemberType;
  healthPlan: HealthPlanType;
  diseaseCase?: Maybe<DiseaseCaseType>;
  caseEvent?: Maybe<CaseEventType>;
  occurrenceDate?: Maybe<Scalars['DateTime']>;
  diagnosisIcd?: Maybe<Array<Scalars['String']>>;
  cpt?: Maybe<Array<Scalars['String']>>;
  providedByContentType?: Maybe<ContentTypeType>;
  providedByObjectId?: Maybe<Scalars['Int']>;
  externalProvider?: Maybe<Scalars['String']>;
  authorizedBy?: Maybe<UserType>;
  authorizedAt?: Maybe<Scalars['DateTime']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  supportSystemLink?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  litigation?: Maybe<Scalars['Boolean']>;
  specification?: Maybe<ServiceSpecification>;
  outOfNetwork: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  relatedService?: Maybe<ServicesType>;
  electronicVoucher?: Maybe<ElectronicVoucherType>;
  deleted?: Maybe<Scalars['DateTime']>;
  diagnosisDescription?: Maybe<Scalars['String']>;
  isPendingObligation: Scalars['Boolean'];
  isAutomaticApprovalWorkflow: Scalars['Boolean'];
  automaticPaymentApprovalFlagLastCheck?: Maybe<ServiceAutomaticPaymentApprovalFlagLastCheck>;
  uuid: Scalars['UUID'];
  servicePtr: ServicesType;
};

export type ElectronicVoucherTaxesType = {
  __typename?: 'ElectronicVoucherTaxesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  contentType?: Maybe<ContentTypeType>;
  objectId?: Maybe<Scalars['Int']>;
  baseAmountCents?: Maybe<Scalars['Int']>;
  taxType?: Maybe<ElectronicVoucherTaxesTaxType>;
  taxId?: Maybe<ElectronicVoucherTaxesTaxId>;
  taxRateValue?: Maybe<Scalars['String']>;
  taxAmountCents: Scalars['Int'];
  taxClass: ElectronicVoucherTaxesTaxClass;
};

export enum ElectronicVoucherTaxesTaxType {
  Tasa = 'TASA',
  Cuota = 'CUOTA',
  Exento = 'EXENTO'
}

export enum ElectronicVoucherTaxesTaxId {
  A_002 = 'A_002',
  A_001 = 'A_001',
  A_003 = 'A_003'
}

export enum ElectronicVoucherTaxesTaxClass {
  Wt = 'WT',
  Tt = 'TT'
}

export enum ElectronicVoucherItemsProductServiceKey {
  A_85121500 = 'A_85121500',
  A_85121501 = 'A_85121501',
  A_85121502 = 'A_85121502',
  A_85121503 = 'A_85121503',
  A_85121504 = 'A_85121504',
  A_85121600 = 'A_85121600',
  A_85121601 = 'A_85121601',
  A_85121602 = 'A_85121602',
  A_85121603 = 'A_85121603',
  A_85121604 = 'A_85121604',
  A_85121605 = 'A_85121605',
  A_85121606 = 'A_85121606',
  A_85121607 = 'A_85121607',
  A_85121608 = 'A_85121608',
  A_85121609 = 'A_85121609',
  A_85121610 = 'A_85121610',
  A_85121611 = 'A_85121611',
  A_85121612 = 'A_85121612',
  A_85121613 = 'A_85121613',
  A_85121614 = 'A_85121614',
  A_85121700 = 'A_85121700',
  A_85121701 = 'A_85121701',
  A_85121702 = 'A_85121702',
  A_85121703 = 'A_85121703',
  A_85121704 = 'A_85121704',
  A_85121705 = 'A_85121705',
  A_85121706 = 'A_85121706',
  A_85121800 = 'A_85121800',
  A_85121801 = 'A_85121801',
  A_85121802 = 'A_85121802',
  A_85121803 = 'A_85121803',
  A_85121804 = 'A_85121804',
  A_85121805 = 'A_85121805',
  A_85121806 = 'A_85121806',
  A_85121807 = 'A_85121807',
  A_85121808 = 'A_85121808',
  A_85121809 = 'A_85121809',
  A_85121810 = 'A_85121810',
  A_85121811 = 'A_85121811',
  A_85121900 = 'A_85121900',
  A_85121901 = 'A_85121901',
  A_85121902 = 'A_85121902',
  A_85122000 = 'A_85122000',
  A_85122001 = 'A_85122001',
  A_85122002 = 'A_85122002',
  A_85122003 = 'A_85122003',
  A_85122004 = 'A_85122004',
  A_85122005 = 'A_85122005',
  A_85122100 = 'A_85122100',
  A_85122101 = 'A_85122101',
  A_85122102 = 'A_85122102',
  A_85122103 = 'A_85122103',
  A_85122104 = 'A_85122104',
  A_85122105 = 'A_85122105',
  A_85122106 = 'A_85122106',
  A_85122107 = 'A_85122107',
  A_85122108 = 'A_85122108',
  A_85122109 = 'A_85122109',
  A_85122200 = 'A_85122200',
  A_85122201 = 'A_85122201',
  A_84131602 = 'A_84131602',
  A_84111506 = 'A_84111506',
  A_85101605 = 'A_85101605',
  A_85101601 = 'A_85101601',
  A_01010101 = 'A_01010101',
  A_85101501 = 'A_85101501',
  A_53131613 = 'A_53131613'
}

export enum ElectronicVoucherItemsUnitKey {
  Ip = 'IP',
  Act = 'ACT',
  E48 = 'E48',
  H87 = 'H87',
  C62 = 'C62',
  Ea = 'EA',
  Kgm = 'KGM',
  E51 = 'E51',
  A9 = 'A9',
  Mtr = 'MTR',
  Ab = 'AB',
  Bb = 'BB',
  Kt = 'KT',
  Set = 'SET',
  Ltr = 'LTR',
  Xbx = 'XBX',
  Mon = 'MON',
  Hur = 'HUR',
  Mtk = 'MTK',
  A_11 = 'A_11',
  Mgm = 'MGM',
  Xpk = 'XPK',
  Xki = 'XKI',
  As = 'AS',
  Grm = 'GRM',
  Pr = 'PR',
  Dpc = 'DPC',
  Xun = 'XUN',
  Xun_28 = 'XUN_28',
  Day = 'DAY',
  Xlt = 'XLT',
  A_10 = 'A_10',
  Mlt = 'MLT',
  E54 = 'E54',
  S6 = 'S6',
  Xci = 'XCI',
  Zz = 'ZZ',
  Xap = 'XAP',
  Lo = 'LO'
}

export enum ElectronicVoucherItemsTaxCode {
  A_01 = 'A_01',
  A_02 = 'A_02',
  A_03 = 'A_03'
}

export type LimitedHealthPlanMemberType = {
  __typename?: 'LimitedHealthPlanMemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  nickname: Scalars['String'];
  user?: Maybe<UserType>;
  healthPlan?: Maybe<HealthPlanType>;
  residenceAddress?: Maybe<AddressType>;
  onDemandConsults?: Maybe<Array<Maybe<OnDemandConsultType>>>;
  welcomeConsultStatus?: Maybe<WelcomeConsultStatusCategory>;
  services?: Maybe<Array<Maybe<LimitedServicesType>>>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
};

export enum WelcomeConsultStatusCategory {
  ToBeScheduled = 'TO_BE_SCHEDULED',
  Scheduled = 'SCHEDULED',
  OptedOut = 'OPTED_OUT',
  Taken = 'TAKEN'
}

export type BriefBusinessBeneficiaryType = {
  __typename?: 'BriefBusinessBeneficiaryType';
  id: Scalars['ID'];
  beneficiaryCategory: BusinessBeneficiaryBeneficiaryCategory;
  briefBusiness?: Maybe<BriefBusinessType>;
  briefBusinessGroupConfig?: Maybe<BriefBusinessGroupsConfigType>;
};

export enum BusinessBeneficiaryBeneficiaryCategory {
  Em = 'EM',
  Fm = 'FM'
}

export type BriefBusinessType = {
  __typename?: 'BriefBusinessType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  subscriptionType: BusinessSubscriptionType;
  includeMaternity?: Maybe<Scalars['Boolean']>;
};

export enum BusinessSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type BriefBusinessGroupsConfigType = {
  __typename?: 'BriefBusinessGroupsConfigType';
  id: Scalars['ID'];
  allowMultimember: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
};

export enum InsuredAmountCents {
  M_1_5 = 'M_1_5',
  M_5_0 = 'M_5_0',
  M_15_0 = 'M_15_0'
}

export enum CoInsuranceConfigFactor {
  Ci_30_50 = 'CI_30_50',
  Ci_10_30 = 'CI_10_30',
  Ci_10_10 = 'CI_10_10'
}

export type InvoiceItemHealthPlanRelatedDetails = {
  __typename?: 'InvoiceItemHealthPlanRelatedDetails';
  member?: Maybe<LimitedMemberType>;
  totalInstallments?: Maybe<Scalars['Int']>;
  concept?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  renewalDatetime?: Maybe<Scalars['DateTime']>;
  maximumCoverageCents?: Maybe<Scalars['Int']>;
  addOns?: Maybe<Array<Maybe<Scalars['String']>>>;
  annualTotalCents?: Maybe<Scalars['Int']>;
};

export type LimitedMemberType = {
  __typename?: 'LimitedMemberType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  gender: MemberGender;
  assignedPrimaryCareDoctor?: Maybe<DoctorType>;
  antiquitySince?: Maybe<Scalars['Date']>;
  hasActiveHealthPlan?: Maybe<Scalars['Boolean']>;
  healthPlanUuid?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Scalars['String']>;
  briefBusinessBeneficiary?: Maybe<BriefBusinessBeneficiaryType>;
};

export type TransactionType = {
  __typename?: 'TransactionType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  paymentMethodInformation: Scalars['JSONString'];
  paymentGatewaySource?: Maybe<PaymentGatewaySourcesType>;
  currency: TransactionCurrency;
  amountCents: Scalars['Int'];
  transactionType: TransactionTransactionType;
  paymentGatewayConfirmation: Scalars['JSONString'];
  paymentGatewayResult?: Maybe<TransactionPaymentGatewayResult>;
  uuid: Scalars['UUID'];
  invoice?: Maybe<InvoiceType>;
  evidenceDoc?: Maybe<DocumentType>;
  cepAvailable?: Maybe<Scalars['Boolean']>;
};

export type PaymentGatewaySourcesType = {
  __typename?: 'PaymentGatewaySourcesType';
  id: Scalars['ID'];
  paymentGatewayType?: Maybe<PaymentGatewayType>;
  paymentGatewaySourceId?: Maybe<Scalars['String']>;
  paymentGatewaySourceReference?: Maybe<Scalars['JSONString']>;
};

export enum PaymentGatewayType {
  Stripe = 'STRIPE',
  StripePaymentMethod = 'STRIPE_PAYMENT_METHOD',
  Stp = 'STP',
  Offline = 'OFFLINE',
  StpPayIn = 'STP_PAY_IN',
  Paypal = 'PAYPAL',
  Manual = 'MANUAL',
  Uncollectible = 'UNCOLLECTIBLE'
}

export enum SatVoucherType {
  Income = 'INCOME',
  Expenditure = 'EXPENDITURE',
  Payment = 'PAYMENT'
}

export type HealthPlanApplicationType = {
  __typename?: 'HealthPlanApplicationType';
  id: Scalars['ID'];
  businessCode?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  waitingSince?: Maybe<Scalars['DateTime']>;
  acceptApplicationAt?: Maybe<Scalars['DateTime']>;
  educationAnswers?: Maybe<Scalars['JSONString']>;
  incentiveCode?: Maybe<IncentiveCodeType>;
  sponsorType: SponsorType;
  subscriptionInvoice?: Maybe<InvoiceType>;
  owner?: Maybe<UserType>;
  applicant?: Maybe<UserType>;
  briefBusiness?: Maybe<BriefBusinessType>;
  subtotalCents?: Maybe<Scalars['Int']>;
  vatCents?: Maybe<Scalars['Int']>;
  grandTotalCents?: Maybe<Scalars['Int']>;
  /** @deprecated Use subscription_type in Quote */
  subscriptionType?: Maybe<Scalars['String']>;
  /** @deprecated Use currency in Quote */
  currency?: Maybe<Scalars['String']>;
  ownerRejectionReason?: Maybe<HealthPlanApplicationItemRejectionReason>;
  items?: Maybe<Array<Maybe<HealthPlanApplicationItemType>>>;
  state: HealthPlanApplicationState;
  subscriptionSource: HealthPlanApplicationSource;
  rejectionReason?: Maybe<InvoiceRejectionReason>;
  interestFreePaymentOptions?: Maybe<Scalars['JSONString']>;
  business?: Maybe<BusinessType>;
  ownedByBusiness?: Maybe<Scalars['Boolean']>;
  hasInterestFreePlan?: Maybe<Scalars['Boolean']>;
  invoicePaymentMethod?: Maybe<BriefPaymentMethodsType>;
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  applicationDescription?: Maybe<Scalars['String']>;
};

export type IncentiveCodeType = {
  __typename?: 'IncentiveCodeType';
  id: Scalars['ID'];
  code: Scalars['String'];
  discountPercent?: Maybe<Scalars['Float']>;
  discountCents?: Maybe<Scalars['Int']>;
  isValid: Scalars['Boolean'];
  campaign?: Maybe<IncentiveCampaignsType>;
  bannerState: BannerState;
};

export type IncentiveCampaignsType = {
  __typename?: 'IncentiveCampaignsType';
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
};

export enum BannerState {
  Referred = 'REFERRED',
  Welcome = 'WELCOME',
  DoNotShow = 'DO_NOT_SHOW'
}

export enum SponsorType {
  Myself = 'MYSELF',
  Others = 'OTHERS',
  MyselfAndOthers = 'MYSELF_AND_OTHERS'
}

export enum HealthPlanApplicationItemRejectionReason {
  Documents = 'DOCUMENTS',
  Medical = 'MEDICAL',
  Kyc = 'KYC',
  OutOfArea = 'OUT_OF_AREA',
  Occupation = 'OCCUPATION',
  Covid = 'COVID',
  Obesity = 'OBESITY',
  Other = 'OTHER'
}

export type HealthPlanApplicationItemType = {
  __typename?: 'HealthPlanApplicationItemType';
  id: Scalars['ID'];
  application: HealthPlanApplicationType;
  product?: Maybe<ProductType>;
  questionnaireAnswers?: Maybe<Scalars['JSONString']>;
  relationship: HealthPlanApplicationItemRelationship;
  initialExclusions?: Maybe<Scalars['String']>;
  waitingTimeSetAt?: Maybe<Scalars['DateTime']>;
  covidVaccinationStatus?: Maybe<CovidVaccinationStatus>;
  insuredAmountCents?: Maybe<InsuredAmountCents>;
  maternityAddon: Scalars['Boolean'];
  healthPlanValidSince?: Maybe<Scalars['DateTime']>;
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
  medicalRecommendationNotes?: Maybe<Scalars['String']>;
  chosenForAutomaticSubscription?: Maybe<Scalars['Boolean']>;
  automaticSubscriptionEligibilityScore?: Maybe<Scalars['Float']>;
  automaticSubscriptionMedicalScore?: Maybe<Scalars['Float']>;
  automaticSubscriptionParameters?: Maybe<Scalars['JSONString']>;
  canSubscribeAutomatically?: Maybe<Scalars['Boolean']>;
  healthPlan?: Maybe<HealthPlanType>;
  questionnaireAnswersValidated?: Maybe<Scalars['JSONString']>;
  healthPlanQuote?: Maybe<HealthPlanQuoteType>;
  hasSubscriptionChecks?: Maybe<Scalars['Boolean']>;
  passesAllSubscriptionChecks?: Maybe<Scalars['Boolean']>;
  subscriptionChecks?: Maybe<Array<Maybe<HealthPlanApplicationItemCheckType>>>;
  comments?: Maybe<Array<Maybe<HealthPlanApplicationItemCommentType>>>;
  member?: Maybe<MemberType>;
  dependentDoc?: Maybe<DocumentType>;
  signatureDoc?: Maybe<DocumentType>;
  covidVaccineProofDoc?: Maybe<DocumentType>;
  rejectionReason?: Maybe<HealthPlanApplicationItemRejectionReason>;
  state: HealthPlanApplicationItemState;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  requiredDocuments?: Maybe<Array<Maybe<DocumentCategory>>>;
  missingDocuments?: Maybe<Array<Maybe<DocumentCategory>>>;
  medicalRecommendation?: Maybe<MedicalRecommendation>;
};

export enum HealthPlanApplicationItemRelationship {
  Wf = 'WF',
  Hs = 'HS',
  Dg = 'DG',
  Sl = 'SL',
  Sn = 'SN',
  Ft = 'FT',
  Mt = 'MT',
  Gp = 'GP',
  Sb = 'SB',
  Gc = 'GC',
  Np = 'NP'
}

export enum CovidVaccinationStatus {
  PartiallyVaccinated = 'PARTIALLY_VACCINATED',
  FullyVaccinated = 'FULLY_VACCINATED',
  VaccinatedWithProof = 'VACCINATED_WITH_PROOF',
  NotVaccinated = 'NOT_VACCINATED',
  NoInformationAvailable = 'NO_INFORMATION_AVAILABLE',
  WaitingToShareProof = 'WAITING_TO_SHARE_PROOF'
}

export type HealthPlanQuoteType = {
  __typename?: 'HealthPlanQuoteType';
  id: Scalars['ID'];
  expiresAt: Scalars['DateTime'];
  currency: HealthPlanQuoteCurrency;
  subscriptionType: HealthPlanQuoteSubscriptionType;
  premiumMaternityCents: Scalars['Int'];
  premiumDentalCents: Scalars['Int'];
  premiumNetBaseRgaCents: Scalars['Int'];
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  subscriptionTypeDiscountCents?: Maybe<Scalars['Int']>;
  requestingUser?: Maybe<UserType>;
  member?: Maybe<MemberType>;
  productParametersValues?: Maybe<Scalars['JSONString']>;
  subtotalCents?: Maybe<Scalars['Int']>;
  discountAmountCents?: Maybe<Scalars['Int']>;
  vatCents?: Maybe<Scalars['Int']>;
  grandTotalCents?: Maybe<Scalars['Int']>;
  installmentTotalCentsWithoutIncentiveCode?: Maybe<Scalars['Int']>;
};

export enum HealthPlanQuoteCurrency {
  Mxn = 'MXN',
  Usd = 'USD',
  Xxx = 'XXX'
}

export enum HealthPlanQuoteSubscriptionType {
  Mn = 'MN',
  Yr = 'YR',
  Qr = 'QR',
  Bn = 'BN'
}

export type HealthPlanApplicationItemCheckType = {
  __typename?: 'HealthPlanApplicationItemCheckType';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  checkType: HealthPlanApplicationItemCheckCheckType;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  comments?: Maybe<Scalars['String']>;
  state: HealthPlanApplicationItemCheckState;
  checkDoc?: Maybe<DocumentType>;
  passedEvidenceDoc?: Maybe<DocumentType>;
  validatedByUser?: Maybe<UserType>;
  validatedAt?: Maybe<Scalars['DateTime']>;
};

export enum HealthPlanApplicationItemCheckCheckType {
  Dp = 'DP',
  Id = 'ID',
  Rf = 'RF',
  Cr = 'CR',
  Sl = 'SL',
  Ad = 'AD',
  Np = 'NP',
  Nb = 'NB',
  Nr = 'NR',
  Sw = 'SW',
  Md = 'MD',
  Ot = 'OT',
  Oc = 'OC',
  Rl = 'RL',
  Ti = 'TI'
}

export enum HealthPlanApplicationItemCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export type HealthPlanApplicationItemCommentType = {
  __typename?: 'HealthPlanApplicationItemCommentType';
  id: Scalars['ID'];
  comment: Scalars['String'];
  author?: Maybe<UserType>;
};

export enum HealthPlanApplicationItemState {
  Draft = 'DRAFT',
  ReviewNeeded = 'REVIEW_NEEDED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  WaitingConditions = 'WAITING_CONDITIONS',
  ToBeRenewed = 'TO_BE_RENEWED'
}

export enum MedicalRecommendation {
  NoExclusions = 'NO_EXCLUSIONS',
  Rejection = 'REJECTION',
  EvaluationRequired = 'EVALUATION_REQUIRED',
  DefinitiveExclusions = 'DEFINITIVE_EXCLUSIONS',
  AutomaticSubscription = 'AUTOMATIC_SUBSCRIPTION'
}

export enum HealthPlanApplicationState {
  Draft = 'DRAFT',
  ReviewNeeded = 'REVIEW_NEEDED',
  MoreInfoNeeded = 'MORE_INFO_NEEDED',
  PaymentFailed = 'PAYMENT_FAILED',
  ApprovedForPayment = 'APPROVED_FOR_PAYMENT',
  Rejected = 'REJECTED',
  Subscribed = 'SUBSCRIBED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Error = 'ERROR',
  WaitingSubscriber = 'WAITING_SUBSCRIBER',
  WaitingConditions = 'WAITING_CONDITIONS',
  ToBeRenewed = 'TO_BE_RENEWED'
}

export enum HealthPlanApplicationSource {
  App = 'APP',
  Web = 'WEB',
  Hybrid = 'HYBRID',
  Renewal = 'RENEWAL',
  Reissue = 'REISSUE',
  Collective = 'COLLECTIVE',
  CollectiveAdditional = 'COLLECTIVE_ADDITIONAL',
  CollectiveMigration = 'COLLECTIVE_MIGRATION'
}

export enum InvoiceRejectionReason {
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Other = 'OTHER'
}

export type BusinessType = {
  __typename?: 'BusinessType';
  id: Scalars['ID'];
  commonName?: Maybe<Scalars['String']>;
  maternityAddon: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  pipedriveDeal?: Maybe<Scalars['Int']>;
  legalName: Scalars['String'];
  personCategory?: Maybe<PersonCategory>;
  administratorName?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  referenceCode: Scalars['String'];
  nationalityCountryCode?: Maybe<CountryCode>;
  taxNumber?: Maybe<Scalars['String']>;
  satUseType?: Maybe<BusinessSatUseType>;
  tradeRegistrationId?: Maybe<Scalars['String']>;
  incorporationDate?: Maybe<Scalars['Date']>;
  digitalSignatureSerialNumber?: Maybe<Scalars['String']>;
  state?: Maybe<BusinessState>;
  taxRegistrationDoc?: Maybe<DocumentType>;
  digitalTaxSignatureProofDoc?: Maybe<DocumentType>;
  tradeRegistrationProofDoc?: Maybe<DocumentType>;
  registrationToCommercePublicRegistryDoc?: Maybe<DocumentType>;
  proofOfAddressDoc?: Maybe<DocumentType>;
  contractDoc?: Maybe<DocumentType>;
  address?: Maybe<AddressType>;
  contractSentAt?: Maybe<Scalars['DateTime']>;
  contractSignedAt?: Maybe<Scalars['DateTime']>;
  originalQuoteAmountCents: Scalars['Int'];
  quoteBeneficiariesNumber: Scalars['Int'];
  complianceTier: BusinessComplianceTier;
  totalHealthplanAmountCents: Scalars['Int'];
  maternityAddonWaitingPeriodMonths?: Maybe<MaternityWaitingPeriodMonths>;
  isCollective: Scalars['Boolean'];
  previousInsurance?: Maybe<Scalars['String']>;
  previousInsuranceValidSince?: Maybe<Scalars['DateTime']>;
  previousInsuranceValidThrough?: Maybe<Scalars['DateTime']>;
  subscriptionFileSent: Scalars['Boolean'];
  allowMultimember: Scalars['Boolean'];
  stakeholders: Array<BusinessStakeholderType>;
  ownerUser?: Maybe<UserType>;
  /** @deprecated Use owner_user */
  adminUser?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
  payoutMethod?: Maybe<PaymentMethodsType>;
  payinSpeiMethod?: Maybe<PaymentMethodsType>;
  healthPlanApplications?: Maybe<Array<Maybe<HealthPlanApplicationType>>>;
  subscriptionType?: Maybe<SubscriptionType>;
  kybChecks?: Maybe<Array<Maybe<BusinessCheckTypeType>>>;
  documentChecks?: Maybe<Array<Maybe<BusinessCheckTypeType>>>;
  contractCheck?: Maybe<BusinessCheckTypeType>;
  missingAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  missingChecks?: Maybe<Array<Maybe<Scalars['String']>>>;
  riskLevel?: Maybe<RiskLevel>;
  includeMaternity?: Maybe<Scalars['Boolean']>;
  businessGroupsConfigs?: Maybe<Array<BusinessGroupsConfigType>>;
  activeBusinessBeneficiaries?: Maybe<Scalars['Int']>;
  totalBusinessBeneficiaries?: Maybe<Scalars['Int']>;
  incentiveCode?: Maybe<Scalars['String']>;
  activeOrPendingActivationConfigurations?: Maybe<Array<BusinessGroupsConfigType>>;
  legalRepresentativeStakeholder?: Maybe<BusinessStakeholderType>;
};

export enum PersonCategory {
  Legal = 'LEGAL',
  Natural = 'NATURAL'
}

export enum CountryCode {
  Other = 'OTHER',
  Mexico = 'MEXICO'
}

export enum BusinessSatUseType {
  D07 = 'D07',
  D01 = 'D01',
  P01 = 'P01',
  G03 = 'G03',
  S01 = 'S01',
  Cp01 = 'CP01',
  Unk = 'UNK'
}

export enum BusinessState {
  Draft = 'DRAFT',
  Approved = 'APPROVED',
  Revoked = 'REVOKED',
  ComplianceReview = 'COMPLIANCE_REVIEW',
  ContractSent = 'CONTRACT_SENT',
  ContractSigned = 'CONTRACT_SIGNED'
}

export enum BusinessComplianceTier {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3'
}

export enum MaternityWaitingPeriodMonths {
  NoWaitingTime = 'NO_WAITING_TIME',
  SixMonths = 'SIX_MONTHS'
}

export type BusinessStakeholderType = {
  __typename?: 'BusinessStakeholderType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  business?: Maybe<BusinessType>;
  idDoc?: Maybe<DocumentType>;
  roleProofDoc?: Maybe<DocumentType>;
  foreignerDoc?: Maybe<DocumentType>;
  curpDoc?: Maybe<DocumentType>;
  curpIncludedInIdDoc: Scalars['Boolean'];
  stakeholderState?: Maybe<StakeHolderState>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  birthCountryCode?: Maybe<CountryCode>;
  birthCountry?: Maybe<Scalars['String']>;
  nationalityCountryCode?: Maybe<CountryCode>;
  extraRoleProofDocNeeded: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  businessControl: Scalars['Boolean'];
  legalRepresentative: Scalars['Boolean'];
  shareholder: Scalars['Boolean'];
  telephone?: Maybe<Scalars['String']>;
  businessStakeholderChecks: Array<BusinessStakeholderCheckType>;
  riskLevel?: Maybe<RiskLevel>;
};

export enum StakeHolderState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type BusinessStakeholderCheckType = {
  __typename?: 'BusinessStakeholderCheckType';
  id: Scalars['ID'];
  checkType: BusinessStakeholderCheckCheckType;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  state: BusinessStakeholderCheckState;
};

export enum BusinessStakeholderCheckCheckType {
  Np = 'NP',
  Nb = 'NB',
  Nr = 'NR',
  Rl = 'RL'
}

export enum BusinessStakeholderCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export enum RiskLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Unknown = 'UNKNOWN'
}

export type PaymentMethodsType = {
  __typename?: 'PaymentMethodsType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  isPrimary: Scalars['Boolean'];
  isPayoutPrimary: Scalars['Boolean'];
  paymentMethodType: PaymentMethodsPaymentMethodType;
  paymentFlowType?: Maybe<PaymentMethodsPaymentFlowType>;
  approved: Scalars['Boolean'];
  referenceName?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
  clabe?: Maybe<Scalars['String']>;
};

export enum PaymentMethodsPaymentMethodType {
  Cr = 'CR',
  Ox = 'OX',
  Se = 'SE',
  Pa = 'PA',
  Mn = 'MN'
}

export enum PaymentMethodsPaymentFlowType {
  In = 'IN',
  Out = 'OUT'
}

export enum SubscriptionType {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  Quarterly = 'QUARTERLY',
  Biannually = 'BIANNUALLY'
}

export type BusinessCheckTypeType = {
  __typename?: 'BusinessCheckTypeType';
  id: Scalars['ID'];
  checkType: BusinessCheckCheckType;
  verifyDetails?: Maybe<Scalars['JSONString']>;
  state: BusinessCheckState;
  required: Scalars['Boolean'];
  justifiableAbsence: Scalars['Boolean'];
  absenceJustification?: Maybe<Scalars['String']>;
};

export enum BusinessCheckCheckType {
  Nb = 'NB',
  Nr = 'NR',
  Rl = 'RL',
  Ad = 'AD',
  Tr = 'TR',
  Ts = 'TS',
  Td = 'TD',
  Co = 'CO',
  Cs = 'CS'
}

export enum BusinessCheckState {
  Pn = 'PN',
  Ps = 'PS',
  Fl = 'FL',
  Mi = 'MI'
}

export type BusinessGroupsConfigType = {
  __typename?: 'BusinessGroupsConfigType';
  id: Scalars['ID'];
  business: BusinessType;
  default: Scalars['Boolean'];
  state?: Maybe<BusinessGroupsConfigState>;
  allowMultimember: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  insuredAmountCentsCoverage?: Maybe<InsuredAmountCents>;
  name?: Maybe<Scalars['String']>;
  coinsuranceConfig?: Maybe<CoInsuranceConfigFactor>;
};

export enum BusinessGroupsConfigState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  PendingActivation = 'PENDING_ACTIVATION'
}

export type BriefPaymentMethodsType = {
  __typename?: 'BriefPaymentMethodsType';
  id: Scalars['ID'];
  paymentMethodType: PaymentMethodsPaymentMethodType;
};

export enum CustomerPaymentFlowType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type BusinessAdminType = {
  __typename?: 'BusinessAdminType';
  id: Scalars['ID'];
  business: BusinessType;
  user?: Maybe<UserType>;
  role?: Maybe<BusinessAdminRole>;
};

export enum BusinessAdminRole {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  FinanceUser = 'FINANCE_USER'
}

export type PushTokenType = {
  __typename?: 'PushTokenType';
  token: Scalars['String'];
  platform: Scalars['String'];
  platformVersion: Scalars['String'];
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  id: Scalars['ID'];
  sources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
  primary?: Maybe<Scalars['Boolean']>;
  source?: Maybe<PaymentGatewaySourcesType>;
  notAppliedSources?: Maybe<Array<Maybe<PaymentGatewaySourcesType>>>;
};

export type RepresentedBeneficiaryHealthPlanType = {
  __typename?: 'RepresentedBeneficiaryHealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
};

export type OwnedHealthPlanType = {
  __typename?: 'OwnedHealthPlanType';
  id: Scalars['ID'];
  beneficiaryMember?: Maybe<MemberType>;
  relationship: HealthPlanRelationship;
  state: HealthPlanState;
  owner?: Maybe<UserType>;
};

export enum GroupName {
  Adjusters = 'ADJUSTERS',
  SuperAdmin = 'SUPER_ADMIN',
  AdminEdit = 'ADMIN_EDIT',
  AdminRead = 'ADMIN_READ',
  Chiefs = 'CHIEFS',
  OpsHeads = 'OPS_HEADS',
  ManualPayments = 'MANUAL_PAYMENTS',
  Developers = 'DEVELOPERS',
  Doctors = 'DOCTORS',
  MedicalOps = 'MEDICAL_OPS',
  Operations = 'OPERATIONS',
  Providers = 'PROVIDERS',
  MedicalSabio = 'MEDICAL_SABIO',
  SalesManagers = 'SALES_MANAGERS',
  Accounting = 'ACCOUNTING',
  Impersonators = 'IMPERSONATORS',
  SuperDoctor = 'SUPER_DOCTOR',
  OpsFinance = 'OPS_FINANCE'
}

export enum MemberBirthCountryCode {
  Ot = 'OT',
  Mx = 'MX'
}

export enum MemberNationalityCountryCode {
  Ot = 'OT',
  Mx = 'MX'
}

export type ComplianceAlertType = {
  __typename?: 'ComplianceAlertType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  subject?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
  comment?: Maybe<Scalars['String']>;
  reviewedByOfficer: Scalars['Boolean'];
  reviewedByOfficerAt?: Maybe<Scalars['DateTime']>;
  officerReported: Scalars['Boolean'];
  internalNotes?: Maybe<Scalars['String']>;
  reviewedByCommittee: Scalars['Boolean'];
  reviewedByCommitteeAt?: Maybe<Scalars['DateTime']>;
  reportToAuthority: Scalars['Boolean'];
  reportedAt?: Maybe<Scalars['DateTime']>;
  alertCategory?: Maybe<ComplianceAlertCategory>;
  member?: Maybe<MemberType>;
  applicationState?: Maybe<HealthPlanApplicationState>;
  reviewedByUser?: Maybe<UserType>;
};

export enum ComplianceAlertCategory {
  Unknown = 'UNKNOWN',
  PepCheckFailed = 'PEP_CHECK_FAILED',
  RestrictedCheckFailed = 'RESTRICTED_CHECK_FAILED',
  BannedCheckFailed = 'BANNED_CHECK_FAILED',
  IdCheckFailed = 'ID_CHECK_FAILED',
  DuplicatedAddress = 'DUPLICATED_ADDRESS',
  AddressUpdated = 'ADDRESS_UPDATED',
  Cancellation = 'CANCELLATION',
  HighRiskLevel = 'HIGH_RISK_LEVEL',
  OccupationCheckFailed = 'OCCUPATION_CHECK_FAILED',
  BirthCountryCheckFailed = 'BIRTH_COUNTRY_CHECK_FAILED',
  ComplianceSurpassedRefundLimit = 'COMPLIANCE_SURPASSED_REFUND_LIMIT',
  ComplianceSurpassedMonthlyLimit = 'COMPLIANCE_SURPASSED_MONTHLY_LIMIT',
  Location = 'LOCATION',
  HighAmountTransaction = 'HIGH_AMOUNT_TRANSACTION',
  InternalOperations = 'INTERNAL_OPERATIONS',
  GeneralUnusualOperations = 'GENERAL_UNUSUAL_OPERATIONS',
  UnusualCustomerBehaviorContractingProducts = 'UNUSUAL_CUSTOMER_BEHAVIOR_CONTRACTING_PRODUCTS',
  UnusualCustomerBehaviorPerformingOperations = 'UNUSUAL_CUSTOMER_BEHAVIOR_PERFORMING_OPERATIONS',
  UnusualCustomerBehaviorPaymentAmount = 'UNUSUAL_CUSTOMER_BEHAVIOR_PAYMENT_AMOUNT',
  UnusualNumberOfAmendmentsInAYear = 'UNUSUAL_NUMBER_OF_AMENDMENTS_IN_A_YEAR'
}

export type NonPathologicalHistoryType = {
  __typename?: 'NonPathologicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  lifestyleNotes?: Maybe<Scalars['String']>;
  sleepHours?: Maybe<Scalars['Int']>;
  sleepNotes?: Maybe<Scalars['String']>;
  nutritionNotes?: Maybe<Scalars['String']>;
  hasExercise?: Maybe<Scalars['Boolean']>;
  exerciseActivities?: Maybe<Scalars['String']>;
  exerciseWeeklyFrequency?: Maybe<Scalars['Int']>;
  exerciseNotes?: Maybe<Scalars['String']>;
  smokingConsumption?: Maybe<NonPathologicalHistoryHabit>;
  smokingCigarettes?: Maybe<Scalars['Int']>;
  smokingYears?: Maybe<Scalars['Int']>;
  smokingSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  smokingNotes?: Maybe<Scalars['String']>;
  alcoholConsumption?: Maybe<NonPathologicalHistoryHabit>;
  alcoholFrequency?: Maybe<Scalars['String']>;
  alcoholConsumptionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  alcoholSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  alcoholNotes?: Maybe<Scalars['String']>;
  drugsConsumption?: Maybe<NonPathologicalHistoryHabit>;
  drugsType?: Maybe<Drug>;
  drugsName?: Maybe<Scalars['String']>;
  drugsSuspensionTime?: Maybe<NonPathologicalHistoryHabitYears>;
  drugsNotes?: Maybe<Scalars['String']>;
  medicalHistory?: Maybe<MedicalHistoryType>;
};

export enum NonPathologicalHistoryHabit {
  Ongoing = 'ONGOING',
  Suspended = 'SUSPENDED',
  NotPresent = 'NOT_PRESENT'
}

export enum NonPathologicalHistoryHabitYears {
  LessThanOne = 'LESS_THAN_ONE',
  OneToFive = 'ONE_TO_FIVE',
  SixToTen = 'SIX_TO_TEN',
  TenToTwenty = 'TEN_TO_TWENTY',
  MoreThanTwenty = 'MORE_THAN_TWENTY'
}

export enum Drug {
  Amphetamine = 'AMPHETAMINE',
  Caffeine = 'CAFFEINE',
  Cannabis = 'CANNABIS',
  Cocaine = 'COCAINE',
  Other = 'OTHER'
}

export enum MedicalHistorySectionState {
  Present = 'PRESENT',
  Denied = 'DENIED'
}

export type PerinatalHistoryType = {
  __typename?: 'PerinatalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  isPresent?: Maybe<MedicalHistorySectionState>;
  gestationalAgeWeeks?: Maybe<Scalars['Int']>;
  gestationalAgeDays?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  headCircumference?: Maybe<Scalars['Float']>;
  gestationalAgeClassification?: Maybe<PerinatalHistoryGestationalAgeClassification>;
  gestationalWeightClassification?: Maybe<PerinatalHistoryGestationalWeightClassification>;
  apgar1MinScore?: Maybe<Scalars['Int']>;
  apgar5MinScore?: Maybe<Scalars['Int']>;
  reanimationRequired?: Maybe<Scalars['Boolean']>;
  reanimationNotes?: Maybe<Scalars['String']>;
  hadComplications?: Maybe<Scalars['Boolean']>;
  complicationsNotes?: Maybe<Scalars['String']>;
  developmentAlteration?: Maybe<Scalars['Boolean']>;
  developmentAlterationNotes?: Maybe<Scalars['String']>;
  incubationRequired?: Maybe<Scalars['Boolean']>;
  other?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  normalCardiologyScreening?: Maybe<Scalars['Boolean']>;
  cardiologyScreeningNotes?: Maybe<Scalars['String']>;
  normalHipScreening?: Maybe<Scalars['Boolean']>;
  hipScreeningNotes?: Maybe<Scalars['String']>;
  normalHearingScreening?: Maybe<Scalars['Boolean']>;
  hearingScreeningNotes?: Maybe<Scalars['String']>;
  normalVisualScreening?: Maybe<Scalars['Boolean']>;
  visualScreeningNotes?: Maybe<Scalars['String']>;
  normalMetabolicScreening?: Maybe<Scalars['Boolean']>;
  metabolicScreeningNotes?: Maybe<Scalars['String']>;
};

export enum PerinatalHistoryGestationalAgeClassification {
  Rnpr = 'RNPR',
  Rnt = 'RNT',
  Rnpt = 'RNPT'
}

export enum PerinatalHistoryGestationalWeightClassification {
  Peg = 'PEG',
  Aeg = 'AEG',
  Geg = 'GEG'
}

export type ParentPerinatalHistoryType = {
  __typename?: 'ParentPerinatalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  isPresent?: Maybe<MedicalHistorySectionState>;
  motherPregnancies?: Maybe<Scalars['Int']>;
  motherAbortions?: Maybe<Scalars['Int']>;
  motherDeliveries?: Maybe<Scalars['Int']>;
  motherCaesareanSections?: Maybe<Scalars['Int']>;
  motherPregnancyAge?: Maybe<Scalars['Int']>;
  motherHeight?: Maybe<Scalars['Float']>;
  fatherHeight?: Maybe<Scalars['Float']>;
  expectedHeight?: Maybe<Scalars['Float']>;
  pregnancyEvolution?: Maybe<ParentPerinatalHistoryPregnancyEvolution>;
  obstetrician?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<ParentPerinatalHistoryDeliveryType>;
  notes?: Maybe<Scalars['String']>;
  pregnancyComplications?: Maybe<Scalars['Boolean']>;
  pregnancyComplicationsNotes?: Maybe<Scalars['String']>;
};

export enum ParentPerinatalHistoryPregnancyEvolution {
  Normal = 'NORMAL',
  Abnormal = 'ABNORMAL',
  HighRisk = 'HIGH_RISK'
}

export enum ParentPerinatalHistoryDeliveryType {
  Spontaneous = 'SPONTANEOUS',
  Conducted = 'CONDUCTED',
  Instrumented = 'INSTRUMENTED',
  IntrapartumCaesarean = 'INTRAPARTUM_CAESAREAN',
  ElectiveCaesarean = 'ELECTIVE_CAESAREAN'
}

export type BasicInfoType = {
  __typename?: 'BasicInfoType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  date?: Maybe<Scalars['Date']>;
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  bloodType?: Maybe<BloodType>;
};

export type ObstetricGynecologicalHistoryType = {
  __typename?: 'ObstetricGynecologicalHistoryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  hasBirthControl?: Maybe<Scalars['Boolean']>;
  birthControlName?: Maybe<ObstetricGynecologicalHistoryBirthControlName>;
  birthControlTimeUsed?: Maybe<Scalars['String']>;
  birthControlNotes?: Maybe<Scalars['String']>;
  hasMenstrualCycle?: Maybe<Scalars['Boolean']>;
  isMenstrualCycleRegular?: Maybe<Scalars['Boolean']>;
  hasDysmenorrhea?: Maybe<Scalars['Boolean']>;
  menstrualCycleNotes?: Maybe<Scalars['String']>;
  hasBeenPregnant?: Maybe<Scalars['Boolean']>;
  numPregnancies: Scalars['Int'];
  numDeliveries: Scalars['Int'];
  numAbortions: Scalars['Int'];
  numStillBirths: Scalars['Int'];
  numCesareans: Scalars['Int'];
  numLiveBirths: Scalars['Int'];
  numDeaths: Scalars['Int'];
  pregnancyNotes?: Maybe<Scalars['String']>;
  gynecologicalExamsNotes?: Maybe<Scalars['String']>;
  gynecologicalExams: Array<GynecologicalExamType>;
  pregnancies: Array<PregnancyType>;
};

export enum ObstetricGynecologicalHistoryBirthControlName {
  Intrauterine = 'INTRAUTERINE',
  Injectable = 'INJECTABLE',
  BarrierMethod = 'BARRIER_METHOD',
  NaturalMethod = 'NATURAL_METHOD',
  SurgicalMethod = 'SURGICAL_METHOD',
  Oral = 'ORAL',
  Subdermal = 'SUBDERMAL'
}

export type GynecologicalExamType = {
  __typename?: 'GynecologicalExamType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
  type?: Maybe<GynecologicalExamClassification>;
  date: Scalars['Date'];
  isNormal: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
};

export enum GynecologicalExamClassification {
  Cytology = 'CYTOLOGY',
  Mammogram = 'MAMMOGRAM'
}

export type PregnancyType = {
  __typename?: 'PregnancyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
  date?: Maybe<Scalars['Date']>;
  laborHours?: Maybe<Scalars['Int']>;
  gestationAge?: Maybe<PregnancyGestationAge>;
  notes?: Maybe<Scalars['String']>;
  deliveries: Array<PregnancyDeliveryType>;
};

export enum PregnancyGestationAge {
  Preterm = 'PRETERM',
  Term = 'TERM',
  Postterm = 'POSTTERM'
}

export type PregnancyDeliveryType = {
  __typename?: 'PregnancyDeliveryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  pregnancy: PregnancyType;
  weight?: Maybe<Scalars['Float']>;
  sexAssignedAtBirth?: Maybe<PregnancySex>;
  isCesarean?: Maybe<Scalars['Boolean']>;
  status?: Maybe<PregnancyStatus>;
};

export enum PregnancySex {
  M = 'M',
  F = 'F'
}

export enum PregnancyStatus {
  Live = 'LIVE',
  Aborted = 'ABORTED',
  Dead = 'DEAD'
}

export type AllergyType = {
  __typename?: 'AllergyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  allergen: Scalars['String'];
  reaction?: Maybe<Scalars['String']>;
  severity?: Maybe<Severity>;
};

export enum Severity {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export type FamilyPathologyType = {
  __typename?: 'FamilyPathologyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  pathology: Scalars['String'];
  cieNotes?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  relatives: Array<Scalars['String']>;
  relative?: Maybe<Relative>;
};

export enum Relative {
  Mother = 'MOTHER',
  Father = 'FATHER',
  PaternalGrandfather = 'PATERNAL_GRANDFATHER',
  MaternalGrandfather = 'MATERNAL_GRANDFATHER',
  PaternalGrandmother = 'PATERNAL_GRANDMOTHER',
  MaternalGrandmother = 'MATERNAL_GRANDMOTHER',
  PaternalUncle = 'PATERNAL_UNCLE',
  MaternalUncle = 'MATERNAL_UNCLE',
  PaternalAunt = 'PATERNAL_AUNT',
  MaternalAunt = 'MATERNAL_AUNT',
  Sibling = 'SIBLING'
}

export type HospitalizationType = {
  __typename?: 'HospitalizationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  reason?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
};

export type MalformationType = {
  __typename?: 'MalformationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  treatment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type MedicineType = {
  __typename?: 'MedicineType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  drugKey: Scalars['String'];
  ailment?: Maybe<Scalars['String']>;
};

export type PathologyType = {
  __typename?: 'PathologyType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  cieKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  diagnosisDate?: Maybe<Scalars['Date']>;
  treatment?: Maybe<Scalars['String']>;
  cieNotes?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type SurgeryType = {
  __typename?: 'SurgeryType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  cptKey?: Maybe<Scalars['String']>;
  cptNotes?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type VaccineType = {
  __typename?: 'VaccineType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  name: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
  notes?: Maybe<Scalars['String']>;
};

export type MentalHealthDiagnosisType = {
  __typename?: 'MentalHealthDiagnosisType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  diagnosis: Scalars['String'];
  treatment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type GeneralObservationType = {
  __typename?: 'GeneralObservationType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  medicalHistory: MedicalHistoryType;
  doctor?: Maybe<DoctorProfileType>;
  addedAt?: Maybe<Scalars['DateTime']>;
  notes: Scalars['String'];
};

export type PaginatedMedicalNoteType = {
  __typename?: 'PaginatedMedicalNoteType';
  totalCount?: Maybe<Scalars['Int']>;
  medicalNotes?: Maybe<Array<Maybe<MedicalNoteType>>>;
};

export type PaginatedServices = {
  __typename?: 'PaginatedServices';
  totalCount?: Maybe<Scalars['Int']>;
  services?: Maybe<Array<Maybe<ServicesType>>>;
};

export enum ServiceType {
  MedicalFees = 'MEDICAL_FEES',
  HospitalService = 'HOSPITAL_SERVICE',
  OtherService = 'OTHER_SERVICE',
  RehabilitationTherapy = 'REHABILITATION_THERAPY',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  MedicalEquipment = 'MEDICAL_EQUIPMENT',
  HomeNurse = 'HOME_NURSE',
  ProstheticsAndMechanicalEquipment = 'PROSTHETICS_AND_MECHANICAL_EQUIPMENT',
  Ambulance = 'AMBULANCE',
  InPersonConsult = 'IN_PERSON_CONSULT',
  OnDemandConsult = 'ON_DEMAND_CONSULT',
  PharmacyOrder = 'PHARMACY_ORDER',
  ExternalConsult = 'EXTERNAL_CONSULT',
  Urgency = 'URGENCY',
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  SurgicalMaterials = 'SURGICAL_MATERIALS',
  IncludedConsult = 'INCLUDED_CONSULT'
}

export enum ServiceProcessStateTypeEnum {
  ProcessStarted = 'PROCESS_STARTED',
  ReviewingEvidences = 'REVIEWING_EVIDENCES',
  Ruling = 'RULING',
  RegisteringAmounts = 'REGISTERING_AMOUNTS',
  ProcessingPayments = 'PROCESSING_PAYMENTS',
  ProcessFinalized = 'PROCESS_FINALIZED',
  Cancelled = 'CANCELLED'
}

export type MemberYearServiceSummary = {
  __typename?: 'MemberYearServiceSummary';
  memberId: Scalars['Int'];
  year: Scalars['Int'];
  labStudiesCount: Scalars['Int'];
  medicinesCount: Scalars['Int'];
  urgenciesCount: Scalars['Int'];
  hospitalServicesCount: Scalars['Int'];
  pharmacyOrdersCount: Scalars['Int'];
  totalNonConsultServicesCount: Scalars['Int'];
  psychologyInPersonConsultsCount: Scalars['Int'];
  nonPsychologyInPersonConsultsCount: Scalars['Int'];
  totalInPersonConsultsCount: Scalars['Int'];
  primaryCareDoctorOnDemandConsultsCount: Scalars['Int'];
  otherDoctorOnDemandConsultsCount: Scalars['Int'];
  totalOnDemandConsultsCount: Scalars['Int'];
  totalServicesCount: Scalars['Int'];
};

export type ReinbursementServiceInfo = {
  __typename?: 'ReinbursementServiceInfo';
  id: Scalars['ID'];
  memberName: Scalars['String'];
  relatedConsultType?: Maybe<Scalars['String']>;
  doctorName?: Maybe<Scalars['String']>;
  doctorSpecialization?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<Scalars['String']>;
};

export type PaginatedCaseEvent = {
  __typename?: 'PaginatedCaseEvent';
  totalCount?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<Maybe<CaseEventType>>>;
};

export type EventDoctorSelectorType = {
  __typename?: 'EventDoctorSelectorType';
  doctorId: Scalars['ID'];
  doctorName: Scalars['String'];
  doctorRole: Scalars['String'];
};

export type PageBasedAccessHealthPlanApplicationType = {
  __typename?: 'PageBasedAccessHealthPlanApplicationType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanApplicationType>>;
};

export type PageBasedAccessProductType = {
  __typename?: 'PageBasedAccessProductType';
  totalCount?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ProductType>>>;
};

export type NubariumIneType = {
  __typename?: 'NubariumIneType';
  names?: Maybe<Scalars['String']>;
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  curp?: Maybe<Scalars['String']>;
  electorKey?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  registry?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
  qr?: Maybe<Scalars['String']>;
  mrz?: Maybe<Scalars['String']>;
  cic?: Maybe<Scalars['String']>;
  ocr?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  citizenId?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  mrzValidation?: Maybe<NubariumIneMrzValidationType>;
};

export type NubariumIneMrzValidationType = {
  __typename?: 'NubariumIneMrzValidationType';
  names?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  cic?: Maybe<Scalars['String']>;
};

export type NubariumSatInfoValidationType = {
  __typename?: 'NubariumSatInfoValidationType';
  messageCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
};

export type InAppRejectionType = {
  __typename?: 'InAppRejectionType';
  rejection?: Maybe<InAppRejection>;
  ok?: Maybe<Scalars['Boolean']>;
};

export enum InAppRejection {
  Pregnancy = 'PREGNANCY',
  Athlete = 'ATHLETE',
  Nationality = 'NATIONALITY',
  Elder = 'ELDER',
  Underage = 'UNDERAGE',
  Infant = 'INFANT',
  OutOfArea = 'OUT_OF_AREA',
  Obesity = 'OBESITY'
}

export type PublicProductType = {
  __typename?: 'PublicProductType';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: ProductCategory;
  productMetadataParameters?: Maybe<Scalars['JSONString']>;
  pricingModel?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  productFamily: ProductProductFamily;
  pricingParameters?: Maybe<Scalars['JSONString']>;
};

export enum ProductProductFamily {
  Morado = 'MORADO',
  Mini = 'MINI',
  Colectivo = 'COLECTIVO'
}

export type PublicKeyValueType = {
  __typename?: 'PublicKeyValueType';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MultipleSubscriptionsPriceType = {
  __typename?: 'MultipleSubscriptionsPriceType';
  monthlyTotalCents?: Maybe<Scalars['Int']>;
  yearlyTotalCents?: Maybe<Scalars['Int']>;
  pricingBreakdown: Array<SubscriptionPriceType>;
  breakdown: Array<SubscriptionPriceType>;
};

export type SubscriptionPriceType = {
  __typename?: 'SubscriptionPriceType';
  gender: GenderType;
  age?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  vaccinatedAgainstCovid?: Maybe<Scalars['Boolean']>;
  monthlyTotalCents?: Maybe<Scalars['Int']>;
  yearlyTotalCents?: Maybe<Scalars['Int']>;
  error?: Maybe<SubscriptionPriceError>;
};

export enum GenderType {
  Unknown = 'UNKNOWN',
  Male = 'MALE',
  Female = 'FEMALE'
}

export type SubscriptionPriceError = {
  __typename?: 'SubscriptionPriceError';
  type: SubscriptionPriceErrorType;
  message: Scalars['String'];
};

export enum SubscriptionPriceErrorType {
  Bmi = 'BMI',
  Age = 'AGE'
}

export type SubscriptionPriceInputType = {
  gender: GenderType;
  age: Scalars['Int'];
  weight?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  vaccinatedAgainstCovid?: InputMaybe<Scalars['Boolean']>;
};

export type ScheduledApprovalCallType = {
  __typename?: 'ScheduledApprovalCallType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  user?: Maybe<UserType>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  calendlyId?: Maybe<Scalars['String']>;
  confirmed: Scalars['Boolean'];
  specialInstructions?: Maybe<Scalars['String']>;
};

export type PageBasedAccessUserType = {
  __typename?: 'PageBasedAccessUserType';
  totalCount?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<UserType>>>;
};

export type PageBasedAccessDocumentType = {
  __typename?: 'PageBasedAccessDocumentType';
  totalCount?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<Maybe<DocumentType>>>;
};

export type PageBasedAccessMemberType = {
  __typename?: 'PageBasedAccessMemberType';
  totalCount?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<Maybe<MemberType>>>;
};

export type MemberSearchResultsType = {
  __typename?: 'MemberSearchResultsType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<LimitedMemberType>>;
};

export type SequentialAccessMemberType = {
  __typename?: 'SequentialAccessMemberType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  members?: Maybe<Array<Maybe<LimitedHealthPlanMemberType>>>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type VerifyPasswordType = {
  __typename?: 'VerifyPasswordType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MinSupportedVersionType = {
  __typename?: 'MinSupportedVersionType';
  android?: Maybe<Scalars['String']>;
  ios?: Maybe<Scalars['String']>;
};

export type CoronaidActiveStatus = {
  __typename?: 'CoronaidActiveStatus';
  isActive?: Maybe<Scalars['Boolean']>;
};

export type AuthenticatedVars = {
  __typename?: 'AuthenticatedVars';
  onDemandConsultPrimaryDoctorName?: Maybe<Scalars['String']>;
  onDemandConsultPrimaryWidgetId?: Maybe<Scalars['String']>;
  incentiveCodeEnabled?: Maybe<Scalars['Boolean']>;
};

export type WaitingRoomEnabledStatus = {
  __typename?: 'WaitingRoomEnabledStatus';
  isActive?: Maybe<Scalars['Boolean']>;
};

export type IntrospectionQueryResult = {
  __typename?: 'IntrospectionQueryResult';
  result?: Maybe<Scalars['JSONString']>;
};

export type IsTelephoneAlreadyOnUseType = {
  __typename?: 'IsTelephoneAlreadyOnUseType';
  onUse?: Maybe<Scalars['Boolean']>;
};

export type IpAddressType = {
  __typename?: 'IpAddressType';
  ipAddress?: Maybe<Scalars['String']>;
};

export type CheckEmailType = {
  __typename?: 'CheckEmailType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type InAppNotificationsType = {
  __typename?: 'InAppNotificationsType';
  mainNotification?: Maybe<Array<Maybe<NotificationType>>>;
  detailNotification?: Maybe<Array<Maybe<NotificationType>>>;
  paymentsNotification?: Maybe<Array<Maybe<NotificationType>>>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  type: InAppNotificationType;
  urgency: InAppNotificationUrgency;
  title: Scalars['String'];
  message: Scalars['String'];
};

export enum InAppNotificationType {
  GracePeriod = 'GRACE_PERIOD'
}

export enum InAppNotificationUrgency {
  Urgent = 'URGENT',
  Warning = 'WARNING',
  Information = 'INFORMATION'
}

export type CheckExpertsWorkingHoursType = {
  __typename?: 'CheckExpertsWorkingHoursType';
  ok: Scalars['Boolean'];
};

export type CheckUserHasPaymentsPending = {
  __typename?: 'CheckUserHasPaymentsPending';
  ok: Scalars['Boolean'];
};

export type VerifyPasswordTokenType = {
  __typename?: 'VerifyPasswordTokenType';
  ok: Scalars['Boolean'];
};

export type ReferralCodeType = {
  __typename?: 'ReferralCodeType';
  user?: Maybe<UserType>;
  code?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  remainingInvitations?: Maybe<Scalars['Int']>;
  referrerName?: Maybe<Scalars['String']>;
};

export type ReferralCodeValidationType = {
  __typename?: 'ReferralCodeValidationType';
  isValid?: Maybe<Scalars['Boolean']>;
  referrerName?: Maybe<Scalars['String']>;
};

export type PaginatedDoctorFriendsType = {
  __typename?: 'PaginatedDoctorFriendsType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<DoctorFriendType>>;
};

export type PaginatedDoctorPatientsType = {
  __typename?: 'PaginatedDoctorPatientsType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  members?: Maybe<Array<MemberType>>;
};

export type ProviderDoctorSpecialtiesType = {
  __typename?: 'ProviderDoctorSpecialtiesType';
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type ProviderBranchOfficeType = {
  __typename?: 'ProviderBranchOfficeType';
  id: Scalars['ID'];
  name: Scalars['String'];
  telephones?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
  tier?: Maybe<ProviderTier>;
  provider: ProvidersType;
  adminUser?: Maybe<UserType>;
  billingInfo?: Maybe<BillingInfoType>;
};

export type PaginatedProviderBranchOfficesType = {
  __typename?: 'PaginatedProviderBranchOfficesType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<ProviderBranchOfficeType>>;
};

export type SequentialAccessDirectoryListType = {
  __typename?: 'SequentialAccessDirectoryListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  directoryList?: Maybe<Array<Maybe<CareDirectoryEntryType>>>;
};

export type CareDirectoryEntryType = DoctorType | ProviderBranchType;

export enum States {
  Aguascalientes = 'AGUASCALIENTES',
  BajaCalifornia = 'BAJA_CALIFORNIA',
  BajaCaliforniaSur = 'BAJA_CALIFORNIA_SUR',
  Campeche = 'CAMPECHE',
  Chiapas = 'CHIAPAS',
  Chihuahua = 'CHIHUAHUA',
  CiudadDeMexico = 'CIUDAD_DE_MEXICO',
  CoahuilaDeZaragoza = 'COAHUILA_DE_ZARAGOZA',
  Colima = 'COLIMA',
  Durango = 'DURANGO',
  Guanajuato = 'GUANAJUATO',
  Guerrero = 'GUERRERO',
  Hidalgo = 'HIDALGO',
  Jalisco = 'JALISCO',
  Mexico = 'MEXICO',
  MichoacanDeOcampo = 'MICHOACAN_DE_OCAMPO',
  Morelos = 'MORELOS',
  Nayarit = 'NAYARIT',
  NuevoLeon = 'NUEVO_LEON',
  Oaxaca = 'OAXACA',
  Puebla = 'PUEBLA',
  Queretaro = 'QUERETARO',
  QuintanaRoo = 'QUINTANA_ROO',
  SanLuisPotosi = 'SAN_LUIS_POTOSI',
  Sinaloa = 'SINALOA',
  Sonora = 'SONORA',
  Tabasco = 'TABASCO',
  Tamaulipas = 'TAMAULIPAS',
  Tlaxcala = 'TLAXCALA',
  VeracruzDeIgnacioDeLaLlave = 'VERACRUZ_DE_IGNACIO_DE_LA_LLAVE',
  Yucatan = 'YUCATAN',
  Zacatecas = 'ZACATECAS'
}

export type PaginatedProvidersType = {
  __typename?: 'PaginatedProvidersType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<ProvidersType>>;
};

export type SequentialAccessProviderListType = {
  __typename?: 'SequentialAccessProviderListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  directoryList?: Maybe<Array<Maybe<CareProviderEntryType>>>;
};

export type CareProviderEntryType = DoctorType | DoctorFriendType | ProviderBranchType;

export type PageBasedAccessDoctorType = {
  __typename?: 'PageBasedAccessDoctorType';
  totalCount?: Maybe<Scalars['Int']>;
  doctors?: Maybe<Array<Maybe<DoctorType>>>;
};

export type HuliDoctorDataType = {
  __typename?: 'HuliDoctorDataType';
  huliId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  specializations?: Maybe<Array<Maybe<HuliSpecialtyType>>>;
  clinics?: Maybe<Array<Maybe<HuliClinicType>>>;
};

export type HuliSpecialtyType = {
  __typename?: 'HuliSpecialtyType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type HuliClinicType = {
  __typename?: 'HuliClinicType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type SequentialAccessDoctorReminderListType = {
  __typename?: 'SequentialAccessDoctorReminderListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  reminders?: Maybe<Array<Maybe<DoctorReminderType>>>;
};

export type DoctorReminderType = {
  __typename?: 'DoctorReminderType';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  remindAt?: Maybe<Scalars['DateTime']>;
  status: DoctorReminderStatus;
  member?: Maybe<MemberType>;
  doctor?: Maybe<DoctorType>;
  consult?: Maybe<OnDemandConsultType>;
};

export enum DoctorReminderStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Overdue = 'OVERDUE'
}

export type PaginatedPharmacyOrdersType = {
  __typename?: 'PaginatedPharmacyOrdersType';
  totalCount?: Maybe<Scalars['Int']>;
  pharmacyOrders?: Maybe<Array<PharmacyOrderType>>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<PermissionType>;
};

export type PaginatedGroupType = {
  __typename?: 'PaginatedGroupType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<GroupType>>;
};

export type PaginatedPermissionType = {
  __typename?: 'PaginatedPermissionType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<PermissionType>>;
};

export type SequentialAccessPaymentMethodsType = {
  __typename?: 'SequentialAccessPaymentMethodsType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethodsType>>>;
};

export enum PaymentFlowTypes {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export type SequentialAccessStripeSourceType = {
  __typename?: 'SequentialAccessStripeSourceType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  stripeSources?: Maybe<Array<Maybe<StripeSourceType>>>;
};

export type StripeSourceType = {
  __typename?: 'StripeSourceType';
  id: Scalars['ID'];
  primary?: Maybe<Scalars['Boolean']>;
  remoteStripeSourceId?: Maybe<Scalars['String']>;
  lastFourDigits?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export enum TransactionResult {
  Ok = 'OK',
  Fail = 'FAIL'
}

export type PageBasedAccessTransactionType = {
  __typename?: 'PageBasedAccessTransactionType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<TransactionType>>>;
};

export type LocationType = {
  __typename?: 'LocationType';
  id: Scalars['ID'];
  zipcode: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  neighborhoodType?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  stateName: Scalars['String'];
  city: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isCore: Scalars['Boolean'];
  isAvailableUpdatedAt: Scalars['DateTime'];
  isCoreUpdatedAt: Scalars['DateTime'];
};

export type PageBasedAccessLocationType = {
  __typename?: 'PageBasedAccessLocationType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LocationType>>>;
};

export type IssuerType = {
  __typename?: 'IssuerType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PageBasedInvoiceType = {
  __typename?: 'PageBasedInvoiceType';
  totalCount?: Maybe<Scalars['Int']>;
  electronicVoucherErrors?: Maybe<Scalars['Int']>;
  notPaidInvoices?: Maybe<Scalars['Int']>;
  cancelledInvoices?: Maybe<Scalars['Int']>;
  failedInvoices?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
};

export enum InvoiceCounterpartyType {
  Members = 'MEMBERS',
  Doctors = 'DOCTORS',
  Businesses = 'BUSINESSES',
  Providers = 'PROVIDERS'
}

export enum InvoicesQueryServiceType {
  AmendmentCancelation = 'AMENDMENT_CANCELATION',
  AmendmentCorrection = 'AMENDMENT_CORRECTION',
  AmendmentSubscription = 'AMENDMENT_SUBSCRIPTION',
  ClaimsAmbulance = 'CLAIMS_AMBULANCE',
  ClaimsHospitalService = 'CLAIMS_HOSPITAL_SERVICE',
  ClaimsInPersonConsult = 'CLAIMS_IN_PERSON_CONSULT',
  ClaimsLabStudies = 'CLAIMS_LAB_STUDIES',
  ClaimsMedicalEquipment = 'CLAIMS_MEDICAL_EQUIPMENT',
  ClaimsMedicalFees = 'CLAIMS_MEDICAL_FEES',
  ClaimsMedicines = 'CLAIMS_MEDICINES',
  ClaimsOnDemandConsult = 'CLAIMS_ON_DEMAND_CONSULT',
  ClaimsOtherServices = 'CLAIMS_OTHER_SERVICES',
  ClaimsProstheticAndMechanicalEquipment = 'CLAIMS_PROSTHETIC_AND_MECHANICAL_EQUIPMENT',
  ClaimsRehabilitationTherapy = 'CLAIMS_REHABILITATION_THERAPY',
  PharmarcyOrder = 'PHARMARCY_ORDER',
  Incentive = 'INCENTIVE'
}

export type PageBasedElectronicVoucherType = {
  __typename?: 'PageBasedElectronicVoucherType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  results?: Maybe<Array<Maybe<ElectronicVoucherType>>>;
};

export enum SatOperationTypeStatus {
  Issued = 'ISSUED',
  Cancelled = 'CANCELLED',
  CancellationInProgress = 'CANCELLATION_IN_PROGRESS',
  CancellationFailed = 'CANCELLATION_FAILED',
  NotIssued = 'NOT_ISSUED',
  Draft = 'DRAFT',
  NonCollectibleVoucher = 'NON_COLLECTIBLE_VOUCHER',
  CancelledBeforeIssued = 'CANCELLED_BEFORE_ISSUED',
  Replaced = 'REPLACED',
  NonDeductible = 'NON_DEDUCTIBLE'
}

export type PageBasedIncomeType = {
  __typename?: 'PageBasedIncomeType';
  totalCount?: Maybe<Scalars['Int']>;
  incomes?: Maybe<Array<Maybe<IncomeType>>>;
};

export type PublicIncentiveCodeType = {
  __typename?: 'PublicIncentiveCodeType';
  valid?: Maybe<Scalars['Boolean']>;
};

export type ReferredOnDatesType = {
  __typename?: 'ReferredOnDatesType';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ReferralIncentiveStageType = {
  __typename?: 'ReferralIncentiveStageType';
  stage?: Maybe<Scalars['Int']>;
  totalCents?: Maybe<Scalars['Int']>;
  sumAmountCents?: Maybe<Scalars['Int']>;
};

export type PageBasedAccessHealthPlanType = {
  __typename?: 'PageBasedAccessHealthPlanType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanType>>;
};

export type PageBasedAccessHealthPlanAmendmentType = {
  __typename?: 'PageBasedAccessHealthPlanAmendmentType';
  totalCount?: Maybe<Scalars['Int']>;
  healthPlanAmendments?: Maybe<Array<Maybe<HealthPlanAmendmentType>>>;
};

export type HealthPlanQuoteDifferenceType = {
  __typename?: 'HealthPlanQuoteDifferenceType';
  installmentSubtotalCents?: Maybe<Scalars['Int']>;
  installmentSubscriptionTypeSurchargeCents?: Maybe<Scalars['Int']>;
  installmentVatCents?: Maybe<Scalars['Int']>;
  installmentTotalCents?: Maybe<Scalars['Int']>;
  installmentNetPremiumCents?: Maybe<Scalars['Int']>;
  installmentIssuanceCents?: Maybe<Scalars['Int']>;
  premiumSubtotalCents?: Maybe<Scalars['Int']>;
  premiumSubscriptionTypeSurchargeCents?: Maybe<Scalars['Int']>;
  premiumVatCents?: Maybe<Scalars['Int']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  premiumNetPremiumCents?: Maybe<Scalars['Int']>;
  premiumIssuanceCents?: Maybe<Scalars['Int']>;
  subscriptionTypeDiscountCents?: Maybe<Scalars['Int']>;
  installmentSubtotalDifferenceCents?: Maybe<Scalars['Int']>;
  installmentVatDifferenceCents?: Maybe<Scalars['Int']>;
  installmentSubscriptionTypeSurchargeDifferenceCents?: Maybe<Scalars['Int']>;
  installmentTotalDifferenceCents?: Maybe<Scalars['Int']>;
  installmentNetPremiumDifferenceCents?: Maybe<Scalars['Int']>;
  installmentIssuanceDifferenceCents?: Maybe<Scalars['Int']>;
  premiumSubtotalDifferenceCents?: Maybe<Scalars['Int']>;
  premiumVatDifferenceCents?: Maybe<Scalars['Int']>;
  premiumSubscriptionTypeSurchargeDifferenceCents?: Maybe<Scalars['Int']>;
  premiumTotalDifferenceCents?: Maybe<Scalars['Int']>;
  premiumNetPremiumDifferenceCents?: Maybe<Scalars['Int']>;
  premiumIssuanceDifferenceCents?: Maybe<Scalars['Int']>;
  adjustFeeCents?: Maybe<Scalars['Int']>;
};

export type SequentialAccessMessageType = {
  __typename?: 'SequentialAccessMessageType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
};

export type DrugType = {
  __typename?: 'DrugType';
  brand?: Maybe<Scalars['String']>;
  generic?: Maybe<Scalars['String']>;
};

export type SequentialAccessPrescriptionListType = {
  __typename?: 'SequentialAccessPrescriptionListType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
};

export type SequentialAccessHuliEmrEntryType = {
  __typename?: 'SequentialAccessHuliEmrEntryType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  huliEmrEntries?: Maybe<Array<Maybe<HuliEmrEntryType>>>;
};

export type PageBasedAccessHuliEmrEntryType = {
  __typename?: 'PageBasedAccessHuliEmrEntryType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<HuliEmrEntryType>>>;
};

export type PageBasedAccessInPersonConsultType = {
  __typename?: 'PageBasedAccessInPersonConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  inPersonConsults?: Maybe<Array<Maybe<InPersonConsultType>>>;
};

export type SequentialAccessOnDemandConsultType = {
  __typename?: 'SequentialAccessOnDemandConsultType';
  totalCount: Scalars['Int'];
  pageInfo: PageInfoType;
  onDemandConsults: Array<OnDemandConsultType>;
};

export type SequentialAccessLimitedInPersonConsultType = {
  __typename?: 'SequentialAccessLimitedInPersonConsultType';
  totalCount: Scalars['Int'];
  pageInfo: PageInfoType;
  inPersonConsults: Array<LimitedInPersonConsultType>;
};

export type SequentialAccessScheduledConsultType = {
  __typename?: 'SequentialAccessScheduledConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  scheduledConsults?: Maybe<Array<Maybe<ScheduledConsultType>>>;
};

export type PaginatedScheduledConsultType = {
  __typename?: 'PaginatedScheduledConsultType';
  totalCount?: Maybe<Scalars['Int']>;
  scheduledConsults?: Maybe<Array<ScheduledConsultType>>;
};

export type ActualConsultType = {
  __typename?: 'ActualConsultType';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  member?: Maybe<MemberType>;
  opentokSession?: Maybe<OpentokSessionType>;
  emrEntry?: Maybe<EmrEntryType>;
  healthPlan?: Maybe<HealthPlanType>;
  billedAmount?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<Array<Maybe<PrescriptionType>>>;
  messages?: Maybe<Array<Maybe<MessageType>>>;
  lastConsultMessage?: Maybe<MessageType>;
  welcomeConsultId?: Maybe<Scalars['Int']>;
  doctorName?: Maybe<Scalars['String']>;
  doctor?: Maybe<DoctorType>;
  consultDate?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  diseaseCase?: Maybe<LimitedDiseaseCaseType>;
  consultType?: Maybe<Scalars['String']>;
};

export type ConsultUnionType = OnDemandConsultType | LimitedInPersonConsultType;

export type OnDemandConsultHourlyFeesType = {
  __typename?: 'OnDemandConsultHourlyFeesType';
  weekdayCents?: Maybe<Scalars['Int']>;
  weekendCents?: Maybe<Scalars['Int']>;
};

export type OnDemandConsultPriceType = {
  __typename?: 'OnDemandConsultPriceType';
  consultCurrentPriceCents?: Maybe<Scalars['Int']>;
};

export type InPersonConsultFeeType = {
  __typename?: 'InPersonConsultFeeType';
  amountCents?: Maybe<Scalars['Int']>;
};

export type TaxesForInPersonConsults = {
  __typename?: 'TaxesForInPersonConsults';
  vatPercentage?: Maybe<Scalars['Float']>;
  withholdTaxesPercentage?: Maybe<Scalars['Float']>;
};

export type WelcomeScheduledConsultType = {
  __typename?: 'WelcomeScheduledConsultType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  scheduledConsultClassification?: Maybe<ScheduledConsultScheduledConsultClassification>;
  user?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  shouldEndAt?: Maybe<Scalars['DateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  extraNotes?: Maybe<Scalars['String']>;
  onDemandConsultClassification?: Maybe<Scalars['String']>;
  scheduledConsultKind?: Maybe<ScheduledConsultScheduledConsultKind>;
  category?: Maybe<ScheduledConsultCategory>;
  tasks?: Maybe<Scalars['JSONString']>;
  claimed?: Maybe<Scalars['Boolean']>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  huliId?: Maybe<Scalars['Int']>;
  calendlyId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  onDemandConsult?: Maybe<OnDemandConsultType>;
  inPersonConsult?: Maybe<InPersonConsultType>;
  doctor?: Maybe<DoctorProfileType>;
  address?: Maybe<AddressType>;
  secondaryAddress?: Maybe<AddressType>;
  confirmed: Scalars['Boolean'];
  specialization?: Maybe<ScheduledConsultSpecialization>;
  urgency?: Maybe<ScheduledConsultUrgency>;
  urgencyDescription?: Maybe<Scalars['String']>;
  timeWindow?: Maybe<ScheduledConsultTimeWindow>;
  timeWindowDescription?: Maybe<Scalars['String']>;
  dayWindow?: Maybe<ScheduledConsultDayWindow>;
  dayWindowDescription?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  latestState?: Maybe<ScheduledConsultLatestState>;
  cancellationReason?: Maybe<ScheduledConsultCancellationReason>;
  cancellationReasonDetails?: Maybe<Scalars['String']>;
};

export enum ScheduledConsultScheduledConsultClassification {
  InPersonConsult = 'IN_PERSON_CONSULT',
  Videoconsult = 'VIDEOCONSULT',
  PrimaryCareDr = 'PRIMARY_CARE_DR',
  Welcome = 'WELCOME',
  General = 'GENERAL',
  PediatricsPrimary = 'PEDIATRICS_PRIMARY',
  PediatricsWelcome = 'PEDIATRICS_WELCOME',
  RehabilitationTherapy = 'REHABILITATION_THERAPY'
}

export enum ScheduledConsultScheduledConsultKind {
  FirstTime = 'FIRST_TIME',
  FollowUp = 'FOLLOW_UP'
}

export enum ScheduledConsultCategory {
  Preventive = 'PREVENTIVE',
  General = 'GENERAL',
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE'
}

export enum ScheduledConsultSpecialization {
  Cra = 'CRA',
  Any = 'ANY',
  Ana = 'ANA',
  Bra = 'BRA',
  Cry = 'CRY',
  Jns = 'JNS',
  Brs = 'BRS',
  Crs = 'CRS',
  Skb = 'SKB',
  Rta = 'RTA',
  Drs = 'DRS',
  Hmg = 'HMG',
  Mfm = 'MFM',
  Gns = 'GNS',
  Ons = 'ONS',
  Pdr = 'PDR',
  Pla = 'PLA',
  Rbs = 'RBS',
  Dry = 'DRY',
  Pdd = 'PDD',
  Eny = 'ENY',
  Dga = 'DGA',
  Phy = 'PHY',
  Gsy = 'GSY',
  Pdg = 'PDG',
  Mdg = 'MDG',
  Grs = 'GRS',
  Gya = 'GYA',
  Dgi = 'DGI',
  Crm = 'CRM',
  Inm = 'INM',
  Npy = 'NPY',
  Nny = 'NNY',
  Pdp = 'PDP',
  Nrs = 'NRS',
  Clr = 'CLR',
  Nry = 'NRY',
  Cln = 'CLN',
  Opy = 'OPY',
  Mdo = 'MDO',
  Ors = 'ORS',
  Otr = 'OTR',
  Pdo = 'PDO',
  Oty = 'OTY',
  Pds = 'PDS',
  Clp = 'CLP',
  Psy = 'PSY',
  Lnp = 'LNP',
  Vsa = 'VSA',
  Rhy = 'RHY',
  Ury = 'URY',
  Gyu = 'GYU',
  Rhm = 'RHM',
  Onu = 'ONU',
  Pdu = 'PDU',
  Iny = 'INY',
  Cly = 'CLY',
  Aos = 'AOS',
  Pnm = 'PNM',
  Aim = 'AIM',
  Gae = 'GAE',
  Cap = 'CAP'
}

export enum ScheduledConsultUrgency {
  As = 'AS',
  Nw = 'NW',
  Nm = 'NM',
  Ot = 'OT'
}

export enum ScheduledConsultTimeWindow {
  Mn = 'MN',
  At = 'AT',
  Ot = 'OT'
}

export enum ScheduledConsultDayWindow {
  Wd = 'WD',
  We = 'WE',
  Ot = 'OT'
}

export enum ScheduledConsultCancellationReason {
  MemberInsufficientTime = 'MEMBER_INSUFFICIENT_TIME',
  MemberInadequateLocation = 'MEMBER_INADEQUATE_LOCATION',
  MemberBadInternet = 'MEMBER_BAD_INTERNET',
  DoctorUrgency = 'DOCTOR_URGENCY',
  StuckConsultCard = 'STUCK_CONSULT_CARD',
  MemberNoShow = 'MEMBER_NO_SHOW',
  CancelledByMember = 'CANCELLED_BY_MEMBER',
  Other = 'OTHER'
}

export enum QuerySortBy {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type CalendarDatesAvailabilityType = {
  __typename?: 'CalendarDatesAvailabilityType';
  date?: Maybe<Scalars['Date']>;
  available?: Maybe<Scalars['Boolean']>;
};

export type CalendarDatesDoctorsAvailabilityType = {
  __typename?: 'CalendarDatesDoctorsAvailabilityType';
  date?: Maybe<Scalars['Date']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarDatesSlotsAvailability = {
  __typename?: 'CalendarDatesSlotsAvailability';
  date?: Maybe<Scalars['Date']>;
  slots?: Maybe<Array<CalendarSlotType>>;
};

export type CalendarSlotType = {
  __typename?: 'CalendarSlotType';
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
};

export type CalendarDatesDoctorsSlotsAvailability = {
  __typename?: 'CalendarDatesDoctorsSlotsAvailability';
  date?: Maybe<Scalars['Date']>;
  slots?: Maybe<Array<CalendarDoctorSlotType>>;
};

export type CalendarDoctorSlotType = {
  __typename?: 'CalendarDoctorSlotType';
  start?: Maybe<Scalars['Time']>;
  end?: Maybe<Scalars['Time']>;
  doctorIds?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarDateNextSlotAvailability = {
  __typename?: 'CalendarDateNextSlotAvailability';
  date?: Maybe<Scalars['Date']>;
  doctorId?: Maybe<Scalars['ID']>;
  slot?: Maybe<CalendarSlotType>;
};

export type SubscriptionConsultType = {
  __typename?: 'SubscriptionConsultType';
  birthday?: Maybe<Scalars['String']>;
  callState?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  consultId?: Maybe<Scalars['Int']>;
  consultReason?: Maybe<Scalars['String']>;
  consultState?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  actualGender?: Maybe<Scalars['String']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientFirstLastName?: Maybe<Scalars['String']>;
  patientSecondLastName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['ID']>;
  memberUuid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
  patientPrefix?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['ID']>;
  zoomMeetingPassword?: Maybe<Scalars['String']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['ID']>;
  doctorFirstName?: Maybe<Scalars['String']>;
  doctorFirstLastName?: Maybe<Scalars['String']>;
  doctorSecondLastName?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['ID']>;
  doctorPrefix?: Maybe<Scalars['String']>;
  doctorSpecialty?: Maybe<Scalars['String']>;
  doctorIsPrimaryCare?: Maybe<Scalars['String']>;
};

export type PageBasedComplianceAlertType = {
  __typename?: 'PageBasedComplianceAlertType';
  totalCount?: Maybe<Scalars['Int']>;
  alerts?: Maybe<Array<Maybe<ComplianceAlertType>>>;
};

export enum ComplianceAlertAnnotation {
  ReviewedByAnybody = 'REVIEWED_BY_ANYBODY',
  NotReviewed = 'NOT_REVIEWED',
  ReviewedOnlyByOfficer = 'REVIEWED_ONLY_BY_OFFICER',
  ReviewedOnlyByCommittee = 'REVIEWED_ONLY_BY_COMMITTEE'
}

export type PaginatedServiceType = {
  __typename?: 'PaginatedServiceType';
  totalCount?: Maybe<Scalars['Int']>;
  serviceTypes?: Maybe<Array<Maybe<ServiceTypeType>>>;
};

export type AppLimitedServicesType = {
  __typename?: 'AppLimitedServicesType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  serviceDescription?: Maybe<Scalars['String']>;
  serviceTypeValue?: Maybe<ServiceType>;
  processState?: Maybe<ServiceProcessStateTypeEnum>;
  serviceTypeName?: Maybe<Scalars['String']>;
  state?: Maybe<RulingState>;
};

export type PaginatedChecksLogType = {
  __typename?: 'PaginatedChecksLogType';
  totalCount: Scalars['Int'];
  checksLog: Array<ChecksLogType>;
};

export type ChecksLogType = {
  __typename?: 'ChecksLogType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  service: ServiceTypeType;
  rule: Scalars['String'];
  checkResult: Scalars['Boolean'];
};

export type PaginatedInvoiceItemType = {
  __typename?: 'PaginatedInvoiceItemType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<InvoiceItemType>>;
};

export type PageBasedAccessBusinessHealthPlanType = {
  __typename?: 'PageBasedAccessBusinessHealthPlanType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<HealthPlanType>>;
};

export type BusinessServicesStatisticsType = {
  __typename?: 'BusinessServicesStatisticsType';
  servicesList?: Maybe<Array<Maybe<ServiceUseCountType>>>;
  welcomeOnDemandConsultsTakenHuliIdeal?: Maybe<Scalars['Int']>;
  welcomeOnDemandConsultsTakenHuliApproximation?: Maybe<Scalars['Int']>;
  welcomeOnDemandConsultsTakenService?: Maybe<Scalars['Int']>;
  membersWhoHaveUsedServices?: Maybe<Scalars['Int']>;
};

export type ServiceUseCountType = {
  __typename?: 'ServiceUseCountType';
  serviceType?: Maybe<Scalars['String']>;
  serviceCount?: Maybe<Scalars['Int']>;
};

export type PageBasedBusinessType = {
  __typename?: 'PageBasedBusinessType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<BusinessType>>>;
};

export type BusinessEmployeeType = {
  __typename?: 'BusinessEmployeeType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  firstLastName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  business: BusinessType;
  email?: Maybe<Scalars['String']>;
  beneficiaryCategory?: Maybe<BusinessBeneficiaryCategory>;
  activationDate?: Maybe<Scalars['Date']>;
  invitationDate?: Maybe<Scalars['Date']>;
  invited?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<BriefBusinessBeneficiaryType>;
  state?: Maybe<BusinessBeneficiaryState>;
  member?: Maybe<LimitedBusinessMemberType>;
  businessGroupConfig?: Maybe<BusinessGroupsConfigType>;
  latestHealthPlanApplication?: Maybe<HealthPlanApplicationType>;
  familyMembers?: Maybe<Array<BusinessEmployeeType>>;
};

export enum BusinessBeneficiaryCategory {
  Employee = 'EMPLOYEE',
  FamilyMember = 'FAMILY_MEMBER'
}

export enum BusinessBeneficiaryState {
  Active = 'ACTIVE',
  PendingActivation = 'PENDING_ACTIVATION',
  Inactive = 'INACTIVE',
  Rejected = 'REJECTED',
  Deactivated = 'DEACTIVATED'
}

export type LimitedBusinessMemberType = {
  __typename?: 'LimitedBusinessMemberType';
  id: Scalars['ID'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  gender: MemberGender;
  antiquitySince?: Maybe<Scalars['Date']>;
  healthPlan?: Maybe<BusinessHealthPlanType>;
  healthPlanList?: Maybe<Array<Maybe<BusinessHealthPlanType>>>;
};

export type BusinessHealthPlanType = {
  __typename?: 'BusinessHealthPlanType';
  id: Scalars['ID'];
  state: HealthPlanState;
  validSince?: Maybe<Scalars['DateTime']>;
  validThrough?: Maybe<Scalars['DateTime']>;
  premiumTotalCents?: Maybe<Scalars['Int']>;
  maternityAddon: Scalars['Boolean'];
  extendedHospitalsCoverage: Scalars['Boolean'];
  dentalAddon: Scalars['Boolean'];
  intlCoverageAddon: Scalars['Boolean'];
  insuredAmountCents?: Maybe<Scalars['Int']>;
  owner?: Maybe<BusinessBriefUserType>;
};

export type BusinessBriefUserType = {
  __typename?: 'BusinessBriefUserType';
  id: Scalars['ID'];
  email: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};

export type PageBasedBusinessEmployeeType = {
  __typename?: 'PageBasedBusinessEmployeeType';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessEmployeeStatisticsType = {
  __typename?: 'BusinessEmployeeStatisticsType';
  total?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  initiated?: Maybe<Scalars['Int']>;
  subscribed?: Maybe<Scalars['Int']>;
  cancelled?: Maybe<Scalars['Int']>;
  rejected?: Maybe<Scalars['Int']>;
};

export type SearchBusinessEmployeesType = {
  __typename?: 'SearchBusinessEmployeesType';
  results?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessBillInfoType = {
  __typename?: 'BusinessBillInfoType';
  paymentTotalCents?: Maybe<Scalars['Int']>;
  paymentTotalCentsEstimation?: Maybe<Scalars['Int']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  statementDueDate?: Maybe<Scalars['DateTime']>;
  paymentExpirationState?: Maybe<BusinessPaymentExpirationState>;
};

export enum BusinessPaymentExpirationState {
  Friendly = 'FRIENDLY',
  Due = 'DUE'
}

export type BusinessLastPaidBillInfoType = {
  __typename?: 'BusinessLastPaidBillInfoType';
  paymentTotalCents?: Maybe<Scalars['Int']>;
  paidAtDate?: Maybe<Scalars['DateTime']>;
};

export type LimitedInvoiceType = {
  __typename?: 'LimitedInvoiceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  state?: Maybe<InvoiceState>;
  billingInfo: Scalars['JSONString'];
  dueAt?: Maybe<Scalars['Date']>;
  electronicVouchersPdfZipDoc?: Maybe<DocumentType>;
  electronicVouchersXmlZipDoc?: Maybe<DocumentType>;
  invoiceSnapshotXlsxDoc?: Maybe<DocumentType>;
  sumTotalCents?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<InvoiceItemType>>>;
  transactions?: Maybe<Array<Maybe<LimitedTransactionType>>>;
  successfulTransaction?: Maybe<LimitedTransactionType>;
  successfulElectronicVoucherCount?: Maybe<Scalars['Int']>;
  expectedElectronicVouchersCount?: Maybe<Scalars['Int']>;
  customerPaymentFlowType?: Maybe<CustomerPaymentFlowType>;
  description?: Maybe<Scalars['String']>;
};

export type PageBasedBusinessInvoiceType = {
  __typename?: 'PageBasedBusinessInvoiceType';
  totalCount?: Maybe<Scalars['Int']>;
  /** @deprecated Not used */
  notPaidInvoices?: Maybe<Scalars['Int']>;
  /** @deprecated Not used */
  failedInvoices?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Array<Maybe<LimitedInvoiceType>>>;
};

export type CurrentBalanceType = {
  __typename?: 'CurrentBalanceType';
  actual?: Maybe<ActualBalanceType>;
  provisional?: Maybe<ProvisionalBalanceType>;
};

export type ActualBalanceType = {
  __typename?: 'ActualBalanceType';
  insuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  coinsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  hospitalService?: Maybe<ServiceBalanceDetailType>;
  labStudies?: Maybe<ServiceBalanceDetailType>;
  medicines?: Maybe<ServiceBalanceDetailType>;
  ambulance?: Maybe<ServiceBalanceDetailType>;
  externalConsult?: Maybe<ServiceBalanceDetailType>;
  urgency?: Maybe<ServiceBalanceDetailType>;
  extVideoConsult?: Maybe<ServiceBalanceDetailType>;
};

export type ServiceBalanceDetailType = {
  __typename?: 'ServiceBalanceDetailType';
  quantity?: Maybe<Scalars['Int']>;
  amountCents?: Maybe<Scalars['Int']>;
  availableQuantity?: Maybe<Scalars['Int']>;
  availableAmountCents?: Maybe<Scalars['Int']>;
};

export type ProvisionalBalanceType = {
  __typename?: 'ProvisionalBalanceType';
  insuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  coinsuredAmountBalanceCents?: Maybe<Scalars['Int']>;
  hospitalService?: Maybe<ServiceBalanceDetailType>;
  labStudies?: Maybe<ServiceBalanceDetailType>;
  medicines?: Maybe<ServiceBalanceDetailType>;
  ambulance?: Maybe<ServiceBalanceDetailType>;
  externalConsult?: Maybe<ServiceBalanceDetailType>;
  urgency?: Maybe<ServiceBalanceDetailType>;
  extVideoConsult?: Maybe<ServiceBalanceDetailType>;
};

export type ServiceParameters = {
  __typename?: 'ServiceParameters';
  service: ServiceType;
  quantity: Scalars['Int'];
  amountCents: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedicalHistoryForMember?: Maybe<CreateMedicalHistoryForMember>;
  updateMedicalHistoryForMember?: Maybe<UpdateMedicalHistoryForMember>;
  addMedicalHistoryAllergy?: Maybe<AddMedicalHistoryAllergy>;
  updateMedicalHistoryAllergy?: Maybe<UpdateMedicalHistoryAllergy>;
  deleteMedicalHistoryAllergy?: Maybe<DeleteMedicalHistoryAllergy>;
  uploadMedicalHistoryExamDocument?: Maybe<UploadMedicalHistoryExamDocument>;
  uploadExamDocumentToMedicalNote?: Maybe<UploadExamDocumentToMedicalNote>;
  assignMedicalHistoryExamToMedicalNote?: Maybe<AssignMedicalHistoryExamToMedicalNote>;
  deleteMedicalHistoryExam?: Maybe<DeleteMedicalHistoryExam>;
  updateMedicalHistoryExam?: Maybe<UpdateMedicalHistoryExam>;
  addFamilyPathology?: Maybe<AddFamilyPathology>;
  updateFamilyPathology?: Maybe<UpdateFamilyPathology>;
  deleteFamilyPathology?: Maybe<DeleteFamilyPathology>;
  addHospitalization?: Maybe<AddHospitalization>;
  updateHospitalization?: Maybe<UpdateHospitalization>;
  deleteHospitalization?: Maybe<DeleteHospitalization>;
  addMalformation?: Maybe<AddMalformation>;
  updateMalformation?: Maybe<UpdateMalformation>;
  deleteMalformation?: Maybe<DeleteMalformation>;
  addMedicalHistoryMedicine?: Maybe<AddMedicalHistoryMedicine>;
  updateMedicalHistoryMedicine?: Maybe<UpdateMedicalHistoryMedicine>;
  deleteMedicalHistoryMedicine?: Maybe<DeleteMedicalHistoryMedicine>;
  updateNonPathologicalHistory?: Maybe<UpdateNonPathologicalHistory>;
  updateObstetricGynecologicalHistory?: Maybe<UpdateObstetricGynecologicalHistory>;
  addPregnancyDelivery?: Maybe<AddPregnancyDelivery>;
  updatePregnancyDelivery?: Maybe<UpdatePregnancyDelivery>;
  deletePregnancyDelivery?: Maybe<DeletePregnancyDelivery>;
  addGynecologicalExam?: Maybe<AddGynecologicalExam>;
  updateGynecologicalExam?: Maybe<UpdateGynecologicalExam>;
  deleteGynecologicalExam?: Maybe<DeleteGynecologicalExam>;
  addPathology?: Maybe<AddPathology>;
  updatePathology?: Maybe<UpdatePathology>;
  deletePathology?: Maybe<DeletePathology>;
  addPregnancy?: Maybe<AddPregnancy>;
  updatePregnancy?: Maybe<UpdatePregnancy>;
  deletePregnancy?: Maybe<DeletePregnancy>;
  addSurgery?: Maybe<AddSurgery>;
  updateSurgery?: Maybe<UpdateSurgery>;
  deleteSurgery?: Maybe<DeleteSurgery>;
  addVaccine?: Maybe<AddVaccine>;
  updateVaccine?: Maybe<UpdateVaccine>;
  deleteVaccine?: Maybe<DeleteVaccine>;
  updateMedicalHistoryBasicInfo?: Maybe<UpdateMedicalHistoryBasicInfo>;
  addMentalHealthDiagnosis?: Maybe<AddMentalHealthDiagnosis>;
  updateMentalHealthDiagnosis?: Maybe<UpdateMentalHealthDiagnosis>;
  deleteMentalHealthDiagnosis?: Maybe<DeleteMentalHealthDiagnosis>;
  updatePerinatalHistory?: Maybe<UpdatePerinatalHistory>;
  updateParentPerinatalHistory?: Maybe<UpdateParentPerinatalHistory>;
  addGeneralObservation?: Maybe<AddGeneralObservation>;
  updateGeneralObservation?: Maybe<UpdateGeneralObservation>;
  deleteGeneralObservation?: Maybe<DeleteGeneralObservation>;
  createNewMedicalNote?: Maybe<CreateNewMedicalNote>;
  createConsultMedicalNote?: Maybe<CreateConsultMedicalNote>;
  updateMedicalNote?: Maybe<UpdateMedicalNote>;
  deleteMedicalNote?: Maybe<DeleteMedicalNote>;
  cancelMedicalNote?: Maybe<CancelMedicalNote>;
  completeMedicalNote?: Maybe<CompleteMedicalNote>;
  addPrescriptionToMedicalNote?: Maybe<AddPrescriptionToMedicalNote>;
  reclassifyMedicalNote?: Maybe<ReclassifyMedicalNote>;
  updateEvaluation?: Maybe<UpdateEvaluation>;
  addInterrogation?: Maybe<AddInterrogation>;
  updateInterrogation?: Maybe<UpdateInterrogation>;
  deleteInterrogation?: Maybe<DeleteInterrogation>;
  addExploration?: Maybe<AddExploration>;
  updateExploration?: Maybe<UpdateExploration>;
  deleteExploration?: Maybe<DeleteExploration>;
  addProcedure?: Maybe<AddProcedure>;
  updateProcedure?: Maybe<UpdateProcedure>;
  uploadProcedureDocument?: Maybe<UploadProcedureDocument>;
  deleteProcedure?: Maybe<DeleteProcedure>;
  addAddendum?: Maybe<AddAddendum>;
  updateAddendum?: Maybe<UpdateAddendum>;
  uploadAddendumDocument?: Maybe<UploadAddendumDocument>;
  deleteAddendum?: Maybe<DeleteAddendum>;
  updatePhysicalExam?: Maybe<UpdatePhysicalExam>;
  updateNoteBasicInfo?: Maybe<UpdateNoteBasicInfo>;
  updateDischargeInfo?: Maybe<UpdateDischargeInfo>;
  updatePhysicalActivity?: Maybe<UpdatePhysicalActivity>;
  updateObstetricGynecologicalInfo?: Maybe<UpdateObstetricGynecologicalInfo>;
  initUserHybridSubscription?: Maybe<InitUserHybridSubscriptionContext>;
  setupUserHealthplanApplication?: Maybe<SetUpUserHealthPlanApplication>;
  checkBusinessAccount?: Maybe<CheckBusinessAccount>;
  createHealthPlanApplication?: Maybe<CreateHealthPlanApplication>;
  updateHealthPlanApplication?: Maybe<UpdateHealthPlanApplication>;
  updateHealthPlanApplicationAnswers?: Maybe<UpdateHealthPlanApplicationAnswers>;
  updateHealthPlanApplicationIncentiveCode?: Maybe<UpdateHealthPlanApplicationIncentiveCode>;
  deleteHealthPlanApplicationIncentiveCode?: Maybe<DeleteHealthPlanApplicationIncentiveCode>;
  submitHealthPlanApplication?: Maybe<SubmitHealthPlanApplication>;
  submitHealthPlanApplicationWithToken?: Maybe<SubmitHealthPlanApplicationWithToken>;
  acceptHealthPlanApplication?: Maybe<AcceptHealthPlanApplication>;
  attemptPreApprovedSubscriptionByUser?: Maybe<AttemptPreApprovedSubscriptionByUser>;
  closeHealthPlanApplication?: Maybe<CloseHealthPlanApplication>;
  setWaitingHealthPlanApplication?: Maybe<SetWaitingHealthPlanApplication>;
  notifyHealthPlanApplicationState?: Maybe<NotifyHealthPlanApplicationState>;
  sendHealthPlanAcceptDocuments?: Maybe<SendHealthPlanAcceptDocuments>;
  resendWelcomeEmailsToCollective?: Maybe<ResendCollectiveWelcomeEmail>;
  updateHealthPlanApplicationItem?: Maybe<UpdateHealthPlanApplicationItem>;
  forceHealthPlanApplicationItemCheck?: Maybe<ForceHealthPlanApplicationItemCheck>;
  createHealthPlanApplicationItem?: Maybe<CreateHealthPlanApplicationItem>;
  createHealthPlanApplicationItemV2?: Maybe<CreateHealthPlanApplicationItemV2>;
  duplicateHealthPlanApplicationItem?: Maybe<DuplicateHealthPlanApplicationItem>;
  cancelHealthPlanApplicationItem?: Maybe<CancelHealthPlanApplicationItem>;
  deleteHealthPlanApplicationItem?: Maybe<DeleteHealthPlanApplicationItem>;
  uploadPhotoHealthPlanApplicationItem?: Maybe<UploadPhotoHealthPlanApplicationItem>;
  uploadHealthPlanApplicationItemDocument?: Maybe<UploadHealthPlanApplicationItemDocument>;
  uploadSignatureForWeb?: Maybe<UploadSignatureForWeb>;
  commentOnHealthPlanApplicationItem?: Maybe<CommentOnHealthPlanApplicationItem>;
  setWaitingHealthPlanApplicationItem?: Maybe<SetWaitingHealthPlanApplicationItem>;
  createHealthPlanQuote?: Maybe<CreateHealthPlanQuote>;
  updateHealthPlanQuote?: Maybe<UpdateHealthPlanQuote>;
  updateHealthPlanApplicationItemAnswers?: Maybe<UpdateHealthPlanApplicationItemAnswers>;
  setSubscriptionCheckState?: Maybe<SetSubscriptionCheckState>;
  retriggerAutoSubscriptionChecks?: Maybe<RetriggerAutoSubscriptionChecks>;
  checkProductParameters?: Maybe<CheckProductParameters>;
  getInstallmentsAvailableForPaymentMethod?: Maybe<GetInstallmentsAvailableForPaymentMethod>;
  updateHealthPlanApplicationInterestFreePlan?: Maybe<UpdateHealthplanApplicationInterestFreePlan>;
  sendReminder?: Maybe<SendReminder>;
  setBusinessAsHealthPlanApplicationOwner?: Maybe<SetBusinessAsHealthPlanApplicationOwner>;
  sendSubscriptionPriceEstimationEmail?: Maybe<SendSubscriptionPriceEstimationEmail>;
  generateSubscriptionPriceEstimationDocument?: Maybe<GenerateSubscriptionPriceEstimationDocument>;
  updateHealthPlanQuoteSubscriptionType?: Maybe<UpdateHealthPlanQuoteSubscriptionType>;
  startMiniSubscription?: Maybe<StartMiniSubscription>;
  updateMedicalRecommendation?: Maybe<UpdateMedicalRecommendation>;
  createUser?: Maybe<CreateUser>;
  createInternalUser?: Maybe<CreateInternalUser>;
  updateInternalUser?: Maybe<UpdateInternalUser>;
  createUserForMember?: Maybe<CreateUserForMember>;
  updateUser?: Maybe<UpdateUser>;
  setUserGroups?: Maybe<SetUserGroups>;
  requestUserEmailUpdate?: Maybe<RequestUserEmailUpdate>;
  confirmUserEmailUpdate?: Maybe<ConfirmUserEmailUpdate>;
  requestUserPasswordUpdate?: Maybe<RequestUserPasswordUpdate>;
  uploadPhotoMember?: Maybe<UploadPhotoMember>;
  forgetPasswordUser?: Maybe<ForgetPasswordUser>;
  resetPasswordUser?: Maybe<ResetPasswordUser>;
  logoutUserFromAllSessions?: Maybe<LogoutUserFromAllSessions>;
  createMember?: Maybe<CreateMember>;
  updateMember?: Maybe<UpdateMember>;
  requestUpdateMember?: Maybe<RequestUpdateMember>;
  createStripeSource?: Maybe<CreateStripeSource>;
  setPrimaryStripeSource?: Maybe<SetPrimaryStripeSource>;
  deleteStripeSource?: Maybe<DeleteStripeSource>;
  deleteMember?: Maybe<DeleteMember>;
  addPushTokenToUser?: Maybe<AddPushTokenToUser>;
  deletePushTokenFromUser?: Maybe<DeletePushTokenFromUser>;
  sendTelephoneConfirm?: Maybe<SendTelephoneConfirm>;
  confirmTelephone?: Maybe<ConfirmTelephone>;
  phoneTokenAuth?: Maybe<PhoneTokenAuth>;
  updateUserTelephone?: Maybe<UpdateUserTelephone>;
  grantRepresentedUserAccess?: Maybe<GrantRepresentedUserAccess>;
  replaceDocument?: Maybe<ReplaceDocument>;
  uploadMemberDocument?: Maybe<UploadMemberDocument>;
  uploadAddressDocument?: Maybe<UploadAddressDocument>;
  uploadClaimsInvoiceDocument?: Maybe<UploadClaimsInvoiceDocument>;
  uploadAmendmentAttachmentDocument?: Maybe<UploadAmendmentAttachmentDocument>;
  createAuthorizationToken?: Maybe<CreateAuthorizationToken>;
  consumeAuthorizationToken?: Maybe<ConsumeAuthorizationToken>;
  resetCollectiveUserCredentials?: Maybe<ResetCollectiveUserCredentials>;
  resetB2bAdminUserCredentials?: Maybe<ResetB2BAdminUserCredentials>;
  requestForgotDoctorPassword?: Maybe<RequestForgotDoctorPassword>;
  setCollectiveUserCredentials?: Maybe<SetCollectiveUserCredentials>;
  confirmCollectiveUserCredentials?: Maybe<ConfirmCollectiveUserCredentials>;
  setSubscriptionState?: Maybe<SetSubscriptionState>;
  toggleAutomaticRenew?: Maybe<ToggleAutomaticRenew>;
  updateFeatureFlagValue?: Maybe<UpdateFeatureFlagValue>;
  userIntercomTag?: Maybe<UserIntercomTag>;
  runCommand?: Maybe<RunCommand>;
  updateHealthPlanApplicationItemMemberData?: Maybe<UpdateHealthPlanApplicationItemMemberData>;
  impersonateUser?: Maybe<ImpersonateUser>;
  logoutFromImpersonation?: Maybe<LogoutFromImpersonation>;
  magicLinkAuth?: Maybe<MagicLinkAuth>;
  otpTokenAuth?: Maybe<OtpTokenAuth>;
  freeUpUserTelephoneOrEmail?: Maybe<FreeUpUserTelephoneOrEmail>;
  sendReferralInvitationCode?: Maybe<SendReferralInvitationCode>;
  providersToken?: Maybe<ProvidersToken>;
  createDoctorFriend?: Maybe<CreateDoctorFriend>;
  updateDoctorFriend?: Maybe<UpdateDoctorFriend>;
  createProviderCategory?: Maybe<CreateProviderCategory>;
  updateProviderCategory?: Maybe<UpdateProviderCategory>;
  deleteProviderCategory?: Maybe<DeleteProviderCategory>;
  createProvider?: Maybe<CreateProvider>;
  updateProvider?: Maybe<UpdateProvider>;
  deleteProvider?: Maybe<DeleteProvider>;
  createProviderBranchOffice?: Maybe<CreateProviderBranchOffice>;
  updateProviderBranchOffice?: Maybe<UpdateProviderBranchOffice>;
  deleteProviderBranchOffice?: Maybe<DeleteProviderBranchOffice>;
  createDoctorSpecialistDetail?: Maybe<CreateDoctorSpecialistDetail>;
  updateDoctorSpecialistDetail?: Maybe<UpdateDoctorSpecialistDetail>;
  deleteDoctorSpecialistDetail?: Maybe<DeleteDoctorSpecialistDetail>;
  uploadDoctorSpecialistDetailDocument?: Maybe<UploadDoctorSpecialistDetailDocument>;
  addDoctorSpecializationCertificateValidity?: Maybe<AddDoctorSpecializationCertificateValidity>;
  createDoctor?: Maybe<CreateDoctor>;
  updateDoctor?: Maybe<UpdateDoctor>;
  updateDoctorById?: Maybe<UpdateDoctorById>;
  uploadDoctorSignatureImage?: Maybe<UploadDoctorSignatureImage>;
  uploadDoctorDocument?: Maybe<UploadDoctorDocument>;
  createSpecializationFare?: Maybe<CreateSpecializationFare>;
  updateSpecializationFare?: Maybe<UpdateSpecializationFare>;
  createDoctorReminder?: Maybe<CreateDoctorReminder>;
  updateDoctorReminder?: Maybe<UpdateDoctorReminder>;
  scheduleAppointmentAttemptNotification?: Maybe<ScheduleAppointmentAttemptNotification>;
  importDoctorDraftFromAirtable?: Maybe<ImportDoctorDraftFromAirtable>;
  createPharmacyOrder?: Maybe<CreatePharmacyOrder>;
  updatePharmacyOrder?: Maybe<UpdatePharmacyOrder>;
  cancelPharmacyOrder?: Maybe<CancelPharmacyOrder>;
  confirmCoinsuredPharmacyOrder?: Maybe<ConfirmCoinsuredPharmacyOrder>;
  uploadPharmacyInvoiceDocument?: Maybe<UploadPharmacyInvoiceDocument>;
  sendPharmacyOrderEmail?: Maybe<SendPharmacyOrderEmail>;
  createGroup?: Maybe<CreateGroup>;
  updateGroup?: Maybe<UpdateGroup>;
  deleteGroup?: Maybe<DeleteGroup>;
  createPaymentMethod?: Maybe<CreatePaymentMethod>;
  makePaymentMethodPrimary?: Maybe<MakePaymentMethodPrimary>;
  deletePaymentMethod?: Maybe<DeletePaymentMethod>;
  makePayoutPaymentMethodPrimary?: Maybe<MakePayoutPaymentMethodPrimary>;
  makePayinPaymentMethodPrimary?: Maybe<MakePayinPaymentMethodPrimary>;
  createBillingInfo?: Maybe<CreateBillingInfo>;
  updateBillingInfo?: Maybe<UpdateBillingInfo>;
  createPaymentMethodForUser?: Maybe<CreatePaymentMethodForUser>;
  createManualPaymentMethodForUser?: Maybe<CreateManualPaymentMethodForUser>;
  editManualPaymentGatewaySource?: Maybe<EditManualPaymentGatewaySource>;
  addSpeiPaymentMethodForUser?: Maybe<AddSpeiPaymentMethodForUser>;
  createPaymentMethodWithMultipleSources?: Maybe<CreatePaymentMethodWithMultipleSources>;
  requestStripeSetupIntent?: Maybe<RequestStripeSetupIntent>;
  createSetupIntents?: Maybe<CreateSetupIntents>;
  createInvoiceDispute?: Maybe<CreateInvoiceDispute>;
  ruleInvoiceDispute?: Maybe<RuleInvoiceDispute>;
  fetchTransactionCep?: Maybe<FetchTransactionCep>;
  updateUserBillingInfo?: Maybe<UpdateUserBillingInfo>;
  createLocation?: Maybe<CreateLocation>;
  updateLocation?: Maybe<UpdateLocation>;
  updateInBatchLocation?: Maybe<UpdateInBatchLocation>;
  retryChargeInvoice?: Maybe<RetryChargeInvoice>;
  retryElectronicVoucherGeneration?: Maybe<RetryElectronicVoucherGeneration>;
  confirmManualInvoicePayment?: Maybe<ConfirmManualInvoicePayment>;
  refundInvoice?: Maybe<RefundInvoice>;
  requestElectronicVoucherDocumentData?: Maybe<RequestElectronicVoucherDocumentData>;
  validateElectronicVoucherXml?: Maybe<ValidateElectronicVoucherXml>;
  validateDraftElectronicVoucherXml?: Maybe<ValidateDraftElectronicVoucherXml>;
  replaceInvoiceDocument?: Maybe<ReplaceInvoiceDocument>;
  uploadElectronicVoucher?: Maybe<UploadElectronicVoucher>;
  uploadDraftElectronicVoucher?: Maybe<UploadDraftElectronicVoucher>;
  payoutInvoice?: Maybe<PayoutInvoice>;
  bundleInvoiceItems?: Maybe<BundleInvoiceItems>;
  payoutInvoiceOffline?: Maybe<PayoutInvoiceOffline>;
  downloadInvoicePayoutCep?: Maybe<DownloadInvoicePayoutCep>;
  updateElectronicVoucher?: Maybe<UpdateElectronicVoucher>;
  retrySignElectronicVoucher?: Maybe<RetrySignElectronicVoucher>;
  manualElectronicVoucherCancellation?: Maybe<ManualElectronicVoucherCancellation>;
  cancelScheduledPayout?: Maybe<CancelScheduledPayout>;
  addCommentToInvoice?: Maybe<AddCommentToInvoice>;
  attemptToSignUsersElectronicVouchers?: Maybe<AttemptToSignUsersElectronicVouchers>;
  setCopayInvoiceAsUncollectible?: Maybe<SetCopayInvoiceAsUncollectible>;
  removeInvoiceItemElectronicVoucher?: Maybe<RemoveInvoiceItemElectronicVoucher>;
  updateInvoiceItemsWithElectronicVoucherAmounts?: Maybe<UpdateInvoiceItemsWithElectronicVoucherAmounts>;
  updateIncentiveCodeBanner?: Maybe<UpdateIncentiveCodeBanner>;
  createHealthPlanAmendment?: Maybe<CreateHealthPlanAmendment>;
  updateHealthPlanAmendment?: Maybe<UpdateHealthPlanAmendment>;
  createReissueApplication?: Maybe<CreateReissueApplication>;
  cancelHealthPlan?: Maybe<CancelHealthPlan>;
  setConsultType?: Maybe<SetConsultType>;
  setClassificationNotes?: Maybe<SetClassificationNotes>;
  authorizeInPersonConsults?: Maybe<AuthorizeInPersonConsult>;
  createPrescription?: Maybe<CreatePrescription>;
  createOnDemandConsult?: Maybe<CreateOnDemandConsult>;
  createOnDemandConsultNew?: Maybe<CreateOnDemandConsultNew>;
  addReasonOnDemandConsult?: Maybe<AddReasonOnDemandConsult>;
  claimOnDemandConsult?: Maybe<ClaimOnDemandConsult>;
  startOnDemandConsult?: Maybe<StartOnDemandConsult>;
  stopOnDemandConsult?: Maybe<StopOnDemandConsult>;
  cancelOnDemandConsult?: Maybe<CancelOnDemandConsult>;
  closeOnDemandConsult?: Maybe<CloseOnDemandConsult>;
  setReadyAgainOnDemandConsult?: Maybe<SetReadyAgainOnDemandConsult>;
  startCallConsult?: Maybe<StartCallConsult>;
  endCallConsult?: Maybe<EndCallConsult>;
  wrapUpConsult?: Maybe<WrapUpConsult>;
  updateEmr?: Maybe<UpdateEmr>;
  sendTextMessage?: Maybe<SendTextMessage>;
  setMessagesRead?: Maybe<SetMessagesRead>;
  startAttachmentMessage?: Maybe<StartAttachmentMessage>;
  completeAttachmentMessage?: Maybe<CompleteAttachmentMessage>;
  autoGenerateElectronicVoucherForOnDemandConsults?: Maybe<AutoGenerateElectronicVoucherForOnDemandConsults>;
  autoGenerateElectronicVoucherForInPersonConsults?: Maybe<AutoGenerateElectronicVoucherForInPersonConsults>;
  requestMedicinePostOnDemandConsult?: Maybe<RequestMedicinePostOnDemandConsult>;
  updatePrescriptionMemberChecks?: Maybe<UpdatePrescriptionMemberChecks>;
  chargeInPersonConsult?: Maybe<ChargeInPersonConsult>;
  createCalendarEvent?: Maybe<CreateCalendarEvent>;
  updateCalendarEvent?: Maybe<UpdateCalendarEvent>;
  deleteCalendarEvent?: Maybe<DeleteCalendarEvent>;
  createEmptyScheduledConsultRequest?: Maybe<CreateEmptyScheduledConsultRequest>;
  createScheduledConsultRequest?: Maybe<CreateScheduledConsultRequest>;
  createScheduledConsult?: Maybe<CreateScheduledConsult>;
  scheduleConsult?: Maybe<ScheduleConsult>;
  confirmScheduledConsult?: Maybe<ConfirmScheduledConsult>;
  cancelScheduledConsult?: Maybe<CancelScheduledConsult>;
  updateScheduledConsultRequest?: Maybe<UpdateScheduledConsultRequest>;
  memberCancelScheduledConsult?: Maybe<MemberCancelScheduledConsult>;
  memberUpdateScheduledConsult?: Maybe<MemberUpdateScheduledConsult>;
  memberUpdateScheduledConsultRequest?: Maybe<MemberUpdateScheduledConsultRequest>;
  reviewComplianceAlert?: Maybe<ReviewComplianceAlert>;
  sendAmlReport?: Maybe<SendAmlReport>;
  doctorCreateDiseaseCase?: Maybe<DoctorCreateDiseaseCase>;
  createDiseaseCase?: Maybe<CreateDiseaseCase>;
  updateDiseaseCase?: Maybe<UpdateDiseaseCase>;
  createService?: Maybe<CreateService>;
  memberCreateService?: Maybe<MemberCreateService>;
  createPrescriptionService?: Maybe<CreatePrescriptionService>;
  updateService?: Maybe<UpdateService>;
  updateServiceDoctor?: Maybe<UpdateServiceDoctor>;
  updateServiceDoctorFriend?: Maybe<UpdateServiceDoctorFriend>;
  deleteService?: Maybe<DeleteService>;
  updateServiceCoverageRuling?: Maybe<UpdateServiceCoverageRuling>;
  updateServiceCoverageRulingState?: Maybe<UpdateServiceCoverageRulingState>;
  updateClaimRulingCheck?: Maybe<UpdateClaimRulingCheck>;
  acceptAllRulingChecks?: Maybe<AcceptAllRulingChecks>;
  groupServicesOnDiseaseCase?: Maybe<GroupServicesOnDiseaseCase>;
  createEvidence?: Maybe<CreateEvidence>;
  completeShortReimbursementSofiaForm?: Maybe<CompleteShortReimbursementSofiaForm>;
  evidencesReviewed?: Maybe<EvidencesReviewed>;
  requestMoreEvidences?: Maybe<RequestMoreEvidences>;
  createMemberReimbursement?: Maybe<CreateMemberReimbursement>;
  createPreapprovedAmount?: Maybe<CreatePreapprovedAmount>;
  updateOrCreateServiceCoveragePayout?: Maybe<UpdateOrCreateServiceCoveragePayout>;
  createServiceCoveragePayoutInvoice?: Maybe<CreateServiceCoveragePayoutInvoice>;
  setAmountsOnServiceCoveragePayout?: Maybe<SetAmountsOnServiceCoveragePayout>;
  approveServiceCoveragePayout?: Maybe<ApproveServiceCoveragePayout>;
  requestServicePayIn?: Maybe<RequestServicePayIn>;
  createCaseEvent?: Maybe<CreateCaseEvent>;
  deleteCaseEvent?: Maybe<DeleteCaseEvent>;
  groupServicesOnCaseEvent?: Maybe<GroupServicesOnCaseEvent>;
  updateCaseEvent?: Maybe<UpdateCaseEvent>;
  removeServicesFromCaseEvent?: Maybe<RemoveServicesFromCaseEvent>;
  uploadDataForClaimDocument?: Maybe<UploadDataForClaimDocument>;
  createServiceElectronicVoucherItems?: Maybe<CreateServiceElectronicVoucherItems>;
  setAcceptedElectronicVoucherItems?: Maybe<SetAcceptedElectronicVoucherItems>;
  bundleEventInvoiceItems?: Maybe<BundleEventInvoiceItems>;
  cancelService?: Maybe<CancelService>;
  businessToken?: Maybe<BusinessToken>;
  createBusiness?: Maybe<CreateBusiness>;
  createBusinessDraft?: Maybe<CreateBusinessDraft>;
  updateBusiness?: Maybe<UpdateBusiness>;
  updateBusinessLimited?: Maybe<UpdateBusinessLimited>;
  updateOrCreateBillingInfo?: Maybe<UpdateOrCreateBillingInfo>;
  updateBusinessPaymentInformation?: Maybe<UpdateBusinessPaymentInformation>;
  retriggerBusinessChecks?: Maybe<RetriggerBusinessChecks>;
  requestBusinessDocumentUploadData?: Maybe<RequestBusinessDocumentUploadData>;
  approveBusinessDocumentCheck?: Maybe<ApproveBusinessDocumentCheck>;
  toggleJustifiableAbsenceForBusinessDocumentCheck?: Maybe<ToggleJustifiableAbsenceForBusinessDocumentCheck>;
  setAbsenceJustificationForBusinessDocumentCheck?: Maybe<SetAbsenceJustificationForBusinessDocumentCheck>;
  transitionBusinessState?: Maybe<TransitionBusinessState>;
  contractUpdateStateDate?: Maybe<ContractUpdateStateDate>;
  createBusinessStakeholder?: Maybe<CreateBusinessStakeholder>;
  updateBusinessStakeholder?: Maybe<UpdateBusinessStakeholder>;
  updateLegalRepresentative?: Maybe<UpdateLegalRepresentative>;
  retriggerBusinessStakeholderChecks?: Maybe<RetriggerBusinessStakeholderChecks>;
  requestBusinessStakeholderDocumentUploadData?: Maybe<RequestBusinessStakeholderDocumentUploadData>;
  createBusinessEmployee?: Maybe<CreateBusinessEmployee>;
  createApplicationsForBusinessBeneficiary?: Maybe<CreateApplicationsForBusinessBeneficiary>;
  createBusinessEmployees?: Maybe<CreateBusinessEmployees>;
  createCollectiveBusinessBeneficiaries?: Maybe<CreateCollectiveBusinessBeneficiaries>;
  createCollectiveBusinessBeneficiaryRelatives?: Maybe<CreateCollectiveBusinessBeneficiaryRelatives>;
  sendBeneficiariesDataToOps?: Maybe<SendBeneficiariesDataToOps>;
  editBusinessEmployee?: Maybe<EditBusinessEmployee>;
  deleteBusinessEmployee?: Maybe<DeleteBusinessEmployee>;
  sendBusinessEmployeeWelcomeEmail?: Maybe<SendBusinessEmployeeWelcomeEmail>;
  sendBusinessAdminWelcomeEmail?: Maybe<SendBusinessAdminWelcomeEmail>;
  createBusinessPaymentMethod?: Maybe<CreateBusinessPaymentMethod>;
  makeBusinessPayinPaymentMethodPrimary?: Maybe<MakeBusinessPayinPaymentMethodPrimary>;
  deleteBusinessPaymentMethod?: Maybe<DeleteBusinessPaymentMethod>;
  createBusinessAdmin?: Maybe<CreateBusinessAdmin>;
  updateBusinessAdmin?: Maybe<UpdateBusinessAdmin>;
  deleteBusinessAdmin?: Maybe<DeleteBusinessAdmin>;
  makeBusinessAdminOwner?: Maybe<MakeBusinessAdminOwner>;
  createBusinessGroupsConfig?: Maybe<CreateBusinessGroupsConfig>;
  updateBusinessGroupsConfig?: Maybe<UpdateBusinessGroupsConfig>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  revokeToken?: Maybe<Revoke>;
};


export type MutationCreateMedicalHistoryForMemberArgs = {
  allergies?: InputMaybe<Array<InputMaybe<AllergyInputType>>>;
  allergiesNotes?: InputMaybe<Scalars['String']>;
  allergiesState?: InputMaybe<MedicalHistorySectionState>;
  familyPathologies?: InputMaybe<Array<InputMaybe<FamilyPathologyInputType>>>;
  familyPathologiesNotes?: InputMaybe<Scalars['String']>;
  familyPathologiesState?: InputMaybe<MedicalHistorySectionState>;
  hospitalizations?: InputMaybe<Array<InputMaybe<HospitalizationInputType>>>;
  hospitalizationsNotes?: InputMaybe<Scalars['String']>;
  hospitalizationsState?: InputMaybe<MedicalHistorySectionState>;
  malformations?: InputMaybe<Array<InputMaybe<MalformationInputType>>>;
  malformationsNotes?: InputMaybe<Scalars['String']>;
  malformationsState?: InputMaybe<MedicalHistorySectionState>;
  medicines?: InputMaybe<Array<InputMaybe<MedicineInputType>>>;
  medicinesNotes?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
  mentalHealthDiagnoses?: InputMaybe<Array<InputMaybe<MentalHealthDiagnosisInputType>>>;
  mentalHealthDiagnosesNotes?: InputMaybe<Scalars['String']>;
  mentalHealthDiagnosesState?: InputMaybe<MedicalHistorySectionState>;
  nonPathologicalHistory?: InputMaybe<NonPathologicalHistoryInputType>;
  obstetricGynecologicalHistory?: InputMaybe<ObstetricGynecologicalHistoryInputType>;
  parentPerinatalHistory?: InputMaybe<ParentPerinatalHistoryInputType>;
  pathologies?: InputMaybe<Array<InputMaybe<PathologyInputType>>>;
  pathologiesNotes?: InputMaybe<Scalars['String']>;
  pathologiesState?: InputMaybe<MedicalHistorySectionState>;
  perinatalHistory?: InputMaybe<PerinatalHistoryInputType>;
  surgeries?: InputMaybe<Array<InputMaybe<SurgeriesInputType>>>;
  surgeriesNotes?: InputMaybe<Scalars['String']>;
  surgeriesState?: InputMaybe<MedicalHistorySectionState>;
  vaccines?: InputMaybe<Array<InputMaybe<VaccineInputType>>>;
  vaccinesNotes?: InputMaybe<Scalars['String']>;
  vaccinesState?: InputMaybe<MedicalHistorySectionState>;
};


export type MutationUpdateMedicalHistoryForMemberArgs = {
  allergiesNotes?: InputMaybe<Scalars['String']>;
  allergiesState?: InputMaybe<MedicalHistorySectionState>;
  familyPathologiesNotes?: InputMaybe<Scalars['String']>;
  familyPathologiesState?: InputMaybe<MedicalHistorySectionState>;
  hasTherapy?: InputMaybe<Scalars['Boolean']>;
  hospitalizationsNotes?: InputMaybe<Scalars['String']>;
  hospitalizationsState?: InputMaybe<MedicalHistorySectionState>;
  id: Scalars['ID'];
  malformationsNotes?: InputMaybe<Scalars['String']>;
  malformationsState?: InputMaybe<MedicalHistorySectionState>;
  medicinesNotes?: InputMaybe<Scalars['String']>;
  mentalHealthDiagnosesNotes?: InputMaybe<Scalars['String']>;
  mentalHealthDiagnosesState?: InputMaybe<MedicalHistorySectionState>;
  pathologiesNotes?: InputMaybe<Scalars['String']>;
  pathologiesState?: InputMaybe<MedicalHistorySectionState>;
  surgeriesNotes?: InputMaybe<Scalars['String']>;
  surgeriesState?: InputMaybe<MedicalHistorySectionState>;
  therapyNotes?: InputMaybe<Scalars['String']>;
  vaccinesNotes?: InputMaybe<Scalars['String']>;
  vaccinesState?: InputMaybe<MedicalHistorySectionState>;
};


export type MutationAddMedicalHistoryAllergyArgs = {
  allergen: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};


export type MutationUpdateMedicalHistoryAllergyArgs = {
  allergen?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};


export type MutationDeleteMedicalHistoryAllergyArgs = {
  id: Scalars['ID'];
};


export type MutationUploadMedicalHistoryExamDocumentArgs = {
  fileName: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationUploadExamDocumentToMedicalNoteArgs = {
  fileName: Scalars['String'];
  medicalNoteId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationAssignMedicalHistoryExamToMedicalNoteArgs = {
  id: Scalars['ID'];
  medicalNoteId: Scalars['ID'];
};


export type MutationDeleteMedicalHistoryExamArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryExamArgs = {
  id: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationAddFamilyPathologyArgs = {
  cieNotes?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  medicalHistoryId: Scalars['ID'];
  pathology: Scalars['String'];
  relatives: Array<InputMaybe<Relative>>;
};


export type MutationUpdateFamilyPathologyArgs = {
  cieNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  pathology?: InputMaybe<Scalars['String']>;
  relatives?: InputMaybe<Array<InputMaybe<Relative>>>;
};


export type MutationDeleteFamilyPathologyArgs = {
  id: Scalars['ID'];
};


export type MutationAddHospitalizationArgs = {
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHospitalizationArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteHospitalizationArgs = {
  id: Scalars['ID'];
};


export type MutationAddMalformationArgs = {
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMalformationArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMalformationArgs = {
  id: Scalars['ID'];
};


export type MutationAddMedicalHistoryMedicineArgs = {
  ailment: Scalars['String'];
  drugKey: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryMedicineArgs = {
  ailment?: InputMaybe<Scalars['String']>;
  drugKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationDeleteMedicalHistoryMedicineArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateNonPathologicalHistoryArgs = {
  alcoholConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  alcoholConsumptionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholFrequency?: InputMaybe<Scalars['String']>;
  alcoholNotes?: InputMaybe<Scalars['String']>;
  alcoholSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  drugsName?: InputMaybe<Scalars['String']>;
  drugsNotes?: InputMaybe<Scalars['String']>;
  drugsSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsType?: InputMaybe<Drug>;
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lifestyleNotes?: InputMaybe<Scalars['String']>;
  nutritionNotes?: InputMaybe<Scalars['String']>;
  sleepHours?: InputMaybe<Scalars['Int']>;
  sleepNotes?: InputMaybe<Scalars['String']>;
  smokingCigarettes?: InputMaybe<Scalars['Int']>;
  smokingConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  smokingNotes?: InputMaybe<Scalars['String']>;
  smokingSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  smokingYears?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateObstetricGynecologicalHistoryArgs = {
  birthControlName?: InputMaybe<BirthControlName>;
  birthControlNotes?: InputMaybe<Scalars['String']>;
  birthControlTimeUsed?: InputMaybe<Scalars['String']>;
  gynecologicalExamsNotes?: InputMaybe<Scalars['String']>;
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  hasDysmenorrhea?: InputMaybe<Scalars['Boolean']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isMenstrualCycleRegular?: InputMaybe<Scalars['Boolean']>;
  menstrualCycleNotes?: InputMaybe<Scalars['String']>;
  numAbortions?: InputMaybe<Scalars['Int']>;
  numCesareans?: InputMaybe<Scalars['Int']>;
  numDeaths?: InputMaybe<Scalars['Int']>;
  numDeliveries?: InputMaybe<Scalars['Int']>;
  numLiveBirths?: InputMaybe<Scalars['Int']>;
  numPregnancies?: InputMaybe<Scalars['Int']>;
  numStillBirths?: InputMaybe<Scalars['Int']>;
};


export type MutationAddPregnancyDeliveryArgs = {
  isCesarean: Scalars['Boolean'];
  pregnancyId: Scalars['ID'];
  sexAssignedAtBirth: PregnancySex;
  status: PregnancyStatus;
  weight: Scalars['Float'];
};


export type MutationUpdatePregnancyDeliveryArgs = {
  id: Scalars['ID'];
  isCesarean: Scalars['Boolean'];
  sexAssignedAtBirth: PregnancySex;
  status: PregnancyStatus;
  weight: Scalars['Float'];
};


export type MutationDeletePregnancyDeliveryArgs = {
  id: Scalars['ID'];
};


export type MutationAddGynecologicalExamArgs = {
  date: Scalars['Date'];
  isNormal: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  obstetricGynecologicalHistoryId: Scalars['ID'];
  type: GynecologicalExamClassification;
};


export type MutationUpdateGynecologicalExamArgs = {
  date: Scalars['Date'];
  id: Scalars['ID'];
  isNormal: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  type: GynecologicalExamClassification;
};


export type MutationDeleteGynecologicalExamArgs = {
  id: Scalars['ID'];
};


export type MutationAddPathologyArgs = {
  cieKey?: InputMaybe<Scalars['String']>;
  cieNotes?: InputMaybe<Scalars['String']>;
  diagnosisDate: Scalars['Date'];
  isActive: Scalars['Boolean'];
  medicalHistoryId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePathologyArgs = {
  cieKey?: InputMaybe<Scalars['String']>;
  cieNotes?: InputMaybe<Scalars['String']>;
  diagnosisDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePathologyArgs = {
  id: Scalars['ID'];
};


export type MutationAddPregnancyArgs = {
  date: Scalars['Date'];
  gestationAge?: InputMaybe<PregnancyGestationAge>;
  laborHours?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  obstetricGynecologicalHistoryId: Scalars['ID'];
};


export type MutationUpdatePregnancyArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gestationAge?: InputMaybe<PregnancyGestationAge>;
  id: Scalars['ID'];
  laborHours?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationDeletePregnancyArgs = {
  id: Scalars['ID'];
};


export type MutationAddSurgeryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  cptNotes?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateSurgeryArgs = {
  cptKey?: InputMaybe<Scalars['String']>;
  cptNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSurgeryArgs = {
  id: Scalars['ID'];
};


export type MutationAddVaccineArgs = {
  date: Scalars['Date'];
  medicalHistoryId: Scalars['ID'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateVaccineArgs = {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteVaccineArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMedicalHistoryBasicInfoArgs = {
  bloodType?: InputMaybe<BloodType>;
  date?: InputMaybe<Scalars['Date']>;
  height?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  weight?: InputMaybe<Scalars['Float']>;
};


export type MutationAddMentalHealthDiagnosisArgs = {
  diagnosis: Scalars['String'];
  medicalHistoryId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  treatment: Scalars['String'];
};


export type MutationUpdateMentalHealthDiagnosisArgs = {
  diagnosis: Scalars['String'];
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  treatment: Scalars['String'];
};


export type MutationDeleteMentalHealthDiagnosisArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePerinatalHistoryArgs = {
  apgar1MinScore?: InputMaybe<Scalars['Int']>;
  apgar5MinScore?: InputMaybe<Scalars['Int']>;
  cardiologyScreeningNotes?: InputMaybe<Scalars['String']>;
  complicationsNotes?: InputMaybe<Scalars['String']>;
  developmentAlteration?: InputMaybe<Scalars['Boolean']>;
  developmentAlterationNotes?: InputMaybe<Scalars['String']>;
  gestationalAgeClassification?: InputMaybe<GestationalAgeClassification>;
  gestationalAgeDays?: InputMaybe<Scalars['Int']>;
  gestationalAgeWeeks?: InputMaybe<Scalars['Int']>;
  gestationalWeightClassification?: InputMaybe<GestationalWeightClassification>;
  hadComplications?: InputMaybe<Scalars['Boolean']>;
  headCircumference?: InputMaybe<Scalars['Float']>;
  hearingScreeningNotes?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  hipScreeningNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  incubationRequired?: InputMaybe<Scalars['Boolean']>;
  isPresent?: InputMaybe<MedicalHistorySectionState>;
  metabolicScreeningNotes?: InputMaybe<Scalars['String']>;
  normalCardiologyScreening?: InputMaybe<Scalars['Boolean']>;
  normalHearingScreening?: InputMaybe<Scalars['Boolean']>;
  normalHipScreening?: InputMaybe<Scalars['Boolean']>;
  normalMetabolicScreening?: InputMaybe<Scalars['Boolean']>;
  normalVisualScreening?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  reanimationNotes?: InputMaybe<Scalars['String']>;
  reanimationRequired?: InputMaybe<Scalars['Boolean']>;
  visualScreeningNotes?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateParentPerinatalHistoryArgs = {
  deliveryType?: InputMaybe<DeliveryType>;
  expectedHeight?: InputMaybe<Scalars['Int']>;
  fatherHeight?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  isPresent?: InputMaybe<MedicalHistorySectionState>;
  motherAbortions?: InputMaybe<Scalars['Int']>;
  motherCaesareanSections?: InputMaybe<Scalars['Int']>;
  motherDeliveries?: InputMaybe<Scalars['Int']>;
  motherHeight?: InputMaybe<Scalars['Float']>;
  motherPregnancies?: InputMaybe<Scalars['Int']>;
  motherPregnancyAge?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  obstetrician?: InputMaybe<Scalars['String']>;
  pregnancyComplications?: InputMaybe<Scalars['Boolean']>;
  pregnancyComplicationsNotes?: InputMaybe<Scalars['String']>;
  pregnancyEvolution?: InputMaybe<PregnancyEvolutionType>;
};


export type MutationAddGeneralObservationArgs = {
  medicalHistoryId: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationUpdateGeneralObservationArgs = {
  id: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationDeleteGeneralObservationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateNewMedicalNoteArgs = {
  memberId: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  noteType: NoteType;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationCreateConsultMedicalNoteArgs = {
  consultId: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  noteType: NoteType;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMedicalNoteArgs = {
  diseaseCaseId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  motive?: InputMaybe<Scalars['String']>;
  occurrenceTime?: InputMaybe<Scalars['DateTime']>;
  shouldBeFreeOfCharge?: InputMaybe<Scalars['Boolean']>;
  suffering?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMedicalNoteArgs = {
  id: Scalars['ID'];
};


export type MutationCancelMedicalNoteArgs = {
  cancellationReason?: InputMaybe<ConsultCancellationReason>;
  cancellationReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCompleteMedicalNoteArgs = {
  diagnosis: Scalars['String'];
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  prescriptionItems?: InputMaybe<Array<PrescriptionItemInputType>>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationAddPrescriptionToMedicalNoteArgs = {
  diagnosis: Scalars['String'];
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  prescriptionItems?: InputMaybe<Array<PrescriptionItemInputType>>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationReclassifyMedicalNoteArgs = {
  id: Scalars['ID'];
  noteType: NoteType;
};


export type MutationUpdateEvaluationArgs = {
  cieKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  diagnosticImpression?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationAddInterrogationArgs = {
  medicalNoteId: Scalars['ID'];
  notes: Scalars['String'];
  system: InterrogationSystem;
  systemName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInterrogationArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<InterrogationSystem>;
  systemName?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteInterrogationArgs = {
  id: Scalars['ID'];
};


export type MutationAddExplorationArgs = {
  medicalNoteId: Scalars['ID'];
  notes: Scalars['String'];
  segment: ExplorationSegment;
  segmentName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateExplorationArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<ExplorationSegment>;
  segmentName?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteExplorationArgs = {
  id: Scalars['ID'];
};


export type MutationAddProcedureArgs = {
  description?: InputMaybe<Scalars['String']>;
  medicalNoteId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pathologySendingRequired?: InputMaybe<Scalars['Boolean']>;
  procedure: ProcedureName;
  scheduled: Scalars['Boolean'];
};


export type MutationUpdateProcedureArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  pathologySendingRequired?: InputMaybe<Scalars['Boolean']>;
  procedure?: InputMaybe<ProcedureName>;
};


export type MutationUploadProcedureDocumentArgs = {
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  procedureId: Scalars['ID'];
};


export type MutationDeleteProcedureArgs = {
  id: Scalars['ID'];
};


export type MutationAddAddendumArgs = {
  medicalNoteId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAddendumArgs = {
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAddendumDocumentArgs = {
  addendumId: Scalars['ID'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationDeleteAddendumArgs = {
  id: Scalars['ID'];
};


export type MutationUpdatePhysicalExamArgs = {
  bodyFat?: InputMaybe<Scalars['Float']>;
  breathingFrequency?: InputMaybe<Scalars['Int']>;
  diastolicBloodPressure?: InputMaybe<Scalars['Int']>;
  heartRate?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  muscleMass?: InputMaybe<Scalars['Float']>;
  notes?: InputMaybe<Scalars['String']>;
  oxygenation?: InputMaybe<Scalars['Int']>;
  systolicBloodPressure?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateNoteBasicInfoArgs = {
  bloodType?: InputMaybe<BloodType>;
  cephalicPerimeter?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  percentile?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
  zScore?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateDischargeInfoArgs = {
  dischargeDate?: InputMaybe<Scalars['Date']>;
  dischargeReason?: InputMaybe<DischargeReason>;
  entryDate?: InputMaybe<Scalars['Date']>;
  evolution?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  staySummary?: InputMaybe<Scalars['String']>;
  visitsAmount?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdatePhysicalActivityArgs = {
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationUpdateObstetricGynecologicalInfoArgs = {
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  hasGynecologicalExams?: InputMaybe<Scalars['Boolean']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationInitUserHybridSubscriptionArgs = {
  address: AddressInputType;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode: CountryCode;
  dateOfBirth: Scalars['Date'];
  educationAnswers: EducationAnswersInputType;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: GenderType;
  height: Scalars['String'];
  lastName: Scalars['String'];
  nationalityCountryCode: CountryCode;
  occupation: Scalars['String'];
  practiceSports: Scalars['Boolean'];
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  telephoneInput: Scalars['String'];
  weight: Scalars['String'];
};


export type MutationSetupUserHealthplanApplicationArgs = {
  applicationSource?: InputMaybe<HealthPlanApplicationSource>;
  businessCode?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  incentiveCode?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  productFamilySubscriptionIntent?: InputMaybe<ProductFamilies>;
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  subscriptionState?: InputMaybe<SubscriptionState>;
  telephoneInput: Scalars['String'];
};


export type MutationCheckBusinessAccountArgs = {
  email: Scalars['String'];
};


export type MutationUpdateHealthPlanApplicationArgs = {
  acceptApplicationAt?: InputMaybe<Scalars['DateTime']>;
  businessCode?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  removeAcceptApplicationAt?: InputMaybe<Scalars['Boolean']>;
  removeBusinessCode?: InputMaybe<Scalars['Boolean']>;
  sponsorType?: InputMaybe<SponsorType>;
  subscriptionType?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHealthPlanApplicationAnswersArgs = {
  educationAnswers: Scalars['JSONString'];
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationIncentiveCodeArgs = {
  id: Scalars['ID'];
  incentiveCode: Scalars['String'];
};


export type MutationDeleteHealthPlanApplicationIncentiveCodeArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitHealthPlanApplicationWithTokenArgs = {
  id: Scalars['Int'];
  token: Scalars['String'];
};


export type MutationAcceptHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationAttemptPreApprovedSubscriptionByUserArgs = {
  id: Scalars['Int'];
};


export type MutationCloseHealthPlanApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationSetWaitingHealthPlanApplicationArgs = {
  id: Scalars['Int'];
  intermediateState: HealthPlanApplicationState;
};


export type MutationNotifyHealthPlanApplicationStateArgs = {
  id: Scalars['Int'];
};


export type MutationSendHealthPlanAcceptDocumentsArgs = {
  id: Scalars['Int'];
};


export type MutationResendWelcomeEmailsToCollectiveArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationItemArgs = {
  covidVaccinationStatus?: InputMaybe<CovidVaccinationStatus>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  healthPlanValidSince?: InputMaybe<Scalars['DateTime']>;
  initialExclusions?: InputMaybe<Scalars['String']>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  questionnaireAnswers?: InputMaybe<Scalars['JSONString']>;
  questionnaireAnswersValidated?: InputMaybe<Scalars['JSONString']>;
  rejectionReason?: InputMaybe<HealthPlanApplicationItemRejectionReason>;
  relationship?: InputMaybe<Relationship>;
  updateProduct?: InputMaybe<Scalars['Boolean']>;
};


export type MutationForceHealthPlanApplicationItemCheckArgs = {
  id: Scalars['Int'];
};


export type MutationCreateHealthPlanApplicationItemArgs = {
  healthPlanApplicationId: Scalars['Int'];
  memberId?: InputMaybe<Scalars['Int']>;
  productId: Scalars['Int'];
  relationship?: InputMaybe<Relationship>;
};


export type MutationCreateHealthPlanApplicationItemV2Args = {
  healthPlanApplicationId: Scalars['ID'];
  memberId?: InputMaybe<Scalars['ID']>;
  productFamily?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Relationship>;
};


export type MutationDuplicateHealthPlanApplicationItemArgs = {
  id: Scalars['ID'];
};


export type MutationCancelHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
  rejectionReason?: InputMaybe<HealthPlanApplicationItemRejectionReason>;
};


export type MutationDeleteHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationUploadPhotoHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationUploadHealthPlanApplicationItemDocumentArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  documentCategory: DocumentCategory;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadSignatureForWebArgs = {
  mimeType: Scalars['String'];
  subscriptionType?: InputMaybe<Scalars['String']>;
  totalPriceCents?: InputMaybe<Scalars['Int']>;
};


export type MutationCommentOnHealthPlanApplicationItemArgs = {
  comment: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationSetWaitingHealthPlanApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationCreateHealthPlanQuoteArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  productParametersValues?: InputMaybe<Scalars['JSONString']>;
  subscriptionType?: InputMaybe<SubscriptionType>;
};


export type MutationUpdateHealthPlanQuoteArgs = {
  id: Scalars['Int'];
  productParametersValues: Scalars['JSONString'];
};


export type MutationUpdateHealthPlanApplicationItemAnswersArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  questionnaireAnswers?: InputMaybe<Scalars['JSONString']>;
  questionnaireAnswersValidated?: InputMaybe<Scalars['JSONString']>;
};


export type MutationSetSubscriptionCheckStateArgs = {
  id: Scalars['Int'];
  comments?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};


export type MutationRetriggerAutoSubscriptionChecksArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
};


export type MutationCheckProductParametersArgs = {
  healthPlanApplicationItemId: Scalars['Int'];
  productParametersValues: Scalars['JSONString'];
};


export type MutationGetInstallmentsAvailableForPaymentMethodArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateHealthPlanApplicationInterestFreePlanArgs = {
  id: Scalars['Int'];
  interestFreePlanSelected: Scalars['JSONString'];
};


export type MutationSendReminderArgs = {
  reminderHours: Scalars['Int'];
};


export type MutationSetBusinessAsHealthPlanApplicationOwnerArgs = {
  id: Scalars['Int'];
};


export type MutationSendSubscriptionPriceEstimationEmailArgs = {
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  discountPercent?: InputMaybe<Scalars['Float']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  to?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationGenerateSubscriptionPriceEstimationDocumentArgs = {
  businessName?: InputMaybe<Scalars['String']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  input: Array<SubscriptionPriceInputType>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateHealthPlanQuoteSubscriptionTypeArgs = {
  id: Scalars['ID'];
  subscriptionType: SubscriptionType;
};


export type MutationStartMiniSubscriptionArgs = {
  countryCode?: InputMaybe<CountryCode>;
  dateOfBirth: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  pronouns?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  sponsorType: SponsorType;
  telephoneInput: Scalars['String'];
};


export type MutationUpdateMedicalRecommendationArgs = {
  id: Scalars['Int'];
  medicalRecommendation: MedicalRecommendation;
  medicalRecommendationNotes?: InputMaybe<Scalars['String']>;
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateUserArgs = {
  appStage?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  productFamilySubscriptionIntent?: InputMaybe<ProductFamilies>;
  referralCode?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInputType>;
  subscriptionState?: InputMaybe<Scalars['String']>;
  telephoneInput?: InputMaybe<Scalars['String']>;
};


export type MutationCreateInternalUserArgs = {
  email: Scalars['String'];
  groups?: InputMaybe<Array<GroupName>>;
  member?: InputMaybe<InternalUserMemberInputType>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInternalUserArgs = {
  groups?: InputMaybe<Array<GroupName>>;
  id: Scalars['ID'];
  member?: InputMaybe<InternalUserMemberInputType>;
};


export type MutationCreateUserForMemberArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId: Scalars['Int'];
  telephone: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  beneficiaryWelcomed?: InputMaybe<Scalars['Boolean']>;
  collectiveOnboarded?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  productFamilySubscriptionIntent?: InputMaybe<ProductFamilies>;
  shippingAddress?: InputMaybe<AddressInputType>;
  telephone?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  welcomed?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetUserGroupsArgs = {
  groups?: InputMaybe<Array<GroupName>>;
  id: Scalars['ID'];
  isSuperuser?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRequestUserEmailUpdateArgs = {
  newEmail: Scalars['String'];
};


export type MutationConfirmUserEmailUpdateArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRequestUserPasswordUpdateArgs = {
  currentPassword: Scalars['String'];
};


export type MutationUploadPhotoMemberArgs = {
  informal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationForgetPasswordUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationResetPasswordUserArgs = {
  actionType?: InputMaybe<ActionTypeEnum>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateMemberArgs = {
  actualGender?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  heightCm?: InputMaybe<Scalars['Int']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  weightKg?: InputMaybe<Scalars['Int']>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationUpdateMemberArgs = {
  actualGender?: InputMaybe<Scalars['String']>;
  assignedPrimaryCareDoctorId?: InputMaybe<Scalars['ID']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  heightCm?: InputMaybe<Scalars['Int']>;
  huliPatientFileId?: InputMaybe<Scalars['ID']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  weightKg?: InputMaybe<Scalars['Int']>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationRequestUpdateMemberArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationCreateStripeSourceArgs = {
  primary?: InputMaybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
};


export type MutationSetPrimaryStripeSourceArgs = {
  id: Scalars['String'];
};


export type MutationDeleteStripeSourceArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberArgs = {
  id: Scalars['Int'];
};


export type MutationAddPushTokenToUserArgs = {
  platform: Scalars['String'];
  platformVersion: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDeletePushTokenFromUserArgs = {
  token: Scalars['String'];
};


export type MutationSendTelephoneConfirmArgs = {
  telephoneInput: Scalars['String'];
};


export type MutationConfirmTelephoneArgs = {
  code: Scalars['String'];
  telephoneInput: Scalars['String'];
};


export type MutationPhoneTokenAuthArgs = {
  password: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateUserTelephoneArgs = {
  code: Scalars['String'];
  telephoneInput: Scalars['String'];
};


export type MutationGrantRepresentedUserAccessArgs = {
  email: Scalars['String'];
  responsibleForServices?: InputMaybe<ResponsibleForServices>;
  telephone: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationReplaceDocumentArgs = {
  id: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadMemberDocumentArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  memberId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAddressDocumentArgs = {
  addressId: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadClaimsInvoiceDocumentArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadAmendmentAttachmentDocumentArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  id: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAuthorizationTokenArgs = {
  capability: Scalars['String'];
  category?: InputMaybe<AuthorizationTokenCategory>;
  channel?: InputMaybe<AuthorizationTokenChannel>;
  tokenUserId?: InputMaybe<Scalars['Int']>;
};


export type MutationConsumeAuthorizationTokenArgs = {
  capability: Scalars['String'];
  token: Scalars['String'];
  tokenUserId?: InputMaybe<Scalars['Int']>;
};


export type MutationResetCollectiveUserCredentialsArgs = {
  email: Scalars['String'];
};


export type MutationResetB2bAdminUserCredentialsArgs = {
  email: Scalars['String'];
};


export type MutationRequestForgotDoctorPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSetCollectiveUserCredentialsArgs = {
  password: Scalars['String'];
  telephone: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConfirmCollectiveUserCredentialsArgs = {
  password: Scalars['String'];
  telephone: Scalars['String'];
};


export type MutationSetSubscriptionStateArgs = {
  state: SubscriptionState;
};


export type MutationToggleAutomaticRenewArgs = {
  healthPlanId: Scalars['Int'];
};


export type MutationUpdateFeatureFlagValueArgs = {
  featureFlagName: FeatureFlags;
  featureFlagValue?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationUserIntercomTagArgs = {
  tagName: Scalars['String'];
  tagValue?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRunCommandArgs = {
  commands: Array<InputMaybe<Scalars['String']>>;
};


export type MutationUpdateHealthPlanApplicationItemMemberDataArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  idIsPassport?: InputMaybe<Scalars['Boolean']>;
  legalName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  nationalityCountryCode?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  relatedUserId?: InputMaybe<Scalars['Int']>;
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  residenceAddress?: InputMaybe<AddressInputType>;
  rfc?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
  welcomeConsultStatus?: InputMaybe<WelcomeConsultStatusCategory>;
};


export type MutationImpersonateUserArgs = {
  impersonatedEmail: Scalars['String'];
};


export type MutationMagicLinkAuthArgs = {
  token: Scalars['String'];
};


export type MutationOtpTokenAuthArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationFreeUpUserTelephoneOrEmailArgs = {
  freeUpEmail?: InputMaybe<Scalars['Boolean']>;
  freeUpTelephone?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['Int'];
};


export type MutationSendReferralInvitationCodeArgs = {
  email: Scalars['String'];
};


export type MutationProvidersTokenArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDoctorFriendArgs = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  taxNumber?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorFriendArgs = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  taxNumber?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateProviderCategoryArgs = {
  color?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};


export type MutationUpdateProviderCategoryArgs = {
  color?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationDeleteProviderCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProviderArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProviderArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProviderArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProviderBranchOfficeArgs = {
  adminUser: CreateUserInputType;
  billingInfo: BillingInfoInputType;
  name: Scalars['String'];
  providerId: Scalars['Int'];
  telephones?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProviderBranchOfficeArgs = {
  adminUser: UpdateUserInputType;
  billingInfo: BillingInfoInputType;
  id: Scalars['Int'];
  name: Scalars['String'];
  telephones?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProviderBranchOfficeArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDoctorSpecialistDetailArgs = {
  specialistInstitution: Scalars['String'];
  specialistNumber: Scalars['String'];
  specialization: Scalars['String'];
};


export type MutationUpdateDoctorSpecialistDetailArgs = {
  id: Scalars['Int'];
  specialistInstitution?: InputMaybe<Scalars['String']>;
  specialistNumber?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteDoctorSpecialistDetailArgs = {
  id: Scalars['Int'];
};


export type MutationUploadDoctorSpecialistDetailDocumentArgs = {
  category: DoctorSpecialistDetailDocuments;
  doctorSpecialistDetailId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationAddDoctorSpecializationCertificateValidityArgs = {
  doctorSpecialistDetailId: Scalars['ID'];
  validUntil: Scalars['Date'];
};


export type MutationCreateDoctorArgs = {
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  descriptor?: InputMaybe<Scalars['String']>;
  doctorSpecialistDetails?: InputMaybe<Array<DoctorSpecialistDetailInputType>>;
  doctoraliaUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  huliId?: InputMaybe<Scalars['Int']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCareDoctor?: InputMaybe<Scalars['Boolean']>;
  licenseInstitution: Scalars['String'];
  licenseNumber: Scalars['String'];
  namePrefix?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  paymentMethod: DoctorPaymentMethodInputType;
  residencyHospital?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorArgs = {
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  onLeaveFrom?: InputMaybe<Scalars['Date']>;
  onLeaveUntil?: InputMaybe<Scalars['Date']>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  signingKey?: InputMaybe<Scalars['String']>;
  signingPassphrase?: InputMaybe<Scalars['String']>;
  specializationFareId?: InputMaybe<Scalars['ID']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDoctorByIdArgs = {
  allowPrimaryCareMembersAssignment?: InputMaybe<Scalars['Boolean']>;
  appointmentTelephone?: InputMaybe<Scalars['String']>;
  autoEmitEnabled?: InputMaybe<Scalars['Boolean']>;
  availableOnDemand?: InputMaybe<Scalars['Boolean']>;
  billingInfo?: InputMaybe<DoctorBillingInfoInputType>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  deactivatedAt?: InputMaybe<Scalars['Date']>;
  descriptor?: InputMaybe<Scalars['String']>;
  directAppointment?: InputMaybe<Scalars['Boolean']>;
  doctorSpecialistDetails?: InputMaybe<Array<DoctorSpecialistDetailInputType>>;
  doctoraliaUrl?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  huliId?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  internalNotes?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForInPersonConsult?: InputMaybe<Scalars['Boolean']>;
  isAuthorizedForOnDemand?: InputMaybe<Scalars['Boolean']>;
  isPediatric?: InputMaybe<Scalars['Boolean']>;
  isPediatricsCareDoctor?: InputMaybe<Scalars['Boolean']>;
  isPrimaryCareDoctor?: InputMaybe<Scalars['Boolean']>;
  leaveReason?: InputMaybe<DoctorLeaveReason>;
  licenseInstitution?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  officeAddress?: InputMaybe<AddressInputType>;
  onLeaveFrom?: InputMaybe<Scalars['Date']>;
  onLeaveUntil?: InputMaybe<Scalars['Date']>;
  paymentMethod?: InputMaybe<DoctorPaymentMethodInputType>;
  residencyHospital?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  secondaryOfficeAddress?: InputMaybe<AddressInputType>;
  showDoctorInApp?: InputMaybe<Scalars['Boolean']>;
  specializationFareId?: InputMaybe<Scalars['ID']>;
  telephone?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
};


export type MutationUploadDoctorDocumentArgs = {
  category: DoctorDocuments;
  doctorId: Scalars['ID'];
  mimeType: Scalars['String'];
};


export type MutationCreateSpecializationFareArgs = {
  amountCents: Scalars['Int'];
  name: Scalars['String'];
  specialization: MedicalSpecialtyType;
};


export type MutationUpdateSpecializationFareArgs = {
  id: Scalars['ID'];
  specializationFare: SpecializationFareInputType;
};


export type MutationCreateDoctorReminderArgs = {
  consultId?: InputMaybe<Scalars['Int']>;
  memberUuid: Scalars['UUID'];
  message?: InputMaybe<Scalars['String']>;
  remindAt: Scalars['DateTime'];
  title: Scalars['String'];
};


export type MutationUpdateDoctorReminderArgs = {
  id: Scalars['Int'];
  message?: InputMaybe<Scalars['String']>;
  remindAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<DoctorReminderStatus>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationScheduleAppointmentAttemptNotificationArgs = {
  doctorId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationImportDoctorDraftFromAirtableArgs = {
  email: Scalars['String'];
};


export type MutationCreatePharmacyOrderArgs = {
  prescriptionId: Scalars['Int'];
};


export type MutationUpdatePharmacyOrderArgs = {
  address?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['Int']>;
  handledBy?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  items?: InputMaybe<Array<InputMaybe<PharmacyItemInput>>>;
  xmlProvided: Scalars['Boolean'];
};


export type MutationCancelPharmacyOrderArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmCoinsuredPharmacyOrderArgs = {
  id: Scalars['Int'];
};


export type MutationUploadPharmacyInvoiceDocumentArgs = {
  documentCategory: DocumentCategory;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationSendPharmacyOrderEmailArgs = {
  id: Scalars['Int'];
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permissionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['Int'];
};


export type MutationCreatePaymentMethodArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isWeb?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
};


export type MutationMakePaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationMakePayoutPaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
};


export type MutationMakePayinPaymentMethodPrimaryArgs = {
  paymentMethodId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateBillingInfoArgs = {
  address?: InputMaybe<AddressInputType>;
  addressZipCode?: InputMaybe<Scalars['String']>;
  legalName: Scalars['String'];
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  taxNumber: Scalars['String'];
  taxRegime?: InputMaybe<SatRegime>;
};


export type MutationUpdateBillingInfoArgs = {
  address?: InputMaybe<AddressInputType>;
  billingInfoId: Scalars['Int'];
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
};


export type MutationCreatePaymentMethodForUserArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentFlow: PaymentFlowTypes;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCreateManualPaymentMethodForUserArgs = {
  invoiceId: Scalars['Int'];
  metadata: Scalars['GenericScalar'];
};


export type MutationEditManualPaymentGatewaySourceArgs = {
  metadata: Scalars['GenericScalar'];
  paymentGatewaySourceId: Scalars['Int'];
};


export type MutationAddSpeiPaymentMethodForUserArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
};


export type MutationCreatePaymentMethodWithMultipleSourcesArgs = {
  forceRetrySubscription?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewaySources?: InputMaybe<Array<InputMaybe<PaymentGatewaySourceInputType>>>;
  paymentMethodType: Scalars['String'];
};


export type MutationCreateSetupIntentsArgs = {
  paymentGateways: Array<InputMaybe<Scalars['String']>>;
};


export type MutationCreateInvoiceDisputeArgs = {
  disputeDescription: Scalars['String'];
  invoiceId: Scalars['Int'];
};


export type MutationRuleInvoiceDisputeArgs = {
  disputeId: Scalars['Int'];
  rulingType: PaymentDisputeRulingTypes;
};


export type MutationFetchTransactionCepArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateUserBillingInfoArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  billingInfoId: Scalars['Int'];
  legalName?: InputMaybe<Scalars['String']>;
  requiresElectronicVoucher?: InputMaybe<Scalars['Boolean']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
  userId: Scalars['Int'];
};


export type MutationCreateLocationArgs = {
  city: Scalars['String'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName: Scalars['String'];
  zipcode: Scalars['String'];
};


export type MutationUpdateLocationArgs = {
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInBatchLocationArgs = {
  city?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCore?: InputMaybe<Scalars['Boolean']>;
  municipality?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  neighborhoodType?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
};


export type MutationRetryChargeInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationRetryElectronicVoucherGenerationArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmManualInvoicePaymentArgs = {
  confirmedDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId: Scalars['Int'];
  paymentGatewaySourceId: Scalars['Int'];
};


export type MutationRefundInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationRequestElectronicVoucherDocumentDataArgs = {
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  electronicVoucherId?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationValidateElectronicVoucherXmlArgs = {
  alreadyValidated?: InputMaybe<Scalars['Boolean']>;
  documentId: Scalars['Int'];
  invoiceItemId: Scalars['Int'];
};


export type MutationValidateDraftElectronicVoucherXmlArgs = {
  documentId: Scalars['Int'];
};


export type MutationReplaceInvoiceDocumentArgs = {
  id: Scalars['Int'];
  documentSubtype?: InputMaybe<DocumentSubtype>;
  electronicVoucherId: Scalars['Int'];
  invoiceId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationUploadElectronicVoucherArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  invoiceItemId: Scalars['Int'];
  mimeType?: InputMaybe<Scalars['String']>;
  voucherType: SatVoucherType;
};


export type MutationUploadDraftElectronicVoucherArgs = {
  description?: InputMaybe<Scalars['String']>;
  documentCategory: DocumentCategory;
  documentSubtype?: InputMaybe<DocumentSubtype>;
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationPayoutInvoiceArgs = {
  confirmationNumber?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  paymentGatewayType: PaymentGatewayType;
  paymentMethodId: Scalars['Int'];
};


export type MutationBundleInvoiceItemsArgs = {
  electronicVoucher: Scalars['Int'];
  invoice?: InputMaybe<Scalars['Int']>;
  invoiceItems: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationPayoutInvoiceOfflineArgs = {
  confirmationNumber: Scalars['String'];
  invoiceId: Scalars['Int'];
};


export type MutationDownloadInvoicePayoutCepArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateElectronicVoucherArgs = {
  id: Scalars['Int'];
  inputData: ElectronicVoucherInputType;
};


export type MutationRetrySignElectronicVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationManualElectronicVoucherCancellationArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationCancelScheduledPayoutArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationAddCommentToInvoiceArgs = {
  id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationAttemptToSignUsersElectronicVouchersArgs = {
  userId: Scalars['Int'];
};


export type MutationSetCopayInvoiceAsUncollectibleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveInvoiceItemElectronicVoucherArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateInvoiceItemsWithElectronicVoucherAmountsArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationUpdateIncentiveCodeBannerArgs = {
  bannerState: BannerState;
  id: Scalars['ID'];
};


export type MutationCreateHealthPlanAmendmentArgs = {
  healthPlanId: Scalars['Int'];
};


export type MutationUpdateHealthPlanAmendmentArgs = {
  amendmentCategory?: InputMaybe<AmendmentCategory>;
  amendmentDetails?: InputMaybe<Scalars['JSONString']>;
  amendmentExtras?: InputMaybe<Scalars['JSONString']>;
  healthPlanAmendmentId: Scalars['Int'];
  reviewResult?: InputMaybe<AmendmentReviewResult>;
};


export type MutationCreateReissueApplicationArgs = {
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  configGroupId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<GenderType>;
  id: Scalars['Int'];
  incentiveCode?: InputMaybe<Scalars['String']>;
  insuredAmountCents?: InputMaybe<InsuredAmountCents>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCancelHealthPlanArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  reason?: InputMaybe<CancelReason>;
};


export type MutationSetConsultTypeArgs = {
  id: Scalars['ID'];
  entryType: EmrType;
};


export type MutationSetClassificationNotesArgs = {
  classificationNotes: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationAuthorizeInPersonConsultsArgs = {
  consults: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationCreatePrescriptionArgs = {
  consultId: Scalars['Int'];
  diagnosis?: InputMaybe<Scalars['String']>;
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<PrescriptionItemInputType>>;
  prescriptionItems?: InputMaybe<Scalars['JSONString']>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOnDemandConsultArgs = {
  classification?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['Int']>;
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateOnDemandConsultNewArgs = {
  classification?: InputMaybe<OnDemandConsultClassificationType>;
  memberId?: InputMaybe<Scalars['Int']>;
  scheduledConsultId?: InputMaybe<Scalars['Int']>;
};


export type MutationAddReasonOnDemandConsultArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationClaimOnDemandConsultArgs = {
  id: Scalars['Int'];
  triggerCall?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationStopOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationCancelOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationCloseOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationSetReadyAgainOnDemandConsultArgs = {
  id: Scalars['Int'];
};


export type MutationStartCallConsultArgs = {
  id: Scalars['Int'];
};


export type MutationEndCallConsultArgs = {
  id: Scalars['Int'];
};


export type MutationWrapUpConsultArgs = {
  id: Scalars['Int'];
  diagnosis?: InputMaybe<Scalars['String']>;
  hasAntibiotics?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<PrescriptionItemInputType>>;
  prescriptionItems?: InputMaybe<Scalars['JSONString']>;
  recommendations?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEmrArgs = {
  diagnosis?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  labs?: InputMaybe<Scalars['String']>;
  medicines?: InputMaybe<Scalars['JSONString']>;
  notes?: InputMaybe<Scalars['String']>;
  otherRecommendations?: InputMaybe<Scalars['String']>;
};


export type MutationSendTextMessageArgs = {
  consultId: Scalars['Int'];
  content: Scalars['String'];
  toUserId: Scalars['Int'];
};


export type MutationSetMessagesReadArgs = {
  ids: Array<InputMaybe<Scalars['Int']>>;
  read?: InputMaybe<Scalars['Boolean']>;
};


export type MutationStartAttachmentMessageArgs = {
  consultId: Scalars['Int'];
  toUserId: Scalars['Int'];
};


export type MutationCompleteAttachmentMessageArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationAutoGenerateElectronicVoucherForOnDemandConsultsArgs = {
  doctorHoursList: Array<InputMaybe<DoctorOnDemandConsultHoursInputType>>;
};


export type MutationAutoGenerateElectronicVoucherForInPersonConsultsArgs = {
  consults: Array<InputMaybe<Scalars['Int']>>;
  doctorId: Scalars['Int'];
};


export type MutationRequestMedicinePostOnDemandConsultArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  id: Scalars['Int'];
  neighborhood: Scalars['String'];
  phone: Scalars['String'];
  saveShippingAddress?: InputMaybe<Scalars['Boolean']>;
  zipcode: Scalars['String'];
};


export type MutationUpdatePrescriptionMemberChecksArgs = {
  checkupsCheck?: InputMaybe<Scalars['Boolean']>;
  labsCheck?: InputMaybe<Scalars['Boolean']>;
  medicinesCheck?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  prescriptionItemsMemberChecks?: InputMaybe<Scalars['JSONString']>;
  referencesCheck?: InputMaybe<Scalars['Boolean']>;
  urgenciesCheck?: InputMaybe<Scalars['Boolean']>;
};


export type MutationChargeInPersonConsultArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCalendarEventArgs = {
  dateTime: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['ID'];
  location?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type MutationUpdateCalendarEventArgs = {
  dateTime?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type MutationDeleteCalendarEventArgs = {
  doctorId: Scalars['ID'];
  eventId: Scalars['String'];
};


export type MutationCreateEmptyScheduledConsultRequestArgs = {
  doctorId?: InputMaybe<Scalars['ID']>;
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  specialization?: InputMaybe<MedicalSpecialtyType>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationCreateScheduledConsultRequestArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  dayWindow: AppointmentDayWindow;
  dayWindowDescription?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['ID']>;
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  secondaryAddressId?: InputMaybe<Scalars['ID']>;
  specialization?: InputMaybe<MedicalSpecialtyType>;
  symptoms?: InputMaybe<Scalars['String']>;
  timeWindow: AppointmentTimeWindow;
  timeWindowDescription?: InputMaybe<Scalars['String']>;
  urgency: AppointmentUrgency;
  urgencyDescription?: InputMaybe<Scalars['String']>;
};


export type MutationCreateScheduledConsultArgs = {
  addressId: Scalars['ID'];
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationScheduleConsultArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['ID']>;
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  shouldEndAt?: InputMaybe<Scalars['DateTime']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmScheduledConsultArgs = {
  id: Scalars['ID'];
};


export type MutationCancelScheduledConsultArgs = {
  cancellationReason?: InputMaybe<ConsultCancellationReason>;
  cancellationReasonDetails?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateScheduledConsultRequestArgs = {
  extraNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationMemberCancelScheduledConsultArgs = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationMemberUpdateScheduledConsultArgs = {
  addressId: Scalars['ID'];
  category?: InputMaybe<ConsultCategory>;
  doctorId: Scalars['ID'];
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledAt: Scalars['DateTime'];
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  symptoms?: InputMaybe<Scalars['String']>;
};


export type MutationMemberUpdateScheduledConsultRequestArgs = {
  addressId?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<ConsultCategory>;
  dayWindow: AppointmentDayWindow;
  dayWindowDescription?: InputMaybe<Scalars['String']>;
  doctorId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  onDemandConsultClassification?: InputMaybe<OnDemandConsultClassificationType>;
  scheduledConsultClassification: GrapheneScheduledConsultClassification;
  scheduledConsultKind?: InputMaybe<ScheduledConsultKind>;
  secondaryAddressId?: InputMaybe<Scalars['ID']>;
  specialization?: InputMaybe<MedicalSpecialtyType>;
  symptoms?: InputMaybe<Scalars['String']>;
  timeWindow: AppointmentTimeWindow;
  timeWindowDescription?: InputMaybe<Scalars['String']>;
  urgency: AppointmentUrgency;
  urgencyDescription?: InputMaybe<Scalars['String']>;
};


export type MutationReviewComplianceAlertArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  internalNotes?: InputMaybe<Scalars['String']>;
  officerReported?: InputMaybe<Scalars['Boolean']>;
  reportToAuthority?: InputMaybe<Scalars['Boolean']>;
  reviewedByCommittee?: InputMaybe<Scalars['Boolean']>;
  reviewedByCommitteeAt?: InputMaybe<Scalars['Date']>;
  reviewedByOfficer?: InputMaybe<Scalars['Boolean']>;
  reviewedByOfficerAt?: InputMaybe<Scalars['Date']>;
};


export type MutationSendAmlReportArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['String']>;
  officerReported?: InputMaybe<Scalars['Boolean']>;
  reportToAuthority?: InputMaybe<Scalars['Boolean']>;
  reportType: ComplianceReportType;
  reviewedByCommittee?: InputMaybe<Scalars['Boolean']>;
  reviewedByOfficer?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type MutationDoctorCreateDiseaseCaseArgs = {
  description: Scalars['String'];
  memberId: Scalars['Int'];
};


export type MutationCreateDiseaseCaseArgs = {
  description: Scalars['String'];
  medicalDescription?: InputMaybe<Scalars['String']>;
  memberId: Scalars['Int'];
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdateDiseaseCaseArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  medicalDescription?: InputMaybe<Scalars['String']>;
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreateServiceArgs = {
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceTypeId: Scalars['Int'];
  specification?: InputMaybe<Scalars['String']>;
};


export type MutationMemberCreateServiceArgs = {
  memberId: Scalars['Int'];
  serviceType: MemberCreateServiceTypesEnum;
};


export type MutationCreatePrescriptionServiceArgs = {
  group: PrescriptionItemGroup;
  prescriptionId: Scalars['Int'];
};


export type MutationUpdateServiceArgs = {
  comments?: InputMaybe<Scalars['String']>;
  cpt?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  diagnosisDescription?: InputMaybe<Scalars['String']>;
  diagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dischargeDate?: InputMaybe<Scalars['Date']>;
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  externalProvider?: InputMaybe<Scalars['String']>;
  hospitalizationDays?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  litigation?: InputMaybe<Scalars['Boolean']>;
  occurrenceDate?: InputMaybe<Scalars['Date']>;
  providerId?: InputMaybe<Scalars['Int']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceDescription?: InputMaybe<Scalars['String']>;
  specification?: InputMaybe<Scalars['String']>;
  supportSystemLink?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateServiceDoctorArgs = {
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationUpdateServiceDoctorFriendArgs = {
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteServiceArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateServiceCoverageRulingArgs = {
  allowPayoutOnRejection?: InputMaybe<Scalars['Boolean']>;
  declinedReason?: InputMaybe<RulingDeclinationReasons>;
  healthPlanId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  initialCostCents?: InputMaybe<Scalars['Int']>;
  postauthorizationDocId?: InputMaybe<Scalars['Int']>;
  preauthorizationDocId?: InputMaybe<Scalars['Int']>;
  rejectionComment?: InputMaybe<Scalars['String']>;
  totalCostCents?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateServiceCoverageRulingStateArgs = {
  id: Scalars['Int'];
  state: RulingState;
};


export type MutationUpdateClaimRulingCheckArgs = {
  id: Scalars['Int'];
  state: RulingCheckState;
};


export type MutationAcceptAllRulingChecksArgs = {
  rulingId: Scalars['ID'];
};


export type MutationGroupServicesOnDiseaseCaseArgs = {
  diseaseCaseId: Scalars['Int'];
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationCreateEvidenceArgs = {
  doctorId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  reportType: TypeformReportTypes;
  serviceId: Scalars['Int'];
};


export type MutationCompleteShortReimbursementSofiaFormArgs = {
  formUuid: Scalars['UUID'];
  occurrenceDate: Scalars['Date'];
};


export type MutationEvidencesReviewedArgs = {
  serviceId: Scalars['Int'];
};


export type MutationRequestMoreEvidencesArgs = {
  serviceId: Scalars['Int'];
};


export type MutationCreateMemberReimbursementArgs = {
  diseaseCaseId?: InputMaybe<Scalars['Int']>;
  doctorId?: InputMaybe<Scalars['Int']>;
  memberId: Scalars['Int'];
  providerId?: InputMaybe<Scalars['Int']>;
  relatedServiceId?: InputMaybe<Scalars['Int']>;
  reportType: TypeformReportTypes;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  serviceTypeId: Scalars['Int'];
  specification?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePreapprovedAmountArgs = {
  currency?: InputMaybe<Currency>;
  description: Scalars['String'];
  serviceCoverageRulingId: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationUpdateOrCreateServiceCoveragePayoutArgs = {
  amountAnalysisDocumentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  payinMethod?: InputMaybe<PayInMethods>;
  payoutMode?: InputMaybe<ServiceCoveragePayoutMode>;
  rejectedAmountCentsComment?: InputMaybe<Scalars['String']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  serviceCoverageRulingId: Scalars['Int'];
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationCreateServiceCoveragePayoutInvoiceArgs = {
  invoiceUuid: Scalars['String'];
  payoutId: Scalars['Int'];
  pdfDocumentId: Scalars['Int'];
  xmlDocumentId: Scalars['Int'];
};


export type MutationSetAmountsOnServiceCoveragePayoutArgs = {
  amountAnalysisDocumentId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  paidCoinsuranceSubtotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceTotalCents?: InputMaybe<Scalars['Int']>;
  paidCoinsuranceVatCents?: InputMaybe<Scalars['Int']>;
  payoutMode: ServiceCoveragePayoutMode;
  rejectedAmountCentsComment?: InputMaybe<Scalars['String']>;
  rejectedAmountSubtotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountTotalCents?: InputMaybe<Scalars['Int']>;
  rejectedAmountVatCents?: InputMaybe<Scalars['Int']>;
  subtotalAmountCents: Scalars['Int'];
  totalAmountCents: Scalars['Int'];
  vatAmountCents: Scalars['Int'];
};


export type MutationApproveServiceCoveragePayoutArgs = {
  id: Scalars['Int'];
  payoutMode: ServiceCoveragePayoutMode;
};


export type MutationRequestServicePayInArgs = {
  id: Scalars['Int'];
  method?: InputMaybe<PayInMethods>;
};


export type MutationCreateCaseEventArgs = {
  description: Scalars['String'];
  originDiagnosisIcd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationDeleteCaseEventArgs = {
  id: Scalars['Int'];
};


export type MutationGroupServicesOnCaseEventArgs = {
  eventCategory?: InputMaybe<CaseEventCategory>;
  eventDescription?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['Int']>;
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationUpdateCaseEventArgs = {
  admissionSource?: InputMaybe<CaseEventAdmissionSource>;
  attendingDoctorsIds?: InputMaybe<Array<Scalars['Int']>>;
  backgroundCheck?: InputMaybe<Scalars['Boolean']>;
  bedNumber?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<CaseEventCategory>;
  deferredSince?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  dischargeReason?: InputMaybe<CaseEventDischargeReason>;
  eventClass?: InputMaybe<CaseEventClassOptions>;
  eventId: Scalars['Int'];
  evidenceLinkSent?: InputMaybe<Scalars['Boolean']>;
  finalAmountsState?: InputMaybe<CaseEventFinalAmountsState>;
  informedHospital?: InputMaybe<Scalars['Boolean']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceReceived?: InputMaybe<Scalars['Boolean']>;
  memberStage?: InputMaybe<CaseEventMemberStage>;
  preAdmissionDoctorsIds?: InputMaybe<Array<Scalars['Int']>>;
  relevantHistory?: InputMaybe<Scalars['String']>;
  requestedPartnerDoctorInfo?: InputMaybe<Scalars['Boolean']>;
  scheduledHospitalDate?: InputMaybe<Scalars['Date']>;
};


export type MutationRemoveServicesFromCaseEventArgs = {
  servicesIds: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationUploadDataForClaimDocumentArgs = {
  description?: InputMaybe<EvidenceDescription>;
  formUuid?: InputMaybe<Scalars['UUID']>;
  mimeType?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['Int'];
};


export type MutationCreateServiceElectronicVoucherItemsArgs = {
  pdfDocumentId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  xmlDocumentId: Scalars['ID'];
};


export type MutationSetAcceptedElectronicVoucherItemsArgs = {
  serviceElectronicVoucherItemsIds: Array<InputMaybe<Scalars['ID']>>;
  serviceId: Scalars['ID'];
};


export type MutationBundleEventInvoiceItemsArgs = {
  invoiceId?: InputMaybe<Scalars['ID']>;
  invoiceItemIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCancelServiceArgs = {
  comments?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
};


export type MutationBusinessTokenArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  adminMail: Scalars['String'];
  adminTelephone: Scalars['String'];
  administratorName?: InputMaybe<Scalars['String']>;
  billingInfoLegalName: Scalars['String'];
  billingInfoTaxNumber: Scalars['String'];
  businessBillingAddress: AddressInputType;
  commonName?: InputMaybe<Scalars['String']>;
  digitalSignatureSerialNumber?: InputMaybe<Scalars['String']>;
  incentiveCode?: InputMaybe<Scalars['String']>;
  incorporationDate?: InputMaybe<Scalars['String']>;
  isCollective?: InputMaybe<Scalars['Boolean']>;
  legalName: Scalars['String'];
  maternityAddon: Scalars['Boolean'];
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  nationalityCountryCode: CountryCode;
  originalQuoteAmountCents: Scalars['Int'];
  payoutClabe?: InputMaybe<Scalars['String']>;
  pipedriveDeal?: InputMaybe<Scalars['Int']>;
  previousInsurance?: InputMaybe<Scalars['String']>;
  previousInsuranceValidSince?: InputMaybe<Scalars['String']>;
  previousInsuranceValidThrough?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  quoteBeneficiariesNumber?: InputMaybe<Scalars['Int']>;
  satUseType?: InputMaybe<Scalars['String']>;
  subscriptionType: SubscriptionType;
  taxRegime?: InputMaybe<SatRegime>;
  tradeRegistrationId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessDraftArgs = {
  adminMail: Scalars['String'];
  adminTelephone: Scalars['String'];
  administratorName: Scalars['String'];
  commonName: Scalars['String'];
  isCollective: Scalars['Boolean'];
  legalName: Scalars['String'];
  nationalityCountryCode: CountryCode;
  originalQuoteAmountCents: Scalars['Int'];
  personCategory: PersonCategory;
  pipedriveDeal: Scalars['Int'];
  quoteBeneficiariesNumber: Scalars['Int'];
};


export type MutationUpdateBusinessArgs = {
  addressZipCode?: InputMaybe<Scalars['String']>;
  adminEmail?: InputMaybe<Scalars['String']>;
  adminTelephone?: InputMaybe<Scalars['String']>;
  administratorName?: InputMaybe<Scalars['String']>;
  billingInfoLegalName?: InputMaybe<Scalars['String']>;
  billingInfoTaxNumber?: InputMaybe<Scalars['String']>;
  businessBillingAddress?: InputMaybe<AddressInputType>;
  commonName?: InputMaybe<Scalars['String']>;
  digitalSignatureSerialNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  incentiveCode?: InputMaybe<Scalars['String']>;
  incorporationDate?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  maternityAddon?: InputMaybe<Scalars['Boolean']>;
  maternityAddonWaitingPeriodMonths?: InputMaybe<MaternityWaitingPeriodMonths>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  payoutClabe?: InputMaybe<Scalars['String']>;
  pipedriveDeal?: InputMaybe<Scalars['Int']>;
  previousInsurance?: InputMaybe<Scalars['String']>;
  previousInsuranceValidSince?: InputMaybe<Scalars['String']>;
  previousInsuranceValidThrough?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
  quoteBeneficiariesNumber?: InputMaybe<Scalars['Int']>;
  satUseType?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<BusinessState>;
  taxRegime?: InputMaybe<SatRegime>;
  totalHealthplanAmountCents?: InputMaybe<Scalars['Int']>;
  tradeRegistrationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessLimitedArgs = {
  adminEmail?: InputMaybe<Scalars['String']>;
  adminTelephone?: InputMaybe<Scalars['String']>;
  administratorName?: InputMaybe<Scalars['String']>;
  businessBillingAddress?: InputMaybe<AddressInputType>;
  businessId: Scalars['ID'];
  commonName?: InputMaybe<Scalars['String']>;
  incorporationDate?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  tradeRegistrationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOrCreateBillingInfoArgs = {
  addressId?: InputMaybe<Scalars['Int']>;
  addressZipCode?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  legalName?: InputMaybe<Scalars['String']>;
  satUseType?: InputMaybe<SatInvoiceUseType>;
  taxNumber?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<SatRegime>;
};


export type MutationUpdateBusinessPaymentInformationArgs = {
  businessId: Scalars['ID'];
  payoutClabe?: InputMaybe<Scalars['String']>;
  subscriptionType: SubscriptionType;
};


export type MutationRetriggerBusinessChecksArgs = {
  businessId: Scalars['ID'];
};


export type MutationRequestBusinessDocumentUploadDataArgs = {
  documentCategory: DocumentCategory;
  id: Scalars['Int'];
  mimeType: Scalars['String'];
};


export type MutationApproveBusinessDocumentCheckArgs = {
  businessId: Scalars['ID'];
  documentCategory: DocumentCategory;
};


export type MutationToggleJustifiableAbsenceForBusinessDocumentCheckArgs = {
  businessCheckType: BusinessCheckType;
  businessId: Scalars['ID'];
};


export type MutationSetAbsenceJustificationForBusinessDocumentCheckArgs = {
  absenceJustification: Scalars['String'];
  businessCheckType: BusinessCheckType;
  businessId: Scalars['ID'];
};


export type MutationTransitionBusinessStateArgs = {
  businessId: Scalars['ID'];
};


export type MutationContractUpdateStateDateArgs = {
  businessId: Scalars['ID'];
  contractSentAt?: InputMaybe<Scalars['DateTime']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateBusinessStakeholderArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  businessControl?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  shareholder?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessStakeholderArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  businessControl?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  shareholder?: InputMaybe<Scalars['Boolean']>;
  stakeholderState?: InputMaybe<StakeHolderState>;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateLegalRepresentativeArgs = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthCountryCode?: InputMaybe<CountryCode>;
  curpIncludedInIdDoc?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extraRoleProofDocNeeded?: InputMaybe<Scalars['Boolean']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  nationalityCountryCode?: InputMaybe<CountryCode>;
  secondLastName?: InputMaybe<Scalars['String']>;
  stakeholderId: Scalars['ID'];
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationRetriggerBusinessStakeholderChecksArgs = {
  businessStakeholderId: Scalars['ID'];
};


export type MutationRequestBusinessStakeholderDocumentUploadDataArgs = {
  documentCategory: DocumentCategory;
  id: Scalars['Int'];
  mimeType: Scalars['String'];
};


export type MutationCreateBusinessEmployeeArgs = {
  beneficiaryCategory?: InputMaybe<BusinessBeneficiaryCategory>;
  businessGroupConfigId: Scalars['ID'];
  businessId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  employeeId?: InputMaybe<Scalars['ID']>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  invitationDate?: InputMaybe<Scalars['Date']>;
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateApplicationsForBusinessBeneficiaryArgs = {
  acceptAt?: InputMaybe<Scalars['DateTime']>;
  beneficiaryCategory?: InputMaybe<BusinessBeneficiaryCategory>;
  birthCountry?: InputMaybe<Scalars['String']>;
  businessBeneficiaryEmployeeId?: InputMaybe<Scalars['ID']>;
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  curp?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  familyMembersList?: InputMaybe<Array<BusinessBeneficiaryFamilyMemberInputType>>;
  firstLastName: Scalars['String'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  occupation: Scalars['String'];
  residenceAddress: AddressInputType;
  rfc: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateBusinessEmployeesArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  input: Array<CreateBusinessEmployeesInputType>;
};


export type MutationCreateCollectiveBusinessBeneficiariesArgs = {
  businessId: Scalars['ID'];
  collectiveBusinessBeneficiariesInput: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationCreateCollectiveBusinessBeneficiaryRelativesArgs = {
  input: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationSendBeneficiariesDataToOpsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  input: Array<CollectiveBusinessBeneficiariesInputType>;
};


export type MutationEditBusinessEmployeeArgs = {
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  invitationDate?: InputMaybe<Scalars['Date']>;
  memberId?: InputMaybe<Scalars['ID']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteBusinessEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationSendBusinessEmployeeWelcomeEmailArgs = {
  id: Scalars['ID'];
};


export type MutationSendBusinessAdminWelcomeEmailArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessPaymentMethodArgs = {
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  paymentGatewayFrontendSourceId?: InputMaybe<Scalars['String']>;
  paymentGatewayType: Scalars['String'];
  paymentMethodType: Scalars['String'];
};


export type MutationMakeBusinessPayinPaymentMethodPrimaryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBusinessPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessAdminArgs = {
  businessId: Scalars['ID'];
  email: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  role: BusinessAdminRole;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessAdminArgs = {
  id: Scalars['ID'];
  nickname?: InputMaybe<Scalars['String']>;
  role: BusinessAdminRole;
  telephone?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBusinessAdminArgs = {
  id: Scalars['ID'];
};


export type MutationMakeBusinessAdminOwnerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  default?: InputMaybe<Scalars['Boolean']>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBusinessGroupsConfigArgs = {
  allowMultimember?: InputMaybe<Scalars['Boolean']>;
  coinsuranceConfig?: InputMaybe<CoInsuranceConfigFactor>;
  dentalAddon?: InputMaybe<Scalars['Boolean']>;
  extendedHospitalsCoverage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insuredAmountCentsCoverage?: InputMaybe<InsuredAmountCents>;
  intlCoverageAddon?: InputMaybe<Scalars['Boolean']>;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type CreateMedicalHistoryForMember = {
  __typename?: 'CreateMedicalHistoryForMember';
  medicalHistory: MedicalHistoryType;
};

export type AllergyInputType = {
  allergen: Scalars['String'];
  reaction?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
};

export type FamilyPathologyInputType = {
  pathology: Scalars['String'];
  cieNotes?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  relative?: InputMaybe<Relative>;
};

export type HospitalizationInputType = {
  date: Scalars['Date'];
  reason?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type MalformationInputType = {
  name: Scalars['String'];
  treatment?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type MedicineInputType = {
  drugKey: Scalars['String'];
  ailment: Scalars['String'];
};

export type MentalHealthDiagnosisInputType = {
  diagnosis: Scalars['String'];
  treatment?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type NonPathologicalHistoryInputType = {
  lifestyleNotes?: InputMaybe<Scalars['String']>;
  sleepHours?: InputMaybe<Scalars['Int']>;
  sleepNotes?: InputMaybe<Scalars['String']>;
  nutritionNotes?: InputMaybe<Scalars['String']>;
  hasExercise?: InputMaybe<Scalars['Boolean']>;
  exerciseActivities?: InputMaybe<Scalars['String']>;
  exerciseWeeklyFrequency?: InputMaybe<Scalars['Int']>;
  exerciseNotes?: InputMaybe<Scalars['String']>;
  smokingConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  smokingCigarettes?: InputMaybe<Scalars['Int']>;
  smokingYears?: InputMaybe<Scalars['Int']>;
  smokingSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  smokingNotes?: InputMaybe<Scalars['String']>;
  alcoholConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  alcoholFrequency?: InputMaybe<Scalars['String']>;
  alcoholConsumptionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  alcoholNotes?: InputMaybe<Scalars['String']>;
  drugsConsumption?: InputMaybe<NonPathologicalHistoryHabit>;
  drugsType?: InputMaybe<Drug>;
  drugsName?: InputMaybe<Scalars['String']>;
  drugsSuspensionTime?: InputMaybe<NonPathologicalHistoryHabitYears>;
  drugsNotes?: InputMaybe<Scalars['String']>;
};

export type ObstetricGynecologicalHistoryInputType = {
  hasBirthControl?: InputMaybe<Scalars['Boolean']>;
  birthControlName?: InputMaybe<BirthControlName>;
  birthControlTimeUsed?: InputMaybe<Scalars['String']>;
  birthControlNotes?: InputMaybe<Scalars['String']>;
  hasMenstrualCycle?: InputMaybe<Scalars['Boolean']>;
  isMenstrualCycleRegular?: InputMaybe<Scalars['Boolean']>;
  hasDysmenorrhea?: InputMaybe<Scalars['Boolean']>;
  menstrualCycleNotes?: InputMaybe<Scalars['String']>;
  hasBeenPregnant?: InputMaybe<Scalars['Boolean']>;
  numPregnancies?: InputMaybe<Scalars['Int']>;
  numDeliveries?: InputMaybe<Scalars['Int']>;
  numAbortions?: InputMaybe<Scalars['Int']>;
  numStillBirths?: InputMaybe<Scalars['Int']>;
  numCesareans?: InputMaybe<Scalars['Int']>;
  numLiveBirths?: InputMaybe<Scalars['Int']>;
  numDeaths?: InputMaybe<Scalars['Int']>;
  gynecologicalExamsNotes?: InputMaybe<Scalars['String']>;
};

export enum BirthControlName {
  Intrauterine = 'INTRAUTERINE',
  Injectable = 'INJECTABLE',
  BarrierMethod = 'BARRIER_METHOD',
  NaturalMethod = 'NATURAL_METHOD',
  SurgicalMethod = 'SURGICAL_METHOD',
  Oral = 'ORAL',
  Subdermal = 'SUBDERMAL'
}

export type ParentPerinatalHistoryInputType = {
  motherPregnancies?: InputMaybe<Scalars['Int']>;
  motherAbortions?: InputMaybe<Scalars['Int']>;
  motherDeliveries?: InputMaybe<Scalars['Int']>;
  motherCaesareanSections?: InputMaybe<Scalars['Int']>;
  motherPregnancyAge?: InputMaybe<Scalars['Int']>;
  motherHeight?: InputMaybe<Scalars['Float']>;
  fatherHeight?: InputMaybe<Scalars['Float']>;
  expectedHeight?: InputMaybe<Scalars['Int']>;
  pregnancyEvolution?: InputMaybe<PregnancyEvolutionType>;
  obstetrician?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<DeliveryType>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum PregnancyEvolutionType {
  Normal = 'NORMAL',
  Abnormal = 'ABNORMAL',
  HighRisk = 'HIGH_RISK'
}

export enum DeliveryType {
  Spontaneous = 'SPONTANEOUS',
  Conducted = 'CONDUCTED',
  Instrumented = 'INSTRUMENTED',
  IntrapartumCaesarean = 'INTRAPARTUM_CAESAREAN',
  ElectiveCaesarean = 'ELECTIVE_CAESAREAN'
}

export type PathologyInputType = {
  cieKey?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  diagnosisDate: Scalars['Date'];
  treatment?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  cieNotes?: InputMaybe<Scalars['String']>;
};

export type PerinatalHistoryInputType = {
  gestationalAgeWeeks?: InputMaybe<Scalars['Int']>;
  gestationalAgeDays?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Float']>;
  headCircumference?: InputMaybe<Scalars['Float']>;
  gestationalAgeClassification?: InputMaybe<GestationalAgeClassification>;
  gestationalWeightClassification?: InputMaybe<GestationalWeightClassification>;
  apgar1MinScore?: InputMaybe<Scalars['Int']>;
  apgar5MinScore?: InputMaybe<Scalars['Int']>;
  reanimationRequired?: InputMaybe<Scalars['Boolean']>;
  incubationRequired?: InputMaybe<Scalars['Boolean']>;
  other?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum GestationalAgeClassification {
  Rnpr = 'RNPR',
  Rnt = 'RNT',
  Rnpt = 'RNPT'
}

export enum GestationalWeightClassification {
  Peg = 'PEG',
  Aeg = 'AEG',
  Geg = 'GEG'
}

export type SurgeriesInputType = {
  name: Scalars['String'];
  cptKey?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  cptNotes?: InputMaybe<Scalars['String']>;
};

export type VaccineInputType = {
  name: Scalars['String'];
  date: Scalars['Date'];
  notes?: InputMaybe<Scalars['String']>;
};

export type UpdateMedicalHistoryForMember = {
  __typename?: 'UpdateMedicalHistoryForMember';
  medicalHistory: MedicalHistoryType;
};

export type AddMedicalHistoryAllergy = {
  __typename?: 'AddMedicalHistoryAllergy';
  allergy: AllergyType;
};

export type UpdateMedicalHistoryAllergy = {
  __typename?: 'UpdateMedicalHistoryAllergy';
  allergy: AllergyType;
};

export type DeleteMedicalHistoryAllergy = {
  __typename?: 'DeleteMedicalHistoryAllergy';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UploadMedicalHistoryExamDocument = {
  __typename?: 'UploadMedicalHistoryExamDocument';
  uploadData: UploadDataType;
};

export type UploadDataType = {
  __typename?: 'UploadDataType';
  url?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSONString']>;
};

export type UploadExamDocumentToMedicalNote = {
  __typename?: 'UploadExamDocumentToMedicalNote';
  uploadData: UploadDataType;
};

export type AssignMedicalHistoryExamToMedicalNote = {
  __typename?: 'AssignMedicalHistoryExamToMedicalNote';
  exam: ExamType;
};

export type DeleteMedicalHistoryExam = {
  __typename?: 'DeleteMedicalHistoryExam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMedicalHistoryExam = {
  __typename?: 'UpdateMedicalHistoryExam';
  exam: ExamType;
};

export type AddFamilyPathology = {
  __typename?: 'AddFamilyPathology';
  familyPathology: FamilyPathologyType;
};

export type UpdateFamilyPathology = {
  __typename?: 'UpdateFamilyPathology';
  familyPathology: FamilyPathologyType;
};

export type DeleteFamilyPathology = {
  __typename?: 'DeleteFamilyPathology';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddHospitalization = {
  __typename?: 'AddHospitalization';
  hospitalization: HospitalizationType;
};

export type UpdateHospitalization = {
  __typename?: 'UpdateHospitalization';
  hospitalization: HospitalizationType;
};

export type DeleteHospitalization = {
  __typename?: 'DeleteHospitalization';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddMalformation = {
  __typename?: 'AddMalformation';
  malformation: MalformationType;
};

export type UpdateMalformation = {
  __typename?: 'UpdateMalformation';
  malformation: MalformationType;
};

export type DeleteMalformation = {
  __typename?: 'DeleteMalformation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddMedicalHistoryMedicine = {
  __typename?: 'AddMedicalHistoryMedicine';
  medicine: MedicineType;
};

export type UpdateMedicalHistoryMedicine = {
  __typename?: 'UpdateMedicalHistoryMedicine';
  medicine: MedicineType;
};

export type DeleteMedicalHistoryMedicine = {
  __typename?: 'DeleteMedicalHistoryMedicine';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateNonPathologicalHistory = {
  __typename?: 'UpdateNonPathologicalHistory';
  nonPathologicalHistory: NonPathologicalHistoryType;
};

export type UpdateObstetricGynecologicalHistory = {
  __typename?: 'UpdateObstetricGynecologicalHistory';
  obstetricGynecologicalHistory: ObstetricGynecologicalHistoryType;
};

export type AddPregnancyDelivery = {
  __typename?: 'AddPregnancyDelivery';
  pregnancyDelivery: PregnancyDeliveryType;
};

export type UpdatePregnancyDelivery = {
  __typename?: 'UpdatePregnancyDelivery';
  pregnancyDelivery: PregnancyDeliveryType;
};

export type DeletePregnancyDelivery = {
  __typename?: 'DeletePregnancyDelivery';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddGynecologicalExam = {
  __typename?: 'AddGynecologicalExam';
  gynecologicalExam: GynecologicalExamType;
};

export type UpdateGynecologicalExam = {
  __typename?: 'UpdateGynecologicalExam';
  gynecologicalExam: GynecologicalExamType;
};

export type DeleteGynecologicalExam = {
  __typename?: 'DeleteGynecologicalExam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddPathology = {
  __typename?: 'AddPathology';
  pathology: PathologyType;
};

export type UpdatePathology = {
  __typename?: 'UpdatePathology';
  pathology: PathologyType;
};

export type DeletePathology = {
  __typename?: 'DeletePathology';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddPregnancy = {
  __typename?: 'AddPregnancy';
  pregnancy: PregnancyType;
};

export type UpdatePregnancy = {
  __typename?: 'UpdatePregnancy';
  pregnancy: PregnancyType;
};

export type DeletePregnancy = {
  __typename?: 'DeletePregnancy';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddSurgery = {
  __typename?: 'AddSurgery';
  surgery: SurgeryType;
};

export type UpdateSurgery = {
  __typename?: 'UpdateSurgery';
  surgery: SurgeryType;
};

export type DeleteSurgery = {
  __typename?: 'DeleteSurgery';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddVaccine = {
  __typename?: 'AddVaccine';
  vaccine: VaccineType;
};

export type UpdateVaccine = {
  __typename?: 'UpdateVaccine';
  vaccine: VaccineType;
};

export type DeleteVaccine = {
  __typename?: 'DeleteVaccine';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateMedicalHistoryBasicInfo = {
  __typename?: 'UpdateMedicalHistoryBasicInfo';
  basicInfo: BasicInfoType;
};

export type AddMentalHealthDiagnosis = {
  __typename?: 'AddMentalHealthDiagnosis';
  mentalHealthDiagnosis: MentalHealthDiagnosisType;
};

export type UpdateMentalHealthDiagnosis = {
  __typename?: 'UpdateMentalHealthDiagnosis';
  mentalHealthDiagnosis: MentalHealthDiagnosisType;
};

export type DeleteMentalHealthDiagnosis = {
  __typename?: 'DeleteMentalHealthDiagnosis';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePerinatalHistory = {
  __typename?: 'UpdatePerinatalHistory';
  perinatalHistory: PerinatalHistoryType;
};

export type UpdateParentPerinatalHistory = {
  __typename?: 'UpdateParentPerinatalHistory';
  parentPerinatalHistory: ParentPerinatalHistoryType;
};

export type AddGeneralObservation = {
  __typename?: 'AddGeneralObservation';
  generalObservation: GeneralObservationType;
};

export type UpdateGeneralObservation = {
  __typename?: 'UpdateGeneralObservation';
  generalObservation: GeneralObservationType;
};

export type DeleteGeneralObservation = {
  __typename?: 'DeleteGeneralObservation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateNewMedicalNote = {
  __typename?: 'CreateNewMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CreateConsultMedicalNote = {
  __typename?: 'CreateConsultMedicalNote';
  medicalNote: MedicalNoteType;
};

export type UpdateMedicalNote = {
  __typename?: 'UpdateMedicalNote';
  medicalNote: MedicalNoteType;
};

export type DeleteMedicalNote = {
  __typename?: 'DeleteMedicalNote';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CancelMedicalNote = {
  __typename?: 'CancelMedicalNote';
  medicalNote: MedicalNoteType;
};

export type CompleteMedicalNote = {
  __typename?: 'CompleteMedicalNote';
  medicalNote: MedicalNoteType;
};

export type PrescriptionItemInputType = {
  group: PrescriptionItemGroup;
  prescriptionItemDetails?: InputMaybe<PrescriptionItemDetailsInputType>;
  description: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  checked?: InputMaybe<Scalars['String']>;
};

export type PrescriptionItemDetailsInputType = {
  time?: InputMaybe<Scalars['String']>;
  dose?: InputMaybe<Scalars['String']>;
  presentation?: InputMaybe<Scalars['String']>;
  administration?: InputMaybe<Scalars['String']>;
  preparation?: InputMaybe<Scalars['String']>;
};

export type AddPrescriptionToMedicalNote = {
  __typename?: 'AddPrescriptionToMedicalNote';
  prescription: PrescriptionType;
};

export type ReclassifyMedicalNote = {
  __typename?: 'ReclassifyMedicalNote';
  medicalNote: MedicalNoteType;
};

export type UpdateEvaluation = {
  __typename?: 'UpdateEvaluation';
  evaluation: EvaluationType;
};

export type AddInterrogation = {
  __typename?: 'AddInterrogation';
  interrogation: InterrogationType;
};

export type UpdateInterrogation = {
  __typename?: 'UpdateInterrogation';
  interrogation: InterrogationType;
};

export type DeleteInterrogation = {
  __typename?: 'DeleteInterrogation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddExploration = {
  __typename?: 'AddExploration';
  exploration: ExplorationType;
};

export type UpdateExploration = {
  __typename?: 'UpdateExploration';
  exploration: ExplorationType;
};

export type DeleteExploration = {
  __typename?: 'DeleteExploration';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddProcedure = {
  __typename?: 'AddProcedure';
  procedure: ProcedureType;
};

export type UpdateProcedure = {
  __typename?: 'UpdateProcedure';
  procedure: ProcedureType;
};

export type UploadProcedureDocument = {
  __typename?: 'UploadProcedureDocument';
  uploadData: UploadDataType;
};

export type DeleteProcedure = {
  __typename?: 'DeleteProcedure';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddAddendum = {
  __typename?: 'AddAddendum';
  addendum: AddendumType;
};

export type UpdateAddendum = {
  __typename?: 'UpdateAddendum';
  addendum: AddendumType;
};

export type UploadAddendumDocument = {
  __typename?: 'UploadAddendumDocument';
  uploadData: UploadDataType;
};

export type DeleteAddendum = {
  __typename?: 'DeleteAddendum';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdatePhysicalExam = {
  __typename?: 'UpdatePhysicalExam';
  physicalExam: PhysicalExamType;
};

export type UpdateNoteBasicInfo = {
  __typename?: 'UpdateNoteBasicInfo';
  noteBasicInfo: NoteBasicInfoType;
};

export type UpdateDischargeInfo = {
  __typename?: 'UpdateDischargeInfo';
  dischargeInfo: DischargeInfoType;
};

export type UpdatePhysicalActivity = {
  __typename?: 'UpdatePhysicalActivity';
  physicalActivity: PhysicalActivityType;
};

export type UpdateObstetricGynecologicalInfo = {
  __typename?: 'UpdateObstetricGynecologicalInfo';
  obstetricGynecologicalInfo: ObstetricGynecologicalInfoType;
};

export type InitUserHybridSubscriptionContext = {
  __typename?: 'InitUserHybridSubscriptionContext';
  user: UserType;
};

export type AddressInputType = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  references?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  huliClinicId?: InputMaybe<Scalars['Int']>;
  temporaryVirtualOffice?: InputMaybe<Scalars['Boolean']>;
};

export type EducationAnswersInputType = {
  insuranceExperience: Scalars['String'];
  insuranceKnowledge: Scalars['String'];
  sofiaKnowledge: Scalars['String'];
};

export type SetUpUserHealthPlanApplication = {
  __typename?: 'SetUpUserHealthPlanApplication';
  user: UserType;
};

export enum SubscriptionState {
  AccountType = 'ACCOUNT_TYPE',
  MemberList = 'MEMBER_LIST',
  PriceMembership = 'PRICE_MEMBERSHIP',
  Documents = 'DOCUMENTS',
  Payment = 'PAYMENT',
  Approval = 'APPROVAL',
  Knowledge = 'KNOWLEDGE',
  Education = 'EDUCATION',
  PersonalQuestionnaire = 'PERSONAL_QUESTIONNAIRE',
  HealthQuestionnaire = 'HEALTH_QUESTIONNAIRE',
  CovidQuestionnaire = 'COVID_QUESTIONNAIRE'
}

export type CheckBusinessAccount = {
  __typename?: 'CheckBusinessAccount';
  businessScheme: BusinessScheme;
};

export enum BusinessScheme {
  Collective = 'COLLECTIVE',
  Bundle = 'BUNDLE'
}

export type CreateHealthPlanApplication = {
  __typename?: 'CreateHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplication = {
  __typename?: 'UpdateHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplicationAnswers = {
  __typename?: 'UpdateHealthPlanApplicationAnswers';
  healthPlanApplication: HealthPlanApplicationType;
};

export type UpdateHealthPlanApplicationIncentiveCode = {
  __typename?: 'UpdateHealthPlanApplicationIncentiveCode';
  healthPlanApplication: HealthPlanApplicationType;
};

export type DeleteHealthPlanApplicationIncentiveCode = {
  __typename?: 'DeleteHealthPlanApplicationIncentiveCode';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SubmitHealthPlanApplication = {
  __typename?: 'SubmitHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SubmitHealthPlanApplicationWithToken = {
  __typename?: 'SubmitHealthPlanApplicationWithToken';
  healthPlanApplication: HealthPlanApplicationType;
};

export type AcceptHealthPlanApplication = {
  __typename?: 'AcceptHealthPlanApplication';
  ok: Scalars['Boolean'];
  healthPlans?: Maybe<Array<Maybe<HealthPlanType>>>;
};

export type AttemptPreApprovedSubscriptionByUser = {
  __typename?: 'AttemptPreApprovedSubscriptionByUser';
  ok: Scalars['Boolean'];
};

export type CloseHealthPlanApplication = {
  __typename?: 'CloseHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SetWaitingHealthPlanApplication = {
  __typename?: 'SetWaitingHealthPlanApplication';
  healthPlanApplication: HealthPlanApplicationType;
};

export type NotifyHealthPlanApplicationState = {
  __typename?: 'NotifyHealthPlanApplicationState';
  ok: Scalars['Boolean'];
};

export type SendHealthPlanAcceptDocuments = {
  __typename?: 'SendHealthPlanAcceptDocuments';
  ok: Scalars['Boolean'];
};

export type ResendCollectiveWelcomeEmail = {
  __typename?: 'ResendCollectiveWelcomeEmail';
  ok: Scalars['Boolean'];
};

export type UpdateHealthPlanApplicationItem = {
  __typename?: 'UpdateHealthPlanApplicationItem';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export enum Relationship {
  Wife = 'WIFE',
  Husband = 'HUSBAND',
  Daughter = 'DAUGHTER',
  Self = 'SELF',
  Son = 'SON',
  Father = 'FATHER',
  Mother = 'MOTHER',
  Grandparent = 'GRANDPARENT',
  Sibling = 'SIBLING',
  Grandchild = 'GRANDCHILD',
  Nephew = 'NEPHEW'
}

export type ForceHealthPlanApplicationItemCheck = {
  __typename?: 'ForceHealthPlanApplicationItemCheck';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export type CreateHealthPlanApplicationItem = {
  __typename?: 'CreateHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CreateHealthPlanApplicationItemV2 = {
  __typename?: 'CreateHealthPlanApplicationItemV2';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type DuplicateHealthPlanApplicationItem = {
  __typename?: 'DuplicateHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CancelHealthPlanApplicationItem = {
  __typename?: 'CancelHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type DeleteHealthPlanApplicationItem = {
  __typename?: 'DeleteHealthPlanApplicationItem';
  ok: Scalars['Boolean'];
};

export type UploadPhotoHealthPlanApplicationItem = {
  __typename?: 'UploadPhotoHealthPlanApplicationItem';
  uploadData: UploadDataType;
};

export type UploadHealthPlanApplicationItemDocument = {
  __typename?: 'UploadHealthPlanApplicationItemDocument';
  uploadData: UploadDataType;
};

export type UploadSignatureForWeb = {
  __typename?: 'UploadSignatureForWeb';
  uploadData: UploadDataType;
};

export type CommentOnHealthPlanApplicationItem = {
  __typename?: 'CommentOnHealthPlanApplicationItem';
  comment?: Maybe<HealthPlanApplicationItemCommentType>;
};

export type SetWaitingHealthPlanApplicationItem = {
  __typename?: 'SetWaitingHealthPlanApplicationItem';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CreateHealthPlanQuote = {
  __typename?: 'CreateHealthPlanQuote';
  healthPlanQuote: HealthPlanQuoteType;
};

export type UpdateHealthPlanQuote = {
  __typename?: 'UpdateHealthPlanQuote';
  healthPlanQuote: HealthPlanQuoteType;
};

export type UpdateHealthPlanApplicationItemAnswers = {
  __typename?: 'UpdateHealthPlanApplicationItemAnswers';
  healthPlanApplicationItem?: Maybe<HealthPlanApplicationItemType>;
};

export type SetSubscriptionCheckState = {
  __typename?: 'SetSubscriptionCheckState';
  check: HealthPlanApplicationItemCheckType;
};

export type RetriggerAutoSubscriptionChecks = {
  __typename?: 'RetriggerAutoSubscriptionChecks';
  ok: Scalars['Boolean'];
};

export type CheckProductParameters = {
  __typename?: 'CheckProductParameters';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type GetInstallmentsAvailableForPaymentMethod = {
  __typename?: 'GetInstallmentsAvailableForPaymentMethod';
  cardOptions?: Maybe<Scalars['JSONString']>;
};

export type UpdateHealthplanApplicationInterestFreePlan = {
  __typename?: 'UpdateHealthplanApplicationInterestFreePlan';
  healthPlanApplication: HealthPlanApplicationType;
};

export type SendReminder = {
  __typename?: 'SendReminder';
  ok: Scalars['Boolean'];
};

export type SetBusinessAsHealthPlanApplicationOwner = {
  __typename?: 'SetBusinessAsHealthPlanApplicationOwner';
  ok: Scalars['Boolean'];
};

export type SendSubscriptionPriceEstimationEmail = {
  __typename?: 'SendSubscriptionPriceEstimationEmail';
  ok: Scalars['Boolean'];
};

export type GenerateSubscriptionPriceEstimationDocument = {
  __typename?: 'GenerateSubscriptionPriceEstimationDocument';
  url: Scalars['String'];
};

export type UpdateHealthPlanQuoteSubscriptionType = {
  __typename?: 'UpdateHealthPlanQuoteSubscriptionType';
  healthPlanQuote: HealthPlanQuoteType;
};

export type StartMiniSubscription = {
  __typename?: 'StartMiniSubscription';
  user: UserType;
};

export type UpdateMedicalRecommendation = {
  __typename?: 'UpdateMedicalRecommendation';
  healthPlanApplicationItem: HealthPlanApplicationItemType;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  user: UserType;
};

export type CreateInternalUser = {
  __typename?: 'CreateInternalUser';
  user: UserType;
};

export type InternalUserMemberInputType = {
  firstName?: InputMaybe<Scalars['String']>;
  firstLastName?: InputMaybe<Scalars['String']>;
  secondLastName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderType>;
};

export type UpdateInternalUser = {
  __typename?: 'UpdateInternalUser';
  user: UserType;
};

export type CreateUserForMember = {
  __typename?: 'CreateUserForMember';
  user: UserType;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  user: UserType;
};

export type SetUserGroups = {
  __typename?: 'SetUserGroups';
  user: UserType;
};

export type RequestUserEmailUpdate = {
  __typename?: 'RequestUserEmailUpdate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConfirmUserEmailUpdate = {
  __typename?: 'ConfirmUserEmailUpdate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RequestUserPasswordUpdate = {
  __typename?: 'RequestUserPasswordUpdate';
  token?: Maybe<Scalars['String']>;
};

export type UploadPhotoMember = {
  __typename?: 'UploadPhotoMember';
  uploadData: UploadDataType;
};

export type ForgetPasswordUser = {
  __typename?: 'ForgetPasswordUser';
  ok: Scalars['Boolean'];
};

export type ResetPasswordUser = {
  __typename?: 'ResetPasswordUser';
  ok: Scalars['Boolean'];
};

export enum ActionTypeEnum {
  Create = 'CREATE',
  ConfirmAccess = 'CONFIRM_ACCESS'
}

export type LogoutUserFromAllSessions = {
  __typename?: 'LogoutUserFromAllSessions';
  ok: Scalars['Boolean'];
};

export type CreateMember = {
  __typename?: 'CreateMember';
  member: MemberType;
};

export type UpdateMember = {
  __typename?: 'UpdateMember';
  member: MemberType;
};

export type RequestUpdateMember = {
  __typename?: 'RequestUpdateMember';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateStripeSource = {
  __typename?: 'CreateStripeSource';
  stripeSource: StripeSourceType;
};

export type SetPrimaryStripeSource = {
  __typename?: 'SetPrimaryStripeSource';
  ok: Scalars['Boolean'];
};

export type DeleteStripeSource = {
  __typename?: 'DeleteStripeSource';
  ok: Scalars['Boolean'];
};

export type DeleteMember = {
  __typename?: 'DeleteMember';
  ok: Scalars['Boolean'];
};

export type AddPushTokenToUser = {
  __typename?: 'AddPushTokenToUser';
  ok: Scalars['Boolean'];
};

export type DeletePushTokenFromUser = {
  __typename?: 'DeletePushTokenFromUser';
  ok: Scalars['Boolean'];
};

export type SendTelephoneConfirm = {
  __typename?: 'SendTelephoneConfirm';
  ok: Scalars['Boolean'];
};

export type ConfirmTelephone = {
  __typename?: 'ConfirmTelephone';
  ok: Scalars['Boolean'];
};

export type PhoneTokenAuth = {
  __typename?: 'PhoneTokenAuth';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type UpdateUserTelephone = {
  __typename?: 'UpdateUserTelephone';
  ok: Scalars['Boolean'];
};

export type GrantRepresentedUserAccess = {
  __typename?: 'GrantRepresentedUserAccess';
  user: UserType;
};

export type ReplaceDocument = {
  __typename?: 'ReplaceDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadMemberDocument = {
  __typename?: 'UploadMemberDocument';
  uploadData: UploadDataType;
};

export type UploadAddressDocument = {
  __typename?: 'UploadAddressDocument';
  uploadData?: Maybe<UploadDataType>;
};

export type UploadClaimsInvoiceDocument = {
  __typename?: 'UploadClaimsInvoiceDocument';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type UploadAmendmentAttachmentDocument = {
  __typename?: 'UploadAmendmentAttachmentDocument';
  uploadData: UploadDataType;
};

export type CreateAuthorizationToken = {
  __typename?: 'CreateAuthorizationToken';
  token: Scalars['String'];
};

export enum AuthorizationTokenCategory {
  Digits = 'DIGITS',
  Token = 'TOKEN',
  LongDigits = 'LONG_DIGITS'
}

export enum AuthorizationTokenChannel {
  Sms = 'SMS',
  Email = 'EMAIL',
  Response = 'RESPONSE',
  Both = 'BOTH'
}

export type ConsumeAuthorizationToken = {
  __typename?: 'ConsumeAuthorizationToken';
  ok: Scalars['Boolean'];
};

export type ResetCollectiveUserCredentials = {
  __typename?: 'ResetCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type ResetB2BAdminUserCredentials = {
  __typename?: 'ResetB2BAdminUserCredentials';
  ok: Scalars['Boolean'];
};

export type RequestForgotDoctorPassword = {
  __typename?: 'RequestForgotDoctorPassword';
  ok: Scalars['Boolean'];
};

export type SetCollectiveUserCredentials = {
  __typename?: 'SetCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type ConfirmCollectiveUserCredentials = {
  __typename?: 'ConfirmCollectiveUserCredentials';
  ok: Scalars['Boolean'];
};

export type SetSubscriptionState = {
  __typename?: 'SetSubscriptionState';
  ok: Scalars['Boolean'];
};

export type ToggleAutomaticRenew = {
  __typename?: 'ToggleAutomaticRenew';
  healthPlan: HealthPlanType;
};

export type UpdateFeatureFlagValue = {
  __typename?: 'UpdateFeatureFlagValue';
  ok: Scalars['Boolean'];
};

export enum FeatureFlags {
  WebVc = 'WEB_VC',
  SummaryUpFront = 'SUMMARY_UP_FRONT',
  InterestFreePayments = 'INTEREST_FREE_PAYMENTS',
  ReferralCode = 'REFERRAL_CODE'
}

export type UserIntercomTag = {
  __typename?: 'UserIntercomTag';
  ok: Scalars['Boolean'];
};

export type RunCommand = {
  __typename?: 'RunCommand';
  ok: Scalars['Boolean'];
};

export type UpdateHealthPlanApplicationItemMemberData = {
  __typename?: 'UpdateHealthPlanApplicationItemMemberData';
  member: MemberType;
};

export type ImpersonateUser = {
  __typename?: 'ImpersonateUser';
  token: Scalars['String'];
  payload: Scalars['JSONString'];
};

export type LogoutFromImpersonation = {
  __typename?: 'LogoutFromImpersonation';
  ok: Scalars['Boolean'];
};

export type MagicLinkAuth = {
  __typename?: 'MagicLinkAuth';
  token: Scalars['String'];
};

export type OtpTokenAuth = {
  __typename?: 'OtpTokenAuth';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type FreeUpUserTelephoneOrEmail = {
  __typename?: 'FreeUpUserTelephoneOrEmail';
  user: UserType;
};

export type SendReferralInvitationCode = {
  __typename?: 'SendReferralInvitationCode';
  sent?: Maybe<Scalars['Boolean']>;
};

export type ProvidersToken = {
  __typename?: 'ProvidersToken';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type CreateDoctorFriend = {
  __typename?: 'CreateDoctorFriend';
  doctorFriend: DoctorFriendType;
  validationWarning?: Maybe<Scalars['String']>;
};

export type UpdateDoctorFriend = {
  __typename?: 'UpdateDoctorFriend';
  doctorFriend: DoctorFriendType;
  validationWarning?: Maybe<Scalars['String']>;
};

export type CreateProviderCategory = {
  __typename?: 'CreateProviderCategory';
  providerCategory: ProviderCategoryType;
};

export type UpdateProviderCategory = {
  __typename?: 'UpdateProviderCategory';
  providerCategory: ProviderCategoryType;
};

export type DeleteProviderCategory = {
  __typename?: 'DeleteProviderCategory';
  ok: Scalars['Boolean'];
};

export type CreateProvider = {
  __typename?: 'CreateProvider';
  provider: ProvidersType;
};

export type UpdateProvider = {
  __typename?: 'UpdateProvider';
  provider: ProvidersType;
};

export type DeleteProvider = {
  __typename?: 'DeleteProvider';
  ok: Scalars['Boolean'];
};

export type CreateProviderBranchOffice = {
  __typename?: 'CreateProviderBranchOffice';
  providerBranchOffice: ProviderBranchOfficeType;
};

export type CreateUserInputType = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInputType>;
  telephoneInput?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  appStage?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
};

export type BillingInfoInputType = {
  legalName: Scalars['String'];
  taxNumber: Scalars['String'];
  address?: InputMaybe<AddressInputType>;
};

export type UpdateProviderBranchOffice = {
  __typename?: 'UpdateProviderBranchOffice';
  providerBranchOffice: ProviderBranchOfficeType;
};

export type UpdateUserInputType = {
  token?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
  unverifiedEmail?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<AddressInputType>;
  nickname?: InputMaybe<Scalars['String']>;
  welcomed?: InputMaybe<Scalars['Boolean']>;
};

export type DeleteProviderBranchOffice = {
  __typename?: 'DeleteProviderBranchOffice';
  ok: Scalars['Boolean'];
};

export type CreateDoctorSpecialistDetail = {
  __typename?: 'CreateDoctorSpecialistDetail';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type UpdateDoctorSpecialistDetail = {
  __typename?: 'UpdateDoctorSpecialistDetail';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type DeleteDoctorSpecialistDetail = {
  __typename?: 'DeleteDoctorSpecialistDetail';
  ok: Scalars['Boolean'];
};

export type UploadDoctorSpecialistDetailDocument = {
  __typename?: 'UploadDoctorSpecialistDetailDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export enum DoctorSpecialistDetailDocuments {
  DoctorSpecializationCertificate = 'DOCTOR_SPECIALIZATION_CERTIFICATE'
}

export type AddDoctorSpecializationCertificateValidity = {
  __typename?: 'AddDoctorSpecializationCertificateValidity';
  doctorSpecialistDetail: DoctorSpecialistDetailType;
};

export type CreateDoctor = {
  __typename?: 'CreateDoctor';
  doctor: DoctorType;
};

export type DoctorBillingInfoInputType = {
  taxNumber?: InputMaybe<Scalars['String']>;
  satProductServiceKey: Scalars['String'];
  taxRegime?: InputMaybe<SatRegime>;
};

export type DoctorSpecialistDetailInputType = {
  specialistNumber: Scalars['String'];
  specialistInstitution: Scalars['String'];
  specialization: MedicalSpecialtyType;
  certificateId?: InputMaybe<Scalars['ID']>;
  certificateValidUntil?: InputMaybe<Scalars['Date']>;
  available?: InputMaybe<Scalars['Boolean']>;
};

export type DoctorPaymentMethodInputType = {
  clabe: Scalars['String'];
  bank: Scalars['Int'];
};

export type UpdateDoctor = {
  __typename?: 'UpdateDoctor';
  doctor: DoctorType;
};

export type UpdateDoctorById = {
  __typename?: 'UpdateDoctorById';
  doctor: DoctorType;
};

export type UploadDoctorSignatureImage = {
  __typename?: 'UploadDoctorSignatureImage';
  uploadData: UploadDataType;
};

export type UploadDoctorDocument = {
  __typename?: 'UploadDoctorDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export enum DoctorDocuments {
  DoctorCurriculumVitae = 'DOCTOR_CURRICULUM_VITAE',
  DoctorLiabilityInsurance = 'DOCTOR_LIABILITY_INSURANCE',
  DoctorPhoto = 'DOCTOR_PHOTO'
}

export type CreateSpecializationFare = {
  __typename?: 'CreateSpecializationFare';
  specializationFare: SpecializationFareType;
};

export type UpdateSpecializationFare = {
  __typename?: 'UpdateSpecializationFare';
  specializationFare: SpecializationFareType;
};

export type SpecializationFareInputType = {
  name?: InputMaybe<Scalars['String']>;
  specialization: MedicalSpecialtyType;
  amountCents?: InputMaybe<Scalars['Int']>;
};

export type CreateDoctorReminder = {
  __typename?: 'CreateDoctorReminder';
  doctorReminder: DoctorReminderType;
};

export type UpdateDoctorReminder = {
  __typename?: 'UpdateDoctorReminder';
  doctorReminder: DoctorReminderType;
};

export type ScheduleAppointmentAttemptNotification = {
  __typename?: 'ScheduleAppointmentAttemptNotification';
  ok: Scalars['Boolean'];
};

export type ImportDoctorDraftFromAirtable = {
  __typename?: 'ImportDoctorDraftFromAirtable';
  ok: Scalars['Boolean'];
};

export type CreatePharmacyOrder = {
  __typename?: 'CreatePharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type UpdatePharmacyOrder = {
  __typename?: 'UpdatePharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type PharmacyItemInput = {
  subtotal: Scalars['Int'];
  vat: Scalars['Int'];
  amount: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
};

export type CancelPharmacyOrder = {
  __typename?: 'CancelPharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type ConfirmCoinsuredPharmacyOrder = {
  __typename?: 'ConfirmCoinsuredPharmacyOrder';
  pharmacyOrder: PharmacyOrderType;
};

export type UploadPharmacyInvoiceDocument = {
  __typename?: 'UploadPharmacyInvoiceDocument';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type SendPharmacyOrderEmail = {
  __typename?: 'SendPharmacyOrderEmail';
  emailSent: Scalars['Boolean'];
};

export type CreateGroup = {
  __typename?: 'CreateGroup';
  group: GroupType;
};

export type UpdateGroup = {
  __typename?: 'UpdateGroup';
  group: GroupType;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreatePaymentMethod = {
  __typename?: 'CreatePaymentMethod';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakePaymentMethodPrimary = {
  __typename?: 'MakePaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type DeletePaymentMethod = {
  __typename?: 'DeletePaymentMethod';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MakePayoutPaymentMethodPrimary = {
  __typename?: 'MakePayoutPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakePayinPaymentMethodPrimary = {
  __typename?: 'MakePayinPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreateBillingInfo = {
  __typename?: 'CreateBillingInfo';
  billingInfo: BillingInfoType;
};

export type UpdateBillingInfo = {
  __typename?: 'UpdateBillingInfo';
  billingInfo: BillingInfoType;
};

export type CreatePaymentMethodForUser = {
  __typename?: 'CreatePaymentMethodForUser';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreateManualPaymentMethodForUser = {
  __typename?: 'CreateManualPaymentMethodForUser';
  ok?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type EditManualPaymentGatewaySource = {
  __typename?: 'EditManualPaymentGatewaySource';
  ok?: Maybe<Scalars['Boolean']>;
  paymentGatewaySource?: Maybe<PaymentGatewaySourcesType>;
};

export type AddSpeiPaymentMethodForUser = {
  __typename?: 'AddSpeiPaymentMethodForUser';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type CreatePaymentMethodWithMultipleSources = {
  __typename?: 'CreatePaymentMethodWithMultipleSources';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type PaymentGatewaySourceInputType = {
  sourceId: Scalars['String'];
  paymentGatewayType: PaymentGatewayType;
  paymentGatewaySourceReference?: InputMaybe<Scalars['JSONString']>;
};

export type RequestStripeSetupIntent = {
  __typename?: 'RequestStripeSetupIntent';
  setupIntent?: Maybe<Scalars['JSONString']>;
};

export type CreateSetupIntents = {
  __typename?: 'CreateSetupIntents';
  setupIntents?: Maybe<Scalars['JSONString']>;
};

export type CreateInvoiceDispute = {
  __typename?: 'CreateInvoiceDispute';
  paymentDispute?: Maybe<PaymentDisputeType>;
};

export type RuleInvoiceDispute = {
  __typename?: 'RuleInvoiceDispute';
  paymentDispute?: Maybe<PaymentDisputeType>;
};

export type FetchTransactionCep = {
  __typename?: 'FetchTransactionCep';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserBillingInfo = {
  __typename?: 'UpdateUserBillingInfo';
  billingInfo: BillingInfoType;
};

export type CreateLocation = {
  __typename?: 'CreateLocation';
  location: LocationType;
};

export type UpdateLocation = {
  __typename?: 'UpdateLocation';
  location: LocationType;
};

export type UpdateInBatchLocation = {
  __typename?: 'UpdateInBatchLocation';
  locations: Array<Maybe<LocationType>>;
};

export type RetryChargeInvoice = {
  __typename?: 'RetryChargeInvoice';
  ok: Scalars['Boolean'];
};

export type RetryElectronicVoucherGeneration = {
  __typename?: 'RetryElectronicVoucherGeneration';
  ok: Scalars['Boolean'];
};

export type ConfirmManualInvoicePayment = {
  __typename?: 'ConfirmManualInvoicePayment';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RefundInvoice = {
  __typename?: 'RefundInvoice';
  ok: Scalars['Boolean'];
};

export type RequestElectronicVoucherDocumentData = {
  __typename?: 'RequestElectronicVoucherDocumentData';
  uploadData: UploadDataType;
  id?: Maybe<Scalars['Int']>;
};

export type ValidateElectronicVoucherXml = {
  __typename?: 'ValidateElectronicVoucherXml';
  result?: Maybe<Scalars['JSONString']>;
};

export type ValidateDraftElectronicVoucherXml = {
  __typename?: 'ValidateDraftElectronicVoucherXml';
  electronicVoucher: ElectronicVoucherType;
};

export type ReplaceInvoiceDocument = {
  __typename?: 'ReplaceInvoiceDocument';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadElectronicVoucher = {
  __typename?: 'UploadElectronicVoucher';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type UploadDraftElectronicVoucher = {
  __typename?: 'UploadDraftElectronicVoucher';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type PayoutInvoice = {
  __typename?: 'PayoutInvoice';
  ok: Scalars['Boolean'];
};

export type BundleInvoiceItems = {
  __typename?: 'BundleInvoiceItems';
  invoice: InvoiceType;
};

export type PayoutInvoiceOffline = {
  __typename?: 'PayoutInvoiceOffline';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DownloadInvoicePayoutCep = {
  __typename?: 'DownloadInvoicePayoutCep';
  ok: Scalars['Boolean'];
};

export type UpdateElectronicVoucher = {
  __typename?: 'UpdateElectronicVoucher';
  electronicVoucher: ElectronicVoucherType;
};

export type ElectronicVoucherInputType = {
  recipientName: Scalars['String'];
  recipientAddressZipcode: Scalars['String'];
  recipientTaxRegime: SatRegime;
  recipientTaxNumber?: InputMaybe<Scalars['String']>;
  recipientUseCase?: InputMaybe<SatInvoiceUseType>;
};

export type RetrySignElectronicVoucher = {
  __typename?: 'RetrySignElectronicVoucher';
  ok: Scalars['Boolean'];
  electronicVoucher: ElectronicVoucherType;
};

export type ManualElectronicVoucherCancellation = {
  __typename?: 'ManualElectronicVoucherCancellation';
  ok: Scalars['Boolean'];
  electronicVoucher: ElectronicVoucherType;
};

export type CancelScheduledPayout = {
  __typename?: 'CancelScheduledPayout';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddCommentToInvoice = {
  __typename?: 'AddCommentToInvoice';
  ok: Scalars['Boolean'];
  invoice: InvoiceType;
};

export type AttemptToSignUsersElectronicVouchers = {
  __typename?: 'AttemptToSignUsersElectronicVouchers';
  ok: Scalars['Boolean'];
};

export type SetCopayInvoiceAsUncollectible = {
  __typename?: 'SetCopayInvoiceAsUncollectible';
  ok: Scalars['Boolean'];
};

export type RemoveInvoiceItemElectronicVoucher = {
  __typename?: 'RemoveInvoiceItemElectronicVoucher';
  ok: Scalars['Boolean'];
};

export type UpdateInvoiceItemsWithElectronicVoucherAmounts = {
  __typename?: 'UpdateInvoiceItemsWithElectronicVoucherAmounts';
  invoice: InvoiceType;
};

export type UpdateIncentiveCodeBanner = {
  __typename?: 'UpdateIncentiveCodeBanner';
  incentiveCode?: Maybe<IncentiveCodeType>;
};

export type CreateHealthPlanAmendment = {
  __typename?: 'CreateHealthPlanAmendment';
  amendment: HealthPlanAmendmentType;
};

export type UpdateHealthPlanAmendment = {
  __typename?: 'UpdateHealthPlanAmendment';
  amendment: HealthPlanAmendmentType;
};

export enum AmendmentCategory {
  Empty = 'EMPTY',
  VoluntaryCancellation = 'VOLUNTARY_CANCELLATION',
  DebtCancellation = 'DEBT_CANCELLATION',
  MemberDataCorrection = 'MEMBER_DATA_CORRECTION',
  ProductParamsCorrection = 'PRODUCT_PARAMS_CORRECTION',
  FraudCancellation = 'FRAUD_CANCELLATION',
  BetaToFreeVideoconsults = 'BETA_TO_FREE_VIDEOCONSULTS',
  SubscriptionAmendment = 'SUBSCRIPTION_AMENDMENT',
  BillingInfoAmendment = 'BILLING_INFO_AMENDMENT',
  MaternityAmendment = 'MATERNITY_AMENDMENT',
  DentalAmendment = 'DENTAL_AMENDMENT',
  IntlCoverageAmendment = 'INTL_COVERAGE_AMENDMENT',
  AddonCancellation = 'ADDON_CANCELLATION',
  CollectiveSubscription = 'COLLECTIVE_SUBSCRIPTION',
  CollectiveAdditionalSubscription = 'COLLECTIVE_ADDITIONAL_SUBSCRIPTION',
  CollectiveDentalAmendment = 'COLLECTIVE_DENTAL_AMENDMENT',
  CollectiveMaternityAmendment = 'COLLECTIVE_MATERNITY_AMENDMENT',
  CollectiveIntlCoverageAmendment = 'COLLECTIVE_INTL_COVERAGE_AMENDMENT',
  CollectivePolicyCancellation = 'COLLECTIVE_POLICY_CANCELLATION',
  CollectiveDebtCancellation = 'COLLECTIVE_DEBT_CANCELLATION',
  CollectiveGroupCancellation = 'COLLECTIVE_GROUP_CANCELLATION',
  CollectiveCertificatesCancellation = 'COLLECTIVE_CERTIFICATES_CANCELLATION',
  CollectiveAddonCancellation = 'COLLECTIVE_ADDON_CANCELLATION'
}

export enum AmendmentReviewResult {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type CreateReissueApplication = {
  __typename?: 'CreateReissueApplication';
  application?: Maybe<HealthPlanApplicationType>;
};

export type CancelHealthPlan = {
  __typename?: 'CancelHealthPlan';
  ok: Scalars['Boolean'];
};

export enum CancelReason {
  Costly = 'COSTLY',
  Errors = 'ERRORS',
  ProblemNotSolved = 'PROBLEM_NOT_SOLVED',
  BadCustomerService = 'BAD_CUSTOMER_SERVICE',
  NotSure = 'NOT_SURE',
  Debt = 'DEBT',
  Fraud = 'FRAUD',
  OutOfArea = 'OUT_OF_AREA',
  JobInsurance = 'JOB_INSURANCE',
  WantsToChange = 'WANTS_TO_CHANGE',
  DidNotLike = 'DID_NOT_LIKE',
  NotUse = 'NOT_USE',
  BusinessCancellation = 'BUSINESS_CANCELLATION',
  BusinessMigration = 'BUSINESS_MIGRATION',
  Reissued = 'REISSUED',
  NotCoveredPreexistence = 'NOT_COVERED_PREEXISTENCE'
}

export type SetConsultType = {
  __typename?: 'SetConsultType';
  ok: Scalars['Boolean'];
};

export type SetClassificationNotes = {
  __typename?: 'SetClassificationNotes';
  ok: Scalars['Boolean'];
};

export type AuthorizeInPersonConsult = {
  __typename?: 'AuthorizeInPersonConsult';
  ok: Scalars['Boolean'];
  couldNotAuthorize?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CreatePrescription = {
  __typename?: 'CreatePrescription';
  prescription: PrescriptionType;
};

export type CreateOnDemandConsult = {
  __typename?: 'CreateOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CreateOnDemandConsultNew = {
  __typename?: 'CreateOnDemandConsultNew';
  onDemandConsult: OnDemandConsultType;
};

export type AddReasonOnDemandConsult = {
  __typename?: 'AddReasonOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type ClaimOnDemandConsult = {
  __typename?: 'ClaimOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StartOnDemandConsult = {
  __typename?: 'StartOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StopOnDemandConsult = {
  __typename?: 'StopOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CancelOnDemandConsult = {
  __typename?: 'CancelOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type CloseOnDemandConsult = {
  __typename?: 'CloseOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type SetReadyAgainOnDemandConsult = {
  __typename?: 'SetReadyAgainOnDemandConsult';
  onDemandConsult: OnDemandConsultType;
};

export type StartCallConsult = {
  __typename?: 'StartCallConsult';
  onDemandConsult: OnDemandConsultType;
};

export type EndCallConsult = {
  __typename?: 'EndCallConsult';
  onDemandConsult: OnDemandConsultType;
};

export type WrapUpConsult = {
  __typename?: 'WrapUpConsult';
  ok: Scalars['Boolean'];
};

export type UpdateEmr = {
  __typename?: 'UpdateEmr';
  ok: Scalars['Boolean'];
};

export type SendTextMessage = {
  __typename?: 'SendTextMessage';
  message: MessageType;
};

export type SetMessagesRead = {
  __typename?: 'SetMessagesRead';
  ok: Scalars['Boolean'];
};

export type StartAttachmentMessage = {
  __typename?: 'StartAttachmentMessage';
  message: MessageType;
  uploadData: UploadDataType;
};

export type CompleteAttachmentMessage = {
  __typename?: 'CompleteAttachmentMessage';
  message: MessageType;
};

export type AutoGenerateElectronicVoucherForOnDemandConsults = {
  __typename?: 'AutoGenerateElectronicVoucherForOnDemandConsults';
  results?: Maybe<Scalars['JSONString']>;
};

export type DoctorOnDemandConsultHoursInputType = {
  doctorId: Scalars['Int'];
  weekdayHours: Scalars['Int'];
  weekendHours: Scalars['Int'];
};

export type AutoGenerateElectronicVoucherForInPersonConsults = {
  __typename?: 'AutoGenerateElectronicVoucherForInPersonConsults';
  ok: Scalars['Boolean'];
};

export type RequestMedicinePostOnDemandConsult = {
  __typename?: 'RequestMedicinePostOnDemandConsult';
  ok: Scalars['Boolean'];
};

export type UpdatePrescriptionMemberChecks = {
  __typename?: 'UpdatePrescriptionMemberChecks';
  prescription: PrescriptionType;
};

export type ChargeInPersonConsult = {
  __typename?: 'ChargeInPersonConsult';
  inPersonConsult: InPersonConsultType;
};

export type CreateCalendarEvent = {
  __typename?: 'CreateCalendarEvent';
  created: Scalars['Boolean'];
};

export type UpdateCalendarEvent = {
  __typename?: 'UpdateCalendarEvent';
  event: Scalars['JSONString'];
};

export type DeleteCalendarEvent = {
  __typename?: 'DeleteCalendarEvent';
  deleted: Scalars['Boolean'];
};

export type CreateEmptyScheduledConsultRequest = {
  __typename?: 'CreateEmptyScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type CreateScheduledConsultRequest = {
  __typename?: 'CreateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type CreateScheduledConsult = {
  __typename?: 'CreateScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type ScheduleConsult = {
  __typename?: 'ScheduleConsult';
  scheduledConsult: ScheduledConsultType;
};

export type ConfirmScheduledConsult = {
  __typename?: 'ConfirmScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type CancelScheduledConsult = {
  __typename?: 'CancelScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type UpdateScheduledConsultRequest = {
  __typename?: 'UpdateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type MemberCancelScheduledConsult = {
  __typename?: 'MemberCancelScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type MemberUpdateScheduledConsult = {
  __typename?: 'MemberUpdateScheduledConsult';
  scheduledConsult: ScheduledConsultType;
};

export type MemberUpdateScheduledConsultRequest = {
  __typename?: 'MemberUpdateScheduledConsultRequest';
  scheduledConsult: ScheduledConsultType;
};

export type ReviewComplianceAlert = {
  __typename?: 'ReviewComplianceAlert';
  alert: ComplianceAlertType;
};

export type SendAmlReport = {
  __typename?: 'SendAMLReport';
  sent?: Maybe<Scalars['Boolean']>;
};

export enum ComplianceReportType {
  Relevant = 'RELEVANT',
  Unusual = 'UNUSUAL',
  Concerning = 'CONCERNING',
  PldFile = 'PLD_FILE',
  Alerts = 'ALERTS',
  File = 'FILE'
}

export type DoctorCreateDiseaseCase = {
  __typename?: 'DoctorCreateDiseaseCase';
  diseaseCase: LimitedDiseaseCaseType;
};

export type CreateDiseaseCase = {
  __typename?: 'CreateDiseaseCase';
  diseaseCase: DiseaseCaseType;
};

export type UpdateDiseaseCase = {
  __typename?: 'UpdateDiseaseCase';
  diseaseCase: DiseaseCaseType;
};

export type CreateService = {
  __typename?: 'CreateService';
  service?: Maybe<ServicesType>;
};

export type MemberCreateService = {
  __typename?: 'MemberCreateService';
  serviceId: Scalars['ID'];
};

export enum MemberCreateServiceTypesEnum {
  ExtVideoConsult = 'EXT_VIDEO_CONSULT',
  ExternalConsult = 'EXTERNAL_CONSULT',
  HospitalService = 'HOSPITAL_SERVICE',
  LabStudies = 'LAB_STUDIES',
  Medicines = 'MEDICINES',
  Urgency = 'URGENCY'
}

export type CreatePrescriptionService = {
  __typename?: 'CreatePrescriptionService';
  serviceId: Scalars['ID'];
};

export type UpdateService = {
  __typename?: 'UpdateService';
  service?: Maybe<ServicesType>;
};

export type UpdateServiceDoctor = {
  __typename?: 'UpdateServiceDoctor';
  service?: Maybe<ServicesType>;
};

export type UpdateServiceDoctorFriend = {
  __typename?: 'UpdateServiceDoctorFriend';
  service?: Maybe<ServicesType>;
};

export type DeleteService = {
  __typename?: 'DeleteService';
  ok: Scalars['Boolean'];
};

export type UpdateServiceCoverageRuling = {
  __typename?: 'UpdateServiceCoverageRuling';
  serviceCoverageRuling: ServicesCoverageRulingType;
};

export enum RulingDeclinationReasons {
  MemberIdentity = 'MEMBER_IDENTITY',
  HealthPlanTerm = 'HEALTH_PLAN_TERM',
  PreExistingConditions = 'PRE_EXISTING_CONDITIONS',
  Exclusions = 'EXCLUSIONS',
  WaitingTimes = 'WAITING_TIMES',
  NoMedicalNeed = 'NO_MEDICAL_NEED',
  OutOfNetwork = 'OUT_OF_NETWORK',
  InsufficientCoverageAmount = 'INSUFFICIENT_COVERAGE_AMOUNT',
  InconsistentPrescriptionInvoice = 'INCONSISTENT_PRESCRIPTION_INVOICE'
}

export type UpdateServiceCoverageRulingState = {
  __typename?: 'UpdateServiceCoverageRulingState';
  serviceCoverageRuling: ServicesCoverageRulingType;
};

export type UpdateClaimRulingCheck = {
  __typename?: 'UpdateClaimRulingCheck';
  claimRulingCheck: ClaimRulingChecksType;
};

export type AcceptAllRulingChecks = {
  __typename?: 'AcceptAllRulingChecks';
  claimRulingChecks: Array<ClaimRulingChecksType>;
};

export type GroupServicesOnDiseaseCase = {
  __typename?: 'GroupServicesOnDiseaseCase';
  ok: Scalars['Boolean'];
};

export type CreateEvidence = {
  __typename?: 'CreateEvidence';
  evidence: EvidenceType;
};

export enum TypeformReportTypes {
  MemberDisease = 'MemberDisease',
  MemberPostService = 'MemberPostService',
  MemberPostServiceShort = 'MemberPostServiceShort',
  MemberPostServiceShortSofiaForm = 'MemberPostServiceShortSofiaForm',
  OtherProvider = 'OtherProvider',
  HospitalService = 'HospitalService',
  DoctorFullProcedure = 'DoctorFullProcedure',
  DoctorOfficeService = 'DoctorOfficeService',
  DoctorPostService = 'DoctorPostService',
  DoctorFullHospitalService = 'DoctorFullHospitalService',
  DoctorPreService = 'DoctorPreService',
  DoctorPostHospitalService = 'DoctorPostHospitalService',
  DoctorInterconsultant = 'DoctorInterconsultant',
  MemberDiseaseDev = 'MemberDiseaseDev',
  MemberPostServiceDev = 'MemberPostServiceDev',
  MemberPostServiceShortDev = 'MemberPostServiceShortDev',
  MemberPostServiceShortSofiaFormDev = 'MemberPostServiceShortSofiaFormDev',
  OtherProviderDev = 'OtherProviderDev',
  HospitalServiceDev = 'HospitalServiceDev',
  DoctorFullProcedureDev = 'DoctorFullProcedureDev',
  DoctorOfficeServiceDev = 'DoctorOfficeServiceDev',
  DoctorPostServiceDev = 'DoctorPostServiceDev',
  DoctorFullHospitalServiceDev = 'DoctorFullHospitalServiceDev',
  DoctorPreServiceDev = 'DoctorPreServiceDev',
  DoctorPostHospitalServiceDev = 'DoctorPostHospitalServiceDev',
  DoctorInterconsultantDev = 'DoctorInterconsultantDev',
  Prescription = 'Prescription'
}

export type CompleteShortReimbursementSofiaForm = {
  __typename?: 'CompleteShortReimbursementSofiaForm';
  ok: Scalars['Boolean'];
};

export type EvidencesReviewed = {
  __typename?: 'EvidencesReviewed';
  ok: Scalars['Boolean'];
};

export type RequestMoreEvidences = {
  __typename?: 'RequestMoreEvidences';
  ok: Scalars['Boolean'];
};

export type CreateMemberReimbursement = {
  __typename?: 'CreateMemberReimbursement';
  service?: Maybe<LimitedServicesType>;
  evidence: LimitedEvidenceType;
};

export type LimitedEvidenceType = {
  __typename?: 'LimitedEvidenceType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  reportType: TypeformTokenReportType;
  typeformEventId?: Maybe<Scalars['String']>;
  typeformResponse?: Maybe<Scalars['JSONString']>;
  createdBy: UserType;
  member: MemberType;
  doctor?: Maybe<DoctorProfileType>;
  provider?: Maybe<ProviderBranchType>;
  state: TypeformTokenState;
  generatedFormLink: Scalars['String'];
  formLink?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['JSONString']>;
  attachments?: Maybe<Array<Maybe<DocumentType>>>;
  serviceTypeValue?: Maybe<Scalars['String']>;
};

export type CreatePreapprovedAmount = {
  __typename?: 'CreatePreapprovedAmount';
  preapprovedAmount: PreapprovedAmountHistoryType;
};

export enum Currency {
  MexicanPeso = 'MEXICAN_PESO',
  UnitedStatesDollar = 'UNITED_STATES_DOLLAR',
  None = 'NONE'
}

export type UpdateOrCreateServiceCoveragePayout = {
  __typename?: 'UpdateOrCreateServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type CreateServiceCoveragePayoutInvoice = {
  __typename?: 'CreateServiceCoveragePayoutInvoice';
  payoutRequest: ServiceCoveragePayoutType;
};

export type SetAmountsOnServiceCoveragePayout = {
  __typename?: 'SetAmountsOnServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
};

export type ApproveServiceCoveragePayout = {
  __typename?: 'ApproveServiceCoveragePayout';
  payoutRequest: ServiceCoveragePayoutType;
  remarks?: Maybe<Scalars['String']>;
};

export type RequestServicePayIn = {
  __typename?: 'RequestServicePayIn';
  payInRequest: ServicePayInRequestType;
};

export type CreateCaseEvent = {
  __typename?: 'CreateCaseEvent';
  caseEvent: CaseEventType;
};

export type DeleteCaseEvent = {
  __typename?: 'DeleteCaseEvent';
  ok: Scalars['Boolean'];
};

export type GroupServicesOnCaseEvent = {
  __typename?: 'GroupServicesOnCaseEvent';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type UpdateCaseEvent = {
  __typename?: 'UpdateCaseEvent';
  ok: Scalars['Boolean'];
  caseEvent: CaseEventType;
};

export type RemoveServicesFromCaseEvent = {
  __typename?: 'RemoveServicesFromCaseEvent';
  ok: Scalars['Boolean'];
};

export type UploadDataForClaimDocument = {
  __typename?: 'UploadDataForClaimDocument';
  uploadData: UploadDataType;
};

export enum EvidenceDescription {
  CfdiPdf = 'CFDI_PDF',
  CfdiXml = 'CFDI_XML',
  LabResult = 'LAB_RESULT'
}

export type CreateServiceElectronicVoucherItems = {
  __typename?: 'CreateServiceElectronicVoucherItems';
  serviceElectronicVoucherItems?: Maybe<Array<ServiceElectronicVoucherItemType>>;
};

export type SetAcceptedElectronicVoucherItems = {
  __typename?: 'SetAcceptedElectronicVoucherItems';
  ok: Scalars['Boolean'];
};

export type BundleEventInvoiceItems = {
  __typename?: 'BundleEventInvoiceItems';
  invoice: InvoiceType;
};

export type CancelService = {
  __typename?: 'CancelService';
  service?: Maybe<ServicesType>;
};

export type BusinessToken = {
  __typename?: 'BusinessToken';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
  payload: Scalars['GenericScalar'];
};

export type CreateBusiness = {
  __typename?: 'CreateBusiness';
  business: BusinessType;
};

export type CreateBusinessDraft = {
  __typename?: 'CreateBusinessDraft';
  business: BusinessType;
};

export type UpdateBusiness = {
  __typename?: 'UpdateBusiness';
  business: BusinessType;
};

export type UpdateBusinessLimited = {
  __typename?: 'UpdateBusinessLimited';
  business: BusinessType;
};

export type UpdateOrCreateBillingInfo = {
  __typename?: 'UpdateOrCreateBillingInfo';
  business: BusinessType;
};

export type UpdateBusinessPaymentInformation = {
  __typename?: 'UpdateBusinessPaymentInformation';
  business: BusinessType;
};

export type RetriggerBusinessChecks = {
  __typename?: 'RetriggerBusinessChecks';
  ok: Scalars['Boolean'];
};

export type RequestBusinessDocumentUploadData = {
  __typename?: 'RequestBusinessDocumentUploadData';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type ApproveBusinessDocumentCheck = {
  __typename?: 'ApproveBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export type ToggleJustifiableAbsenceForBusinessDocumentCheck = {
  __typename?: 'ToggleJustifiableAbsenceForBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export enum BusinessCheckType {
  NotBanned = 'NOT_BANNED',
  NotRestricted = 'NOT_RESTRICTED',
  RiskLevel = 'RISK_LEVEL',
  AddressProof = 'ADDRESS_PROOF',
  TaxRegistration = 'TAX_REGISTRATION',
  DigitalTaxSignature = 'DIGITAL_TAX_SIGNATURE',
  TradeRegistration = 'TRADE_REGISTRATION',
  RegistrationToCommercePublicRegistry = 'REGISTRATION_TO_COMMERCE_PUBLIC_REGISTRY',
  ContractStatus = 'CONTRACT_STATUS'
}

export type SetAbsenceJustificationForBusinessDocumentCheck = {
  __typename?: 'SetAbsenceJustificationForBusinessDocumentCheck';
  ok: Scalars['Boolean'];
};

export type TransitionBusinessState = {
  __typename?: 'TransitionBusinessState';
  ok: Scalars['Boolean'];
};

export type ContractUpdateStateDate = {
  __typename?: 'ContractUpdateStateDate';
  ok: Scalars['Boolean'];
};

export type CreateBusinessStakeholder = {
  __typename?: 'CreateBusinessStakeholder';
  stakeholder: BusinessStakeholderType;
};

export type UpdateBusinessStakeholder = {
  __typename?: 'UpdateBusinessStakeholder';
  stakeholder: BusinessStakeholderType;
};

export type UpdateLegalRepresentative = {
  __typename?: 'UpdateLegalRepresentative';
  stakeholder: BusinessStakeholderType;
};

export type RetriggerBusinessStakeholderChecks = {
  __typename?: 'RetriggerBusinessStakeholderChecks';
  ok: Scalars['Boolean'];
};

export type RequestBusinessStakeholderDocumentUploadData = {
  __typename?: 'RequestBusinessStakeholderDocumentUploadData';
  uploadData: UploadDataType;
  document: DocumentType;
};

export type CreateBusinessEmployee = {
  __typename?: 'CreateBusinessEmployee';
  businessEmployee: BusinessEmployeeType;
};

export type CreateApplicationsForBusinessBeneficiary = {
  __typename?: 'CreateApplicationsForBusinessBeneficiary';
  businessEmployee: BusinessEmployeeType;
  familyBusinessBeneficiaries?: Maybe<Array<BusinessEmployeeType>>;
};

export type BusinessBeneficiaryFamilyMemberInputType = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
  relationship: Scalars['String'];
  gender: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthCountry?: InputMaybe<Scalars['String']>;
  curp?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessEmployees = {
  __typename?: 'CreateBusinessEmployees';
  errors?: Maybe<Array<CreateBusinessEmployeesErrorType>>;
  results?: Maybe<Array<BusinessEmployeeType>>;
  total?: Maybe<Scalars['Int']>;
};

export type CreateBusinessEmployeesErrorType = {
  __typename?: 'CreateBusinessEmployeesErrorType';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type CreateBusinessEmployeesInputType = {
  email: Scalars['String'];
  businessGroupConfigName: Scalars['String'];
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
};

export type CreateCollectiveBusinessBeneficiaries = {
  __typename?: 'CreateCollectiveBusinessBeneficiaries';
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CollectiveBusinessBeneficiariesInputType = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstLastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthCountry?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  secondLastName?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
  employeeEmail?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  businessGroupConfigId?: InputMaybe<Scalars['ID']>;
  curp?: InputMaybe<Scalars['String']>;
};

export type CreateCollectiveBusinessBeneficiaryRelatives = {
  __typename?: 'CreateCollectiveBusinessBeneficiaryRelatives';
  errors?: Maybe<Array<CreateBusinessEmployeesErrorType>>;
  results?: Maybe<Array<BusinessEmployeeType>>;
  total?: Maybe<Scalars['Int']>;
};

export type SendBeneficiariesDataToOps = {
  __typename?: 'SendBeneficiariesDataToOps';
  ok: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
};

export type EditBusinessEmployee = {
  __typename?: 'EditBusinessEmployee';
  businessEmployee: BusinessEmployeeType;
};

export type DeleteBusinessEmployee = {
  __typename?: 'DeleteBusinessEmployee';
  ok: Scalars['Boolean'];
};

export type SendBusinessEmployeeWelcomeEmail = {
  __typename?: 'SendBusinessEmployeeWelcomeEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendBusinessAdminWelcomeEmail = {
  __typename?: 'SendBusinessAdminWelcomeEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessPaymentMethod = {
  __typename?: 'CreateBusinessPaymentMethod';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type MakeBusinessPayinPaymentMethodPrimary = {
  __typename?: 'MakeBusinessPayinPaymentMethodPrimary';
  paymentMethod?: Maybe<PaymentMethodsType>;
};

export type DeleteBusinessPaymentMethod = {
  __typename?: 'DeleteBusinessPaymentMethod';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessAdmin = {
  __typename?: 'CreateBusinessAdmin';
  businessAdmin?: Maybe<BusinessAdminType>;
};

export type UpdateBusinessAdmin = {
  __typename?: 'UpdateBusinessAdmin';
  businessAdmin?: Maybe<BusinessAdminType>;
};

export type DeleteBusinessAdmin = {
  __typename?: 'DeleteBusinessAdmin';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MakeBusinessAdminOwner = {
  __typename?: 'MakeBusinessAdminOwner';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessGroupsConfig = {
  __typename?: 'CreateBusinessGroupsConfig';
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
};

export type UpdateBusinessGroupsConfig = {
  __typename?: 'UpdateBusinessGroupsConfig';
  businessGroupsConfig?: Maybe<BusinessGroupsConfigType>;
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  waitingRoomItem: SubscriptionConsultType;
};

export type LoggedUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type LoggedUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserType', id: string } | null };

export type ProvidersAuthMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
}>;


export type ProvidersAuthMutation = { __typename?: 'Mutation', providersToken?: { __typename?: 'ProvidersToken', token: string, refreshToken: string } | null };

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', verifyToken?: { __typename?: 'Verify', payload: any } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'Refresh', token: string, payload: any, refreshExpiresIn: number, refreshToken: string } | null };


export const LoggedUserDocument = gql`
    query LoggedUser($id: Int) {
  user(id: $id) {
    id
  }
}
    `;

/**
 * __useLoggedUserQuery__
 *
 * To run a query within a React component, call `useLoggedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoggedUserQuery(baseOptions?: Apollo.QueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options);
      }
export function useLoggedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedUserQuery, LoggedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedUserQuery, LoggedUserQueryVariables>(LoggedUserDocument, options);
        }
export type LoggedUserQueryHookResult = ReturnType<typeof useLoggedUserQuery>;
export type LoggedUserLazyQueryHookResult = ReturnType<typeof useLoggedUserLazyQuery>;
export type LoggedUserQueryResult = Apollo.QueryResult<LoggedUserQuery, LoggedUserQueryVariables>;
export const ProvidersAuthDocument = gql`
    mutation ProvidersAuth($email: String, $telephone: String, $password: String!) {
  providersToken(email: $email, telephone: $telephone, password: $password) {
    token
    refreshToken
  }
}
    `;
export type ProvidersAuthMutationFn = Apollo.MutationFunction<ProvidersAuthMutation, ProvidersAuthMutationVariables>;

/**
 * __useProvidersAuthMutation__
 *
 * To run a mutation, you first call `useProvidersAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvidersAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providersAuthMutation, { data, loading, error }] = useProvidersAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      telephone: // value for 'telephone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useProvidersAuthMutation(baseOptions?: Apollo.MutationHookOptions<ProvidersAuthMutation, ProvidersAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvidersAuthMutation, ProvidersAuthMutationVariables>(ProvidersAuthDocument, options);
      }
export type ProvidersAuthMutationHookResult = ReturnType<typeof useProvidersAuthMutation>;
export type ProvidersAuthMutationResult = Apollo.MutationResult<ProvidersAuthMutation>;
export type ProvidersAuthMutationOptions = Apollo.BaseMutationOptions<ProvidersAuthMutation, ProvidersAuthMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation VerifyToken($token: String!) {
  verifyToken(token: $token) {
    payload
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    payload
    refreshExpiresIn
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;