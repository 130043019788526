import { Card, Descriptions, Tag } from "antd";
import { getFormattedDate } from "library/utils/date";
import { getMemberName } from "library/utils/fields";
import { valueOrUndefined } from "library/utils/valueOrUndefined";
import React from "react";
import { LimitedMemberType } from "types/schema";
import MemberSearchResult from "./MemberSearchResult";
import { ResultMemberState } from "./MemberSearchState";

interface MemberCardProps {
  member: LimitedMemberType;
  stateColor: string;
  stateText: string;
  resultText: string;
  resultState: ResultMemberState;
}

const MemberCardInfo: React.FC<MemberCardProps> = ({
  member,
  stateColor,
  stateText,
  resultText,
  resultState,
}) => {
  return (
    <Card>
      <Descriptions layout="vertical" column={4} bordered>
        <Descriptions.Item label="Nombre">
          {getMemberName(member)}
        </Descriptions.Item>

        <Descriptions.Item label="UUID">
          {member.healthPlanUuid}
        </Descriptions.Item>

        <Descriptions.Item label="Fecha de nacimiento">
          {valueOrUndefined(member.dateOfBirth, date => getFormattedDate(date))}
        </Descriptions.Item>

        <Descriptions.Item label="Estado de póliza">
          <Tag color={stateColor}>{stateText}</Tag>
        </Descriptions.Item>
      </Descriptions>
      <MemberSearchResult status={resultState} title={resultText} />
    </Card>
  );
};

export default MemberCardInfo;
