import { ApolloError, isApolloError } from "@apollo/client";
import isArray from "lodash/isArray";
import upperFirst from "lodash/upperFirst";
import strings from "res/strings";

const getErrorFromArray = (collection: Array<any>, index: number) => {
  const item = collection[index];
  if (item && item.message) {
    return upperFirst(item.message);
  }
};

export const includesError = (error: ApolloError, message: string) => {
  return error.graphQLErrors.map(({ message }) => message).includes(message);
};

export const getErrorMsg = (error: Error) => {
  const { empty } = strings.errors;

  if (isApolloError(error)) {
    const { graphQLErrors, networkError } = error;
    if (networkError) {
      return {
        title: strings.errors.networkError,
        content: networkError.message,
      };
    }

    if (isArray(graphQLErrors) && graphQLErrors.length) {
      return {
        title: "Graphql Error",
        content: getErrorFromArray(graphQLErrors, 0) || empty,
      };
    }
  }

  return {
    title: "Error",
    content: error.message || empty,
  };
};

export default getErrorMsg;
