import { message, Modal } from "antd";
import Loader from "stores/Loader";

interface AfterQueryActionOpts {
  errorTitle?: string;
  errorContent?: string;
}

interface AfterMutationActionsOpts extends AfterQueryActionOpts {
  successContent?: string;
}

const displayErrorModal = (loader: Loader, opts?: AfterQueryActionOpts) => {
  const { errorMessage } = loader;
  Modal.warning({
    title: opts?.errorTitle || errorMessage?.title,
    content: opts?.errorContent || errorMessage?.content,
  });
};

export const afterQueryActions = (
  loader: Loader,
  opts?: AfterQueryActionOpts,
) => {
  if (loader.hasError) {
    displayErrorModal(loader, opts);
  }
};

export const afterMutationActions = (
  loader: Loader,
  opts?: AfterMutationActionsOpts,
) => {
  if (loader.hasError) {
    displayErrorModal(loader, opts);
  } else {
    message.success(opts?.successContent || "Enviado");
  }
};
