export default {
  months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"],
  globals: {
    undef: "Indefinido",
  },
  errors: {
    empty: "Error sin mensaje",
    generic: "Ocurrió un error",
    networkError: "Error de red",
  },
};
