import { Card as AntCard, Button as AntButton } from "antd";
import styled from "styled-components";

export const SearchCard: typeof AntCard = styled(AntCard)`
  margin-bottom: 16px !important;
` as any;

export const Container = styled("div")`
  display: flex;
  width: 100%;
  max-width: 600px;
`;

export const InputContainer = styled("div")`
  flex-grow: 1;
  padding-right: 16px;
`;

export const SearchButton: typeof AntButton = styled(AntButton)`
  margin-right: 8px;
`;
