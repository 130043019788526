import React from "react";
import { Route } from "react-router-dom";
import isFunction from "lodash/isFunction";

/**
 * @todo - Type properly
 * @see {@link https://bit.ly/2Mj3X2f} Route config example
 */
const RouteWithSubRoutes = (route: any) => {
  return (
    <Route
      path={route.path}
      render={props => {
        if (isFunction(route.component)) {
          // pass the sub-routes down to keep nesting
          return <route.component {...props} routes={route.routes} />;
        }
      }}
    />
  );
};

export default RouteWithSubRoutes;
