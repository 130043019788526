import { Layout, Menu as AntMenu } from "antd";
import { APP_MENU_THEME, SCREEN_XL } from "config/style";
import styled from "styled-components";

const backgroundColor = APP_MENU_THEME === "dark" ? "#5e32d2" : "#fff";
const { SubMenu: AntSubMenu, ItemGroup: AntItemGroup, Item: AntItem } = AntMenu;

export const LogoContainer = styled.div`
  padding: 16px 24px;
`;

export const Sider: typeof Layout.Sider = styled(Layout.Sider)`
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  @media all and (min-width: ${SCREEN_XL}px) {
    position: relative;
    min-height: 100vh;
    overflow: scroll;
    height: auto;
  }
` as any;

export const SiderContainer: typeof Layout.Content = styled(Layout.Content)`
  position: fixed;
  left: 0;
  height: 100vh;
  overflow: auto;
` as any;

export const Menu: typeof AntMenu = styled(AntMenu)`
  margin: 16px 0;
` as any;

export const SubMenu: typeof AntSubMenu = styled(AntSubMenu)`
  background: ${backgroundColor};
` as any;

export const ItemGroup: typeof AntItemGroup = styled(AntItemGroup)`
  background: ${backgroundColor};

  .ant-menu-item-group-title {
    display: none;
  }
` as any;

export const Item: typeof AntItem = styled(AntItem)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &:hover {
    color: #ffffff;
  }
` as any;
